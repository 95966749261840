import React, { useState } from "react";
import { useEffect } from "react";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  addImage,
  crossEditImage,
  editWhiteImage,
  subtractImage,
} from "../../../../../../assets";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getMovers, time_convert } from "../../../../../../ui_libs/Functions/functions";
import EditRequest from "../Edit-Request/EditRequest";

function DirectRequestAccepted(props) {
  const { requestData, fetchRequestDetails, bidCancel, setIsLoadingPage } =
    props;

  const {
    customer_budget,
    recommend_amount,
    id,
    s_time,
    final_amount,
    s_date,
    movers,
    request_timeline_status,
    card_payment_rate,
    cash_payment_rate,
    request_type,
    rate_method,
    cash_max_flat_rate,
    cash_min_flat_rate,
    card_max_flat_rate,
    card_min_flat_rate,
  } = requestData.request;

  const { sale_team, truck_array } = requestData;

  //animation
  const animatedComponents = makeAnimated();

  // to store foremans and truck list fr om api

  const [foremanList, setForemanList] = useState([]);
  const [salesmanList, setSalesmanList] = useState([]);
  const [truckList, setTruckList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = React.useState("");
  const [warning, setWarning] = useState("");

  const [isLoadingTrucks, setIsLoadingTruck] = useState(true);
  const [isLoadingForeman, setIsLoadingForeman] = useState(true);
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(true);

  const [selectedTruck, setSelectedTruck] = useState([]);
  const [selectedForeman, setSelectedForeman] = useState("");
  const [selectedSalesman, setSelectedSalesman] = useState("");

  //handle change functions
  const handleTruck = (e) => {
    setSelectedTruck(e);
  };

  const handleForeman = (e) => {
    setSelectedForeman(e);
  };

  const handleSalesman = (e) => {
    setSelectedSalesman(e);
  };
  ////

  //fetch and refresh foreman list data from api

  const fetchForemanList = () => {
    let requestObj = {
      path: "/api/company/get_foreman",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        let temp = res.data.allForeman.map((foreman) => {
          return {
            value: foreman.user_model.id,
            label: foreman.first_name + " " + foreman.last_name,
            color: "#c4314b",
          };
        });
        setForemanList(temp);
        setIsLoadingForeman(false);
      }
    });
  };

  //fetch and refresh Salesman list data from api

  const fetchSalesmanList = () => {
    let requestObj = {
      path: "/api/company/get_sale_team",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        let temp = res.sale_team.map((salesman) => {
          return {
            value: salesman.user_model.id,
            label: salesman.first_name + " " + salesman.last_name,
            color: "#c4314b",
          };
        });

        setSalesmanList(temp);

        setIsLoadingSalesman(false);

        const salesman = {
          value: sale_team.user_id,
          label: sale_team.first_name + " " + sale_team.last_name,
        };
        if (sale_team) {
          setSelectedSalesman(salesman);
        }
      }
    });
  };

  //fetch and refresh truck list data from api

  const fetchTruckList = () => {
    let requestObj = {
      path: "/api/company/get_truck",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        let temp = res.data.allTruck.map((truck) => {
          return {
            value: truck.id,
            label: truck.name,
            color: "#c4314b",
          };
        });
        console.log(temp, "TEMP");

        setTruckList(temp);
        setIsLoadingTruck(false);
        const truck = truck_array.map((tempTruck) => {
          return { value: tempTruck.id, label: tempTruck.name };
        });
        if (truck_array) {
          setSelectedTruck(truck);
        }
      }
    });
  };

  const assignForeman = (req_id, foreman) => {
    let requestObj = {
      path: "/api/company/add_foreman_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${req_id}`,
      foreman_id: `${foreman.value}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        assignTrucks(id, selectedTruck);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const assignSalesman = (req_id, salesman) => {
    let requestObj = {
      path: "/api/company/add_sale_team_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${req_id}`,
      sale_team_id: `${salesman ? salesman.value : ""}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        fetchRequestDetails();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };
  const assignTrucks = (req_id, trucks) => {
    let requestObj = {
      path: "/api/company/add_truck_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let truckIds = trucks.map((truck) => {
      return { id: `${truck.value}` };
    });
    let postData = {
      request_id: `${req_id}`,
      truck_id_array: truckIds,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        assignSalesman(id, selectedSalesman);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const handleClick = () => {
    if (selectedTruck.length === 0) {
      setErrMessage("Please select trucks");
      return;
    }
    if (selectedForeman === "") {
      setErrMessage("Please select foreman");
      return;
    }
    setErrMessage("");
    setIsLoading(true);
    assignForeman(id, selectedForeman);
  };

  useEffect(() => {
    fetchForemanList();
    fetchTruckList();
    fetchSalesmanList();
  }, []);

  //for handle edit

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setErrMessage("");
  }, [isEdit]);

  if (isEdit) {
    return (
      <EditRequest
        requestData={requestData}
        fetchRequestDetails={fetchRequestDetails}
        setIsLoadingPage={setIsLoadingPage}
        setIsEdit={setIsEdit}
      />
    );
  }
  //for handle edit end
  return (
    <>
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <h6 className="text-white pt-1">Request #{id}</h6>
            </div>
            <div className="col-2 text-right">
              <img
                className="pl-1 pointer-point"
                src={editWhiteImage}
                onClick={() => setIsEdit(true)}
              ></img>
            </div>
          </div>
        </div>
        {errMessage && (
          <Alert variant="filled" severity="error">
            {errMessage}
          </Alert>
        )}
        {warning && (
          <Alert variant="filled" severity="warning">
            {warning}
          </Alert>
        )}
        <div className="request-left-box p-3 p-sm-4">
          <div className="row">
            <div className="col-8">
              <div className="request-staus small-font-size">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#C4314B" />
                </svg>{" "}
                {requestData.additional_status === 1
                  ? "Direct Request Accepted"
                  : "Awaiting Customer Response"}
              </div>
            </div>
            <div className="col-4 text-right">
              <a
                href=""
                className="small-text-bold red-color add-new-btn"
                data-toggle="modal"
                data-target="#cancel-request"
              >
                <span>Cancel</span>
              </a>
            </div>
          </div>
          <div className="bid-form-wrapper pt-3">
            <div className="row small-font-size">
              <div className="col-6 text-left dark-grey-color">Start time:</div>
              <div className="col-6 text-right black-color">
              {/* {`${time_convert(s_time.slice(0,5))} to ${time_convert(s_time.slice(8,13))}`} */}
              {s_time && s_time !== "8AM - 12PM Morning" &&
                    s_time !== "12PM - 5PM Evening" &&
                    s_time !== "Entire day" &&
                    `${time_convert(
                      s_time.split('-')[0].trim()
                    )} to ${time_convert(s_time.split('-')[1].trim())}`}
                  {s_time && (s_time == "8AM - 12PM Morning" ||
                    s_time == "12PM - 5PM Evening" ||
                    s_time == "Entire day") && `${s_time}`}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Movers:</div>
              <div className="col-6 text-right black-color">
                {getMovers(requestData)}
              </div>
            </div>
            {/* Hourly rate details */}
            {requestData.request.rate_method === "Hourly" && (
              <>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Cash Hour Rate:
                  </div>
                  <div className="col-6 text-right black-color">
                    ${requestData.request.cash_payment_rate}
                  </div>
                </div>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Card Hour Rate:
                  </div>
                  <div className="col-6 text-right black-color">
                    ${requestData.request.card_payment_rate}
                  </div>
                </div>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Minimum Hours:
                  </div>
                  <div className="col-6 text-right black-color">
                    {requestData.request.minimum_hours}
                  </div>
                </div>
              </>
            )}
            {/* Flat rate details */}
            {requestData.request.rate_method === "Flat" && (
              <>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Cash Flat Rate:
                  </div>
                  <div className="col-6 text-right black-color">
                    ${requestData.request.cash_min_flat_rate} - $
                    {requestData.request.cash_max_flat_rate}
                  </div>
                </div>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Card Flat Rate:
                  </div>
                  <div className="col-6 text-right black-color">
                    ${requestData.request.card_min_flat_rate} - $
                    {requestData.request.card_max_flat_rate}
                  </div>
                </div>
              </>
            )}

            {requestData.request.customer_phone_number && (
              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color">
                  Customer Phone Number:
                </div>
                <div className="col-6 text-right black-color">
                  {requestData.request.customer_phone_number}
                </div>
              </div>
            )}

            {requestData.sale_team && (
              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color">
                  Salesman Name:
                </div>
                <div className="col-6 text-right light-red-color">
                  {requestData.sale_team?.first_name +
                    " " +
                    requestData.sale_team?.last_name}
                </div>
              </div>
            )}

            <div className="row text-center pt-3">
              <div className="col-12 caption">
                {requestData.additional_status === 1
                  ? "Add truck and foreman to start the move."
                  : "You can add truck and foreman after customer response."}
              </div>
            </div>
            {requestData.additional_status === 1 && (
              <div className="add-info-wrapper pt-3">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label
                        htmlFor="from-time"
                        className="overline dark-grey-color"
                      >
                        choose Trucks Names
                      </label>
                      <Select
                        value={selectedTruck}
                        isMulti
                        components={animatedComponents}
                        name="name"
                        options={truckList}
                        isLoading={isLoadingTrucks}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleTruck}
                        isDisabled={isLoading}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label
                        htmlFor="from-time"
                        className="overline dark-grey-color"
                      >
                        choose foreman Name
                      </label>

                      <Select
                        options={foremanList}
                        isLoading={isLoadingForeman}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleForeman}
                        isDisabled={isLoading}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label
                        htmlFor="from-time"
                        className="overline dark-grey-color"
                      >
                        choose Salesman Name
                      </label>

                      <Select
                        value={selectedSalesman}
                        options={salesmanList}
                        isLoading={isLoadingSalesman}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleSalesman}
                        isDisabled={isLoading}
                        isClearable
                      />
                    </div>
                  </div>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-12">
                    {isLoading ? (
                      <div className="text-center p-1" style={{ margin: 2 }}>
                        <CircularProgress />
                      </div>
                    ) : (
                      <button
                        className="custom-buton custom-buton-large w-100"
                        data-toggle="modal"
                        data-target="#bid-placed"
                        type="button"
                        onClick={handleClick}
                      >
                        Add info
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- Bid Placed --> */}
      <div
        className="modal fade"
        id="cancel-request"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancel-request-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-bid-cancel"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0 text-center">
                <h5 className="text-center" id="cancel-request-title">
                  Cancel request
                </h5>
                <p className="pt-3">
                  Are you sure you want to cancel the request?
                </p>
                {!request_type && (
                  <p className="text-left light-grey-color caption">
                    When cancelling request, you will be charged an additional
                    fee 50%.
                  </p>
                )}
                <div className="buttons-wrapper pt-3">
                  <div className="row justify-content-center text-center">
                    <div className="col-6">
                      <button
                        onClick={bidCancel}
                        className="custom-btn-small custom-buton float-right"
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        onClick={() =>
                          document.getElementById("close-bid-cancel").click()
                        }
                        className="custom-btn-small custom-buton custom-button-pink float-left"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DirectRequestAccepted;
