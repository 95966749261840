import { CircularProgress, TablePagination } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  base_uri,
  DirectLinkUrl,
  invokeApi,
} from "../../../../bl_libs/invokeApi";
import moment from "moment";
import Axios from "axios";
import ActiveFilter from "./ActiveFilter";
import { filterImage } from "../../../../assets";
import ShareLink from "../Share-Link/ShareLink";
import ShareIcon from "@material-ui/icons/Share";
import { CopyToClipboard, RequestInfo } from "../../../../components";
import { getStatusForCompany } from "../../../../ui_libs/Functions/functions";
import { Icon } from "@iconify/react";
import FileSaver from "file-saver";
import TableHead from "../../../../components/TableHead/TableHead";
function ActiveRequests(props) {
  const [requests, SetRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilter, setIsFilter] = useState(
    localStorage.getItem("directReqActiveFilter")
      ? true
      : localStorage.getItem("directReqActiveSort")
      ? true
      : false
  );
  const [isExport, setIsExport] = useState("0");
  const [subscribtion, setSubscribtion] = useState("");
  const { company_direct_access_link } = JSON.parse(
    localStorage.getItem("user")
  );

  //filter state management
  const [filters, setFilters] = useState(
    localStorage.getItem("directReqActiveFilter")
      ? JSON.parse(localStorage.getItem("directReqActiveFilter"))
      : {
          from_date: "",
          to_date: "",
          schedule: "",
          distance: "",
          move_type: "",
          truck_name: "",
          sort_by: localStorage.getItem("directReqActiveSort")
            ? JSON.parse(localStorage.getItem("directReqActiveSort")).sort_by
            : "id",
          sorting_way: localStorage.getItem("directReqActiveSort")
            ? JSON.parse(localStorage.getItem("directReqActiveSort"))
                .sorting_way
            : "DESC",
          request_timeline_status: props.status == "3.1" ? "3" : props.status,
          confirmation_letter:
            props.status == "3.1" ? "1" : props.status == "3" ? "0" : "",
          date_range: "custom",
        }
  );
  //------------------------------------------>  pagination settings
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);
  //----------------------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  ////////////////////////////////////////////////////

  const handleExportData = () => {
    setIsExport(() => "1");
  };

  const handleClearFilter = () => {
    setFilters({
      from_date: "",
      to_date: "",
      schedule: "",
      distance: "",
      move_type: "",
      truck_name: "",
      sort_by: localStorage.getItem("directReqActiveSort")
        ? JSON.parse(localStorage.getItem("directReqActiveSort")).sort_by
        : "id",
      sorting_way: localStorage.getItem("directReqActiveSort")
        ? JSON.parse(localStorage.getItem("directReqActiveSort")).sorting_way
        : "DESC",
      request_timeline_status: props.status == "3.1" ? "3" : props.status,
      confirmation_letter:
        props.status == "3.1" ? "1" : props.status == "3" ? "0" : "",
      date_range: "custom",
    });
  };

  const handleDownloadFile = async (path) => {
    fetch(`${base_uri}/api/company/get_excel/${path}`).then((response) => {
      response.blob().then(async (blob) => {
        FileSaver.saveAs(blob, path);
      });
    });
  };

  const fetchActiveRequests = (type) => {
    let requestObj = {
      path: `/api/company/get_company_request_for_direct_access?page=${page}&limit=${rowsPerPage}`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {};
    if (type === "filter") {
      setIsFilter(true);
      postData = {
        request_status: "0",
        filter_status: "0",
        from_date:
          filters.from_date === ""
            ? ""
            : moment(filters.from_date).format("MM-DD-YYYY"),
        to_date:
          filters.to_date === ""
            ? ""
            : moment(filters.to_date).format("MM-DD-YYYY"),
        time: filters.schedule,
        min_distance:
          filters.distance === "" ? "" : filters.distance.split("-")[0],
        max_distance:
          filters.distance === "" ? "" : filters.distance.split("-")[1],
        move_type: filters.move_type,
        truck_name: filters.truck_name,
        foreman_name: "",
        sort_by: filters.sort_by,
        sorting_way: filters.sorting_way,
        request_timeline_status: props.status == "3.1" ? "3" : props.status,
        confirmation_letter:
          props.status == "3.1" ? "1" : props.status == "3" ? "0" : "",
        export_status: isExport,
      };
    } else {
      setIsFilter(false);

      //if type is undefined
      //we are not passing filters in API
      postData = {
        request_status: "0",
        export_status: isExport,
      };
    }

    console.log(postData, "POSTDATA");
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        if (isExport === "0") {
          props.setCounts({
            ...props.counts,
            activeCount: res.request_count.active_request_count,
            doneCount: res.request_count.done_request_count,
            cancelCount: res.request_count.cancle_request_count,
            rejectedCount: res.request_count.rejected_request_count,
          });
        }
        if (isExport !== "0") {
          const file_path = res.file_path;
          if (file_path) {
            handleDownloadFile(file_path);
          }
        } else {
          SetRequests(res.company_request_data);
          setTotalCount(res.count);
        }
        setIsExport("0");
        setIsLoading(false);
      }
    });
  };

  const [moveSizeList, setMoveSizeList] = useState([]);
  const [truckList, setTruckList] = useState([]);

  //fetch and refresh truck list data from api

  const fetchTruckList = () => {
    let requestObj = {
      path: "/api/company/get_truck",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        setTruckList(res.data.allTruck);
      }
    });
  };

  const fetchListForMoverType = () => {
    let requestObj = {
      path: `/api/customer/get_move_type?page=${page}&limit=${rowsPerPage}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setMoveSizeList(res.data.moveTypeItems);
      }
    });
  };

  const CheckForSubscription = () => {
    let requestObj = {
      path: "/api/company/get_subscription",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setSubscribtion(res.subscribtion_data);
      } else {
      }
    });
  };

  const handleSortClick = (sort_by) => {
    if (sort_by === filters.sort_by) {
      setFilters({
        ...filters,
        sort_by,
        sorting_way: filters.sorting_way === "DESC" ? "ASC" : "DESC",
      });
    } else {
      setFilters({
        ...filters,
        sort_by,
        sorting_way: "ASC",
      });
    }
  };
  ///////////////////////////////////////////////////////////////////

  const table_columns = {
    sort_by: filters.sort_by,
    sorting_way: filters.sorting_way,
    columns: [
      {
        title: "Request number",
        sort: "id",
        onclick: () => handleSortClick("id"),
      },
      {
        title: "Start date and time",
        sort: "schedule_time",
        onclick: () => handleSortClick("schedule_time"),
      },
      {
        title: "Status",
        sort: "request_timeline_status",
        onClick: () => handleSortClick("request_timeline_status"),
      },
      {
        title: "Distance",
        sort: "netDistance",
        onclick: () => handleSortClick("net_distance"),
      },
      {
        title: "Move Type",
        sort: "move_type",
        onclick: () => handleSortClick("move_type"),
      },
      {
        title: "Truck",
      },
      {
        title: "",
      },
    ],
  };

  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    localStorage.setItem("directReqActiveFilter", JSON.stringify(filters));
    let date;
    if (filters.from_date !== "" && filters.to_date !== "") {
      date = `${moment(filters.from_date).format("ll")} to ${moment(
        filters.to_date
      ).format("ll")}`;
    } else {
      date = "";
    }
    let truck = truckList.map((truck) => {
      if (filters.truck_name == truck.id) {
        return truck.name;
      }
      return "";
    });
    props.setActiveFilterTitles([
      date,
      filters.schedule,
      filters.distance !== "" ? filters.distance + " miles" : filters.distance,
      filters.move_type,
      truck[0],
    ]);
  }, [filters]);

  useEffect(() => {
    localStorage.setItem("directReqActiveSort", JSON.stringify(filters));
    fetchActiveRequests("filter");
  }, [filters.sorting_way, filters.sort_by]);

  useEffect(() => {
    if (filters.date_range === "month") {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setFilters({ ...filters, from_date: firstDay, to_date: lastDay });
    }

    if (filters.date_range === "year") {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), 0);
      var lastDay = new Date(date.getFullYear() + 1, 0, 0);
      setFilters({ ...filters, from_date: firstDay, to_date: lastDay });
    }

    if (filters.date_range === "custom") {
      setFilters({ ...filters, from_date: "", to_date: "" });
    }
  }, [filters.date_range]);

  useEffect(() => {
    if (isExport !== "0") {
      if (isFilter) {
        fetchActiveRequests("filter");
      } else {
        fetchActiveRequests();
      }
    }
  }, [isExport]);

  useEffect(() => {
    if (isFilter) {
      fetchActiveRequests("filter");
    } else {
      fetchActiveRequests();
    }
  }, [page, rowsPerPage, props.status]);

  useEffect(() => {
    fetchListForMoverType();
    fetchTruckList();
    CheckForSubscription();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress ">
          <CircularProgress />
        </div>
      </>
    );
  }

  if (requests.length === 0 && !isFilter) {
    return (
      <>
        <ShareLink subscribtion={subscribtion} />
      </>
    );
  }

  if (window.innerWidth < 500) {
    console.log(window.innerWidth, "Width");
    return (
      <>
        <div className="rounded-box pb-3">
          <div className="row">
            <div className="col-12">
              <table
                id="example"
                className="responsive table table-hover"
                style={{ width: "100%" }}
              >
                <thead>
                  <th className="small-sub-title dark-grey-color">
                    <div className="row">
                      <div className="col-6 small-sub-title dark-grey-color">
                        Active Requests
                      </div>
                      <div className="col-6">
                        {/* #{request.request_data.id} */}
                      </div>
                    </div>
                  </th>
                </thead>
                <tbody>
                  {requests.map((request, i) => (
                    <tr
                      key={request.request_data.id}
                      className="pointer-point border-bottom"
                      onClick={() =>
                        props.history.push(
                          "/company/company-requests/" + request.request_data.id
                        )
                      }
                    >
                      <div className="row pl-2">
                        <div className="col-4 small-sub-title dark-grey-color">
                          Request No.
                        </div>
                        <div className="col-8 small-sub-title dark-grey-color">
                          #{request.request_data.id}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Start date:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.s_date}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Start time:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.schedule_time.slice(
                            11,
                            request.request_data.schedule_time.length
                          )}
                        </div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Distance:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.net_distance} mi
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Move Type:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.move_type}
                        </div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Truck:</span>
                        </div>
                        <div className="col-8">
                          {request.trcuk_array.length === 0
                            ? "-"
                            : request.trcuk_array.map((truck, i) => {
                                return (
                                  truck.name +
                                  (i === request.trcuk_array.length - 1
                                    ? ""
                                    : ", ")
                                );
                              })}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Status:</span>
                        </div>
                        <div className="col-8">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="8" cy="8" r="8" fill="#C4314B"></circle>
                          </svg>{" "}
                          {getStatusForCompany(
                            request.request_data.request_timeline_status,
                            request.additional_status
                          )}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Last update:</span>
                        </div>
                        <div className="col-8">
                          <span className="light-grey-color extra-small-font-size">
                            Updated{" "}
                            {moment(request.request_data.updated_at).fromNow()}
                          </span>
                        </div>
                        <div className="col-12">
                          {/* Request Info */}
                          <RequestInfo request={request} />
                        </div>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={
                  <span className="pagination-text">Rows:</span>
                }
              />
            </div>
          </div>
        </div>
        <ActiveFilter
          filters={filters}
          setFilters={setFilters}
          moveSizeList={moveSizeList}
          truckList={truckList}
          setPage={setPage}
          fetchActiveRequests={fetchActiveRequests}
        />
      </>
    );
  }

  return (
    <>
      <div className="row pb-3">
        {subscribtion.subscription_status && (
          <div className="col-12 text-right">
            <CopyToClipboard
              content={DirectLinkUrl + company_direct_access_link}
            />
            <button
              style={{ color: "white", outline: "none", width: 210 }}
              className="custom-btn-small red-btn ml-1 mr-1 mt-2"
              data-toggle="modal"
              data-target="#share-link-email"
            >
              Share Via Email <ShareIcon className="ml-1" />
            </button>

            <button
              style={{ color: "white", outline: "none", width: 210 }}
              className="custom-btn-small red-btn ml-1 mr-1 mt-2"
              data-toggle="modal"
              data-target="#share-link-phone"
            >
              Share Via Phone <ShareIcon className="ml-1" />
            </button>
          </div>
        )}
        {subscribtion && !subscribtion.subscription_status && (
          <div className="col-12 text-right">
            <button
              onClick={() => {
                props.history.push("/subscriptions-plan");
              }}
              className="custom-btn-small red-btn"
            >
              Choose a plan
            </button>
          </div>
        )}
        <div className="col-12 text-right mt-3">
          <div
            className="pointer-point d-flex align-items-center justify-content-end"
            onClick={handleExportData}
          >
            <Icon
              className="theme-icon mr-2"
              icon="ant-design:export-outlined"
            />
            <span>Export As Excel</span>
          </div>
        </div>
      </div>
      <div className="rounded-box pb-3">
        <div className="row">
          <div className="col-12">
            <table
              id="example"
              className="responsive table table-hover"
              style={{ width: "100%" }}
            >
              <TableHead columns={table_columns} />
              {/* <thead>
                <tr>
                  <th
                    onClick={() => handleSortClick("id")}
                    className={
                      filters.sort_by === "id"
                        ? "small-sub-title dark-grey-color table-heading table-sorted"
                        : "small-sub-title dark-grey-color table-heading"
                    }
                  >
                    Request number
                    {filters.sort_by === "id" &&
                    filters.sorting_way === "DESC" ? (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-up-outlined" />
                      </span>
                    ) : (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-down-outlined" />
                      </span>
                    )}
                  </th>

                  <th
                    onClick={() => handleSortClick("schedule_time")}
                    className={
                      filters.sort_by === "schedule_time"
                        ? "small-sub-title dark-grey-color table-heading table-sorted"
                        : "small-sub-title dark-grey-color table-heading"
                    }
                  >
                    Start date and time
                    {filters.sort_by === "schedule_time" &&
                    filters.sorting_way === "DESC" ? (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-up-outlined" />
                      </span>
                    ) : (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-down-outlined" />
                      </span>
                    )}
                  </th>

                  <th
                    onClick={() => handleSortClick("request_timeline_status")}
                    className={
                      filters.sort_by === "request_timeline_status"
                        ? "small-sub-title dark-grey-color table-heading table-sorted"
                        : "small-sub-title dark-grey-color table-heading"
                    }
                  >
                    Status
                    {filters.sort_by === "request_timeline_status" &&
                    filters.sorting_way === "DESC" ? (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-up-outlined" />
                      </span>
                    ) : (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-down-outlined" />
                      </span>
                    )}
                  </th>

                  <th
                    onClick={() => handleSortClick("net_distance")}
                    className={
                      filters.sort_by === "net_distance"
                        ? "small-sub-title dark-grey-color table-heading table-sorted"
                        : "small-sub-title dark-grey-color table-heading"
                    }
                  >
                    Distance
                    {filters.sort_by === "net_distance" &&
                    filters.sorting_way === "DESC" ? (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-up-outlined" />
                      </span>
                    ) : (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-down-outlined" />
                      </span>
                    )}
                  </th>

                  <th
                    onClick={() => handleSortClick("move_type")}
                    className={
                      filters.sort_by === "move_type"
                        ? "small-sub-title dark-grey-color table-heading table-sorted"
                        : "small-sub-title dark-grey-color table-heading"
                    }
                  >
                    Move Type
                    {filters.sort_by === "move_type" &&
                    filters.sorting_way === "DESC" ? (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-up-outlined" />
                      </span>
                    ) : (
                      <span className="ml-2 sorting-icon">
                        <Icon icon="ant-design:arrow-down-outlined" />
                      </span>
                    )}
                  </th>

                  <th className="small-sub-title dark-grey-color">Truck</th>
                  <th className="small-sub-title dark-grey-color"></th>
                </tr>
              </thead> */}
              <tbody>
                {requests.map((request, i) => (
                  <tr
                    key={i}
                    className="pointer-point"
                    onClick={() =>
                      props.history.push(
                        "/company/direct-requests/" + request.request_data.id
                      )
                    }
                  >
                    <td>
                      <span
                        href="request-detail-submitted.html"
                        className="request-link"
                      >
                        #{request.request_data.id}
                        <br />
                        <span className="light-grey-color extra-small-font-size">
                          Updated{" "}
                          {moment(request.request_data.updated_at).fromNow()}
                        </span>
                        <br />
                        <RequestInfo request={request} />
                      </span>
                    </td>
                    <td>{request.request_data.schedule_time}</td>
                    <td>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#C4314B"></circle>
                      </svg>{" "}
                      {getStatusForCompany(
                        request.request_data.request_timeline_status,
                        request.additional_status
                      )}
                      <br />
                      <span className="light-grey-color extra-small-font-size pl-3">
                        {moment(request.request_data.updated_at).format(
                          "MMM Do YY , hh:mm"
                        )}
                      </span>
                    </td>
                    <td>{request.request_data.net_distance} mi</td>
                    <td>{request.request_data.move_type}</td>
                    <td>
                      {" "}
                      {request.trcuk_array.length === 0
                        ? "-"
                        : request.trcuk_array.map((truck, i) => {
                            return (
                              truck.name +
                              (i === request.trcuk_array.length - 1 ? "" : ", ")
                            );
                          })}
                    </td>

                    <td>
                      <a className="custom-btn-small custom-button-extra-small req-status-button status-button-active">
                        Active
                      </a>
                    </td>
                  </tr>
                ))}
                {/* loop Close */}
              </tbody>
            </table>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={
                <span className="pagination-text">Rows per page:</span>
              }
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
      <ActiveFilter
        filters={filters}
        setFilters={setFilters}
        moveSizeList={moveSizeList}
        truckList={truckList}
        setPage={setPage}
        fetchActiveRequests={fetchActiveRequests}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
}

export default withRouter(ActiveRequests);
