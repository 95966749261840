import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../../../assets/images/logo.png";
import {
  navBtnImage,
  notificationImage,
  searchIconImage,
  notificationIconImage,
  companyProfilePicImage,
} from "../../../../assets";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Notification from "./components/Notification/Notification";
import { invokeApi, s3baseUrl } from "../../../../bl_libs/invokeApi";
import { useSnakbar } from "../../../../Context/Context-Snakbar/ContextSnackbar";
import ChangePassword from "./components/Change-Password/ChangePassword";
import { Avatar } from "@material-ui/core";

function Headers(props) {
  const { email, name, phone, profile_picture, address, company_rating } =
    JSON.parse(localStorage.getItem("user"));
  const { showErrorSnak } = useSnakbar();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [notification, setNotification] = useState([]);

  const [unreadCount, setUnreadCount] = useState(0);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    FetchNotifications();
  };

  const FetchNotifications = () => {
    let requestObj = {
      path: "/app_api/get_notification",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setIsLoading(false);
        setNotification(res.notification);
        setUnreadCount(res.notification_count);
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  const MarkAsRead = (id) => {
    let requestObj = {
      path: "/app_api/mark_notification_read",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {};

    if (id) {
      postData = {
        read_all: "0",
        notification_id: id.toString(),
      };
    } else {
      postData = {
        read_all: "1",
        notification_id: "",
      };
    }
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        FetchNotifications();
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  const handleNotificationClick = (id) => {
    setState({ ...state, right: false });

    props.history.push("/company/company-requests");
    setTimeout(() => {
      props.history.push("/company/company-requests/" + id);
    });
  };

  const anchor = "right";

  useEffect(() => {
    FetchNotifications();
  }, [props.history.location.pathname]);

  return (
    <div className="main-bg">
      <header>
        <div className="container-fluid">
          <div className="row">
            {window.innerWidth < 769 && (
              <div className="col-2 col-md-1 col-lg-1">
                <img
                  src={navBtnImage}
                  onClick={() => {
                    props.setSideNavActive(!props.sideNavActive);
                    console.log("object");
                  }}
                />
              </div>
            )}
            <div className="col-4 col-md-6 col-lg-6">
              <div className="logo">
                <Link
                  // onClick={() => props.setActive("")}
                  to="/company/calender"
                >
                  <img src={logo} height={"48 px"} alt="LA Moving Star" title="LA Moving Star" />
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-5 col-lg-6 text-right ">
              <div className="header-icons-wrapper">
                <span>
                  {unreadCount > 0 ? (
                    <img
                      onClick={toggleDrawer(anchor, true)}
                      src={notificationImage}
                      className="pointer-point"
                    />
                  ) : (
                    <img
                      onClick={toggleDrawer(anchor, true)}
                      src={notificationIconImage}
                      className="pointer-point"
                    />
                  )}
                </span>

                <button
                  className="btn btn-default dropdown-toggle dropdown-toggle-custom"
                  type="button"
                  id="menu1"
                  data-toggle="dropdown"
                >
                  {profile_picture ? (
                    <img
                      style={{ borderRadius: "50%" }}
                      src={s3baseUrl + profile_picture}
                      className="profile-img"
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: 40,
                        height: 40,
                        fontSize: 22,
                      }}
                    >
                      {/* {name[0].toUpperCase()} */}
                    </Avatar>
                  )}
                </button>
                <ul
                  className="dropdown-menu rounded-box pl-3 pr-3"
                  role="menu"
                  aria-labelledby="menu1"
                >
                  <li role="presentation">
                    <Link
                      role="menuitem"
                      tabIndex="-1"
                      to="/company/edit-profile"
                    >
                      Edit Profile
                    </Link>
                  </li>
                  <li role="presentation">
                    <Link role="menuitem" tabIndex="-1" to="/company/stats">
                      Company Stats
                    </Link>
                  </li>
                  <li role="presentation">
                    <a
                      role="menuitem"
                      tabIndex="-1"
                      href=""
                      data-toggle="modal"
                      data-target="#change-password"
                    >
                      Change Password
                    </a>
                  </li>
                  <li role="presentation">
                    <Link role="menuitem" tabIndex="-1" to="/create-request">
                      Create Request
                    </Link>
                  </li>
                  <li role="presentation">
                    <Link role="menuitem" tabIndex="-1" to="/company/my-cards">
                      Payment Methods
                    </Link>
                  </li>
                  <li role="presentation">
                    <Link
                      role="menuitem"
                      tabIndex="-1"
                      to="/company/subscriptions-info"
                    >
                      Subscription Info
                    </Link>
                  </li>
                  {props.subscription !== undefined &&
                  props.subscription.subscription_status ? (
                    <>
                      <li role="presentation">
                        <a
                          role="menuitem"
                          tabIndex="-1"
                          href=""
                          data-toggle="modal"
                          data-target="#share-link-phone"
                        >
                          Share Via Phone
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          role="menuitem"
                          tabIndex="-1"
                          href=""
                          data-toggle="modal"
                          data-target="#share-link-email"
                        >
                          Share Via Email
                        </a>
                      </li>
                    </>
                  ) : (
                    <Link
                      role="menuitem"
                      tabIndex="-1"
                      to="/subscriptions-plan"
                    >
                      Choose a plan
                    </Link>
                  )}
                  <li role="presentation">
                    <Link role="menuitem" tabIndex="-1" to="/email-templates">
                      Email Templates
                    </Link>
                  </li>
                  <li role="presentation">
                    <Link role="menuitem" tabIndex="-1" to="/extra-items">
                      Manage Extra Items
                    </Link>
                  </li>
                  <li role="presentation">
                    <Link role="menuitem" tabIndex="-1" to="/company">
                      Company Info
                    </Link>
                  </li>

                  <li role="presentation">
                    <Link role="menuitem" tabIndex="-1" to="/company/about-us">
                      About Us
                    </Link>
                  </li>
                  <li role="presentation" className="divider"></li>
                  <li role="presentation">
                    <a
                      role="menuitem"
                      tabIndex="-1"
                      href=""
                      data-toggle="modal"
                      data-target="#logout"
                    >
                      Logout
                    </a>
                  </li>
                </ul>
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </header>
      <SwipeableDrawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        <Notification
          toggleDrawer={toggleDrawer}
          isLoading={isLoading}
          notification={notification}
          handleNotificationClick={handleNotificationClick}
          MarkAsRead={MarkAsRead}
        />
      </SwipeableDrawer>
      <ChangePassword showErrorSnak={showErrorSnak} />
    </div>
  );
}
export default withRouter(Headers);
