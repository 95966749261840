import React from "react";
import { withRouter } from "react-router";

function ExtraItems(props) {
  const { extra_request_items, request } = props;
  console.log(extra_request_items, "Extra");
  return (
    <div className="info-detail-wrapper p-3 pt-20">
      <div className="row">
        <div className="col-12 col-lg-6">
          {/* <div className="row pt-1">
            <div className="col-9 text-left small-font-size">Packing kit</div>
            <div className="col-3 small-sub-title">
              <span className="pr-2 align-bottom">5</span>
            </div>
          </div> */}
          {/* display extra item list */}
          {extra_request_items.map((item, i) => (
            <div key={i} className="row pt-1">
              <div className="col-6 text-left small-font-size">{item.name}</div>
              <div className="col-3 small-sub-title">{item.quantity} </div>
              <div className="col-3 small-sub-title">
                ${item.unit_price * item.quantity}{" "}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row mt-4 pb-2">
        <div className="col-12 justify-content-center d-flex">
          {request.request_type && request.request_timeline_status < 6 && (
            <button
              className="custom-btn-small custom-buton"
              onClick={() =>
                props.history.push(
                  "/company/my-requests/edit-extra-items/" +
                    props.match.params.requestId
                )
              }
            >
              {extra_request_items.length === 0 ? "Add" : "Edit"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(ExtraItems);
