import moment from "moment";
import React, { useState } from "react";
import { Rating } from "@material-ui/lab";
import { getMovers, time_convert } from "../../../../../../ui_libs/Functions/functions";
import EditRequest from "../Edit-Request/EditRequest";
import { checkCircleImage, editWhiteImage } from "../../../../../../assets";
import { base_uri } from "../../../../../../bl_libs/invokeApi";
import Axios from "axios";
import { saveAs } from "file-saver";
function Completed(props) {
  const { requestData, bidCancel, setIsLoadingPage, fetchRequestDetails } =
    props;
  const { truck_array } = requestData;
  const {
    customer_budget,
    recommend_amount,
    total_discount,
    id,
    final_amount,
    s_time,
    payment_method,
    request_type,
    moving_cost,
    minimum_hours,
    additional_cost,
    rate_method,
    customer_email,
    completed_date,
  } = requestData.request;
  const { first_name, last_name, user_model } =
    requestData.get_foreman_of_company;

  const { phone, email } = user_model;

  const [isEdit, setIsEdit] = useState(false);

  const readable_hours = (_hours) => {
    var hours = Number(_hours) * 60;
    var minutes = hours * 60;
    var r_minutes = Math.round(minutes);

    if (hours === 0) {
      return r_minutes + " min";
    }
    return hours + " hr " + r_minutes + " min";
  };

  const handleViewDocument = (agreement, action) => {
    Axios.post(
      `${base_uri}/app_api/get_file`,
      { file_path: `${agreement}/${id}.pdf` },
      {
        headers: {
          "x-sh-auth": localStorage.getItem("token"),
        },
        method: "POST",
        responseType: "blob", //Force to receive data in a Blob Format
      }
    )
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });

        if (action === "view") {
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
          return;
        }

        if (action === "download") {
          saveAs(file, `${agreement}_${id}.pdf`);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isEdit) {
    return (
      <EditRequest
        requestData={requestData}
        fetchRequestDetails={fetchRequestDetails}
        setIsLoadingPage={setIsLoadingPage}
        setIsEdit={setIsEdit}
      />
    );
  }

  return (
    <>
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <h6 className="text-white pt-1">Request #{id}</h6>
            </div>
            <div className="col-2 text-right">
              <img
                className="pl-1 pointer-point"
                src={editWhiteImage}
                onClick={() => setIsEdit(true)}
              ></img>
            </div>
          </div>
        </div>
        <div className="request-left-box p-3 p-sm-4">
          <div className="row">
            <div className="col-9">
              <div className="request-staus small-font-size">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#C4314B" />
                </svg>{" "}
                Completed
              </div>
            </div>
            <div className="col-3 text-right">
              {/* <a
                href=""
                className="small-text-bold red-color add-new-btn"
                data-toggle="modal"
                data-target="#cancel-request"
              >
                <span>Cancel</span>
              </a> */}
            </div>
          </div>
          <div className="bid-form-wrapper pt-3">
            <div className="row small-font-size">
              <div className="col-6 text-left dark-grey-color">Start time:</div>
              <div className="col-6 text-right black-color">
              {/* {`${time_convert(s_time.slice(0,5))} to ${time_convert(s_time.slice(8,13))}`} */}
              {s_time && s_time !== "8AM - 12PM Morning" &&
                    s_time !== "12PM - 5PM Evening" &&
                    s_time !== "Entire day" &&
                    `${time_convert(
                      s_time.split('-')[0].trim()
                    )} to ${time_convert(s_time.split('-')[1].trim())}`}
                  {s_time && (s_time == "8AM - 12PM Morning" ||
                    s_time == "12PM - 5PM Evening" ||
                    s_time == "Entire day") && `${s_time}`}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Movers:</div>
              <div className="col-6 text-right black-color">
                {getMovers(requestData)}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Truck Name:</div>
              <div className="col-6 text-right light-red-color">
                {truck_array.map((truck, i) => {
                  return (
                    truck.name + (i === truck_array.length - 1 ? "" : ", ")
                  );
                })}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">
                Foreman name:
              </div>
              <div className="col-6 text-right light-red-color">
                {first_name + " " + last_name}
              </div>
            </div>
            {/* salesman information */}
            {requestData.sale_team && (
              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color">
                  Salesman name:
                </div>
                <div className="col-6 text-right light-red-color">
                  {requestData.sale_team.first_name +
                    " " +
                    requestData.sale_team.last_name}
                </div>
              </div>
            )}
            {request_type && (
              <>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Rate Method
                  </div>
                  <div className="col-6 text-right black-color">
                    {rate_method}
                  </div>
                </div>

                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Payment Method
                  </div>
                  <div className="col-6 text-right black-color">
                    {payment_method}
                  </div>
                </div>
                {/* Hourly rate details */}
                {requestData.request.rate_method === "Hourly" && (
                  <>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Cash Hour Rate:
                      </div>
                      <div className="col-6 text-right black-color">
                        ${requestData.request.cash_payment_rate}
                      </div>
                    </div>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Card Hour Rate:
                      </div>
                      <div className="col-6 text-right black-color">
                        ${requestData.request.card_payment_rate}
                      </div>
                    </div>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Minimum Hours:
                      </div>
                      <div className="col-6 text-right black-color">
                        {requestData.request.minimum_hours}
                      </div>
                    </div>
                  </>
                )}
                {/* Flat rate details */}
                {requestData.request.rate_method === "Flat" && (
                  <>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Cash Flat Rate:
                      </div>
                      <div className="col-6 text-right black-color">
                        ${requestData.request.cash_min_flat_rate} - $
                        {requestData.request.cash_max_flat_rate}
                      </div>
                    </div>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Card Flat Rate:
                      </div>
                      <div className="col-6 text-right black-color">
                        ${requestData.request.card_min_flat_rate} - $
                        {requestData.request.card_max_flat_rate}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">
                Customer Phone #:
              </div>
              <div className="col-6 text-right black-color">
                {requestData.request.customer_phone_number}
              </div>
            </div>

            <div className="row small-font-size pt-3">
              <div className="col-4 text-left dark-grey-color">
                Customer Email:
              </div>
              <div className="col-8 text-right black-color">
                <a
                  href={`mailto:${customer_email}`}
                  className="light-red-color"
                >
                  {customer_email}
                </a>
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">
                {" "}
                Foreman Phone #:
              </div>
              <div className="col-6 text-right black-color">{phone}</div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-4 text-left dark-grey-color">
                Foreman Email:
              </div>
              <div className="col-8 text-right black-color">
                <a href={`mailto:${email}`} className="light-red-color">
                  {email}
                </a>
              </div>
            </div>

            {requestData.sale_team && (
              <>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    {" "}
                    Salesman Phone #:
                  </div>
                  <div className="col-6 text-right black-color">
                    {requestData.sale_team.user_model.phone}
                  </div>
                </div>
                <div className="row small-font-size pt-3">
                  <div className="col-4 text-left dark-grey-color">
                    Salesman Email:
                  </div>
                  <div className="col-8 text-right black-color">
                    <a
                      href={`mailto:${requestData.sale_team.user_model.email}`}
                      className="light-red-color"
                    >
                      {requestData.sale_team.user_model.email}
                    </a>
                  </div>
                </div>
              </>
            )}

            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">
                Completion Date:
              </div>
              <div className="col-6 text-right black-color">
                {moment(completed_date).format("LL")}
              </div>
            </div>
            {request_type && total_discount && total_discount !== "0" && (
              <>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">Price:</div>
                  <div className="col-6 text-right black-color">
                    ${Number(final_amount) + Number(total_discount)}
                  </div>
                </div>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Discount:
                  </div>
                  <div className="col-6 text-right light-red-color">
                    ${total_discount}
                  </div>
                </div>
              </>
            )}

            {request_type && (
              <>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Moving Cost:
                  </div>
                  <div className="col-6 text-right black-color">
                    ${moving_cost}
                  </div>
                </div>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    Additional Cost:
                  </div>
                  <div className="col-6 text-right black-color">
                    ${additional_cost}
                  </div>
                </div>
              </>
            )}
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">
                Final Price:
              </div>
              <div className="col-6 text-right caption">${final_amount}</div>
            </div>

            <div className="row text-center pt-3">
              <div className="col-12 caption">Completed</div>
            </div>

            <div className="row small-font-size pt-3">
              <div className="col-8 text-left dark-grey-color">
                Confirmation Letter
              </div>
              <div className="col-4 text-right">
                <span
                  className="pointer-point light-red-color mr-2"
                  onClick={() =>
                    handleViewDocument("confirmation_letter", "view")
                  }
                >
                  View
                </span>
                <span
                  className="pointer-point light-red-color"
                  onClick={() =>
                    handleViewDocument("confirmation_letter", "download")
                  }
                >
                  Download
                </span>
              </div>
            </div>

            <div className="row small-font-size pt-3">
              <div className="col-8 text-left dark-grey-color">
                Request Invoice
              </div>
              <div className="col-4 text-right">
                <span
                  className="pointer-point light-red-color mr-2"
                  onClick={() => handleViewDocument("invoice", "view")}
                >
                  View
                </span>
                <span
                  className="pointer-point light-red-color"
                  onClick={() => handleViewDocument("invoice", "download")}
                >
                  Download
                </span>
              </div>
            </div>

            <div className="row small-font-size pt-3">
              <div className="col-8 text-left dark-grey-color">
                Agreement policy (Start Move)
              </div>
              <div className="col-4 text-right">
                <span
                  className="pointer-point light-red-color mr-2"
                  onClick={() => handleViewDocument("agreement_1", "view")}
                >
                  View
                </span>
                <span
                  className="pointer-point light-red-color"
                  onClick={() => handleViewDocument("agreement_1", "download")}
                >
                  Download
                </span>
              </div>
            </div>

            <div className="row small-font-size pt-3">
              <div className="col-8 text-left dark-grey-color">
                Agreement policy (Finish Move)
              </div>
              <div className="col-4 text-right">
                <span
                  className="pointer-point light-red-color mr-2"
                  onClick={() => handleViewDocument("agreement_2", "view")}
                >
                  View
                </span>
                <span
                  className="pointer-point light-red-color"
                  onClick={() => handleViewDocument("agreement_2", "download")}
                >
                  Download
                </span>
              </div>
            </div>

            <div className="row small-font-size pt-3">
              <div className="col-12 text-left caption pt-2">
                Foreman Response
              </div>
              <div className="col-6 text-left dark-grey-color pt-2">
                Rating:
              </div>
              <div className="col-6 text-right black-color">
                <Rating
                  style={{ verticalAlign: "bottom" }}
                  name="read-only"
                  precision={0.1}
                  value={requestData?.get_feedback?.customer_rating}
                  size="large"
                  readOnly
                />
              </div>
              <div className="col-12 text-left dark-grey-color">Feedback:</div>
              <div className="col-12 black-color">
                {requestData?.get_feedback?.customer_comment}
              </div>
            </div>

            <div className="row small-font-size pt-3">
              <div className="col-12 text-left caption pt-2">
                Customer Response
              </div>
              <div className="col-6 text-left dark-grey-color pt-2">
                Rating:
              </div>
              <div className="col-6 text-right black-color">
                <Rating
                  style={{ verticalAlign: "bottom" }}
                  name="read-only"
                  precision={0.1}
                  value={requestData?.get_feedback?.company_rating}
                  size="large"
                  readOnly
                />
              </div>
              <div className="col-12 text-left dark-grey-color">Feedback:</div>
              <div className="col-12 black-color">
                {requestData?.get_feedback?.company_comment}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Bid Placed --> */}
      <div
        className="modal fade"
        id="cancel-request"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancel-request-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-bid-cancel"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0 text-center">
                <h5 className="text-center" id="cancel-request-title">
                  Cancel request
                </h5>
                <p className="pt-3">
                  Are you sure you want to cancel the request?
                </p>
                {!request_type && (
                  <p className="text-left light-grey-color caption">
                    When cancelling request, you will be charged an additional
                    fee 50%.
                  </p>
                )}
                <div className="buttons-wrapper pt-3">
                  <div className="row justify-content-center text-center">
                    <div className="col-6">
                      <button
                        onClick={bidCancel}
                        className="custom-btn-small custom-buton float-right"
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        onClick={() =>
                          document.getElementById("close-bid-cancel").click()
                        }
                        className="custom-btn-small custom-buton custom-button-pink float-left"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Completed;
