import React, { useEffect, useState } from "react";
import { filterImage } from "../../assets";
import AllPayments from "./components/All-Payments/AllPayments";
import InReview from "./components/In-Review/InReview";
import InProgress from "./components/In-Progress/InProgress";
import Available from "./components/Available/Available";
import { withRouter } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import { invokeApi } from "../../bl_libs/invokeApi";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
function PaymentHistory(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();

  const [requestType, setRequestType] = useState("IN_PROGRESS");

  //--------------------------------------------> Available balance screen state
  const [currentScreen, setCurrentScreen] = useState("withdraw");
  //----------------------------------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState({
    all: 0,
    in_progress: 0,
    in_review: 0,
    available: 0,
  });

  const FetchPaymentTotal = () => {
    let requestObj = {
      path: "/api/company/payment_count",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {};
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setTotal({
          all: res.all_payment,
          in_progress: res.work_in_progress_payment,
          in_review: res.in_review_payment,
          available: res.available_payment,
        });
        setIsLoading(false);
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  const fullReload = (value, screen) => {};

  useEffect(() => {
    FetchPaymentTotal();
  }, []);

  useEffect(() => {
    FetchPaymentTotal();
  }, [requestType]);

  if (isLoading) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">PAYMENTS</p>
            <div className="Circular-Progress ">
              <CircularProgress />
            </div>
          </div>
        </section>
      </>
    );
  }
  return (
    <>
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title">PAYMENTS</p>
          <div className="calendar-wrapper">
            <div className="row payment-tab-row ">
              <div className="col-12 text-right pb-4">
                <Button
                  onClick={() => props.history.push("/company/transactions")}
                  variant="outlined"
                  className="mr-4 ml-4"
                  color="primary"
                  endIcon={<UpdateIcon />}
                >
                  View Transactions
                </Button>
                {/* </div>
              <div className="col-12 text-right pb-2 pt-4"> */}
                {requestType !== "AVAILABLE" && (
                  <span
                    className="black-color pointer-point"
                    data-toggle="modal"
                    data-target="#change-filter"
                  >
                    <img src={filterImage} />
                    <span className="pl-2">Filter</span>
                  </span>
                )}
              </div>
              {/* New section */}
              {/* <div
                onClick={() => setRequestType("ALL")}
                className={
                  requestType === "ALL"
                    ? "col-6 col-md-3 payment-tab-active"
                    : "col-6 col-md-3 payment-tab"
                }
              >
                <span>All Payments</span>
                <div>
                  <span className="payment-tab-total">${total.all}</span>
                </div>
              </div> */}
              <div
                onClick={() => setRequestType("IN_PROGRESS")}
                className={
                  requestType === "IN_PROGRESS"
                    ? "col-6 col-md-4 payment-tab-active"
                    : "col-6 col-md-4 payment-tab"
                }
              >
                <span>Work In Progress</span>
                <div>
                  <span className="payment-tab-total">
                    ${total.in_progress}{" "}
                  </span>
                </div>
              </div>
              <div
                onClick={() => setRequestType("IN_REVIEW")}
                className={
                  requestType === "IN_REVIEW"
                    ? "col-6 col-md-4 payment-tab-active "
                    : "col-6 col-md-4 payment-tab "
                }
              >
                <span>In Review</span>
                <div>
                  <span className="payment-tab-total">${total.in_review}</span>
                </div>
              </div>
              <div
                onClick={() => setRequestType("AVAILABLE")}
                className="col-6 col-md-3 payment-tab"
                className={
                  requestType === "AVAILABLE"
                    ? "col-12 col-md-4 payment-tab-active"
                    : "col-12 col-md-4 payment-tab"
                }
              >
                <span>Available Balance</span>
                <div>
                  <span className="payment-tab-total">${total.available}</span>
                </div>
              </div>
            </div>

            {/* {requestType === "ALL" && <AllPayments />} */}
            {requestType === "IN_PROGRESS" && (
              <InProgress showErrorSnak={showErrorSnak} />
            )}
            {requestType === "IN_REVIEW" && (
              <InReview showErrorSnak={showErrorSnak} />
            )}
            {requestType === "AVAILABLE" && (
              <Available
                currentScreen={currentScreen}
                setCurrentScreen={setCurrentScreen}
                showErrorSnak={showErrorSnak}
                balance={total.available}
                FetchPaymentTotal={FetchPaymentTotal}
              />
            )}
            {/* {requestType === "DONE" && <DonePayments />} */}
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(PaymentHistory);
