import React from "react";
import { companyProfilePicImage } from "../../../../assets";
import SidebarHeader from "./components/Sidebar-Header/SidebarHeader";
import User from "./components/Chat-User/User";

function ChatSidebar(props) {
  const {
    chatRooms,
    selectedUser,
    userList,
    handleSelectUser,
    setChats,
    profile,
    setScreen,
  } = props;
  const count = [];

  const handleActiveRoom = (target_room) => {
    if (target_room.room_id === selectedUser.room_id) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="row">
        {/* my chat header */}
        <div className="col-12 border-bottom chat-my-header">
          <SidebarHeader profile={profile} />
        </div>

        <div className="col-12 pt-1 scrolling chat-vh-sidebar">
          {userList.map((user) => (
            <User
              key={user.id}
              user={user}
              setScreen={setScreen}
              handleSelectUser={handleSelectUser}
              handleActiveRoom={handleActiveRoom}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default ChatSidebar;
