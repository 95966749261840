import { CircularProgress, TablePagination } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import moment from "moment";
function RejectedRequest(props) {
  const [requests, SetRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({
    from_date: "",
    to_date: "",
    schedule: "",
    distance: "",
    move_type: "",
    truck_name: "",
  });

  const [moveSizeList, setMoveSizeList] = useState([]);
  const [truckList, setTruckList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  //------------------------------------------>  pagination settings
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);

  //----------------------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //////////////////////////////////////////////////////////////////

  const getStatusForCompany = (status) => {
    if (status === 0) {
      return "New Request";
    }
    if (status === 1) {
      return "Bid submitted";
    }
    if (status === 2) {
      return "Bid accepted";
    }
    if (status === 3) {
      return "Paid to Escrow";
    }
    if (status === 4) {
      return "Truck assigned and ready to move";
    }
    if (status === 5) {
      return "Move in progress";
    }
    if (status === 6) {
      return "Completed, waiting for confirmation";
    }
    if (status === 7) {
      return "Completed";
    }
    if (status === 8) {
      return "";
    }
    if (status === 11) {
      return "Bid Cancelled";
    }
  };

  const fetchCancelledRequests = (type) => {
    let requestObj = {
      path: `/api/company/cancelled_bids?page=${page}&limit=${rowsPerPage}`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {};
    if (type === "filter") {
      setIsFilter(true);
      postData = {};
    } else {
      setIsFilter(false);
      //if type is undefined
      //we are not passing filters in API
      postData = {};
    }
    setIsLoading(true);
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        SetRequests(res.get_request_bid_data);
        setTotalCount(res.count);
        setIsLoading(false);
      }
    });
  };

  //fetch and refresh truck list data from api

  const fetchTruckList = () => {
    let requestObj = {
      path: "/api/company/get_truck",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        setTruckList(res.data.allTruck);
      }
    });
  };

  const fetchListForMoverType = () => {
    let requestObj = {
      path: "/api/customer/get_move_type",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "MOVE SIZE RESPONSCE");
      if (res.code === 200) {
        setMoveSizeList(res.data.moveTypeItems);
      }
    });
  };

  useEffect(() => {
    if (isFilter) {
      fetchCancelledRequests("filter");
    } else {
      fetchCancelledRequests();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchListForMoverType();
    fetchTruckList();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress ">
          <CircularProgress />
        </div>
      </>
    );
  }
  // if (requests.length === 0) {
  //   return <div>No request found</div>;
  // }

  if (window.innerWidth < 500) {
    console.log(window.innerWidth, "Width");
    return (
      <>
        <div className="rounded-box pb-3">
          <div className="row">
            <div className="col-12">
              <table
                id="example"
                className="responsive table table-hover"
                style={{ width: "100%" }}
              >
                <thead>
                  <th className="small-sub-title dark-grey-color">
                    <div className="row">
                      <div className="col-6 small-sub-title dark-grey-color">
                        Rejected Requests
                      </div>
                      <div className="col-6">
                        {/* #{request.request_data.id} */}
                      </div>
                    </div>
                  </th>
                </thead>
                <tbody>
                  {requests.map((request, i) => (
                    <tr
                      key={request.id}
                      className="pointer-point border-bottom"
                    >
                      <div className="row pl-2">
                        <div className="col-4 small-sub-title dark-grey-color">
                          Request No.
                        </div>
                        <div className="col-8 small-sub-title dark-grey-color">
                          #{request.request_id}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Bid Date and time:</span>
                        </div>
                        <div className="col-8">
                          {moment(request.bid_datetime).format("LLL")}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Reject Date and time:</span>
                        </div>
                        <div className="col-8">
                          {moment(request.result_datetime).format("LLL")}
                        </div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Bid Amount:</span>
                        </div>
                        <div className="col-8">${request.bid_amount}</div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Movers:</span>
                        </div>
                        <div className="col-8">{request.movers}</div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Rejected:</span>
                        </div>
                        <div className="col-8">
                          <span className="light-grey-color extra-small-font-size">
                            {moment(request.created_at).fromNow()}
                          </span>
                        </div>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={
                  <span className="pagination-text">Rows:</span>
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="rounded-box pb-3">
        <div className="row">
          <div className="col-12">
            <table
              id="example"
              className="responsive table table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="small-sub-title dark-grey-color">
                    Request number
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Bid Date and time
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Reject Date and time
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Bid Amount
                  </th>
                  <th className="small-sub-title dark-grey-color">Movers</th>
                  <th className="small-sub-title dark-grey-color"></th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request, i) => (
                  <tr key={i} className="pointer-point">
                    <td>
                      <span
                        href="request-detail-submitted.html"
                        className="request-link"
                      >
                        #{request.request_id}
                        <br />
                        <span className="light-grey-color extra-small-font-size">
                          Rejected {moment(request.created_at).fromNow()}
                        </span>
                      </span>
                    </td>
                    <td>{moment(request.bid_datetime).format("LLL")}</td>
                    <td>{moment(request.result_datetime).format("LLL")}</td>
                    <td>${request.bid_amount}</td>
                    <td>{request.movers}</td>
                    <td>
                      <a className="custom-btn-small custom-button-extra-small req-status-button status-button-cancelled">
                        Rejected
                      </a>
                    </td>
                  </tr>
                ))}
                {/* loop Close */}
              </tbody>
            </table>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={
                <span className="pagination-text">Rows per page:</span>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default RejectedRequest;
