import React from "react";
import { addImage, subtractImage, addImgIconImage } from "../../../assets";
import { useState } from "react";
import { Badge, makeStyles } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { invokeApi, s3baseUrl } from "../../../bl_libs/invokeApi";
import { useSnakbar } from "../../../Context/Context-Snakbar/ContextSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
}));
function ItemDetails(props) {
  console.log(props, "PROPS DETAILS");
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const classes = useStyles();
  const [item, setItem] = useState({
    ...props.item,
    name: props.item.name,
    id: props.item.id,
    value: props.item.value,
    comments: props.item.comments,
    item_id: props.item.item_id,
  });
  const [images, setImages] = useState(
    props.item.images ? props.item.images : []
  );

  console.log(images, "Images");

  const handleImages = (event) => {
    console.log(event.target.files);

    let files = event.target.files[0];

    console.log(files, "Track file");
    let tempImage = images.map((pic) => pic);

    // ------------------------------------------

    // let formData = new FormData();

    // formData.append("upload_file", files);

    // fetch("https://stage-api.evom.io/app_api/upload_s3_file", {
    //   method: "POST",
    //   headers: {
    //     // Accept: "application/json",
    //     // "Content-Type": "multipart/form-data",
    //     //   'Token': token
    //   },
    //   body: formData,
    // })
    //   .then((response) => response.json())
    //   .then(async (res) => {
    //     console.log(res, "RESPONSE from image gallery upload api");
    //     tempImage.push({ image_url: res.file_url });
    //     setImages(tempImage);
    //   })
    //   .catch((error) => {
    //     console.log(error, "error from image gallery upload api");
    //   });
    // ------------------------------------------

    let requestObj = {
      path: "/app_api/upload_s3_file",
      method: "POST",
      headers: {},
    };

    let formData = new FormData();
    formData.append("upload_file", files);

    requestObj["postData"] = formData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "IMAGE UPLOADER");
      if (res.code === 200) {
        tempImage.push({ image_url: res.file_url });
        setImages(tempImage);
      } else {
      }
    });
  };

  const handleImageCross = (pic) => {
    let temp = images.filter((img) => img.image_url !== pic.image_url);
    setImages(temp);
  };

  const handleUpdateDetails = () => {
    props.handleUpdateItemDetails({ ...item, images });
    props.closePopUp();
  };

  return (
    <div className="form-wrapper pl-4 pr-4 pl-sm-4 pr-sm-4 pt-sm-4 pb-m-100">
      <div className="modal-body p-0">
        <h1 className="text-center text-uppercase">Item Details</h1>
        <p className="font-weight-bold pt-3 text-center">{item.name}</p>
      </div>
      <div className="item-detail-form">
        <div className="row pt-2 separator-border no-gutters">
          <div className="col-7 text-left overline dark-grey-color pt-2">
            How Many?
          </div>
          <div className="col-5 small-sub-title items-count-wrapper">
            <img
              src={addImage}
              className="float-right"
              style={{ cursor: "pointer" }}
              onClick={() => {
                let temp = item.value + 1;
                setItem({ ...item, value: temp });
              }}
            />
            <NumberFormat
              maxLength={3}
              className="form-control small-font-size items-count float-right"
              value={item.value}
              allowNegative={false}
              onChange={(e) => {
                if (e.target.value === "" || parseInt(e.target.value) === 0) {
                  setItem({ ...item, value: 1 });
                } else {
                  setItem({ ...item, value: parseInt(e.target.value) });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === ".") {
                  setTimeout(() => {
                    setItem({ ...item, value: 1 });
                  }, 200);
                }
              }}
            />
            <img
              src={subtractImage}
              className="float-right"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (item.value > 1) {
                  let temp = item.value - 1;
                  setItem({ ...item, value: temp });
                }
              }}
            />
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="add-img-wrapper pt-2">
          <div className="row">
            <div className="col-12">
              <label className="overline dark-grey-color">Add Photo</label>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {images.length === 0
                ? ""
                : images.map((pic, i) => (
                    <Badge
                      key={i}
                      color="secondary"
                      overlap="circle"
                      badgeContent={
                        <span
                          style={{
                            fontSize: 16,
                            marginBottom: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => handleImageCross(pic)}
                        >
                          x
                        </span>
                      }
                    >
                      <img
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        src={s3baseUrl + pic.image_url}
                        style={{
                          margin: 5,
                        }}
                      ></img>
                    </Badge>
                  ))}
              {images.length < 3 && (
                <>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file-t"
                    onChange={handleImages}
                    value=""
                    type="file"
                  />
                  <label htmlFor="icon-button-file-t">
                    <img
                      style={{ cursor: "pointer" }}
                      src={addImgIconImage}
                    ></img>
                  </label>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="comment-box-wrapper pt-2">
          <div className="row">
            <div className="col-12">
              <label className="overline dark-grey-color">Comments</label>
              <textarea
                className="form-control small-font-size text-area"
                placeholder="Some comments."
                value={item.comments}
                onChange={(e) => {
                  setItem({ ...item, comments: e.target.value });
                }}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-4 pt-sm-5 justify-content-center bottom-fixed-area pb-3 no-gutters">
        <div className="col-12">
          <button
            type="button"
            className="red-btn custom-buton custom-buton-large w-100"
            onClick={handleUpdateDetails}
          >
            Add Details
          </button>
        </div>
      </div>
    </div>
  );
}

export default ItemDetails;
