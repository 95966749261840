import React from "react";

function WorkTime(props) {
  const { requestData } = props;

  const { move_time } = requestData;

  const destructureMinutes = (minutes) => {
    var hours = parseInt(minutes) / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if (rhours === 0) {
      return rminutes + " minute(s)";
    }
    return rhours + " hour(s) and " + rminutes + " minute(s)";
  };

  const getTimeByType = (type) => {
    //
    //type 0 for Drive time
    //type 1 for Move time
    //type 3 for Break time
    //type "total" for Total time
    //

    if (type === "total") {
      let total_time = 0;
      move_time.map((time) => {
        if (time.type !== 2) {
          total_time += parseInt(time.total_hours);
        }
      });

      return total_time;
    }
    const time = move_time.find((t) => t.type === type);
    return time.total_hours;
  };

  return (
    <>
      <div className="rounded-box mt-4">
        <div className="heading-wrapper black-bg text-center">
          <h6 className="text-white">Work time</h6>
        </div>
        <div className="request-left-box p-3 p-sm-4">
          <div className="row small-font-size pt-3">
            <div className="col-3 text-left dark-grey-color">Drive:</div>
            <div className="col-9 text-right black-color">
              {destructureMinutes(getTimeByType(0))}
            </div>
          </div>
          <div className="row small-font-size pt-3">
            <div className="col-3 text-left dark-grey-color">Move:</div>
            <div className="col-9 text-right black-color">
              {destructureMinutes(getTimeByType(1))}
            </div>
          </div>
          <div className="row small-font-size pt-3">
            <div className="col-3 text-left dark-grey-color">Break:</div>
            <div className="col-9 text-right black-color">
              {destructureMinutes(getTimeByType(2))}
            </div>
          </div>
          <div className="row small-font-size pt-3">
            <div className="col-3 text-left dark-grey-color">Total:</div>
            <div className="col-9 text-right black-color">
              {destructureMinutes(getTimeByType("total"))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkTime;
