import React, { useState } from "react";
import { filterImage } from "../../assets";
import SearchList from "./components/SearchList";
import SearchFilter from "./components/SearchFilter";
import { useEffect } from "react";
import { invokeApi } from "../../bl_libs/invokeApi";
import { CircularProgress } from "@material-ui/core";
import SearchNotAvailable from "./components/SearchNotAvailable";
function SearchScreen() {
  const _loading = "0";
  const _pleaseAdd = "1";
  const _available = "2";

  const [screen, setScreen] = useState(_loading);

  const fetchForemanListCount = () => {
    return new Promise(function (resolve, reject) {
      let requestObj = {
        path: "/api/company/get_foreman",
        method: "GET",
        headers: {
          "x-sh-auth": localStorage.getItem("token"),
        },
      };
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          console.log(res.data);
          resolve(res.data.allForeman.length);
        }
      });
    });
  };

  const fetchTruckListCount = () => {
    return new Promise(function (resolve, reject) {
      let requestObj = {
        path: "/api/company/get_truck",
        method: "GET",
        headers: {
          "x-sh-auth": localStorage.getItem("token"),
        },
      };
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          console.log(res.data);
          resolve(res.data.allTruck.length);
        }
      });
    });
  };

  const CheckForSearch = async () => {
    const foreman = await fetchForemanListCount();
    const truck = await fetchTruckListCount();
    const { company_booklet_link } = JSON.parse(localStorage.getItem("user"));

    if (foreman === 0 || truck === 0 || !company_booklet_link) {
      setScreen(_pleaseAdd);
    } else {
      setScreen(_available);
    }
  };

  useEffect(() => {
    CheckForSearch();
  }, []);

  if (screen === _loading) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <div className="calendar-wrapper mt-40 mt-m-20 pb-30">
              <div className="Circular-Progress ">
                <CircularProgress />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  if (screen === _pleaseAdd) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <div className="">
              {screen === _pleaseAdd && <SearchNotAvailable />}
            </div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title">SEARCH REQUESTS</p>
          <div className="calendar-wrapper mt-40 mt-m-20 pb-30">
            <div className="row">
              <div className="col-8 text-left mb-3"></div>
              <div className="col-4 text-right mb-3">
                <span
                  className="black-color pointer-point"
                  data-toggle="modal"
                  data-target="#change-filter"
                >
                  <img src={filterImage} />
                  <span className="pl-2">Filter</span>
                </span>
              </div>
            </div>

            {screen === _available && <SearchList />}
          </div>
        </div>
      </section>
    </>
  );
}

export default SearchScreen;
