import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { blackBackArrowImage } from "../../assets";
import { v4 as uuidv4 } from "uuid";
import { invokeApi } from "../../bl_libs/invokeApi";
import AddItemsByCommonList from "./CommonList/AddItemsByCommonList";
import AddItemsByRoom from "./ByRoomList/AddItemsByRoom";
import AddItemsOption from "./Add-Item_Options/AddItemsOption";
function EditRequestItems(props) {
  const [requestData, setRequestData] = useState();
  const [cards, setCards] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [extraItemsList, setExtraItemList] = useState([]);

  const [listType, setListType] = useState("");

  ////////////////////////////////////////////////////////////////////////

  //by common list
  const [commonList, setCommonList] = useState([]);
  const [commonListCopy, setCommonListCopy] = useState([]);
  //by room
  const [rooms, setRooms] = useState([]);
  const [roomsCopy, setRoomsCopy] = useState([]);

  const getItemsCountByRoom = () => {
    let count = 0;
    rooms.map((room) => {
      room.itemList.map((item) => {
        count++;
      });
    });
    return count;
  };
  ////////////////////////////////////////////////////////////////////////

  const getItemsByRoom = (items) => {
    console.log(items, "ITESMS");

    let rooms = [];
    items.map((item) => {
      let found = rooms.find((room) => room === item.category_name);
      if (found === undefined) {
        rooms.push(item.category_name);
      }
    });

    let itemsByRoom = [];
    rooms.map((room) => {
      let temp = items.filter((item) => room === item.category_name);

      temp = temp.map((item) => {
        return {
          ...item,
          category_name: item.category_name,
          comments: item.additional_info,
          item_id: uuidv4(),
          name: item.name,
          type: item.type,
          value: item.quantity,
          images: item.request_item_image_models,
        };
      });
      console.log(temp, "TEMP AFTER FILTER");
      itemsByRoom.push({
        room_id: uuidv4(),
        room_name: room,
        itemList: temp,
      });
    });
    return itemsByRoom;
  };

  const UpdateItemsByCommonList = () => {
    let temp = [];
    //filter non zero value items
    let filteredItems = commonList.filter((item) => item.value > 0);

    // now its filtered non empty value array

    temp = filteredItems.map((item) => {
      return {
        _id: item.id ? item.id.toString() : "0",
        category_name: item.category_name,
        change_by: "1",
        name: item.name,
        type: "1",
        size: `${item.size}`,
        added_by_type: "1",
        unit_price: item.unit_price.toString(),
        currency: "USD",
        quantity: item.value.toString(),
        additional_info: item.comments,
        items_image_array: item.images
          ? item.images.map((img) => {
              return {
                _pic: img.image_url,
              };
            })
          : [],
      };
    });

    const editItemsUrl = requestData.request.request_type
      ? "/app_api/edit_items"
      : "/api/customer/edit_items";

    let requestObj = {
      path: editItemsUrl,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${props.match.params.requestId}`,
      items_array: temp,
    };

    requestObj["postData"] = postData;

    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        props.history.goBack();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const UpdateItemsByRoom = () => {
    let temp = [];
    //check type by room or by common list to get items from it
    //if it is by room

    let filteredEmptyRooms = rooms.filter((room) => room.itemList.length > 0);

    filteredEmptyRooms.map((room) => {
      room.itemList.map((item) => {
        if (item.value > 0) {
          temp.push({
            _id: item.id ? item.id.toString() : "0",
            category_name: item.category_name,
            change_by: "1",
            name: item.name,
            type: "1",
            size: `${item.size}`,
            added_by_type: "1",
            unit_price: item.unit_price.toString(),
            currency: "USD",
            quantity: item.value.toString(),
            additional_info: item.comments,
            items_image_array: item.images
              ? item.images.map((img) => {
                  return {
                    _pic: img.image_url,
                  };
                })
              : [],
          });
        }
      });
    });

    const editItemsUrl = requestData.request.request_type
      ? "/app_api/edit_items"
      : "/api/customer/edit_items";

    let requestObj = {
      path: editItemsUrl,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${props.match.params.requestId}`,
      items_array: temp,
    };

    requestObj["postData"] = postData;

    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        props.history.goBack();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const fetchRequestDetails = (req_Id) => {
    let requestObj = {
      path: "/api/company/company_request_detail",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${req_Id}`,
    };

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        setRequestData(res);

        if (res.request_item.length > 0) {
          if (res.request_item[0].category_name === "Common List") {
            // alert("Common List");

            // by commonList
            let temp = res.request_item.map((item) => {
              return {
                ...item,
                category_name: item.category_name,
                comments: item.additional_info,
                item_id: uuidv4(),
                name: item.name,
                type: item.type,
                unit_price: 10,
                value: item.quantity,
                images: item.request_item_image_models,
              };
            });

            setCommonList(temp);
            setCommonListCopy(temp);
            setListType("common");
            //
          } else {
            // alert("Room List");
            //by room
            let temp = getItemsByRoom(res.request_item);
            setRooms(temp);
            setRoomsCopy(temp);
            setListType("by_room");
          }
        }

        setIsLoading(false);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchRequestDetails(props.match.params.requestId);
  }, []);
  if (isLoading) {
    return (
      <section id="content-wrapper" className="content-wrapper rating-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point"
              src={blackBackArrowImage}
              onClick={() => props.history.goBack()}
            />
            <span>Request DETAIL</span>
          </p>
          <div className="container-fluid">
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (listType === "") {
    return (
      <section id="content-wrapper" className="content-wrapper rating-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point"
              src={blackBackArrowImage}
              onClick={() => props.history.goBack()}
            />
            <span>Request DETAIL</span>
          </p>
          <div className="container-fluid">
            <AddItemsOption
              setAddItemsOptionType={setListType}
              isDirectRequest={requestData.request.request_type}
            />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section id="content-wrapper" className="content-wrapper rating-page">
      <div className="container-fluid">
        <p className="small-text-bold page-title ">
          <img
            className="pointer-point"
            src={blackBackArrowImage}
            onClick={() => props.history.goBack()}
          />
          <span>Request DETAIL</span>
        </p>

        <div className="rating-wrapper mt-40 mt-m-30">
          {/*  */}

          {listType === "common" && (
            <AddItemsByCommonList
              commonList={commonList}
              commonListCopy={commonListCopy}
              setCommonList={setCommonList}
              UpdateItemsByCommonList={UpdateItemsByCommonList}
            />
          )}
          {listType === "by_room" && (
            <AddItemsByRoom
              UpdateItemsByRoom={UpdateItemsByRoom}
              getItemsCountByRoom={getItemsCountByRoom}
              rooms={rooms}
              roomsCopy={roomsCopy}
              setRooms={setRooms}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default withRouter(EditRequestItems);
