import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Tippy from "@tippy.js/react";
import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import {
  addImage,
  blackBackArrowImage,
  infoIconImage,
  masterCardLogoImage,
  subtractImage,
} from "../../assets";
import { v4 as uuidv4 } from "uuid";
import { invokeApi } from "../../bl_libs/invokeApi";

function EditRequestItems(props) {
  const [requestData, setRequestData] = useState();
  const [cards, setCards] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [extraItemsList, setExtraItemList] = useState([]);
  const [extraItemsListCopy, setExtraItemListCopy] = useState([]);
  const [isEdited, setIsEdited] = useState(false);

  const [price, SetPrice] = useState("");

  // useEffect(() => {
  //   setIsEdited(true);
  // }, [isEdited]);

  const pushUrl = (url) => {
    props.history.push(url);
  };

  const handleKeyDown = (selectedItem, e) => {
    if (e.key === ".") {
      setTimeout(() => {
        let temp = extraItemsList.map((item) => {
          if (item.item_id === selectedItem.item_id) {
            return {
              ...item,
              value: 1,
            };
          }
          return item;
        });
        setExtraItemList(temp);
      }, 200);
    }
  };

  const handleChange = (selectedItem, e) => {
    if (!selectedItem.isSelected) return;
    let temp = extraItemsList.map((item) => {
      if (item.item_id === selectedItem.item_id) {
        if (e.target.value === "") {
          return {
            ...item,
            value: 1,
          };
        } else {
          return {
            ...item,
            value: parseInt(e.target.value),
          };
        }
      }
      return item;
    });
    setExtraItemList(temp);
  };

  const handleAdd = (selectedItem) => {
    if (!selectedItem.isSelected) return;
    let temp = extraItemsList.map((item) => {
      if (item.item_id === selectedItem.item_id) {
        return {
          ...item,
          value: item.value + 1,
        };
      }
      return item;
    });
    setExtraItemList(temp);
  };
  const handleSubtract = (selectedItem) => {
    if (selectedItem.value === 1 || !selectedItem.isSelected) return;
    let temp = extraItemsList.map((item) => {
      if (item.item_id === selectedItem.item_id) {
        return {
          ...item,
          value: item.value - 1,
        };
      }
      return item;
    });
    setExtraItemList(temp);
  };
  const handleCheckBox = (selectedItem) => {
    let temp = extraItemsList.map((item) => {
      if (item.item_id === selectedItem.item_id) {
        if (selectedItem.isSelected === false) {
          return {
            ...item,
            isSelected: !item.isSelected,
            value: 1,
          };
        } else {
          return {
            ...item,
            isSelected: !item.isSelected,
            value: 0,
          };
        }
      }
      return item;
    });
    setExtraItemList(temp);
  };

  const UpdateExtraItem = () => {
    const editExtraItemsUrl = requestData.request.request_type
      ? "/app_api/edit_extra_item"
      : "/api/customer/edit_extra_item";

    let requestObj = {
      path: editExtraItemsUrl,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let items = extraItemsList.map((item) => {
      return {
        _id: item.id.toString(),
        category_name: item.category_name,
        change_by: "1",
        name: item.name,
        type: "2",
        added_by_type: "1",
        unit_price: item.unit_price.toString(),
        currency: "USD",
        quantity: item.value.toString(),
        additional_info: item.comments,
        items_image_array: [],
      };
    });

    // items = items.filter((item) => item.quantity !== "0");
    let postData = {
      request_id: `${props.match.params.requestId}`,
      items_array: items,
    };

    requestObj["postData"] = postData;

    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        props.history.goBack();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const fetchRequestDetails = (req_Id) => {
    let requestObj = {
      path: "/api/company/company_request_detail",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${req_Id}`,
    };

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        setRequestData(res);
        fetchExtraList(res.extra_request_items);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };
  const fetchExtraList = (extra_items) => {
    console.log("FETCH EXTRA ITEMS CALLED");
    let requestObj = {
      path: "/api/customer/get_item_by_category_id",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      type: "2",
      category_id: "",
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "Extra items");
      if (res.code === 200) {
        extra_items = extra_items.filter((item) => item.quantity !== 0);

        let temp = res.data.items.map((item) => {
          console.log(extra_items, "REQUESTDATA");

          let find_item = extra_items.find(
            (extra_item) => extra_item.name === item.name
          );

          console.log(find_item, "FIND ITEM");
          return {
            ...item,
            value: find_item === undefined ? 0 : find_item.quantity,
            item_id: uuidv4(),
            comments: "",
            isSelected: find_item === undefined ? false : true,
            type: "2",
            category_name: "Extra Items",
          };
        });

        setExtraItemList(temp);
        setExtraItemListCopy(temp);

        setIsLoading(false);
      }
    });
  };

  const checkEdited = () => {
    let flag = false;
    let count = extraItemsList.find((item) => item.value > 0);
    for (let i = 0; i < extraItemsList.length; i++) {
      if (extraItemsList[i].value !== extraItemsListCopy[i].value) {
        flag = true;
      }
    }

    // flag ? alert("edited") : alert("Not Edited");
    return flag;
  };

  useEffect(() => {
    fetchRequestDetails(props.match.params.requestId);
  }, []);
  if (isLoading) {
    return (
      <section id="content-wrapper" className="content-wrapper rating-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point"
              src={blackBackArrowImage}
              onClick={() => props.history.goBack()}
            />
            <span>Request DETAIL</span>
          </p>
          <div className="container-fluid">
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </div>
      </section>
    );
  }
  return (
    <section id="content-wrapper" className="content-wrapper rating-page">
      <div className="container-fluid">
        <p className="small-text-bold page-title ">
          <img
            className="pointer-point"
            src={blackBackArrowImage}
            onClick={() => props.history.goBack()}
          />
          <span>Request DETAIL</span>
        </p>

        <div className="rating-wrapper mt-40 mt-m-30">
          <section className="main-content address-page pb-5">
            <div className="container">
              <div className="add-items-wrapper mb-m-90 mb-90 mt-3 mt-sm-5">
                <h1 className="text-center text-uppercase pt-3 pt-sm-4 pt-md-5 mb-0">
                  Edit Extra Items
                </h1>

                <div className="row justify-content-center pt-sm-5 pt-4">
                  <div className="col-12 col-sm-8">
                    <div className="row border-bottom no-gutters">
                      <div className="col-10">
                        <label className="dark-grey-color overline">
                          Packing And Moving Material
                        </label>
                      </div>

                      <div className="col-2 text-right">
                        <Tippy
                          content="Packing kit contains 15 small boxes, 15 medium boxes, 15 large boxes, bubble roll, paper roll."
                          delay={[300, 0]}
                          arrow={false}
                          placement="bottom"
                          maxWidth={200}
                        >
                          <img src={infoIconImage} />
                        </Tippy>
                      </div>
                    </div>
                    {errMessage && (
                      <Alert variant="filled" severity="error">
                        {errMessage}
                      </Alert>
                    )}
                  </div>
                </div>

                <div className="selected-room mt-4 mt-sm-5">
                  {extraItemsList.length > 0 &&
                    extraItemsList.map((item) => (
                      <div
                        key={item.item_id}
                        className="row justify-content-center"
                      >
                        <div className="col-12 col-sm-8 mb-4 mb-sm-4">
                          <div
                            className="rounded-box "
                            style={{
                              backgroundColor:
                                !item.isSelected && "rgb(236, 235, 237)",
                            }}
                          >
                            <div className="selected-items">
                              <div className="single-item">
                                <div className="row">
                                  <div className="col-10">
                                    <label className="dark-grey-color mb-0 custom-checkbox-wrapper">
                                      <input
                                        type="checkbox"
                                        name="storage"
                                        value={item.isSelected}
                                        checked={item.isSelected}
                                        onChange={() => handleCheckBox(item)}
                                      />
                                      <span className="custom-check-box"></span>
                                    </label>
                                    <div
                                      className="item-name pl-1"
                                      style={{ marginLeft: 35 }}
                                    >
                                      {item.name}
                                    </div>
                                  </div>
                                  <div className="col-2 text-right">
                                    <Tippy
                                      content={item.desciption}
                                      animation="scale"
                                      delay={[300, 0]}
                                      arrow={false}
                                      placement="bottom"
                                      maxWidth={200}
                                    >
                                      <img src={infoIconImage} />
                                    </Tippy>
                                  </div>
                                </div>
                                <div className="row pt-2">
                                  <div className="col-9 small-sub-title items-count-wrapper">
                                    <img
                                      className="pointer-point"
                                      onClick={() => handleSubtract(item)}
                                      src={subtractImage}
                                    />
                                    <NumberFormat
                                      maxLength={3}
                                      type="text"
                                      disabled={!item.isSelected}
                                      className="form-control small-font-size items-count"
                                      value={item.value}
                                      onChange={(e) => handleChange(item, e)}
                                      onKeyDown={(e) => handleKeyDown(item, e)}
                                    />
                                    <img
                                      className="pointer-point"
                                      onClick={() => handleAdd(item)}
                                      src={addImage}
                                    />
                                    <div className="clearfix"></div>
                                  </div>
                                  <div className="col-3 text-right">
                                    <span
                                      className="extra-item-price-text"
                                      style={{
                                        color: !item.isSelected && "#747474",
                                      }}
                                    >
                                      $
                                      {item.unit_price *
                                        (item.value === 0 ? 1 : item.value)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  <div className="row justify-content-center pt-3">
                    {/* {checkEdited() && (
                      <>
                        <div className=" dark-grey-color pt-2">
                          <label
                            htmlFor="email"
                            className="overline dark-grey-color pt-1 pr-3"
                          >
                            Add Price against this change :
                          </label>
                        </div>
                        <div className=" black-color">
                          <NumberFormat
                            allowNegative={false}
                            // disabled={isLoadingBtn}
                            allowLeadingZeros
                            value={price}
                            required
                            onChange={(e) => SetPrice(e.target.value)}
                            type="text"
                            className="form-control small-font-size"
                            id="your-price"
                            placeholder="100"
                            autoComplete="off"
                          />
                        </div>
                      </>
                    )} */}
                  </div>
                  <div className="row justify-content-center pt-3">
                    {checkEdited() && (
                      <div className="">
                        <button
                          onClick={UpdateExtraItem}
                          className="custom-btn-small custom-buton"
                        >
                          Update extra items
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
}

export default withRouter(EditRequestItems);
