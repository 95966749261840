import React, { useState } from "react";
import { googleBtnImage } from "../../../assets";
import GoogleLogin from "react-google-login";
import { withRouter } from "react-router-dom";
import { googleAuthClientID } from "../../../bl_libs/invokeApi";
function GoogleAuth(props) {
  const { connect_with_google, email } = props;
  const responseGoogle = (response) => {
    console.log(response, "google response");
    connect_with_google(response.code);
  };

  const responseGoogleFailed = (response) => {
    // showErrorSnak("Authentication failed for Google.");
  };

  return (
    <>
      <GoogleLogin
        clientId={googleAuthClientID}
        buttonText={email ? email : "CONNECT WITH GOOGLE"}
        autoLoad={false}
        disabled={false}
        onSuccess={responseGoogle}
        onFailure={responseGoogleFailed}
        cookiePolicy={"single_host_origin"}
        //
        responseType="code"
        accessType="offline"
        scope="openid email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
      />
    </>
  );
}

export default withRouter(GoogleAuth);
