import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useState } from "react";

const SnakbarContext = React.createContext();

export const useSnakbar = () => useContext(SnakbarContext);

export function ContextSnackbar({ children }) {
  const [snakbar, setSnakbar] = useState({
    show: false,
    message: "hi",
    type: "success",
  });

  const handleClose = () => {
    setSnakbar({
      show: false,
      message: "",
      type: "",
    });
  };

  const showErrorSnak = (msg) => {
    if (!msg) return;
    setSnakbar({
      show: true,
      message: msg,
      type: "error",
    });
  };

  const showSuccessSnak = (msg) => {
    if (!msg) return;
    setSnakbar({
      show: true,
      message: msg,
      type: "success",
    });
  };

  console.log(children, "props child");
  return (
    <SnakbarContext.Provider value={{ showErrorSnak, showSuccessSnak }}>
      {children}
      <Snackbar
        open={snakbar.show}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={snakbar.type}>
          {snakbar.message}
        </Alert>
      </Snackbar>
    </SnakbarContext.Provider>
  );
}
