import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { invokeApi } from "../../../bl_libs/invokeApi";
import { useEffect } from "react";
import {
  CALENDER_COLORS,
  CALENDER_STATUSES,
  SORT_BY_OPTIONS,
  TIMELINE_STATUS_OPTIONS,
} from "../../../ui_libs/constants";
import { settingsIconImage } from "../../../assets";
import GoogleAuth from "./GoogleAuth";
import { useSnakbar } from "../../../Context/Context-Snakbar/ContextSnackbar";

function CalenderSettings(props) {
  const {
    connect_with_google,
    calenderSettings,
    get_calender_settings,
    isConnect,
  } = props;
  const { showSuccessSnak } = useSnakbar();
  const [inputs, setInputs] = useState({});

  const handleChange = (key, value) => {
    setInputs({ ...inputs, [key]: value });
  };

  const get_selected_color = (value) => {
    const selected_color = CALENDER_COLORS.find(
      (c_color) => c_color.value === value
    );
    return selected_color?.color;
  };

  const update_settings = () => {
    let requestObj = {
      path: "/api/company/update_calendar_setting",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    requestObj["postData"] = {
      sync_status: inputs.sync_status,
      active_status_color: inputs.active_status_color,
      cancel_status_color: inputs.cancel_status_color,
      done_status_color: inputs.done_status_color,
      new_request_status: inputs.new_request_status,
      awaiting_company_response_status: inputs.awaiting_company_response_status,
      direct_request_accepted_status: inputs.direct_request_accepted_status,
      truck_assigned_and_ready_to_move_status:
        inputs.truck_assigned_and_ready_to_move_status,
      move_in_progress_status: inputs.move_in_progress_status,
      completed_waiting_for_confirmation_status:
        inputs.completed_waiting_for_confirmation_status,
      completed_status: inputs.completed_status,
      feedback_given_status: inputs.feedback_given_status,
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showSuccessSnak("Google calender settings updated successfully");
        get_calender_settings();
        setTimeout(() => {
          const close_btn = document.getElementById("close-filter");
          if (close_btn) {
            close_btn.click();
          }
        }, 200);
      } else {
      }
    });
  };

  useEffect(() => {
    setInputs(calenderSettings);
  }, [calenderSettings]);
  return (
    <>
      <span
        className="black-color pointer-point"
        data-toggle="modal"
        data-target="#change-filter"
      >
        <img src={settingsIconImage} />
        <span className="pl-2">Google Calender</span>
      </span>
      {/* <!-- Change Filter --> */}
      <div
        className="modal fade pb-m-80 mt-m-80"
        id="change-filter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-filter-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-filter"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="change-filter-title">
                  Google Calender Settings
                </h5>
                <div className="form-box pt-20">
                  <div className="row text-left">
                    <div className="col-12">
                      <label className="overline dark-grey-color">
                        Connect Google Account
                      </label>
                    </div>
                    <div className="col-12 text-center">
                      <GoogleAuth
                        email={calenderSettings?.email}
                        connect_with_google={connect_with_google}
                      />
                    </div>
                    {true && (
                      <>
                        <div className="col-12 mt-3">
                          <label className="overline dark-grey-color">
                            Sync with google calender
                          </label>
                        </div>
                        <div className="col-12">
                          <label className="dark-grey-color mb-0 custom-checkbox-wrapper">
                            Sync With Google Calender
                            <input
                              id="isStorage"
                              type="checkbox"
                              name="storage"
                              value={inputs.sync_status}
                              checked={inputs.sync_status}
                              onChange={() =>
                                handleChange("sync_status", !inputs.sync_status)
                              }
                            />
                            <span className="custom-check-box"></span>
                          </label>
                        </div>
                        <div className="col-12 mt-3">
                          <label className="overline dark-grey-color">
                            Choose Statuses for calender
                          </label>
                        </div>
                        {CALENDER_STATUSES.map((c_status) => (
                          <div className="col-12 mt-2">
                            <label className="dark-grey-color mb-0 custom-checkbox-wrapper">
                              {c_status.label}
                              <input
                                id="isStorage"
                                type="checkbox"
                                name="storage"
                                value={inputs[c_status.value]}
                                checked={inputs[c_status.value]}
                                onChange={() =>
                                  handleChange(
                                    c_status.value,
                                    !inputs[c_status.value]
                                  )
                                }
                              />
                              <span className="custom-check-box"></span>
                            </label>
                          </div>
                        ))}

                        <div className="col-9 mt-3">
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          >
                            Active Request Color
                          </label>
                          <select
                            name=""
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={inputs.active_status_color}
                            onChange={(e) =>
                              handleChange(
                                "active_status_color",
                                e.target.value
                              )
                            }
                          >
                            {CALENDER_COLORS.map((c_color) => (
                              <option
                                style={{ color: c_color.color }}
                                value={c_color.value}
                              >
                                {c_color.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-3 mt-3">
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          ></label>
                          <input
                            className="form-control form-control-sm mt-1"
                            type="color"
                            disabled
                            value={get_selected_color(
                              inputs.active_status_color
                            )}
                          />
                        </div>

                        <div className="col-9 mt-2">
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          >
                            Done Request Color
                          </label>
                          <select
                            name=""
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={inputs.done_status_color}
                            onChange={(e) =>
                              handleChange("done_status_color", e.target.value)
                            }
                          >
                            {CALENDER_COLORS.map((c_color) => (
                              <option
                                style={{ color: c_color.color }}
                                value={c_color.value}
                              >
                                {c_color.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-3 mt-2">
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          ></label>
                          <input
                            className="form-control form-control-sm mt-1"
                            type="color"
                            disabled
                            value={get_selected_color(inputs.done_status_color)}
                          />
                        </div>

                        <div className="col-9 mt-2">
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          >
                            Cancel Request Color
                          </label>
                          <select
                            name=""
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            onChange={(e) =>
                              handleChange(
                                "cancel_status_color",
                                e.target.value
                              )
                            }
                            value={inputs.cancel_status_color}
                          >
                            {CALENDER_COLORS.map((c_color) => (
                              <option
                                style={{ color: c_color.color }}
                                value={c_color.value}
                              >
                                {c_color.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-3 mt-2">
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          ></label>
                          <input
                            className="form-control form-control-sm mt-1"
                            type="color"
                            disabled
                            value={get_selected_color(
                              inputs.cancel_status_color
                            )}
                          />
                        </div>

                        <div className="col-12 mt-4">
                          <button
                            type="button"
                            className="red-btn custom-buton custom-buton-large w-100"
                            onClick={update_settings}
                          >
                            Apply
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CalenderSettings;
