import { CircularProgress, TablePagination } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { invokeApi } from "../../bl_libs/invokeApi";
import { editIconImage, addIconImage, deleteIcon, menu } from "../../assets";
import AddItem from "./components/AddItem";
import EditItem from "./components/EditItem";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
function ExtraItems(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const [isLoading, setIsLoading] = useState(true);
  //------------------------------------------>  pagination settings
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [items, setItems] = useState();
  const [editItemId, setEditItemId] = useState();
  const [editItemName, setEditItemName] = useState();
  const [editItemDescription, setEditItemDescription] = useState();
  const [editItemStatus, setEditItemStatus] = useState();
  const [editItemPrice, setEditItemPrice] = useState();
  const [deleteId, setDeleteId] = useState();
  const [update, setUpdate] = useState(false);
  //----------------------------------------------------------------

  //////////////////////////////////////////////////////////////////

  const fetchActiveRequests = (type) => {
    let requestObj = {
      path: `/api/company/company_extra_item_list`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        // SetRequests(res.emailtemplate);
        setIsLoading(false);
        setItems(res.get_items_list);
      }
    });
  };

  useEffect(() => {
    fetchActiveRequests();
  }, [update]);

  if (isLoading) {
    return (
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title"> Company Extra Items</p>
          <div className="container-fluid">
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </div>
      </section>
    );
  }
  const handleEdit = (item) => {
    function setItem() {
      setEditItemId(item.id);
      setEditItemName(item.name);
      setEditItemDescription(item.desciption);
      setEditItemPrice(item.unit_price);
      setEditItemStatus(item.is_active === true ? "1" : "0");
      return true;
    }
    if (setItem()) {
      document.getElementById("hiddenEditButton").click();
    }
  };
  const handleDelete = (id) => {
    console.log("helooooooooooooo delete");
    let requestObj = {
      path: `/api/company/delete_company_extra_item/${id}`,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setDeleteId();
        setUpdate(!update);
        // document.getElementById("del-btn").click();
        showSuccessSnak("Item deleted successfully");
      }
    });
  };
  return (
    <>
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid min-width-650">
            <div className="d-flex justify-content-between">
              <p className="small-text-bold page-title">Company Extra Items</p>
              <a
                href=""
                className="small-text-bold red-color add-new-btn"
                data-toggle="modal"
                data-target="#add-item"
              >
                <img src={addIconImage} /> <span>Add New</span>
              </a>
            </div>
            <div className="calendar-wrapper mt-40 mt-m-20 pb-30">
              <div className="row"></div>
              <div className="rounded-box pb-3">
                <div className="row">
                  <div className="col-12">
                    <table
                      id="example"
                      className="responsive table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th className="small-sub-title dark-grey-color">#</th>
                          <th className="small-sub-title dark-grey-color">
                            Name
                          </th>
                          <th className="small-sub-title dark-grey-color">
                            Description
                          </th>
                          <th className="small-sub-title dark-grey-color">
                            Unit Price
                          </th>
                          <th className="small-sub-title dark-grey-color">
                            Currency
                          </th>

                          <th className="small-sub-title dark-grey-color">
                            Status
                          </th>

                          <th className="small-sub-title dark-grey-color">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items &&
                          items.map((item, i) => (
                            <tr key={item.id}>
                              <td>{i + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.desciption}</td>
                              <td>{item.unit_price}</td>
                              <td>{item.currency}</td>
                              <td>
                                {item.is_active === true && (
                                  <a className="custom-btn-small custom-button-extra-small req-status-button status-button-active">
                                    Active
                                  </a>
                                )}
                                {item.is_active === false && (
                                  <a className="custom-btn-small custom-button-extra-small req-status-button status-button-cancelled">
                                    In-Active
                                  </a>
                                )}
                              </td>
                              <td>
                                <div className="dropdown">
                                  <a
                                    id="more-menu"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src={menu} />
                                  </a>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="more-menu"
                                  >
                                    <button
                                      className="dropdown-item add-new-btn"
                                      onClick={() => handleEdit(item)}
                                    >
                                      <img
                                        src={editIconImage}
                                        className="red-color mr-2"
                                      />
                                      <span>Edit</span>
                                    </button>
                                    <button
                                      hidden
                                      id="hiddenEditButton"
                                      data-toggle="modal"
                                      data-target="#edit-item"
                                    />
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        setDeleteId(item.id);
                                        document
                                          .getElementById("hiddenDeleteButton")
                                          .click();
                                      }}
                                    >
                                      <img src={deleteIcon} className="mr-2" />
                                      Delete
                                    </button>
                                    <button
                                      hidden
                                      id="hiddenDeleteButton"
                                      data-toggle="modal"
                                      data-target="#delete"
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {/* loop Close */}
                      </tbody>
                    </table>
                    {/* <TablePagination
                      component="div"
                      count={totalCount}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage={
                        <span className="pagination-text">Rows per page:</span>
                      }
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade mt-m-80 pb-m-80"
          id="add-item"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="add-truck-title"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content pb-30">
              <div className="modal-header text-center border-0 pb-0">
                <button
                  id="close_truck"
                  type="button"
                  className="close black-color"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AddItem update={update} setUpdate={setUpdate} />
            </div>
          </div>
        </div>
        <div
          className="modal fade mt-m-80 pb-m-80"
          id="edit-item"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="add-truck-title"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content pb-30">
              <div className="modal-header text-center border-0 pb-0">
                <button
                  id="close_truck"
                  type="button"
                  className="close black-color"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setEditItemId();
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {editItemId && (
                <EditItem
                  editId={editItemId}
                  setEditId={setEditItemId}
                  editName={editItemName}
                  editDescription={editItemDescription}
                  editStatus={editItemStatus}
                  editPrice={editItemPrice}
                  update={update}
                  setUpdate={setUpdate}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="delete"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal-title"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content pb-30">
              <div className="modal-header text-center border-0 pb-0">
                <button
                  type="button"
                  className="close black-color"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
                {/* {deleteId && ( */}
                <div className="modal-body p-0">
                  <h5 className="text-center" id="modal-title">
                    Delete
                  </h5>
                  <p className="text-center sub-title pb-4 mb-3 pt-40">
                    Are your sure you want to Delete this item?
                  </p>
                  <div className="row justify-content-center text-center">
                    <div className="col-6">
                      <button
                        onClick={() => handleDelete(deleteId)}
                        data-dismiss="modal"
                        aria-label="Close"
                        className="custom-btn-small custom-buton float-right"
                      >
                        Yes
                      </button>
                      <button
                        // id="del-btn"
                        className="d-none"
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="col-6">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="custom-btn-small custom-buton custom-button-pink float-left"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
        {/* {requestType === "active" && <ActiveFilter />} */}
        {/* {requestType === "cancelled" && <CancelRequestsFilter />} */}
        {/* {requestType === "done" && <DoneRequestFilter />} */}
      </>
    </>
  );
}

export default withRouter(ExtraItems);
