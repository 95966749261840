import { Radio } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { invokeApi } from "../../bl_libs/invokeApi";
function WithdrawScreen() {
  const [foremanList, setForemanList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForeman, setIsLoadingForeman] = useState(true);

  const FetchWithdrawDetails = () => {
    let requestObj = {
      path: "/api/company/withdraw_payment",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      request_id: "427",
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res);
      } else {
      }
    });
  };

  useEffect(() => {
    FetchWithdrawDetails();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <p className="small-text-bold page-title ml-2 pt-4">
              Payment Withdraw
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <section className="main-content address-page">
              <div className="container">
                <div className="add-items-wrapper">
                  <div className="selected-room ">
                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-8 text-center ">
                        {/* {errMessage && (
                          <Alert variant="filled" severity="error">
                            {errMessage}
                          </Alert>
                        )} */}
                        <div className="rounded-box pt-40 pb-40">
                          <span className=" text-center font-weight-bold">
                            Get Paid Now
                          </span>
                          <div className="row pt-40 text-left pl-4 ">
                            <div className="col-12 col-md-5 pb-3 sub-title black-color">
                              Withdraw Bank :
                            </div>
                            <div className="col-12 col-sm-7 col-md-7 pb-3">
                              <Select
                                name="name"
                                options={foremanList}
                                isLoading={isLoadingForeman}
                                className="basic-multi-select pr-3"
                                classNamePrefix="select"
                                // onChange={handleForeman}
                                isDisabled={isLoading}
                              />
                            </div>
                            <div className="col-5 pb-3 sub-title black-color">
                              Amount :
                            </div>
                            <div className="col-7 pb-3">
                              <Radio
                                checked={true}
                                value="a"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                              />
                            </div>
                            <div className="col-5 pb-3 sub-title black-color">
                              LA Moving Star Fee :
                            </div>
                            <div className="col-7 pb-3">${1.0}</div>

                            <div className="col-12 pt-40">
                              <div className="row justify-content-center">
                                <div className="status-buttons">
                                  <span className="custom-btn-small custom-button-extra-small req-status-button filter-button req-done active">
                                    Get Paid Now
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default WithdrawScreen;
