import React from "react";
import {Link} from "react-router-dom";

const Menu = () => (
    <div>
            <ul>
                    <li>
                            <Link to={'/about'}>About</Link>
                    </li>
                    <li>
                        <Link to={'/profile'}>404 Page</Link>
                    </li>
                    <li>
                            <Link to={'/login'}>Logout</Link>
                    </li>

            </ul>
    </div>
);

export default Menu;