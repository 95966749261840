import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import clsx from "clsx";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import CloseIcon from "@material-ui/icons/Close";
import { infoGreyImage } from "../../../assets";
import RequestCard from "./RequestCard";
import moment from "moment";
import { invokeApi } from "../../../bl_libs/invokeApi";

const anchor = "right";
function RequestsSlider(props) {
  const { toggleDrawer, date, tab, setTab } = props;

  const [list, setList] = useState({
    active: [],
    done: [],
    cancelled: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  const handleChangeTab = (newValue) => {
    setTab(newValue);
  };

  const FetchTodaysRequest = (date) => {
    if (!date) return;
    let requestObj = {
      path: "/app_api/today_calendar_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      date: moment(date).format("MM-DD-YYYY"),
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res, "FetchTodaysRequest");
        setList({
          active: res.active_request,
          done: res.done_request,
          cancelled: res.cancel_request,
        });
        setIsLoading(false);
      } else {
      }
    });
  };

  const getTabList = () => {
    if (tab === "ACTIVE") {
      return list.active;
    }
    if (tab === "DONE") {
      return list.done;
    }
    if (tab === "CANCELLED") {
      return list.cancelled;
    }
  };

  useEffect(() => {
    FetchTodaysRequest(date);
  }, [tab]);

  if (isLoading) {
    return (
      <>
        <div
          role="presentation"
          onKeyDown={toggleDrawer(anchor, false)}
          className="notification-sidebar"
        >
          <div
            className="notification-header"
            style={{
              paddingTop: window.innerWidth > 760 ? 0 : 60,
            }}
          >
            <ListItem className="notification-header">
              <ListItemIcon>
                <CloseIcon
                  htmlColor="white"
                  onClick={toggleDrawer(anchor, false)}
                  className="pointer-point"
                />
              </ListItemIcon>
              <ListItemText
                // className="notification-text"
                primaryTypographyProps={{
                  className: "notification-text",
                  style: { fontWeight: 600 },
                }}
                primary={moment(date).format("LL")}
              />
            </ListItem>
            <div className="row text-center slider-tabs">
              <div
                onClick={() => handleChangeTab("ACTIVE")}
                className={
                  tab === "ACTIVE"
                    ? "col-4 slider-tab-active"
                    : "col-4 slider-tab"
                }
              >
                Active
              </div>
              <div
                className={
                  tab === "DONE"
                    ? "col-4 slider-tab-active"
                    : "col-4 slider-tab"
                }
                onClick={() => handleChangeTab("DONE")}
              >
                Done
              </div>
              <div
                className={
                  tab === "CANCELLED"
                    ? "col-4 slider-tab-active"
                    : "col-4 slider-tab"
                }
                onClick={() => handleChangeTab("CANCELLED")}
              >
                Cancelled
              </div>
            </div>
          </div>
        </div>
        <div
          className="Circular-Progress"
          style={{ paddingTop: 300, width: "100%" }}
        >
          <CircularProgress />
        </div>
      </>
    );
  }

  return (
    <>
      <div
        role="presentation"
        onKeyDown={toggleDrawer(anchor, false)}
        className="notification-sidebar"
      >
        <div
          className="notification-header"
          style={{
            paddingTop: window.innerWidth > 760 ? 0 : 60,
          }}
        >
          <ListItem className="notification-header">
            <ListItemIcon>
              <CloseIcon
                htmlColor="white"
                onClick={toggleDrawer(anchor, false)}
                className="pointer-point"
              />
            </ListItemIcon>
            <ListItemText
              // className="notification-text"
              primaryTypographyProps={{
                className: "notification-text",
                style: { fontWeight: 600 },
              }}
              primary={moment(date).format("LL")}
            />
          </ListItem>
          <div className="row text-center slider-tabs">
            <div
              onClick={() => handleChangeTab("ACTIVE")}
              className={
                tab === "ACTIVE"
                  ? "col-4 slider-tab-active"
                  : "col-4 slider-tab"
              }
            >
              Active
            </div>
            <div
              className={
                tab === "DONE" ? "col-4 slider-tab-active" : "col-4 slider-tab"
              }
              onClick={() => handleChangeTab("DONE")}
            >
              Done
            </div>
            <div
              className={
                tab === "CANCELLED"
                  ? "col-4 slider-tab-active"
                  : "col-4 slider-tab"
              }
              onClick={() => handleChangeTab("CANCELLED")}
            >
              Cancelled
            </div>
          </div>
        </div>

        <List
          style={{
            paddingTop: window.innerWidth > 760 ? 110 : 170,
          }}
        >
          {getTabList().length > 0 ? (
            getTabList().map((req) => (
              <RequestCard key={req.request_id} request={req} />
            ))
          ) : (
            <div className="pt-40 text-center">
              <span className=" light-red-color small-font-size mt-40">
                No Request Exist
              </span>
            </div>
          )}
        </List>
      </div>
    </>
  );
}

export default RequestsSlider;
