import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { IconButton } from "@material-ui/core";

function CopyToBoard(props) {
  const { content } = props;
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <>
      <CopyToClipboard text={content} onCopy={onCopyText}>
        <button
          style={{ color: "white", outline: "none", width: 210 }}
          className="custom-btn-small red-btn ml-1 mr-1 mt-2"
        >
          {isCopied ? (
            "Direct Link Copied!"
          ) : (
            <>
              Copy Direct Link <FileCopyIcon className="ml-1" />
            </>
          )}
        </button>
      </CopyToClipboard>
    </>
  );
}

export default CopyToBoard;
