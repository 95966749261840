import React from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { createEventId } from "./event-utils";
import { Backdrop, CircularProgress, LinearProgress } from "@material-ui/core";
import {
  cancelledIconImage,
  activeIconImage,
  doneTickImage,
} from "../../../assets";
import moment from "moment";
import { invokeApi } from "../../../bl_libs/invokeApi";
export default class DemoApp extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    weekendsVisible: true,
    isLoading: true,
    currentEvents: [],
  };

  render() {
    const handleDateClick = (event) => {
      this.props.hadleSliderOpen(event.dateStr);
    };

    const FetchEvents = (start, end) => {
      // set date for one day in past
      end.setDate(end.getDate() - 1);
      let requestObj = {
        path: "/app_api/month_request_count",
        method: "POST",
        headers: {
          "x-sh-auth": localStorage.getItem("token"),
        },
      };
      let postData = {
        start_date: moment(start).format("MM-DD-YYYY"),
        end_date: moment(end).format("MM-DD-YYYY"),
      };
      requestObj["postData"] = postData;

      this.setState({
        ...this.state,
        isLoading: true,
      });

      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          console.log(res, "FetchEvents");

          let collect_events = [];

          res.month_request_count.map((day) => {
            if (day.active_count > 0) {
              collect_events.push({
                title: day.active_count,
                date: moment(day.s_date).format("YYYY-MM-DD"),
                className: "active-req pointer-point",
                type: "active",
                extra_date: moment(day.s_date).format("YYYY-MM-DD"),
              });
            }

            if (day.cancel_count > 0) {
              collect_events.push({
                title: day.cancel_count,
                date: moment(day.s_date).format("YYYY-MM-DD"),
                className: "cancelled-req pointer-point",
                type: "cancel",
                extra_date: moment(day.s_date).format("YYYY-MM-DD"),
              });
            }

            if (day.done_count > 0) {
              collect_events.push({
                title: day.done_count,
                date: moment(day.s_date).format("YYYY-MM-DD"),
                className: "done-req pointer-point",
                type: "done",
                extra_date: moment(day.s_date).format("YYYY-MM-DD"),
              });
            }
          });

          this.setState({
            currentEvents: collect_events,
            isLoading: false,
          });
        } else {
        }
      });
    };
    console.log(this.props.dateRange, "EVENTS");

    const renderEventContent = (eventInfo) => {
      console.log(eventInfo, "eventInfo");
      let type = eventInfo.event._def.extendedProps.type;
      let date = eventInfo.event._def.extendedProps.extra_date;
      return (
        <div
          onClick={() => {
            {
              type === "active" && this.props.hadleSliderOpen(date, "ACTIVE");
            }
            {
              type === "done" && this.props.hadleSliderOpen(date, "DONE");
            }
            {
              type === "cancel" &&
                this.props.hadleSliderOpen(date, "CANCELLED");
            }
          }}
        >
          {/* <span className=" light-red-color small-font-size">Today</span> */}
          <b>{eventInfo.timeText}</b>
          <span>{eventInfo.event.title}</span>
          {type === "active" && <img src={activeIconImage} className="pl-1" />}
          {type === "done" && <img src={doneTickImage} className="pl-1" />}
          {type === "cancel" && (
            <img src={cancelledIconImage} className="pl-1" />
          )}
        </div>
      );
    };

    return (
      <>
        <div className="demo-app">
          <div style={{ height: 20 }} className="text-center">
            {this.state.isLoading && (
              <CircularProgress
                // className="text-center"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            )}
          </div>
          <div className="demo-app-main">
            <FullCalendar
              dateClick={handleDateClick}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "today",
              }}
              initialView="dayGridMonth"
              // initialDate={}
              datesSet={(arg) => {
                console.log(arg);
                //arg includes data about current visible dates
                // console.log(arg.view.activeStart, "starting visible date"); //starting visible date
                // console.log(arg.view.activeEnd, "ending visible date"); //ending visible date
                FetchEvents(arg.view.activeStart, arg.view.activeEnd);
              }}
              showNonCurrentDates={false}
              editable={false}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={this.state.weekendsVisible}
              events={this.state.currentEvents}
              // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
              // select={this.handleDateSelect}
              eventContent={renderEventContent} // custom render function
              // eventClick={this.handleEventClick}
              // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
              /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            />
          </div>
        </div>
      </>
    );
  }

  renderSidebar() {
    return (
      <div className="demo-app-sidebar">
        <div className="demo-app-sidebar-section">
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
        </div>
        <div className="demo-app-sidebar-section">
          <label>
            <input
              type="checkbox"
              checked={this.state.weekendsVisible}
              onChange={this.handleWeekendsToggle}
            ></input>
            toggle weekends
          </label>
        </div>
        <div className="demo-app-sidebar-section">
          <h2>All Events ({this.state.currentEvents.length})</h2>
          <ul>{this.state.currentEvents.map(renderSidebarEvent)}</ul>
        </div>
      </div>
    );
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleDateSelect = (selectInfo) => {
    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  handleEventClick = (clickInfo) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'`
      )
    ) {
      clickInfo.event.remove();
    }
  };

  handleEvents = (events) => {
    console.log(events, "from handleEvents");
    this.setState({
      currentEvents: events,
    });
  };
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>
        {formatDate(event.start, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </b>
      <i>{event.title}</i>
    </li>
  );
}
