import { Avatar } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { withRouter } from "react-router-dom";
import { companyProfilePicImage } from "../../../../../../assets";
import { s3baseUrl } from "../../../../../../bl_libs/invokeApi";

function ChatHeader(props) {
  const { selectedUser } = props;
  const { profile_data, type } = selectedUser;

  const animation = "";

  if (selectedUser === "") {
    return (
      <>
        <div className="row pt-2">
          <div className="col-3 col-lg-2 col-xl-1">
            <div className="profile-pic-small">
              <Skeleton
                variant="circle"
                width={60}
                height={60}
                animation={animation}
              />
            </div>
          </div>

          <div className="col-6 col-lg-6 col-xl-7 pt-2">
            <div className="my-header-title">
              <span className="pl-xl-0">
                <Skeleton variant="text" width={200} animation={animation} />
              </span>
            </div>
            <div className="under-text">
              {" "}
              <Skeleton variant="text" width={100} animation={animation} />
            </div>
          </div>

          <div
            className="col-3 col-lg-4 col-xl-4"
            onClick={() =>
              props.history.push(
                "/dashboard/my-requests/" + selectedUser.request_id
              )
            }
          >
            <span className="Show-request-text pt-1 pointer-point"></span>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="row pt-2">
        <div className="col-3 col-lg-2 col-xl-1">
          <div className="profile-pic-small">
            {type === 0 ? (
              //company
              profile_data.profile_picture ? (
                // company has image
                <img
                  src={s3baseUrl + profile_data.profile_picture}
                  className="rounded-circle circle-box circle-box-small img-50"
                />
              ) : (
                // company has no image
                <Avatar style={{ width: 60, height: 60 }}>
                  {profile_data.name[0].toUpperCase()}
                </Avatar>
              )
            ) : type === 2 ? (
              //foreman
              profile_data.profile_pic ? (
                //foreman has image
                <img
                  src={s3baseUrl + profile_data.profile_pic}
                  className="rounded-circle circle-box circle-box-small img-50"
                />
              ) : (
                //foreman no image
                <Avatar style={{ width: 60, height: 60 }}>
                  {profile_data.first_name[0].toUpperCase()}
                </Avatar>
              )
            ) : //customer
            profile_data.profile_picture ? (
              //customer has image
              <img
                src={s3baseUrl + profile_data.profile_picture}
                className="rounded-circle circle-box circle-box-small img-50"
              />
            ) : (
              // customer has no image
              <Avatar style={{ width: 60, height: 60 }}>
                {profile_data.first_name[0].toUpperCase()}
              </Avatar>
            )}
            {selectedUser.profile_data.online_status === 0 ? (
              <div className="status-inactive"></div>
            ) : (
              <div className="status-active"></div>
            )}
          </div>
        </div>

        <div className="col-6 col-lg-6 col-xl-7 pt-2">
          <div className="my-header-title">
            <span className="pl-xl-0">
              {selectedUser.type !== 0
                ? selectedUser.profile_data.first_name +
                  " " +
                  selectedUser.profile_data.last_name
                : selectedUser.profile_data.name}
            </span>
          </div>
          <div className="under-text">Request #{selectedUser.request_id}</div>
        </div>

        <div
          className="col-3 col-lg-4 col-xl-4"
          onClick={() =>
            props.history.push(
              "/company/company-requests/" + selectedUser.request_id
            )
          }
        >
          <span className="Show-request-text pt-4 pointer-point">
            Show request
          </span>
        </div>
      </div>
    </>
  );
}

export default withRouter(ChatHeader);
