import React, { useState } from "react";
import { invokeApi, s3baseUrl } from "../../../bl_libs/invokeApi";
import { makeStyles, Badge } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

function EditTruck(props) {
  const { truck, closePopUp, fetchTruckList } = props;

  const classes = useStyles();

  //for show error

  const [errMessage, setErrMessage] = React.useState("");

  const [preview, setPreview] = useState(
    truck.truck_image_models.map((pic) => {
      return {
        _pic: pic.image_url,
      };
    })
  );
  // truck.truck_image_models
  //for add truck inputs

  const [truckInputs, setTruckInputs] = useState({
    truck_name: truck.name,
    plate_number: truck.plate_number,
    year: truck.year,
    truck_make: truck.make,
    truck_model: truck.model,
    truck_size: truck.size,
  });

  //it is use to refresh state if changes not occures automatically
  const [refresh, setRefresh] = useState(true);
  const hardRefresh = () => {
    setRefresh(!refresh);
  };

  //handle change in truck inputs

  const handleChangeTruckInputs = (e) => {
    setTruckInputs({
      ...truckInputs,
      [e.target.id]: e.target.value,
    });
  };

  //handle cross image

  const handleImageCross = (pic) => {
    console.log(pic);
    console.log(preview);
    let temp = [];
    for (let i = 0; i < preview.length; i++) {
      if (preview[i] !== pic) {
        temp.push(preview[i]);
      }
    }
    setPreview(temp);
    hardRefresh();
  };

  ////////////////////////////////////////////////////////////////////handle delete section

  //for delete tab
  const [deleteScreen, setDeleteScreen] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState("");

  const handleTruckDelete = () => {
    console.log("delete");
    let requestObj = {
      path: "/api/company/delete_truck/" + truck.id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res);
        setDeleteSuccessMessage("Truck Deleted Successfully");
        fetchTruckList();
        setTimeout(() => {
          closePopUp();
        }, 1000);
      } else {
        setErrMessage(res.message);
      }
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////handle truck submit

  const handleTruckSubmit = (e) => {
    e.preventDefault();

    console.log(truckInputs);
    //Api call
    let requestObj = {
      path: "/api/company/edit_truck/" + truck.id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      name: truckInputs.truck_name,
      plate_No: truckInputs.plate_number,
      year: truckInputs.year,
      make: truckInputs.truck_make,
      model: truckInputs.truck_model,
      size: truckInputs.truck_size,
      truck_pic_array: preview,
    };
    console.log(postData);
    requestObj["postData"] = postData;

    invokeApi(requestObj).then((res) => {
      console.log(res, " RESPONSE");
      if (res.code === 200) {
        console.log(res);
        //for refresh truck list
        fetchTruckList();
        setDeleteSuccessMessage("Truck updated successfully");
        setTimeout(() => {
          closePopUp();
        }, 1000);
      } else {
        console.log(res);
        setErrMessage(res.message);
      }
    });
  };

  //handle upload files

  const handleUpload = (event) => {
    console.log("upload");
    let requestObj = {
      path: "/app_api/upload_s3_file",
      method: "POST",
      headers: {},
    };
    let formData = new FormData();
    formData.append("upload_file", event.target.files[0]);
    formData.append("upload_type", "profile");
    requestObj["postData"] = formData;
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        let temp = preview;
        temp.push({
          _pic: res.file_url,
        });
        setPreview(temp);
        hardRefresh();
      } else {
        setErrMessage(res.message);
      }
    });
  };

  if (deleteSuccessMessage !== "") {
    return (
      <Alert variant="filled" severity="success">
        {deleteSuccessMessage}
      </Alert>
    );
  }

  if (deleteScreen) {
    return (
      <>
        {deleteSuccessMessage !== "" ? (
          <Alert variant="filled" severity="success">
            {deleteSuccessMessage}
          </Alert>
        ) : (
          <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
            <div className="modal-body p-0">
              {errMessage && (
                <Alert variant="filled" severity="error">
                  {errMessage}
                </Alert>
              )}
              <h5 className="text-center" id="logout-title">
                Delete Truck
              </h5>

              <p className="text-center sub-title pb-4 mb-3 pt-40">
                Are your sure you want to delete this truck?
              </p>
              <div className="row justify-content-center text-center">
                <div className="col-6">
                  <button
                    onClick={handleTruckDelete}
                    className="custom-btn-small custom-buton float-right"
                  >
                    Yes
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="custom-btn-small custom-buton custom-button-pink float-left"
                    onClick={() => setDeleteScreen(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="modal-body p-0">
        <h5 className="text-center" id="add-truck-title">
          Edit Truck
        </h5>
        {errMessage && (
          <Alert variant="filled" severity="error">
            {errMessage}
          </Alert>
        )}
        <div className="form-box pt-20">
          <form onSubmit={handleTruckSubmit}>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label
                    htmlFor="truck_name"
                    className="overline dark-grey-color"
                  >
                    Truck name
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control small-font-size"
                    id="truck_name"
                    placeholder="Truck name"
                    onChange={handleChangeTruckInputs}
                    value={truckInputs.truck_name}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label
                    htmlFor="plate_number"
                    className="overline dark-grey-color"
                  >
                    Plate number
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control small-font-size"
                    id="plate_number"
                    placeholder="Plate number"
                    value={truckInputs.plate_number}
                    onChange={handleChangeTruckInputs}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label htmlFor="year" className="overline dark-grey-color">
                    Year
                  </label>
                  <NumberFormat
                    required
                    type="text"
                    className="form-control small-font-size"
                    id="year"
                    placeholder="Year"
                    value={truckInputs.year}
                    onChange={handleChangeTruckInputs}
                    format="####"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label
                    htmlFor="truck_make"
                    className="overline dark-grey-color"
                  >
                    Truck make
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control small-font-size"
                    id="truck_make"
                    placeholder="Truck make"
                    value={truckInputs.truck_make}
                    onChange={handleChangeTruckInputs}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label
                    htmlFor="truck_model"
                    className="overline dark-grey-color"
                  >
                    Truck model
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control small-font-size"
                    id="truck_model"
                    placeholder="Truck model "
                    value={truckInputs.truck_model}
                    onChange={handleChangeTruckInputs}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label
                    htmlFor="truck_size"
                    className="overline dark-grey-color"
                  >
                    truck size
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control small-font-size"
                    id="truck_size"
                    placeholder="truck size"
                    onChange={handleChangeTruckInputs}
                    value={truckInputs.truck_size}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {preview.length === 0
                  ? ""
                  : preview.map((pic, i) => (
                      <Badge
                        key={i}
                        color="secondary"
                        overlap="circle"
                        badgeContent={
                          <span
                            style={{
                              fontSize: 16,
                              marginBottom: 2,
                              cursor: "pointer",
                            }}
                            onClick={() => handleImageCross(pic)}
                          >
                            x
                          </span>
                        }
                      >
                        <img
                          width="50"
                          height="50"
                          viewBox="0 0 50 50"
                          src={s3baseUrl + pic._pic}
                          style={{
                            margin: 5,
                          }}
                        ></img>
                      </Badge>
                    ))}

                {preview.length < 4 && (
                  <>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="icon-button-file-truck"
                      onChange={handleUpload}
                      type="file"
                      value=""
                    />
                    <label htmlFor="icon-button-file-truck">
                      <svg
                        style={{ cursor: "pointer" }}
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="50" height="50" rx="6" fill="#F2F2F2" />
                        <path
                          d="M16 17V14H18V17H21V19H18V22H16V19H13V17H16ZM19 23V20H22V17H29L30.83 19H34C35.1 19 36 19.9 36 21V33C36 34.1 35.1 35 34 35H18C16.9 35 16 34.1 16 33V23H19ZM26 32C28.76 32 31 29.76 31 27C31 24.24 28.76 22 26 22C23.24 22 21 24.24 21 27C21 29.76 23.24 32 26 32ZM22.8 27C22.8 28.77 24.23 30.2 26 30.2C27.77 30.2 29.2 28.77 29.2 27C29.2 25.23 27.77 23.8 26 23.8C24.23 23.8 22.8 25.23 22.8 27Z"
                          fill="#C4C4C4"
                        />
                      </svg>
                    </label>
                  </>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6">
                <div className="form-group pt-3">
                  <button
                    type="submit"
                    className="red-btn custom-buton custom-buton-large w-100"
                  >
                    Update Truck
                  </button>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#C4314B",
                    cursor: "pointer",
                  }}
                >
                  <span onClick={() => setDeleteScreen(true)}>DELETE</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditTruck;
