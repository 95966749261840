import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { blackBackArrowImage } from "../../assets";
import { invokeApi, s3baseUrl } from "../../bl_libs/invokeApi";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
import { Editor } from "@tinymce/tinymce-react";
import { CustomEditor } from "../../components";
function EditEmailTemplate(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const [isLoading, setIsLoading] = React.useState(true);
  const [emailData, setEmailData] = React.useState(true);

  const editorRef = useRef(null);

  const handleChange = (e) => {
    setEmailData({ ...emailData, [e.target.name]: e.target.value });
  };

  const getEditorData = () => {
    if (editorRef.current) {
      const value = editorRef.current.getContent();
      return value;
    }
    return "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const editor_data = getEditorData();

    if (!editor_data) {
      showErrorSnak("Email template is required.");
      return;
    }

    const postData = {
      title: emailData.title,
      type: emailData.type,
      email_subject: emailData.email_subject,
      email_message: editor_data,
      is_active: `${emailData.is_active}`,
    };
    let requestObj = {
      path: "/api/company/edit_email_template/" + props.match.params.id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData,
    };
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showSuccessSnak("Email template updated successfully.");
        props.history.goBack();
      } else {
        showErrorSnak(res.message);
        setIsLoading(false);
      }
    });
  };

  const GetEmailDetails = (type) => {
    let requestObj = {
      path: "/api/company/detail_email_template/" + props.match.params.id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setEmailData(res.emailtemplate);
        setIsLoading(false);
      } else {
        showErrorSnak(res.message);
      }
    });
  };

  function example_image_upload_handler(blobInfo, success, failure, progress) {
    let requestObj = {
      path: "/app_api/upload_s3_file",
      method: "POST",
      headers: {},
    };

    let _formData = new FormData();
    _formData.append("upload_file", blobInfo.blob());
    requestObj["postData"] = _formData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "IMAGE UPLOADER");
      if (res.code === 200) {
        success(s3baseUrl + res.file_url);
      } else {
        failure(res.message);
      }
    });
  }

  useEffect(() => {
    GetEmailDetails();
  }, []);

  if (isLoading) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">
              <img
                className="pointer-point pr-1"
                src={blackBackArrowImage}
                onClick={() => props.history.goBack()}
              />
              <span>Edit Email Template</span>
            </p>
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </section>
      </>
    );
  }
  return (
    <section id="content-wrapper" className="content-wrapper calendar-page">
      <div className="container-fluid">
        <p className="small-text-bold page-title">
          <img
            className="pointer-point pr-1"
            src={blackBackArrowImage}
            onClick={() => props.history.goBack()}
          />
          <span>Edit Email Template</span>
        </p>
        <div className="calendar-wrapper">
          <div className="row justify-content-center pt-40">
            <div className="col-10 col-lg-8">
              <form onSubmit={handleSubmit}>
                <div className="form-group ">
                  <label htmlFor="email" className="overline dark-grey-color">
                    Email Title
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    value={emailData.title}
                    name="title"
                    className="form-control small-font-size"
                    placeholder=""
                  />
                </div>
                <div className="form-group ">
                  <label htmlFor="email" className="overline dark-grey-color">
                    Email Subject
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    value={emailData.email_subject}
                    name="email_subject"
                    className="form-control small-font-size"
                    placeholder=""
                  />
                </div>

                <div className="form-group ">
                  <label htmlFor="email" className="overline dark-grey-color">
                    Email Type
                  </label>
                  <input
                    required
                    disabled
                    // onChange={handleChange}
                    value={emailData.type}
                    name="type"
                    className="form-control small-font-size"
                    placeholder=""
                  />
                </div>

                <div className="form-group ">
                  <label htmlFor="email" className="overline dark-grey-color">
                    Email Template
                  </label>
                  <CustomEditor
                    data={
                      emailData.email_message ? emailData.email_message : ""
                    }
                    editorRef={editorRef}
                  />
                </div>
                <div className="form-group button-wrapper col-12">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                      <button
                        type="submit"
                        className="custom-buton custom-buton-large w-100"
                      >
                        Update Template
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditEmailTemplate;
