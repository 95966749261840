import React from "react";
import { Icon } from "@iconify/react";
function TeableHead({ columns }) {
  return (
    <thead>
      <tr>
        {columns.columns.map((column) => {
          return (
            <th
              onClick={() => column.onclick()}
              className={
                columns.sort_by === column.sort
                  ? "small-sub-title dark-grey-color table-heading table-sorted"
                  : "small-sub-title dark-grey-color table-heading"
              }
            >
              {column.title}
              {columns.sort_by === column.sort &&
              columns.sorting_way === "DESC" ? (
                <span className="ml-2 sorting-icon">
                  <Icon icon="ant-design:arrow-up-outlined" />
                </span>
              ) : (
                <span className="ml-2 sorting-icon">
                  <Icon icon="ant-design:arrow-down-outlined" />
                </span>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export default TeableHead;
