import React, { useEffect, useState } from "react";
import { invokeApi } from "../../bl_libs/invokeApi";
import BillingInfo from "./components/Billing-Info/BillingInfo";
import CurrentSubscription from "./components/Current-Subscription/CurrentSubscription";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
function SubscriptionBilling() {
  const [isLoading, setIsLoading] = useState(true);
  const [userSubscription, setUserSubscription] = useState({});
  const { showSuccessSnak } = useSnakbar();

  const CheckForSubscription = () => {
    let requestObj = {
      path: "/api/company/get_subscription",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setUserSubscription(res.subscribtion_data);
        setIsLoading(false);
      }
    });
  };

  const CancelSubscription = () => {
    let requestObj = {
      path: "/api/company/cancel_subscription",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showSuccessSnak("Subscription cancelled successfully");
        CheckForSubscription();
      } else {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    CheckForSubscription();
  }, []);
  return (
    <>
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid min-width-650">
          <div className="calendar-wrapper mt-3 pb-30">
            <p className="small-text-bold page-title">Current Subscription </p>
            <CurrentSubscription
              userSubscription={userSubscription}
              isLoading={isLoading}
              CancelSubscription={CancelSubscription}
            />
            <p className="small-text-bold page-title mt-3">Billing Info</p>
            <BillingInfo />
          </div>
        </div>
      </section>
    </>
  );
}

export default SubscriptionBilling;
