import React, { Component, useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
//Toast Notification imports
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import CookieConsent from "react-cookie-consent";
//
import { ContextSnackbar } from "./Context/Context-Snakbar/ContextSnackbar";
import { ContextSocket } from "./Context/Context-Sockets/ContextSockets";
import Routes from "./Routes";
import { googleApiKey, googleAnalyticsID } from "./bl_libs/invokeApi";
import ReactGA from "react-ga";

// load Google map script
const script = document.createElement("script");

script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
script.async = true;

document.body.appendChild(script);

const browserHistory = createBrowserHistory();
const theme = createMuiTheme({
  typography: {
    fontFamily: [" Work Sans", "sans-serif"],
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#c4314b",
    },
  },
});
function App() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      console.log = () => {};
      console.disableYellowBox = true;
    }
  }, []);

  // Google Tag Initialization
  ReactGA.initialize(googleAnalyticsID);
  browserHistory.listen(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  });
  return (
    <ContextSnackbar>
      <ContextSocket>
        <ReactNotification />
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Routes />
            <CookieConsent
              location="bottom"
              buttonText="I understand"
              // debug={true}
              flipButtons
              // enableDeclineButton
              cookieName="myAwesomeCookieName2"
              style={{
                background: "white",
                color: "black",
                boxShadow: "-1px -8px 19px 0px rgba(219,211,211,0.93)",
                paddingBottom: 5,
              }}
              buttonStyle={{
                color: "white",
                fontSize: "16px",
                background: "#c4314b",
              }}
              expires={150}
            >
              <b style={{ fontSize: 18 }}>
                We use cookies to deliver LA Moving Star's services
              </b>
              <br />
              <span style={{ fontSize: "16px" }}>
                We and our partners use cookies to enhance and personalize your
                experience.
              </span>
            </CookieConsent>
          </Router>
        </ThemeProvider>
      </ContextSocket>
    </ContextSnackbar>
  );
}

export default App;

// export default class App extends Component {
//   render() {
//     return (
//       <FlagContext.Provider
//         value={{
//           isNavOpen,
//           setIsNavOpen,
//         }}
//       >
//         <ThemeProvider theme={theme}>
//           <Router history={browserHistory}>
//             <Routes />
//           </Router>
//         </ThemeProvider>
//       </FlagContext.Provider>
//     );
//   }
// }
