import React, { useEffect, useState } from "react";
import { ChatBox, ChatSidebar } from "./components";
import { useSockets } from "../../Context/Context-Sockets/ContextSockets";
import { CircularProgress } from "@material-ui/core";
import { blackBackArrowImage } from "../../assets";

function Chats(props) {
  const {
    chatMessages,
    setChatMessage,
    selectedUser,
    userList,
    profile,
    isLoading,
    chatLoading,
    //--------------->handlers
    handleSelectUser,
    handleSendMessage,
  } = useSockets();

  const [message, setMessage] = useState("");

  const [screen, setScreen] = useState("sidebar");
  //------------------>>Empty the input if selected user changed

  useEffect(() => {
    setMessage("");
  }, [selectedUser]);

  //////////////////////////////////////////////////////////////

  if (isLoading) {
    return (
      <>
        <div className="container-fluid">
          <div className="Circular-Progress">
            <CircularProgress />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {/* <p className="small-text-bold page-title ml-2 pt-4">Chat</p> */}
          </div>
        </div>
        <div className="row mt-4 ">
          <div className="col-12 pb-40">
            {/* main card */}
            <div>
              {window.innerWidth > 600 ? (
                <p className="small-text-bold page-title ml-2 pt-5 pt-lg-3 pl-1 pb-3">
                  Chat
                </p>
              ) : screen === "chat" ? (
                <p className="small-text-bold page-title ml-2 pt-5 pt-lg-3 pl-1 pb-3">
                  <img
                    className="pointer-point pr-1"
                    src={blackBackArrowImage}
                    onClick={() => setScreen("sidebar")}
                  />
                  <span>Chat List</span>
                </p>
              ) : (
                <p className="small-text-bold page-title ml-2 pt-5 pt-lg-3 pl-1 pb-3">
                  Chat
                </p>
              )}

              {window.innerWidth < 600 ? (
                <div className="row chat-card chat-vh m-2">
                  {screen === "sidebar" ? (
                    <div className="col-12 col-sm-3 col-md-5 col-xl-3 chat-vh mb-5">
                      <ChatSidebar
                        setScreen={setScreen}
                        userList={userList}
                        selectedUser={selectedUser}
                        handleSelectUser={handleSelectUser}
                        setChats={setChatMessage}
                        profile={profile}
                      />
                    </div>
                  ) : (
                    <div className="col-12 col-sm-9 col-md-7 col-xl-9 border-left chat-vh ">
                      <ChatBox
                        userList={userList}
                        selectedUser={selectedUser}
                        profile={profile}
                        chats={chatMessages}
                        message={message}
                        setMessage={setMessage}
                        handleSendMessage={handleSendMessage}
                        chatLoading={chatLoading}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="row chat-card chat-vh m-2">
                  <div className="col-12 col-sm-6 col-md-5 col-xl-3 chat-vh mb-5">
                    <ChatSidebar
                      setScreen={setScreen}
                      userList={userList}
                      selectedUser={selectedUser}
                      handleSelectUser={handleSelectUser}
                      setChats={setChatMessage}
                      profile={profile}
                    />
                  </div>

                  <div className="col-12 col-sm-6 col-md-7 col-xl-9 border-left chat-vh ">
                    <ChatBox
                      userList={userList}
                      selectedUser={selectedUser}
                      profile={profile}
                      chats={chatMessages}
                      message={message}
                      setMessage={setMessage}
                      handleSendMessage={handleSendMessage}
                      chatLoading={chatLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chats;
