import React, { useEffect } from "react";
import { filterImage } from "../../assets";
import "date-fns";
import ActiveRequests from "./components/Active-Requests/ActiveRequests";
import { useState } from "react";
import CancelRequests from "./components/Cancel-Requests/CancelRequests";
import DoneRequests from "./components/Done-Requests/DoneRequests";
import ActiveFilter from "./components/Active-Requests/ActiveFilter";
import CancelRequestsFilter from "./components/Cancel-Requests/CancelRequestsFilter";
import DoneRequestFilter from "./components/Done-Requests/DoneRequestFilter";
import RejectedRequest from "./components/Rejected-Request/RejectedRequest";
import ShareIcon from "@material-ui/icons/Share";
import { SearchableDropdown } from "../../components";
import Select from "react-select";
import moment from "moment";
import FilterChips from "./components/FilterChips";
import { TIMELINE_STATUS_OPTIONS } from "../../ui_libs/constants";

function DirectRequests() {
  const [filter, setfilter] = useState();
  const [activeFilterTitles, setActiveFilterTitles] = useState([]);
  const [doneFilterTitles, setDoneFilterTitles] = useState([]);
  const [cancelFilterTitles, setCancelFilterTitles] = useState([]);
  const [status, setStatus] = useState(
    localStorage.getItem("directActiveReqStatus")
      ? `${localStorage.getItem("directActiveReqStatus")}`
      : ""
  );
  const [doneStatus, setDoneStatus] = useState(
    localStorage.getItem("directDoneReqStatus")
      ? `${localStorage.getItem("directDoneReqStatus")}`
      : ""
  );
  const [requestType, setRequestType] = useState(
    localStorage.getItem("directReqType")
      ? localStorage.getItem("directReqType")
      : "active"
  );
  const [counts, setCounts] = useState({
    activeCount: 0,
    doneCount: 0,
    cancelCount: 0,
    rejectedCount: 0,
  });
  useEffect(() => {
    localStorage.setItem("directReqType", requestType);
  }, [requestType]);
  useEffect(() => {
    localStorage.setItem("directActiveReqStatus", status);
  }, [status]);
  useEffect(()=>{
    localStorage.setItem("directDoneReqStatus",doneStatus)
  }, [doneStatus])
  return (
    <>
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid min-width-650">
          <p className="small-text-bold page-title">Direct requests</p>
          <div className="calendar-wrapper mt-20 pb-30">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8 mb-3">
                <SearchableDropdown />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 text-left">
                <div className="status-buttons mb-3">
                  <span
                    onClick={() => setRequestType("active")}
                    className={
                      requestType === "active"
                        ? "custom-btn-small custom-button-extra-small req-status-button req-active active"
                        : "custom-btn-small custom-button-extra-small req-status-button filter-button req-active"
                    }
                  >
                    Active ({counts.activeCount})
                  </span>
                  <span
                    onClick={() => setRequestType("cancelled")}
                    className={
                      requestType === "cancelled"
                        ? "custom-btn-small custom-button-extra-small req-status-button filter-button req-cancelled  active"
                        : "custom-btn-small custom-button-extra-small req-status-button filter-button req-cancelled "
                    }
                  >
                    Cancelled ({counts.cancelCount})
                  </span>
                  <span
                    onClick={() => setRequestType("done")}
                    className={
                      requestType === "done"
                        ? "custom-btn-small custom-button-extra-small req-status-button filter-button req-done mt-2 mt-sm-0 active"
                        : "custom-btn-small custom-button-extra-small req-status-button filter-button req-done mt-2 mt-sm-0"
                    }
                  >
                    Done ({counts.doneCount})
                  </span>
                  <span
                    onClick={() => setRequestType("rejected")}
                    className={
                      requestType === "rejected"
                        ? "custom-btn-small custom-button-extra-small req-status-button filter-button req-cancelled mt-2 mt-sm-0 active"
                        : "custom-btn-small custom-button-extra-small req-status-button filter-button req-cancelled mt-2 mt-sm-0"
                    }
                  >
                    Rejected ({counts.rejectedCount})
                  </span>

                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="col-4 text-right">
                {requestType !== "rejected" && (
                  <span
                    className="black-color pointer-point"
                    data-toggle="modal"
                    data-target="#change-filter"
                  >
                    <img src={filterImage} />
                    <span className="pl-2">Filter</span>
                  </span>
                )}
              </div>
            </div>
            {requestType !== "rejected" && (
              <div className="row">
                <div className="col-6">
                  {requestType === "active" && (
                    <select
                      className="form-control form-control-sm w-75"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      {TIMELINE_STATUS_OPTIONS.slice(0, 6).map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  )}
                  {requestType === "done" && (
                    <select
                      className="form-control form-control-sm w-75"
                      value={doneStatus}
                      onChange={(e) => setDoneStatus(e.target.value)}
                    >
                        <option value={""}>{"All Done Requests"}</option>
                      {TIMELINE_STATUS_OPTIONS.slice(7, 9).map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="col-6">
                  {requestType === "active" && (
                    <FilterChips titles={activeFilterTitles} />
                  )}
                  {requestType === "done" && (
                    <FilterChips titles={doneFilterTitles} />
                  )}
                  {requestType === "cancelled" && (
                    <FilterChips titles={cancelFilterTitles} />
                  )}
                </div>
              </div>
            )}
            {/* // */}
            {requestType === "active" && (
              <ActiveRequests
                setActiveFilterTitles={setActiveFilterTitles}
                counts={counts}
                status={status}
                setCounts={setCounts}
              />
            )}
            {requestType === "cancelled" && (
              <CancelRequests
                setCancelFilterTitles={setCancelFilterTitles}
                counts={counts}
                setCounts={setCounts}
              />
            )}
            {requestType === "done" && (
              <DoneRequests
                setDoneFilterTitles={setDoneFilterTitles}
                counts={counts}
                status={doneStatus}
                setCounts={setCounts}
              />
            )}
            {requestType === "rejected" && (
              <RejectedRequest counts={counts} setCounts={setCounts} />
            )}
          </div>
        </div>
      </section>
      {/* {requestType === "active" && <ActiveFilter />} */}
      {/* {requestType === "cancelled" && <CancelRequestsFilter />} */}
      {/* {requestType === "done" && <DoneRequestFilter />} */}
    </>
  );
}

export default DirectRequests;
