import React, { useState, useEffect } from "react";
import { CircularProgress, Container } from "@material-ui/core";
import { invokeApi } from "../../bl_libs/invokeApi";

function AboutUs() {
  const [data, setData] = useState({
    about: "",
    contact_us: "",
    faq: "",
    how_evom_work: "",
    cancellation_policy: "",
    legal: "",
    terms_of_service: "",
    privacy_policy: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const GetSettingsData = () => {
    const token = localStorage.getItem("token");
    let requestObj = {
      path: `/api/admin/get_setting`,
      method: "GET",
      headers: {
        "x-sh-auth": token,
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setData(res.setting_data);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    GetSettingsData();
  }, []);

  if (isLoading) {
    return (
      <section id="content-wrapper" className="content-wrapper rating-page">
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title">About</p>

          <div className="calendar-wrapper mt-40 mt-m-20 pb-30">
            <p
              className="small-font-size"
              dangerouslySetInnerHTML={{ __html: data.about }}
            ></p>
            <div id="accordion">
              <div className="card rounded-box mb-4">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link sub-title black-color pl-0"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Contact Us
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div
                    className="card-body small-font-size pt-0"
                    dangerouslySetInnerHTML={{ __html: data.contact_us }}
                  ></div>
                </div>
              </div>
              <div className="card rounded-box mb-4">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed sub-title black-color pl-0"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      FAQ
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div
                    className="card-body small-font-size pt-0"
                    dangerouslySetInnerHTML={{ __html: data.faq }}
                  ></div>
                </div>
              </div>
              <div className="card rounded-box mb-4">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed sub-title black-color pl-0"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How LA Moving Star Works
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div
                    className="card-body small-font-size pt-0"
                    dangerouslySetInnerHTML={{ __html: data.how_evom_work }}
                  ></div>
                </div>
              </div>
              <div className="card rounded-box mb-4">
                <div className="card-header" id="heading-cancellation-policy">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed sub-title black-color pl-0"
                      data-toggle="collapse"
                      data-target="#collapse-cancellation-policy"
                      aria-expanded="false"
                      aria-controls="cancellation-policy"
                    >
                      Cancellation policy
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse-cancellation-policy"
                  className="collapse"
                  aria-labelledby="cancellation-policy"
                  data-parent="#accordion"
                >
                  <div
                    className="card-body small-font-size pt-0"
                    dangerouslySetInnerHTML={{
                      __html: data.cancellation_policy,
                    }}
                  ></div>
                </div>
              </div>
              <div className="card rounded-box mb-4">
                <div className="card-header" id="heading-legal">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed sub-title black-color pl-0"
                      data-toggle="collapse"
                      data-target="#collapse-legal"
                      aria-expanded="false"
                      aria-controls="legal"
                    >
                      Legal
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse-legal"
                  className="collapse"
                  aria-labelledby="legal"
                  data-parent="#accordion"
                >
                  <div
                    className="card-body small-font-size pt-0"
                    dangerouslySetInnerHTML={{ __html: data.legal }}
                  ></div>
                </div>
              </div>
              <div className="card rounded-box mb-4">
                <div className="card-header" id="heading-terms">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed sub-title black-color pl-0"
                      data-toggle="collapse"
                      data-target="#collapse-terms"
                      aria-expanded="false"
                      aria-controls="terms"
                    >
                      Terms of Service
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse-terms"
                  className="collapse"
                  aria-labelledby="terms"
                  data-parent="#accordion"
                >
                  <div
                    className="card-body small-font-size pt-0"
                    dangerouslySetInnerHTML={{ __html: data.terms_of_service }}
                  ></div>
                </div>
              </div>
              <div className="card rounded-box mb-4">
                <div className="card-header" id="heading-privacy">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed sub-title black-color pl-0"
                      data-toggle="collapse"
                      data-target="#collapse-privacy"
                      aria-expanded="false"
                      aria-controls="privacy"
                    >
                      Privacy Policy
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse-privacy"
                  className="collapse"
                  aria-labelledby="privacy"
                  data-parent="#accordion"
                >
                  <div
                    className="card-body small-font-size pt-0"
                    dangerouslySetInnerHTML={{ __html: data.privacy_policy }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
