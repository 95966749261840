import React from "react";
import { Switch, Redirect } from "react-router-dom";

import {
  RouteWithLayout,
  PublicRouteWithLayout,
  PrivateRouteWithLayout,
} from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  MainWithSidebar as MainLayoutWithSidebar,
  MainWithHeader,
} from "./layouts";
import {
  Login as LoginPage,
  NotFound as NotFoundPage,
  ForgetPassword,
  QuickAdvice,
  RegisterCompany,
  SetPassword,
  Welcome,
  ShowSubscriptions,
  EmailTemplates,
  EditEmailTemplate,
  RequestScreens,
  ExtraItems,
  AddItem,
  EditItem
} from "./pages";
import CompanyRoutes from "./company_routes/Routes";

const Routes = () => {
  //------------------>>ReRender changes on resize window
  // It fixed problem which are accours due to screen resizes
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
  }, []);
  ///////////////////////////////////////////////////////
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />

      <PublicRouteWithLayout
        component={LoginPage}
        exact
        layout={MinimalLayout}
        path="/login"
      />

      <PublicRouteWithLayout
        component={ForgetPassword}
        exact
        layout={MinimalLayout}
        path="/forget-password"
      />
      <PublicRouteWithLayout
        component={SetPassword}
        exact
        layout={MinimalLayout}
        path="/set-forget-password/:token"
      />
      <PublicRouteWithLayout
        component={QuickAdvice}
        exact
        layout={MinimalLayout}
        path="/quick-advice"
      />
      <PublicRouteWithLayout
        component={RegisterCompany}
        exact
        layout={MainLayout}
        path="/register-company"
      />
      <PrivateRouteWithLayout
        component={Welcome}
        layout={MinimalLayout}
        path="/welcome"
      />
      <PrivateRouteWithLayout
        component={ShowSubscriptions}
        layout={MainLayoutWithSidebar}
        path="/subscriptions-plan"
      />
      <PrivateRouteWithLayout
        component={CompanyRoutes}
        layout={MainLayoutWithSidebar}
        path="/company"
      />
      <PrivateRouteWithLayout
        component={EmailTemplates}
        layout={MainLayoutWithSidebar}
        exact
        path="/email-templates"
      />
        <PrivateRouteWithLayout
          component={EditEmailTemplate}
          layout={MainLayoutWithSidebar}
          path="/email-templates/edit-email-template/:id"
        />
      <PrivateRouteWithLayout
        component={ExtraItems}
        layout={MainLayoutWithSidebar}
        exact
        path="/extra-items"
      />
      <PrivateRouteWithLayout
          component={AddItem}
          layout={MainLayoutWithSidebar}
          path="/extra-items/add-new-item"
        />
      <PrivateRouteWithLayout
          component={EditItem}
          layout={MainLayoutWithSidebar}
          path="/extra-items/edit-item/:id"
        />
      <PrivateRouteWithLayout
        component={RequestScreens}
        exact
        layout={MainLayout}
        path="/create-request"
      />

      <RouteWithLayout
        component={NotFoundPage}
        exact={false}
        layout={MinimalLayout}
        path="/"
      />

      {/* <RouteWithLayout
        component={LoginPage}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={HomePage}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={AboutPage}
        exact
        layout={MainLayout}
        path="/about"
      />
      <RouteWithLayout
        component={NotFoundPage}
        exact={false}
        layout={MinimalLayout}
        path="/"
      /> */}
    </Switch>
  );
};

export default Routes;
