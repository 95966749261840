import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { addIconImage } from "../../../assets";
import { invokeApi } from "../../../bl_libs/invokeApi";
import { useSnakbar } from "../../../Context/Context-Snakbar/ContextSnackbar";

function ManageLeadSource() {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState("");
  const [leads, setLeads] = useState([]);

  const handleRemove = (i) => {
    const temp = leads.map((c) => c);
    temp.splice(i, 1);
    setLeads(temp);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    setLeads([...leads, input]);
    setInput("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestObj = {
      path: "/api/company/add_lead_source_text",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      lead_source_text: JSON.stringify(leads),
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showSuccessSnak("Lead source list updated successfully.");
        handleGetLeads();
        setTimeout(() => {
          if (document.getElementById("close_add-leads")) {
            document.getElementById("close_add-leads").click();
          }
        }, 1000);
      } else {
      }
    });
  };

  const handleGetLeads = () => {
    let requestObj = {
      path: "/api/company/get_lead_source_text",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      console.log(res, "--res");
      if (res.code === 200) {
        setLeads(res.lead_source_text ? JSON.parse(res.lead_source_text) : []);
      } else {
      }
    });
  };

  useEffect(() => {
    handleGetLeads();
  }, []);
  return (
    <>
      <div className="small-text-bold mb-3">Lead Source</div>
      {isLoading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="lh-m-16">
            {true ? (
              <span>{leads.map((lead) => `${lead}, `)}</span>
            ) : (
              "No lead source added yet"
            )}
          </div>
          <div className={"float-right"}>
            <span
              data-toggle="modal"
              data-target="#add-leads"
              className="small-text-bold red-color add-new-btn pointer-point"
            >
              <img src={addIconImage} /> <span>Add New</span>
            </span>
          </div>
        </>
      )}

      {/* // <!-- Edit Manager--> */}
      <div
        className="modal fade mt-m-80 pb-m-80 "
        id="add-leads"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-password-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg "
          role="document"
        >
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close_add-leads"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <h5 className="text-center" id="add-salesman-title">
                Manage Source Leads
              </h5>
              {leads.map((ls, i) => (
                <div className="row border-bottom pb-1 pt-2">
                  <div className="col-8">{ls}</div>
                  <div className="col-4 text-right pointer-point">
                    <span
                      onClick={() => handleRemove(i)}
                      className="custom-link small-text-bold"
                    >
                      Remove
                    </span>
                  </div>
                </div>
              ))}
              <div className="row mt-2">
                <div className="col-12 p-0">
                  <form onSubmit={handleAdd}>
                    <div className="form-group d-flex">
                      <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        className="form-control small-font-size mr-2"
                        id="new-lead"
                        placeholder="Facebook"
                      />
                      <button
                        type="submit"
                        className="custom-btn-small custom-buton m-auto"
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-12">
                  <button
                    onClick={handleSubmit}
                    className="custom-btn-small custom-buton m-auto"
                  >
                    Update List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageLeadSource;
