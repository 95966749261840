import React from "react";
import {
  addressAImage,
  addressBImage,
  addressCImage,
  addressDImage,
  addressEImage,
  addressFImage,
} from "../../../../../../assets";

function AddressCard(props) {
  const { action, icon } = props;
  const {
    complete_address,
    zip_code,
    apartment_number,
    is_storage,
    is_stairs,
    is_hardwood_floor,
    is_elevator,
    number_of_flights,
    parking_and_building_info,
  } = props.address;
  console.log(props, "=>>Address");

  const { index } = props;
  const addressIcon = (i) => {
    return i === 0
      ? addressAImage
      : i === 1
      ? addressBImage
      : i === 2
      ? addressCImage
      : i === 3
      ? addressDImage
      : i === 4
      ? addressEImage
      : addressFImage;
  };
  return (
    <div className="col-12 col-md-6 mb-3 ">
      <div className="rounded-box p-3 ">
        <div className="address-pin-zipcode">
          <div className="row">
            <div className="col-9">
              {icon && (
                <span>
                  <img src={addressIcon(index)} />
                </span>
              )}
              <span className="sub-title dark-grey-color text-uppercase"></span>
              <span className="black-color pl-1">{complete_address}</span>
            </div>
            <div className="col-3 text-right">
              <span className="light-red-color small-font-size pl-2 pr-2 ">
                {action}
              </span>
            </div>
          </div>
        </div>
        <div className="pt-2">
          <span className="dark-grey-color small-sub-title">Zip: </span>
          <span className="black-color small-font-size pl-3">
            {" "}
            {zip_code ? zip_code : "N/A"}{" "}
          </span>
        </div>
        <div className="pt-2">
          <span className="dark-grey-color small-sub-title">
            Unit or Ap. number:{" "}
          </span>
          <span className="black-color small-font-size pl-3">
            {apartment_number ? apartment_number : "N/A"}
          </span>
        </div>
        <div className="pt-2">
          <span className="dark-grey-color small-sub-title">Storage: </span>
          <span className="black-color small-font-size pl-3">
            {is_storage ? "Yes" : "No"}{" "}
          </span>
        </div>
        <div className="pt-2">
          <span className="dark-grey-color small-sub-title">Lift type: </span>
          <span className="black-color small-font-size pl-3">
            {is_elevator
              ? "Mover(s) need to use elevator"
              : "Mover(s) need to use stairs"}
          </span>
        </div>
        {is_stairs && (
          <div className="pt-2">
            <span className="dark-grey-color small-sub-title">
              Number of flights:{" "}
            </span>
            <span className="black-color small-font-size pl-3">
              {number_of_flights}
            </span>
          </div>
        )}
        <div className="pt-2">
          <span className="dark-grey-color small-sub-title">
            Hardwood Floor:{" "}
          </span>
          <span className="black-color small-font-size pl-3">
            {is_hardwood_floor ? "Yes" : "No"}
          </span>
        </div>
        <div className="pt-2">
          <span className="dark-grey-color small-sub-title">
            Parking and Building info:{" "}
          </span>
          <span className="black-color small-font-size pl-3">
            {parking_and_building_info ? parking_and_building_info : "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default AddressCard;
