import React from "react";
import { useState } from "react";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import { useSnakbar } from "../../../../../../Context/Context-Snakbar/ContextSnackbar";

function CompanyNotes(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const [isEdit, setIsEdit] = useState(false);
  const [commentInput, setCommentInput] = useState(props.final_comment);

  const handleCancel = () => {
    setCommentInput(props.final_comment);
    setIsEdit(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let requestObj = {
      path: "/app_api/add_company_notes",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${props.request.id}`,
      company_notes: `${commentInput}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showSuccessSnak("Company notes updated successfully.");
        props.fetchRequestDetails();
        setIsEdit(false);
      } else {
      }
    });
  };

  if (isEdit) {
    return (
      <div className="info-detail-wrapper p-3 pt-20">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <span className="dark-grey-color small-sub-title">
                Company Notes
              </span>
            </div>
            <div className="col-12">
              <div className="form-group">
                <textarea
                  required
                  rows="5"
                  multiple
                  autoComplete="off"
                  type="textarea"
                  name="reply"
                  className="form-control small-font-size"
                  placeholder="Enter your notes here..."
                  value={commentInput}
                  onChange={(e) => setCommentInput(e.target.value)}
                />
              </div>
            </div>

            <div className="col-12 justify-content-center d-flex">
              <button type="submit" className="custom-btn-small custom-buton">
                Update
              </button>
              <button onClick={handleCancel} className="custom-btn-small ml-2">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="info-detail-wrapper p-3 pt-20">
      <div className="row">
        <div className="col-12 small-font-size">
          <p>{commentInput}</p>
        </div>
      </div>
      <div className="row mt-4 pb-2">
        <div className="col-12 justify-content-center d-flex">
          <button
            onClick={() => setIsEdit(true)}
            className="custom-btn-small custom-buton"
          >
            {!commentInput ? "Add" : "Edit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyNotes;
