import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import {
  addressARedIcon,
  addressBRedIcon,
  addressCRedIcon,
  addressDRedIcon,
  addressERedIcon,
  addressFRedIcon,
} from "../../../../../assets";
import { withRouter } from "react-router-dom";
import { googleApiKey } from "../../../../../bl_libs/invokeApi";

const libraries = ["places"];
const mapContainerStyle = {
  height:
    window.innerWidth < 769
      ? window.innerHeight - 60
      : window.innerHeight - 120,
  width: "100%",
};
const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: window.innerWidth < 769 ? false : true,
};
const center = {
  lat: 33.9679,
  lng: -118.297,
};

function LiveMapTracking(props) {
  console.log(props, "props");
  const { addressList } = props;

  // comment this because google maps script is already included
  // if it include twice then sometime address suggestions not work properly.

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: googleApiKey,
  //   libraries,
  // });

  const [markers, setMarkers] = React.useState([]);
  const [directions, setDirections] = useState([]);

  useEffect(() => {
    console.log(markers, "MARKERS EFFECT");
  }, [markers]);

  const addressIcon = (i) => {
    return i === 0
      ? addressARedIcon
      : i === 1
      ? addressBRedIcon
      : i === 2
      ? addressCRedIcon
      : i === 3
      ? addressDRedIcon
      : i === 4
      ? addressERedIcon
      : addressFRedIcon;
  };

  const onMapClick = React.useCallback((e) => {
    /* ---------------------------------------------------
            CODE FOR CREATING ON MAP CLICK MARKERS
    ----------------------------------------------------- */
    // setMarkers((current) => [
    //   ...current,
    //   {
    //     lat: e.latLng.lat(),
    //     lng: e.latLng.lng(),
    //     time: new Date(),
    //   },
    // ]);
  }, []);

  const SetNewMarkerOnMap = (marker) => {
    if (marker.lat) {
      const alreadySet = markers.find((mark) => mark.label === marker.label);
      if (alreadySet) {
        setMarkers((current) =>
          current.map((mark) => {
            if (mark.id === marker.id) {
              return marker;
            } else {
              return mark;
            }
          })
        );
      } else {
        setMarkers((current) => [...current, marker]);
      }
    }
    if (marker.lat && mapRef.current) {
      mapRef.current.panTo(marker);
      mapRef.current.setZoom(15);
    }
  };

  const SetDirection = (origin, destination, id) => {
    if (!window.google) {
      return;
    }
    // when google maps loaded successfully
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        avoidHighways: true,
        avoidFerries: true,
        avoidTolls: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const alreadySet = directions.find((dir) => dir.id === id);
          if (alreadySet) {
            setDirections((current) =>
              current.map((dir) => {
                if (dir.id === id) {
                  return { path: result, status: true, id };
                } else {
                  return dir;
                }
              })
            );
          }

          setDirections((current) => [
            ...current,
            { path: result, status: true, id },
          ]);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const SetAllMarkers = (addressList) => {
    console.log(addressList, "addressList");
    addressList.map((address, i) => {
      const markerObject = {
        ...address,
        lat: address.lat,
        lng: address.lng,
        icon: address.icon,
      };

      SetNewMarkerOnMap(markerObject);
    });
  };

  const SetAllDirections = (addressList) => {
    if (addressList.length === 1) return;
    for (let i = 0; i < addressList.length - 1; i++) {
      //geting start and end positions
      const origin = {
        lat: addressList[i].lat,
        lng: addressList[i].lng,
      };
      const destination = {
        lat: addressList[i + 1].lat,
        lng: addressList[i + 1].lng,
      };

      const id =
        i === 0
          ? "a-b"
          : i === 1
          ? "b-c"
          : i === 2
          ? "c-d"
          : i === 3
          ? "d-e"
          : i === 4
          ? "e-f"
          : addressFRedIcon;

      SetDirection(origin, destination, id);
    }
  };

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const setDirectionsStatus = () => {
    let temp = directions.map((direction, i) => {
      return {
        ...direction,
        status: false,
      };
    });
    console.log(temp);
    setDirections(temp);
  };

  useEffect(() => {
    setTimeout(() => {
      SetAllMarkers(addressList);
      SetAllDirections(addressList);
    }, 1000);
  }, []);

  useEffect(() => {
    if (window.map) {
      SetAllMarkers(addressList);
      SetAllDirections(addressList);
    }
  }, [addressList]);

  // if (loadError) return "Error";
  // if (!isLoaded) return <></>;

  return (
    <div style={{ width: "100%" }}>
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={11}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {markers.map((marker, i) => (
          <Marker
            key={i}
            position={{ lat: marker.lat, lng: marker.lng }}
            icon={{
              url: marker.icon,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        ))}

        {directions.length !== 0 &&
          directions.map((direction, i) => (
            <DirectionsRenderer
              key={i}
              options={{
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: "#C4314B",
                  visible: direction.status,
                },
              }}
              directions={direction.path}
            ></DirectionsRenderer>
          ))}
      </GoogleMap>
    </div>
  );
}

export default withRouter(LiveMapTracking);
