import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { invokeApi } from "../../../bl_libs/invokeApi";

function EmailVarification(props) {
  const {
    email,
    SendCodeOnEmail,
    isLoading,
    setIsLoading,
    handleSignUp,
    showErrorSnak,
    jumpSteps,
  } = props;

  const [code, setCode] = useState();

  const [errMessage, setErrMessage] = React.useState("");

  const varifyEmail = () => {
    let requestObj = {
      path: "/app_api/verify_code_send_on_email",
      method: "POST",
      headers: {},
    };
    let postData = {
      email: email,
      code: code,
    };

    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setErrMessage("");
        handleSignUp();
      } else {
        setIsLoading(false);
        setErrMessage(res.message);
        //showErrorSnak(res.message);
        console.log(res);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    varifyEmail();
  };

  if (isLoading) {
    return (
      <div className="Circular-Progress">
        <CircularProgress />
      </div>
    );
  }

  return (
    <section className="main-content">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            {errMessage && (
              <Alert variant="filled" severity="error">
                {errMessage}
              </Alert>
            )}
            <div className="form-box-wrapper">
              <div className="form-box">
                <h5 className="text-center">Check Your Email</h5>

                <div className="evom-form login-form">
                  <form onSubmit={handleSubmit}>
                    <div className="row justify-content-center">
                      <div></div>
                      <div className="col-12">
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className="overline dark-grey-color float-left"
                          >
                            Enter code
                          </label>
                          <NumberFormat
                            autoFocus
                            required
                            onChange={(e) => {
                              setCode(e.target.value);
                            }}
                            className="form-control small-font-size"
                            id="phone"
                            value={code}
                            placeholder="Enter your 6 digit confirmation code"
                            format="######"
                            mask="-"
                            allowLeadingZeros
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-12">
                        <div className="form-group button-wrapper">
                          <button className="custom-buton custom-buton-large w-100">
                            Verify
                          </button>
                        </div>

                        <div className="no-account-text-wrapper-custom text-center mt-4 pt-4">
                          <p className="light-grey-color">
                            I didn't receive the email?{" "}
                            <span
                              onClick={() => {
                                setErrMessage("");
                                setCode("");
                                SendCodeOnEmail();
                              }}
                              className="custom-link pointer-point"
                            >
                              Resend
                            </span>
                          </p>
                        </div>
                        <div className="no-account-text-wrapper-custom text-center mt-1 pt-1">
                          <p className="light-grey-color">
                            Want to change email? <br />
                            <span
                              onClick={() => jumpSteps(0)}
                              className="custom-link pointer-point"
                            >
                              Go Back
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="no-account-text-wrapper-custom text-center mt-4 pt-4 mt-sm-5 pt-sm-5"></div>
      </div>
    </section>
  );
}

export default EmailVarification;
