import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import { validatePasswordStrength } from "../../../../../../ui_libs/Validations/validations";

function ChangePassword(props) {
  const { showErrorSnak } = props;
  const [updatePassword, setUpdatePassword] = useState({
    password: "",
    confirm_password: "",
  });

  const [
    updatePasswordSuccessMessage,
    setUpdatePasswordSuccessMessage,
  ] = useState("");
  const [passStrength, setPassStrength] = React.useState({
    color: "",
    strength: "",
    level: 0,
  });

  const handleUpdatePasswordInputs = (e) => {
    if (e.target.id === "password") {
      setPassStrength(validatePasswordStrength(e.target.value));
    }
    setUpdatePassword({
      ...updatePassword,
      [e.target.id]: e.target.value.trim(),
    });
  };

  const handleUpdatePasswordSubmit = (e) => {
    e.preventDefault();

    //validations step for password
    if (updatePassword.password.length < 8) {
      showErrorSnak("Minimum 8 characters required for password");
      return;
    }
    //
    //check password strength

    if (passStrength.level < 3) {
      showErrorSnak(
        "A minimum 8 characters password contains a combination of uppercase letter and number. And at least one special character are required."
      );
      return;
    }

    //match password
    if (updatePassword.password !== updatePassword.confirm_password) {
      showErrorSnak("Your password and confirmation password does not match");
      return;
    }

    //Api call
    let requestObj = {
      path: "/app_api/update_password",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = updatePassword;
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showErrorSnak("");
        setUpdatePasswordSuccessMessage("Password updated successfully");
        setTimeout(() => {
          document.getElementById("close-change-password").click();
          setTimeout(() => {
            setUpdatePasswordSuccessMessage("");
          }, 500);
        }, 1000);
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  return (
    <div>
      {/* // <!-- Change Password --> */}
      <div
        className="modal fade"
        id="change-password"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-password-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-change-password"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {updatePasswordSuccessMessage !== "" ? (
              <Alert variant="filled" severity="success">
                {updatePasswordSuccessMessage}
              </Alert>
            ) : (
              <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
                <div className="modal-body p-0">
                  <h5 className="text-center" id="change-password-title">
                    Change password
                  </h5>

                  <div className="form-box pt-20">
                    <form onSubmit={handleUpdatePasswordSubmit}>
                      <div className="form-group">
                        <label
                          htmlFor="password"
                          className="overline dark-grey-color"
                        >
                          New Password
                        </label>
                        <input
                          required
                          type="password"
                          className="form-control small-font-size"
                          id="password"
                          value={updatePassword.password}
                          onChange={handleUpdatePasswordInputs}
                          placeholder="*****************"
                        />
                        {passStrength.level > 0 && updatePassword.password && (
                          <div
                            className="progress"
                            style={{ height: 2, marginTop: -3 }}
                          >
                            <div
                              className={
                                "progress-bar " +
                                ((passStrength.level < 2 && "bg-danger") ||
                                  // (passStrength.level === 2 && "bg-info") ||
                                  (passStrength.level === 3 &&
                                    updatePassword.password.length >= 8 &&
                                    "bg-success"))
                              }
                              role="progressbar"
                              style={{
                                width: passStrength.level * 33.333 + "%",
                              }}
                              aria-valuenow={passStrength.level}
                              aria-valuemin="0"
                              aria-valuemax="3"
                            ></div>
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="confirm_password"
                          className="overline dark-grey-color"
                        >
                          Re-Enter New Password
                        </label>
                        <input
                          required
                          type="password"
                          className="form-control small-font-size"
                          id="confirm_password"
                          value={updatePassword.confirm_password}
                          onChange={handleUpdatePasswordInputs}
                          placeholder="*****************"
                        />
                      </div>
                      <div className="form-group pt-3">
                        <button
                          type="submit"
                          className="red-btn custom-buton custom-buton-large w-100"
                        >
                          Save Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
