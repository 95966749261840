import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router-dom";

const anchor = "right";

function Notification(props) {
  const {
    toggleDrawer,
    isLoading,
    notification,
    handleNotificationClick,
    MarkAsRead,
  } = props;

  useEffect(() => {
    // FetchNotifications();
  }, []);

  if (isLoading) {
    return (
      <>
        <div
          role="presentation"
          onKeyDown={toggleDrawer(anchor, false)}
          className="notification-sidebar"
        >
          <div
            className="notification-header"
            style={{
              paddingTop: window.innerWidth > 760 ? 0 : 60,
            }}
          >
            <ListItem className="notification-header">
              <ListItemIcon>
                <CloseIcon
                  htmlColor="white"
                  onClick={toggleDrawer(anchor, false)}
                  className="pointer-point"
                />
              </ListItemIcon>
              <ListItemText
                // className="notification-text"
                primaryTypographyProps={{
                  className: "notification-text",
                  style: { fontWeight: 600 },
                }}
                primary="Notifications"
              />

              <span className="notification-mark-as-read float-right pointer-point">
                Mark as read
              </span>
            </ListItem>
          </div>
          <List
            style={{
              paddingTop: window.innerWidth > 760 ? 60 : 120,
            }}
          >
            <div className="Circular-Progress ">
              <CircularProgress />
            </div>
          </List>
        </div>
      </>
    );
  }
  return (
    <>
      <div
        role="presentation"
        onKeyDown={toggleDrawer(anchor, false)}
        className="notification-sidebar"
      >
        <div
          className="notification-header"
          style={{
            paddingTop: window.innerWidth > 760 ? 0 : 60,
          }}
        >
          <ListItem className="notification-header">
            <ListItemIcon>
              <CloseIcon
                htmlColor="white"
                onClick={toggleDrawer(anchor, false)}
                className="pointer-point"
              />
            </ListItemIcon>
            <ListItemText
              // className="notification-text"
              primaryTypographyProps={{
                className: "notification-text",
                style: { fontWeight: 600 },
              }}
              primary="Notifications"
            />

            <span
              onClick={() => MarkAsRead()}
              className="notification-mark-as-read float-right pointer-point"
            >
              Mark as read
            </span>
          </ListItem>
        </div>
        <List
          style={{
            paddingTop: window.innerWidth > 760 ? 60 : 120,
          }}
        >
          {/* <div className="pt-3 pl-2">
            <span className="notification-day-title">TODAY</span>
          </div> */}

          {notification.length > 0 ? (
            notification.map((notify, index) => (
              <div
                className="row notification-single-box m-2"
                style={notify.is_active ? {} : { backgroundColor: "#f1f2f4" }}
                onClick={() => {
                  // props.history.push(
                  //   "/company/company-requests/" + notify.action_value
                  // );
                  MarkAsRead(notify.id);
                  handleNotificationClick(notify.action_value);
                }}
              >
                <div className="col-6 pt-1">
                  <span className="notification-req-id">
                    Request # {notify.action_value}
                  </span>
                </div>
                <div className="col-6 text-right">
                  <span className="notification-from-now">
                    {moment(notify.created_at).fromNow()}
                  </span>
                </div>
                <div className="col-12 pb-2">
                  <span className="notification-body">
                    {notify.message_body}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="pt-40 text-center">
              <span className=" light-red-color small-font-size mt-40">
                No Notification Found
              </span>
            </div>
          )}
        </List>
      </div>
    </>
  );
}

export default withRouter(Notification);
