import { CircularProgress, TablePagination } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import DoneRequestFilter from "./DoneRequestFilter";
import moment from "moment";
import { withRouter } from "react-router-dom";
function DoneRequests(props) {
  const [filters, setFilters] = useState({
    from_date: "",
    to_date: "",
    distance: "",
    move_type: "",
    truck_name: "",
    foreman_name: "",
  });
  const [requests, SetRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [moveSizeList, setMoveSizeList] = useState([]);
  const [truckList, setTruckList] = useState([]);
  const [foremanList, setForemanList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  //------------------------------------------>  pagination settings
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);

  //----------------------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //////////////////////////////////////////////////////////////////

  const fetchDoneRequests = (type) => {
    let requestObj = {
      path: `/api/company/get_company_request?page=${page}&limit=${rowsPerPage}`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {};
    if (type === "filter") {
      setIsFilter(true);
      postData = {
        request_status: "1",
        filter_status: "1",
        from_date:
          filters.from_date === ""
            ? ""
            : moment(filters.from_date).format("MM-DD-YYYY"),
        to_date:
          filters.to_date === ""
            ? ""
            : moment(filters.to_date).format("MM-DD-YYYY"),
        time: "",
        min_distance:
          filters.distance === "" ? "" : filters.distance.split("-")[0],
        max_distance:
          filters.distance === "" ? "" : filters.distance.split("-")[1],
        move_type: filters.move_type,
        truck_name: filters.truck_name,
        foreman_name: filters.foreman_name,
      };
    } else {
      setIsFilter(false);
      //if type is undefined
      //we are not passing filters in API
      postData = {
        request_status: "1",
      };
    }
    setIsLoading(true);

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "DONE");
      if (res.code === 200) {
        SetRequests(res.company_request_data);
        setTotalCount(res.count);
        setIsLoading(false);
      }
    });
  };

  //fetch and refresh truck list data from api

  const fetchTruckList = () => {
    let requestObj = {
      path: "/api/company/get_truck",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        setTruckList(res.data.allTruck);
      }
    });
  };

  const fetchListForMoverType = () => {
    let requestObj = {
      path: "/api/customer/get_move_type",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "MOVE SIZE RESPONSCE");
      if (res.code === 200) {
        setMoveSizeList(res.data.moveTypeItems);
      }
    });
  };

  const fetchForemanList = () => {
    let requestObj = {
      path: "/api/company/get_foreman",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        setForemanList(res.data.allForeman);
      }
    });
  };
  const getStatusForCompany = (status) => {
    if (status === 0) {
      return "New Request";
    }
    if (status === 1) {
      return "Bid submitted";
    }
    if (status === 2) {
      return "Bid accepted";
    }
    if (status === 3) {
      return "Paid to Escrow";
    }
    if (status === 4) {
      return "Truck assigned and ready to move";
    }
    if (status === 5) {
      return "Move in progress";
    }
    if (status === 6) {
      return "Completed, waiting for confirmation";
    }
    if (status === 7) {
      return "Completed";
    }
    if (status === 8) {
      return "Feedback Done";
    }
  };

  useEffect(() => {
    if (isFilter) {
      fetchDoneRequests("filter");
    } else {
      fetchDoneRequests();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchListForMoverType();
    fetchTruckList();
    fetchForemanList();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress ">
          <CircularProgress />
        </div>
      </>
    );
  }
  // if (requests.length === 0) {
  //   return <div>No request found</div>;
  // }

  if (window.innerWidth < 500) {
    console.log(window.innerWidth, "Width");
    return (
      <>
        <div className="rounded-box pb-3">
          <div className="row">
            <div className="col-12">
              <table
                id="example"
                className="responsive table table-hover"
                style={{ width: "100%" }}
              >
                <thead>
                  <th className="small-sub-title dark-grey-color">
                    <div className="row">
                      <div className="col-6 small-sub-title dark-grey-color">
                        Done Requests
                      </div>
                      <div className="col-6">
                        {/* #{request.request_data.id} */}
                      </div>
                    </div>
                  </th>
                </thead>
                <tbody>
                  {requests.map((request, i) => (
                    <tr
                      key={request.request_data.id}
                      className="pointer-point border-bottom"
                      onClick={() =>
                        props.history.push(
                          "/company/company-requests/" + request.request_data.id
                        )
                      }
                    >
                      <div className="row pl-2">
                        <div className="col-4 small-sub-title dark-grey-color">
                          Request No.
                        </div>
                        <div className="col-8 small-sub-title dark-grey-color">
                          #{request.request_data.id}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Start date:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.s_date}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Start time:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.schedule_time.slice(
                            11,
                            request.request_data.schedule_time.length
                          )}
                        </div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Distance:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.net_distance} mi
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Move Type:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.move_type}
                        </div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Truck:</span>
                        </div>
                        <div className="col-8">
                          {request.trcuk_array.length === 0
                            ? "-"
                            : request.trcuk_array.map((truck, i) => {
                                return (
                                  truck.name +
                                  (i === request.trcuk_array.length - 1
                                    ? ""
                                    : ", ")
                                );
                              })}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Status:</span>
                        </div>
                        <div className="col-8">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="8" cy="8" r="8" fill="#C4314B"></circle>
                          </svg>{" "}
                          {getStatusForCompany(
                            request.request_data.request_timeline_status
                          )}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Last update:</span>
                        </div>
                        <div className="col-8">
                          <span className="light-grey-color extra-small-font-size">
                            Updated{" "}
                            {moment(request.request_data.updated_at).fromNow()}
                          </span>
                        </div>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Rows"
              />
            </div>
          </div>
        </div>
        <DoneRequestFilter
          filters={filters}
          setFilters={setFilters}
          moveSizeList={moveSizeList}
          truckList={truckList}
          foremanList={foremanList}
          fetchDoneRequests={fetchDoneRequests}
          setPage={setPage}
          labelRowsPerPage={<span className="pagination-text">Rows:</span>}
        />
      </>
    );
  }
  return (
    <>
      <div className="rounded-box pb-3">
        <div className="row">
          <div className="col-12">
            <table
              id="example"
              className="responsive table table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="small-sub-title dark-grey-color">
                    Request number
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Start date and time
                  </th>
                  <th className="small-sub-title dark-grey-color">Status</th>
                  <th className="small-sub-title dark-grey-color">Distance</th>
                  <th className="small-sub-title dark-grey-color">Move Type</th>
                  <th className="small-sub-title dark-grey-color">Truck</th>
                  <th className="small-sub-title dark-grey-color"></th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request, i) => (
                  <tr
                    key={i}
                    className="pointer-point"
                    onClick={() =>
                      props.history.push(
                        "/company/company-requests/" + request.request_data.id
                      )
                    }
                  >
                    <td>
                      <span
                        href="request-detail-submitted.html"
                        className="request-link"
                      >
                        #{request.request_data.id}
                        <br />
                        <span className="light-grey-color extra-small-font-size">
                          Updated{" "}
                          {moment(request.request_data.updated_at).fromNow()}
                        </span>
                      </span>
                    </td>
                    <td>
                      {request.request_data.schedule_time}
                      <br />
                      {/* 9:00 AM */}
                    </td>
                    <td>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#C4314B"></circle>
                      </svg>{" "}
                      {getStatusForCompany(
                        request.request_data.request_timeline_status
                      )}
                      <br />
                      <span className="light-grey-color extra-small-font-size">
                        {moment(request.request_data.updated_at).format(
                          "MMM Do YY , hh:mm"
                        )}
                      </span>
                    </td>
                    <td>{request.request_data.net_distance} mi</td>
                    <td>{request.request_data.move_type}</td>
                    <td>
                      {request.trcuk_array.length === 0
                        ? "-"
                        : request.trcuk_array.map((truck, i) => {
                            return (
                              truck.name +
                              (i === request.trcuk_array.length - 1 ? "" : ", ")
                            );
                          })}
                    </td>
                    <td>
                      <a className="custom-btn-small custom-button-extra-small req-status-button status-button-done">
                        Done
                      </a>
                    </td>
                  </tr>
                ))}
                {/* loop Close */}
              </tbody>
            </table>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={
                <span className="pagination-text">Rows per page:</span>
              }
            />
          </div>
        </div>
      </div>
      <DoneRequestFilter
        filters={filters}
        setFilters={setFilters}
        moveSizeList={moveSizeList}
        truckList={truckList}
        foremanList={foremanList}
        setPage={setPage}
        fetchDoneRequests={fetchDoneRequests}
      />
    </>
  );
}

export default withRouter(DoneRequests);
