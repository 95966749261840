import React from "react";

function Done(props) {
  return (
    <>
      <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5 pt-40">
        <div className="modal-body p-0">
          <h6 id="area-not-found-title">
            We are not in your area yet. But will be there very soon, we will
            let you know once there.
          </h6>
          <div className="form-box">
            <div className="form-group pt-3">
              <button
                type="button"
                onClick={props.closePopUp}
                className="red-btn custom-buton custom-buton-large w-100"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Done;
