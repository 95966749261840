import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { addImage, subtractImage } from "../../../assets";
import { v4 as uuidv4 } from "uuid";

function AddCustomItem(props) {
  const { itemName, setItemList, addCustomItem } = props;

  const [input, setInput] = useState(itemName);

  const [value, setValue] = useState(1);

  const [size, setSize] = useState("0");

  const handleSubtract = () => {
    if (value > 0) {
      setValue((current) => parseInt(current) - 1);
    }
  };

  const handleAdd = () => {
    if (value < 999) {
      setValue((current) => parseInt(current) + 1);
    }
  };

  const handleValue = (e) => {
    setValue(parseInt(e.target.value));
  };

  const handleClick = () => {
    const newItem = {
      category_id: null,
      category_name: "",
      comments: "",
      currency: "USD",
      desciption: "",
      id: "",
      is_active: true,
      item_id: uuidv4(),
      name: input.trim(),
      size: size,
      type: "1",
      unit_price: "0",
      added_by_type: "1",
      value: value,
    };

    addCustomItem(newItem);
  };
  return (
    <>
      <div className="row pl-3 pr-3 no-gutters pt-40">
        <div className="col-12 text-center">
          <h2>Add Custom Item</h2>
        </div>
        <div className="col-12 text-left sub-title">
          <label htmlFor="distance" className="overline dark-grey-color">
            Item Name
          </label>
          <input
            type="text"
            className="form-control small-font-size"
            id="add-item-input"
            placeholder="Enter Item name"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            autoComplete="off"
          />
        </div>

        <div className="col-7 pt-2">
          <label htmlFor="distance" className="overline dark-grey-color">
            Item Size
          </label>
          <select
            name=""
            value={size}
            onChange={(e) => setSize(e.target.value)}
            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
          >
            <option value="0">Extra Small</option>
            <option value="1">Small</option>
            <option value="2">Medium</option>
            <option value="3">Large</option>
            <option value="4">Extra Large</option>
          </select>
        </div>

        <div className="col-5 small-sub-title items-count-wrapper pt-40">
          <img
            src={addImage}
            className="float-right pointer-point"
            onClick={() => handleAdd()}
          />
          <NumberFormat
            maxLength={3}
            className="form-control small-font-size items-count float-right"
            value={value}
            onChange={handleValue}
          />
          <img
            src={subtractImage}
            className="float-right pointer-point"
            onClick={() => handleSubtract()}
          />
          <div className="clearfix"></div>
        </div>
        <div className="col-12 pt-40">
          <button
            type="button"
            className="red-btn custom-buton custom-buton-large w-100"
            onClick={handleClick}
          >
            Add To Move
          </button>
          <p className="small-font-size light-grey-color text-center mt-2">
            You can add extra details after adding items
          </p>
        </div>
      </div>
    </>
  );
}

export default AddCustomItem;
