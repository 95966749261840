import React from "react";
import DatePicker from "react-datepicker";
function PaymentsFilter(props) {
  const { filter, setFilters, FetchPaymentData, setPage } = props;

  const handleFilter = () => {
    FetchPaymentData("filter");
    setPage(0);
    document.getElementById("close-filter").click();
  };
  return (
    <>
      <div
        className="modal fade pb-m-80 mt-m-80"
        id="change-filter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-filter-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close-filter"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="change-filter-title">
                  Filter
                </h5>
                <div className="form-box pt-20">
                  <form onSubmit={handleFilter}>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-6 col-sm-6 pt-3">
                          <label
                            htmlFor="from-date"
                            className="overline dark-grey-color"
                          >
                            From Date
                          </label>
                          <br></br>

                          <DatePicker
                            required
                            className="form-control small-font-size date-picker"
                            id="to-date"
                            placeholderText="MM/DD/YYYY"
                            selected={filter.from_date}
                            onChange={(date) =>
                              setFilters({ ...filter, from_date: date })
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-6 col-sm-6 pt-3">
                          <label
                            htmlFor="to-date"
                            className="overline dark-grey-color"
                          >
                            To Date
                          </label>
                          <br></br>
                          <DatePicker
                            required
                            className="form-control small-font-size date-picker"
                            id="to-date"
                            placeholderText="MM/DD/YYYY"
                            selected={filter.to_date}
                            onChange={(date) =>
                              setFilters({ ...filter, to_date: date })
                            }
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group pt-3">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-10">
                          <button
                            type="submit"
                            className="red-btn custom-buton custom-buton-large w-100"
                          >
                            Use Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentsFilter;
