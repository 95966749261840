import React, { useState, useEffect } from "react";
import { IconButton, Menu, MenuItem, Divider } from "@material-ui/core";
import DeleteItemFromList from "./DeleteItemFromList";
import AddItemsInCommonList from "./AddItemsInCommonList";
import ItemDetails from "./ItemDetails";
import { addIconImage, editImage } from "../../../assets";
import NumberFormat from "react-number-format";

function AddItemsByCommonList(props) {
  //
  const [popUpFlag, setPopUpFlag] = useState(false);

  // const [commonList, setCommonList] = useState([]);

  const { commonList, commonListCopy, setCommonList, UpdateItemsByCommonList } =
    props;

  const [selectedItem, setSelectedItem] = useState("");

  const [price, SetPrice] = useState("");

  const check_list = () => {
    let items = commonList.filter((item) => item.value > 0);
    return items;
  };

  //delete item from list

  const handleDeleteFromCommonList = (id) => {
    console.log(id);
    let temp = commonList.filter((item) => item.item_id !== id);
    console.log(temp);
    setCommonList(temp);
  };

  //update item from list
  const handleUpdateItemDetails = (updatedItem) => {
    console.log(updatedItem);
    let temp = commonList.map((item) => {
      if (item.item_id === updatedItem.item_id) {
        return updatedItem;
      }
      return item;
    });
    console.log(temp, "from update item");
    setCommonList(temp);
  };

  const handleAddItems = (newItems) => {
    console.log(newItems, "new items");
    let temp = commonList.map((item) => item);
    newItems.map((item) => {
      let count = 1;
      if (item.id === "") {
        temp.push(item);
        return;
      }
      commonList.map((listItem) => {
        if (listItem.id === item.id) {
          count++;
        }
      });
      if (count < 2) {
        temp.push(item);
      } else {
        temp.push({ ...item, name: item.name + " #" + count, images: [] });
      }
    });

    setCommonList(temp);
  };

  // handle material ui popup
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkEdited = () => {
    let flag = false;

    if (commonList.length === commonListCopy.length) {
      //check values
      for (let i = 0; i < commonList.length; i++) {
        if (commonList[i].value !== commonListCopy[i].value) {
          flag = true;
          return true;
        }
        if (commonList[i].name !== commonListCopy[i].name) {
          flag = true;
          return true;
        }
        if (commonList[i].comments !== commonListCopy[i].comments) {
          flag = true;
          return true;
        }

        if (
          commonList[i].images
            ? commonList[i].images.length
            : 0 !== commonListCopy[i].images
            ? commonListCopy[i].images.length
            : 0
        ) {
          flag = true;
          return true;
        }
      }
    } else {
      flag = true;
      return true;
    }

    // flag ? alert("edited") : alert("Not Edited");
    return false;
  };

  return (
    <>
      <section className="main-content address-page pb-5">
        <div className="container">
          <div className="add-items-wrapper mb-m-90 mb-90 mt-3 mt-sm-5">
            <h1 className="text-center text-uppercase pt-3 pt-sm-4 pt-md-5 mb-0">
              Edit Items list
            </h1>
            <p className="small-font-size text-center light-grey-color2">
              Total items: {commonList.length}
            </p>

            <div className="selected-room mt-4 mt-sm-5">
              <div className="row justify-content-center pb-3">
                <div className="col-12 col-sm-8 mb-4 mb-sm-4">
                  <div className="rounded-box">
                    <div className="selected-items">
                      {commonList.length === 0
                        ? ""
                        : commonList.map((item) => (
                            <div className="single-item" key={item.item_id}>
                              <div className="row">
                                <div className="col-10">
                                  <div className="item-name">{item.name}</div>
                                  <div className="item-count small-font-size light-grey-color">
                                    {item.value} pieces
                                  </div>
                                </div>
                                <div className="col-2 text-right">
                                  <div className="dropdown dropleft">
                                    <img
                                      className="dropdown-toggle pointer-point"
                                      data-toggle="dropdown"
                                      src={editImage}
                                    />
                                    <div
                                      className="dropdown-menu "
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        className="dropdown-item"
                                        style={{
                                          color: "#747474",
                                          fontSize: "15px",
                                          cursor: "pointer",
                                        }}
                                        data-toggle="modal"
                                        data-target="#details-item-in-commonlist"
                                        onClick={() => {
                                          setPopUpFlag(false);
                                          setSelectedItem(item);
                                          setTimeout(() => {
                                            setPopUpFlag(true);
                                          }, 100);
                                        }}
                                      >
                                        Details
                                      </a>
                                      <Divider />
                                      <a
                                        className="dropdown-item"
                                        style={{
                                          color: "#747474",
                                          fontSize: "15px",
                                          cursor: "pointer",
                                        }}
                                        data-toggle="modal"
                                        data-target="#delete-item"
                                        onClick={() => {
                                          setPopUpFlag(false);
                                          setSelectedItem(item);
                                          setTimeout(() => {
                                            setPopUpFlag(true);
                                          }, 100);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                      <div className="single-item">
                        <div className="row">
                          <div className="col-12 text-center">
                            <img
                              onClick={() => {
                                setPopUpFlag(false);
                                setTimeout(() => {
                                  setPopUpFlag(true);
                                }, 100);
                              }}
                              data-toggle="modal"
                              data-target="#add-item"
                              style={{ cursor: "pointer" }}
                              src={addIconImage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center ">
                {/* {check_list().length > 0 && checkEdited() && (
                  <>
                    <div className=" dark-grey-color pt-1">
                      <label
                        htmlFor="email"
                        className="overline dark-grey-color pt-1 pr-3"
                      >
                        Add Price against this change :
                      </label>
                    </div>
                    <div className="black-color">
                      <NumberFormat
                        allowNegative={false}
                        // disabled={isLoadingBtn}
                        allowLeadingZeros
                        value={price}
                        required
                        onChange={(e) => SetPrice(e.target.value)}
                        type="text"
                        className="form-control small-font-size"
                        id="your-price"
                        placeholder="100"
                        autoComplete="off"
                      />
                    </div>
                  </>
                )} */}
              </div>
              <div className="row justify-content-center pt-3">
                <div className="">
                  {checkEdited() && (
                    <button
                      onClick={UpdateItemsByCommonList}
                      className="custom-btn-small custom-buton"
                    >
                      Update items List
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Delete Item popup --> */}
      <div
        className="modal fade delete-item popup-bottom mt-0"
        id="delete-item"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="delete-item"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30 mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="DeleteItemFromListClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {popUpFlag && (
              <DeleteItemFromList
                handleDeleteFromCommonList={handleDeleteFromCommonList}
                item={selectedItem}
                closePopUp={() =>
                  document.getElementById("DeleteItemFromListClose").click()
                }
              />
            )}
          </div>
        </div>
      </div>
      {/* <!-- Add Item Popup --> */}
      <div
        className="modal fade add-item popup-bottom full-height"
        id="add-item"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="add-item"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="add-item-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {popUpFlag && (
              <AddItemsInCommonList
                handleAddItems={handleAddItems}
                commonList={commonList}
                closePopUp={() =>
                  document.getElementById("add-item-close").click()
                }
              />
            )}
          </div>
        </div>
      </div>
      {/* <!-- Item Detail Popup --> */}
      <div
        className="modal fade item-detail popup-bottom full-height"
        id="details-item-in-commonlist"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="item-detail"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="DetailsItemFromListClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {popUpFlag && (
              <ItemDetails
                handleUpdateItemDetails={handleUpdateItemDetails}
                item={selectedItem}
                closePopUp={() =>
                  document.getElementById("DetailsItemFromListClose").click()
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddItemsByCommonList;
