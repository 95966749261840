import React, { useState, useRef } from "react";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import { useEffect } from "react";
import { addImage, subtractImage } from "../../../../assets";
import { ListItem } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import NumberFormat from "react-number-format";
import SearchInput, { createFilter } from "react-search-input";
import AddCustomItem from "./AddCustomItem";
import { useSnakbar } from "../../../../Context/Context-Snakbar/ContextSnackbar";
function AddItemsInCommonList(props) {
  const { showErrorSnak } = useSnakbar();
  const { commonList } = props;
  //from server
  const [itemList, setItemList] = useState([]);
  const [input, setInput] = useState("");
  const KEYS_TO_FILTERS = ["name"];

  const [customScreen, setCustomScreen] = useState("");

  const filteredItems = itemList.filter(createFilter(input, KEYS_TO_FILTERS));

  const handleAdd = (itemSelected) => {
    console.log(itemSelected, "sel");
    let temp = itemList.map((item) => {
      if (item.item_id === itemSelected.item_id) {
        return {
          ...item,
          value: itemSelected.value + 1,
        };
      }
      return item;
    });

    setItemList(temp);
  };

  const handleSubtract = (itemSelected) => {
    if (itemSelected.value > 0) {
      let temp = itemList.map((item) => {
        if (item.item_id === itemSelected.item_id) {
          return {
            ...item,
            value: itemSelected.value - 1,
          };
        }
        return item;
      });
      setItemList(temp);
    }
  };

  const fetchCommonList = () => {
    let requestObj = {
      path: "/api/customer/get_item_by_category_id",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      type: "",
      category_id: "",
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        let temp = res.data.items.map((item) => {
          return {
            ...item,
            value: 0,
            item_id: uuidv4(),
            comments: "",
            type: "1",
            category_name: "Common List",
          };
        });

        setItemList(temp);
        //focus on input for type
        if (document.getElementById("add-item-input")) {
          document.getElementById("add-item-input").focus();
        }
      }
    });
  };
  const fetchCommonListWithoutToken = () => {
    let requestObj = {
      path: "/api/customer/get_item",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      type: "1",
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        let temp = res.data.items.map((item) => {
          return {
            ...item,
            value: 0,
            item_id: uuidv4(),
            comments: "",
            type: "1",
            category_name: "Common List",
          };
        });

        setItemList(temp);
        //focus on input for type
        if (document.getElementById("add-item-input")) {
          document.getElementById("add-item-input").focus();
        }
      }
    });
  };

  //get count for show number of selected items

  const itemsToAddCount = () => {
    let temp = itemList.filter((item) => item.value > 0);
    return temp.length;
  };

  const handleClick = () => {
    let temp = itemList.filter((item) => item.value > 0);
    console.log(temp);
    props.handleAddItems(temp);
    props.closePopUp();
  };

  const handleKeyDown = (itemSelected, e) => {
    if (e.key === ".") {
      setTimeout(() => {
        let temp = itemList.map((item) => {
          if (item.item_id === itemSelected.item_id) {
            return {
              ...item,
              value: 0,
            };
          }
          return item;
        });
        setItemList(temp);
      }, 200);
    }
  };

  const handleChange = (itemSelected, e) => {
    let value = parseInt(e.target.value);
    let temp = itemList.map((item) => {
      if (item.item_id === itemSelected.item_id) {
        if (e.target.value === "") {
          return {
            ...item,
            value: 0,
          };
        } else {
          return {
            ...item,
            value: value,
          };
        }
      }
      return item;
    });

    setItemList(temp);
  };

  const handleCustomItem = () => {
    const isAleady = itemList.find(
      (item) => item.name.trim().toLowerCase() === input.trim().toLowerCase()
    );
    if (isAleady) {
      showErrorSnak(
        "Item with exact same name is already exist in search list. Please select from the search list."
      );
      return;
    }

    const isAlreadyInSelected = commonList.find(
      (item) => item.name.trim().toLowerCase() === input.trim().toLowerCase()
    );
    if (isAlreadyInSelected) {
      showErrorSnak(
        "Item with exact same name is already exist in selected items list."
      );
      return;
    }
    setCustomScreen("size");
  };

  const addCustomItem = (newItem) => {
    // setItemList((current) => [...current, newItem]); //stop doing this

    const isAleady = itemList.find(
      (item) =>
        item.name.trim().toLowerCase() === newItem.name.trim().toLowerCase()
    );

    if (isAleady) {
      showErrorSnak(
        "Item with exact same name is already exist in search list. Please select from the search list."
      );
      return;
    }

    const isAlreadyInSelected = commonList.find(
      (item) =>
        item.name.trim().toLowerCase() === newItem.name.trim().toLowerCase()
    );
    if (isAlreadyInSelected) {
      showErrorSnak(
        "Item with exact same name is already exist in selected items list."
      );
      return;
    }

    setCustomScreen("");
    setInput("");
    props.handleAddItems([newItem]);
    props.closePopUp();
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchCommonList();
    } else {
      fetchCommonListWithoutToken();
    }
  }, []);

  if (customScreen === "size") {
    return (
      <AddCustomItem
        itemName={input}
        setItemList={setItemList}
        addCustomItem={addCustomItem}
      />
    );
  }

  return (
    <div className="form-wrapper pl-4 pr-4 pl-sm-4 pr-sm-4 pt-3 pt-sm-4 pb-m-230px">
      <div className="row">
        <div className="col-12">
          <input
            type="text"
            className="form-control small-font-size"
            id="add-item-input"
            placeholder="Enter Item name"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="items-search-results pt-4" style={{ paddingBottom: 200 }}>
        {/* Get name for custom item */}
        {input.length > 2 && customScreen === "" && (
          <div
            onClick={handleCustomItem}
            className="border-bottom add-custom-item pointer-point pt-2"
          >
            <span>
              Add custom item <span>`{input}`</span>{" "}
            </span>
          </div>
        )}

        {filteredItems.map((item) => {
          if (input !== "") {
            return (
              <div
                key={item.item_id}
                className="row pt-3 separator-border no-gutters"
              >
                <div className="col-7 text-left sub-title"> {item.name}</div>
                <div className="col-5 small-sub-title items-count-wrapper">
                  <img
                    src={addImage}
                    className="float-right pointer-point"
                    onClick={() => handleAdd(item)}
                  />
                  <NumberFormat
                    maxLength={3}
                    className="form-control small-font-size items-count float-right"
                    value={item.value}
                    allowNegative={false}
                    onChange={(e) => handleChange(item, e)}
                    onKeyDown={(e) => handleKeyDown(item, e)}
                  />
                  <img
                    src={subtractImage}
                    className="float-right pointer-point"
                    onClick={() => handleSubtract(item)}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className="row pt-0 pt-sm-5 justify-content-center bottom-fixed-area-item">
        <div className="col-12">
          <p className="text-center dark-grey-color small-font-size mb-1">
            You have {itemsToAddCount()} items to add
          </p>
          <button
            type="button"
            className="red-btn custom-buton custom-buton-large w-100"
            onClick={handleClick}
          >
            Add To Move
          </button>
          <p className="small-font-size light-grey-color text-center mt-2">
            You can add extra details after adding items
          </p>
        </div>
      </div>
    </div>
  );
}

export default AddItemsInCommonList;
