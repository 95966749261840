import React from "react";
import { Link } from "react-router-dom";

function QuickAdvice(props) {
  const handleClick = () => {
    props.history.push("/register-company");
  };
  return (
    <section className="main-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-0 col-md-3 col-xl-4"></div>
          <div className="col-12 col-md-6 col-xl-4">
            <div className="form-box-wrapper quick-advice">
              <div className="form-box">
                <h5 className="text-center">Quick advice before starting</h5>

                <p className="sub-title">
                  In order to register your company, please prepare the
                  followong documents:
                </p>
                <p>1. Personal Liability insurance</p>
                <p>2. Personal Damage Insurance</p>
                <p>3. Cargo Policy Insurance</p>
                <p>4. MTR(PUC) number</p>
                <p>5. USDOT number/ CA number</p>

                <div className="form-group button-wrapper">
                  <button
                    onClick={handleClick}
                    className="custom-buton custom-buton-large w-100"
                  >
                    I have these documents
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-0 col-md-3 col-xl-4"></div>
        </div>
        <div className="text-center mt-lg-4">
          <p className="light-grey-color pb-5">
            Already have an account?{" "}
            <Link to="/login" className="dark-grey-color">
              Log In
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
}

export default QuickAdvice;
