import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { invokeApi, base_uri } from "../../bl_libs/invokeApi";
import socketIOClient from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import ReactHowler from "react-howler";
import { bongoMp3 } from "../../assets";

const SocketContext = React.createContext();

export const useSockets = () => useContext(SocketContext);

let socket;

//----------------------------------------------->Socket join

// if (!localStorage.getItem("token")) {
//   socket.off();
// } else {
//   socket.emit("create", "company");
// }

export function ContextSocket({ children }) {
  /////////////////////////////////////////////////////////////////////////chat states

  const [startSocket, setStartSocket] = useState(
    localStorage.getItem("token") ? true : false
  );

  const [chatMessages, setChatMessage] = useState([
    { message: "message1", type: "1", id: "msg_id" },
    { message: "message2", type: "1", id: "2" },
  ]);

  const [selectedUser, setSelectedUser] = useState("");

  const [userList, setUserList] = useState([]);

  const [profile, setProfile] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [chatLoading, setChatLoading] = useState(false);

  ////////////////////////////////////////////////--->Start and stop socket

  const socketON = () => {
    setStartSocket(true);
  };

  const socketOFF = () => {
    setStartSocket(false);
    socket.disconnect();
    setSelectedUser("");
  };

  ////////////////////////////////////////////////--->chat handlers and state management

  const playNotification = () => {
    const tune = new Audio(bongoMp3);
    tune.play();
  };

  //------------------------------------------>Select User from sidebar

  const handleSelectUser = (user) => {
    console.log(user, "SELECTED USER");

    setSelectedUser(user);
    FetchOldMessages(user);
  };

  //------------------------------------------> Send message

  const handleSendMessage = (message) => {
    console.log(message, "SEND MESSAGE");

    const msg_id = uuidv4();

    setChatMessage([
      ...chatMessages,
      {
        id: msg_id,
        is_active: true,
        message_datatime: new Date(),
        message_status: 1,
        message_text: message,
        message_type: 0,
        sender_type: 0,
      },
    ]);

    console.log(selectedUser, "SELECTED USER ROOM");
    console.log(profile, "PROFILE");

    const message_obj = {
      message_text: message,
      message_type: "0",
      request_id: selectedUser.request_id,
      sender_type: "0",
      sender_id: profile.room_id,
      receive_type: selectedUser.type,
      receiver_id: selectedUser.profile_data.room_id,
      room_id: selectedUser.receiver_room_id,
    };
    console.log(message_obj, "MESSAGE_OBJ");
    socket.emit("sendMessage", message_obj);

    setTimeout(() => {
      if (document.getElementById(msg_id)) {
        document.getElementById(msg_id).scrollIntoView();
      }
    }, 500);
  };

  //------------------------------------------> Receive new message

  const handleNewMessage = (data) => {
    console.log(data, "RECIVE MESSAGE", chatMessages);
    console.log(selectedUser, "SELECTED USER");
    const msg_id = uuidv4();
    playNotification();
    showInfoSnak("New Message");
    if (
      data.room_id === selectedUser.room_id &&
      window.location.pathname === "/company/chats"
    ) {
      setChatMessage([
        ...chatMessages,
        {
          id: msg_id,
          is_active: true,
          message_datatime: data.message_datatime,
          message_status: 1,
          message_text: data.message_text,
          message_type: 0,
          sender_type: 1,
        },
      ]);

      HitMessageAreReaded();
    } else {
      setUserList((list) => {
        let temp = list.map((chat) => {
          return {
            ...chat,
            unread_messages:
              chat.room_id === data.room_id
                ? chat.unread_messages + 1
                : chat.unread_messages,
          };
        });
        return temp;
      });
    }
    setTimeout(() => {
      if (document.getElementById(msg_id)) {
        document.getElementById(msg_id).scrollIntoView();
      }
    }, 500);
  };

  //------------------------------------------> Open Chat with specific room from request

  const OpenChatFromRequest = (user) => {
    console.log(user, "OpenChatFromRequest");

    const selectedChat = userList.find(
      (singleUser) =>
        singleUser.room_id === user.room_id &&
        singleUser.request_id === user.request_id &&
        singleUser.receiver_room_id === user.receiver_room_id
    );

    console.log(selectedChat, "SELECTED CHAT");
    setSelectedUser(selectedChat);
    FetchOldMessages(selectedChat);
  };

  //------------------------------------------> Fetch user old chat from Api

  const FetchOldMessages = (user) => {
    setChatLoading(true);
    console.log(user, "FETCH OLD CHAT");
    let requestObj = {
      path: "/app_api/get_messages",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    console.log(profile, "PROFILE");
    let postData = {
      socket_id: profile.room_id.toString(),
      request_id: user.request_id.toString(),
      type: user.type.toString(),
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res, "OLD CHAT RES");
        setChatMessage(res.getchatdata);
        setChatLoading(false);
      } else {
        //showErrorSnak(res.message);
      }

      if (res.getchatdata.length > 0) {
        if (
          document.getElementById(
            res.getchatdata[res.getchatdata.length - 1].id
          )
        ) {
          document
            .getElementById(res.getchatdata[res.getchatdata.length - 1].id)
            .scrollIntoView();
        }
      }
    });
  };

  const HitMessageAreReaded = () => {
    let requestObj = {
      path: "/app_api/change_chat_status",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    console.log(profile, "PROFILE");
    let postData = {
      socket_id: profile.room_id.toString(),
      request_id: selectedUser.request_id.toString(),
      type: selectedUser.type.toString(),
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        FetchChanels();
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  // useEffect(() => {
  //   FetchChanels();
  // }, []);

  useEffect(() => {
    if (startSocket) {
      const user_socket_id = JSON.parse(localStorage.getItem("user")).room_id;
      const ENDPOINT = `${base_uri}?token=${user_socket_id}&type=${0}`;
      console.log(ENDPOINT, "ENDPOINT");
      var connectionOptions = {
        "force new connection": true,
        reconnection: true,
        reconnectionDelay: 2000, //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
        reconnectionDelayMax: 60000, //1 minute maximum delay between connections
        reconnectionAttempts: "Infinity", //to prevent dead clients, having the user to having to manually reconnect after a server restart.
        timeout: 10000, //before connect_error and connect_timeout are emitted.
        transports: ["websocket"], //forces the transport to be only websocket. Server needs to be setup as well/
      };
      socket = socketIOClient(ENDPOINT, connectionOptions);
      socket.emit("create", user_socket_id);
      FetchChanels();
    }
  }, [startSocket]);

  useEffect(() => {
    // if (socket.connected === true) {
    //   // showSuccessSnak("Socket Connected");
    // } else {
    //   console.log(socket, "chk");
    //   // showErrorSnak("Socket is not connected");
    // }
  }, [userList]);

  //------------------->Listen for message

  useEffect(() => {
    startSocket &&
      socket.off("chatMessage").on("chatMessage", (data) => {
        handleNewMessage(data);
      });
  }, [chatMessages]);

  useEffect(() => {
    setTimeout(() => {
      FetchChanels();
    }, 1000);
    // setUserList((list) => {
    //   let temp = list.map((chat) => {
    //     return {
    //       ...chat,
    //       unread_messages: 0,
    //     };
    //   });
    //   return temp;
    // });
  }, [selectedUser]);

  ////////////////////////////////////////////////////////////////////////Snackbar config

  const [snakbar, setSnakbar] = useState({
    show: false,
    message: "hi",
    type: "success",
    duration: 6000,
  });

  const handleClose = () => {
    setSnakbar({
      show: false,
      message: "",
      type: "",
      duration: 6000,
    });
  };

  const showErrorSnak = (msg) => {
    if (!msg) return;
    setSnakbar({
      show: true,
      message: msg,
      type: "error",
      duration: 6000,
    });
  };

  const showSuccessSnak = (msg) => {
    if (!msg) return;
    setSnakbar({
      show: true,
      message: msg,
      type: "success",
      duration: 6000,
    });
  };
  const showInfoSnak = (msg) => {
    if (!msg) return;
    setSnakbar({
      show: true,
      message: msg,
      type: "info",
      duration: 2000,
    });
  };

  const FetchChanels = () => {
    if (!localStorage.getItem("token")) return;
    let requestObj = {
      path: "/app_api/channels",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {};
    requestObj["postData"] = postData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res, "RESPONSE");
        setUserList(
          res.request_data.map((data) => {
            return {
              ...data,
            };
          })
        );
        setProfile(res.profile);
        setIsLoading(false);
        // showSuccessSnak("get all list");
      } else {
        //showErrorSnak(res.message);
      }
    });
  };
  const handleRefreshChatList = () => {
    FetchChanels();
  };

  return (
    <SocketContext.Provider
      value={{
        chatMessages,
        setChatMessage,
        selectedUser,
        userList,
        profile,
        setProfile,
        isLoading,
        chatLoading,
        //--------------->>Open chat from request
        OpenChatFromRequest,
        //--------------->>handlers
        handleRefreshChatList,
        handleSelectUser,
        handleSendMessage,
        socketON,
        socketOFF,
      }}
    >
      {children}
      <Snackbar
        open={snakbar.show}
        autoHideDuration={snakbar.duration}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={snakbar.type}>
          {snakbar.message}
        </Alert>
      </Snackbar>
    </SocketContext.Provider>
  );
}
