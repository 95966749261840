import React from "react";
import { withRouter } from "react-router-dom";
import { congratulationsImage } from "../../assets";
function Welcome(props) {
  return (
    <>
      <section className="main-content address-page">
        <div className="container">
          <div className="add-items-wrapper pt-100 pb-5">
            <div className="to-do-img text-center pb-40">
              <img src={congratulationsImage} />
            </div>
            <div className="row text-center justify-content-center">
              <div className="col-12 col-sm-8 small-font-size text-center">
                <h4 className="text-center text-uppercase">Welcome</h4>
                <p
                  className="light-grey-color font-weight-bod"
                  style={{ fontSize: 20, lineHeight: 1.5 }}
                >
                  Congrats on start using the LA Moving Star, in order to see your
                  requests here and start moving clients please add trucks and
                  foreman information.
                </p>
              </div>
            </div>
            <div className="row pt-30 justify-content-center">
              <div className="col-12 col-sm-8 col-md-6">
                <button
                  className="custom-buton custom-buton-large w-100"
                  onClick={() => props.history.push("/company")}
                >
                  add trucks and foreman
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(Welcome);
