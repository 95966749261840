import React, { useState } from "react";
import DateAndTimePicker from "../Date-And-Time/DateAndTimePicker";
import PickUpAddress from "./PickUpAddress";
import Destination from "./Destination";
import { isDOMComponent } from "react-dom/test-utils";
import moment from "moment";
import { useEffect } from "react";
import GoogleMapPointing from "./google-Map-Tracking/GoogleMapPointing.jsx";
import GoogleMaps from "./google-Map-Tracking/GoogleMaps";
import { addIconImage } from "../../../../assets";
import GoogleMapTracking from "./google-Map-Tracking/GoogleMapTracking";
import { invokeApi } from "../../../../bl_libs/invokeApi";
function AddressSelector(props) {
  const {
    //all addressList
    isDirectRequest,
    addressList,
    setAddressList,
    addressListRefresh,
    setAddressListRefresh,
    handleInsertAddress,
    date,
    setDate,
    isDateEmpty,
    setIsDateEmpty,
    timeRange,
    setTimeRange,
    customFromTime,
    customToTime,
    setCustomFromTime,
    setCustomToTime,
    getCustomFromTime,
    getCustomToTime
  } = props;
  const [screen, setScreen] = useState(0);

  const [popUpFlag, setPopUpFlag] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState("");

  const [isMapUpdated, setIsMapUpdated] = useState(true);
  const ShowDateAndTime = () => {
    if(timeRange.start_window_from === "" || timeRange.start_window_to==="")
    {
      return "Please select valid time range"
    }else {
    return (
      moment(date).format("MMM Do YY") + " ( " + timeRange.move_duration + " )"
    );
  }};

  const checkZipCode = (zip) => {
    return new Promise(function (resolve, reject) {
      // do a thing, possibly async, then…
      let requestObj = {
        path: "/api/customer/check_service_area",
        method: "POST",
        toast: false,
        headers: {
          "x-sh-auth": localStorage.getItem("token"),
        },
      };
      let postData = {
        zip_code: zip,
      };
      requestObj["postData"] = postData;

      invokeApi(requestObj).then((res) => {
        resolve(res);
      });
    });
  };

  const setValidAddress = async (address) => {
    console.log(address, "valid address");

    let temp = addressList.map((addr) => {
      if (addr.id === address.id) {
        return {
          ...addr,
          ...address,
        };
      } else {
        return addr;
      }
    });

    setAddressList(temp);

    if (address.address === "") {
      setIsMapUpdated(false);
      setTimeout(() => {
        setIsMapUpdated(true);
      }, 0);
    }

    return true;

    const zip_result = await checkZipCode(address.postal_code);

    if (zip_result.code === 200 || address.address === "" || isDirectRequest) {
      let temp = addressList.map((addr) => {
        if (addr.id === address.id) {
          return {
            ...addr,
            ...address,
          };
        } else {
          return addr;
        }
      });

      setAddressList(temp);

      if (address.address === "") {
        setIsMapUpdated(false);
        setTimeout(() => {
          setIsMapUpdated(true);
        }, 0);
      }

      return true;
    } else {
      setPopUpFlag(false);
      setSelectedAddress(address);
      setTimeout(() => {
        setPopUpFlag(true);
      }, 100);

      if (address.address !== "") {
        document.getElementById("area-popup-toggle").click();
      }
      return false;
    }
  };

  useEffect(() => {
    console.log(addressListRefresh, "object");
    // setIsMapUpdated(false);
    // setTimeout(() => {
    //   setIsMapUpdated(true);
    // }, 0);
  }, [addressListRefresh]);

  //--------------------> for removing scroll from map screen
  useEffect(() => {
    var x = document.getElementsByTagName("BODY")[0];
    x.style.height = "90%";
    return () => {
      var y = document.getElementsByTagName("BODY")[0];
      y.style.height = "";
    };
  }, []);

  return (
    <>
      {/* address picker screen */}
      {screen === 0 && (
        <section className="main-content address-page">
          <div className="map-wrapper">
            <div className="address-date-box">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-sm-4 col-md-3">
                    <div
                      id="area-popup-toggle"
                      data-toggle="modal"
                      data-target="#address-a-picker-model"
                    ></div>
                    <div className="rounded-box position-relative">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          style={{ border: isDateEmpty && "solid 1px red" }}
                          type="text"
                          className="form-control dark-grey-color"
                          placeholder="Set Move Date and Time"
                          value={date && ShowDateAndTime()}
                          data-toggle="modal"
                          data-target="#date-picker-model"
                          onSelect={() => {
                            setPopUpFlag(false);
                            setTimeout(() => {
                              setPopUpFlag(true);
                            }, 100);
                          }}
                        />
                      </div>
                      {/*  */}

                      {/*  */}
                      {window.google &&
                        addressList.map((addr, i) => (
                          <div className="form-group" key={addr.id}>
                            <GoogleMapTracking
                              address={addr}
                              customPlaceholder={addr.placeholder}
                              customClass={
                                i === 0
                                  ? "form-control dark-grey-color address-a"
                                  : i === 1
                                  ? "form-control dark-grey-color address-b"
                                  : i === 2
                                  ? "form-control dark-grey-color address-c"
                                  : i === 3
                                  ? "form-control dark-grey-color address-d"
                                  : i === 4
                                  ? "form-control dark-grey-color address-e"
                                  : "form-control dark-grey-color address-f"
                              }
                              setAddress={setValidAddress}
                              isEmpty={addr.isEmpty}
                            />
                            {/* <input
                            style={{
                              border: addr.isEmpty && "solid 1px red",
                            }}
                            autoComplete="off"
                            type="text"
                            className={
                              i === 0
                                ? "form-control dark-grey-color address-a"
                                : i === 1
                                ? "form-control dark-grey-color address-b"
                                : i === 2
                                ? "form-control dark-grey-color address-c"
                                : i === 3
                                ? "form-control dark-grey-color address-d"
                                : i === 4
                                ? "form-control dark-grey-color address-e"
                                : "form-control dark-grey-color address-f"
                            }
                            placeholder={addr.placeholder}
                            data-toggle="modal"
                            data-target="#address-a-picker-model"
                            value={addr.address}
                            onSelect={() => {
                              setPopUpFlag(false);
                              setSelectedAddress(addr);
                              setTimeout(() => {
                                setPopUpFlag(true);
                              }, 100);
                            }}
                          /> */}
                          </div>
                        ))}
                      <div className="form-group">
                        <div className="single-item">
                          <div className="row">
                            <div className="col-12 text-center">
                              <img
                                onClick={handleInsertAddress}
                                style={{ cursor: "pointer" }}
                                src={addIconImage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {isMapUpdated && <GoogleMaps addressList={addressList} />}
              {/* <GoogleMapPointing addressList={addressList} /> */}
            </div>
          </div>
        </section>
      )}

      {/* <!-- Time and date picker popup --> */}
      <div
        className="modal fade area-not-found"
        id="date-picker-model"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="area-not-found-title"
        aria-hidden="true"
      >
        {" "}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30 mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="date-and-time-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {popUpFlag && (
              <DateAndTimePicker
                setDate={setDate}
                date={date}
                timeRange={timeRange}
                setTimeRange={setTimeRange}
                setIsDateEmpty={setIsDateEmpty}
                closePopUp={() =>
                  document.getElementById("date-and-time-close").click()
                }
                customFromTime={customFromTime}
          setCustomFromTime={setCustomFromTime}
          getCustomFromTime={getCustomFromTime}
          getCustomToTime={getCustomToTime}
          customToTime={customToTime}
          setCustomToTime={setCustomToTime}
              />
            )}
          </div>
        </div>
      </div>

      {/* <!-- Address picker popup --> */}
      <div
        className="modal fade add-item popup-bottom full-height"
        id="address-a-picker-model"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="area-not-found-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30 mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="address-a-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {popUpFlag && (
              <PickUpAddress
                addressList={addressList}
                setAddressList={setAddressList}
                addressListRefresh={addressListRefresh}
                setAddressListRefresh={setAddressListRefresh}
                selectedAddress={selectedAddress}
                closePopUp={() =>
                  document.getElementById("address-a-close").click()
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddressSelector;
