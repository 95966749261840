import { Badge } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Tippy from "@tippy.js/react";
import React from "react";
import { cameraImage } from "../../../../assets";
import { s3baseUrl } from "../../../../bl_libs/invokeApi";

function ChangeInItems(props) {
  const { ItemsConfirmation, errMessage } = props;

  const { request_item, change_item } = props.requestData;

  const getItemsByRoom = (items) => {
    let rooms = [];
    items.map((item) => {
      let found = rooms.find((room) => room === item.category_name);
      if (found === undefined) {
        rooms.push(item.category_name);
      }
    });

    let itemsByRoom = [];
    rooms.map((room) => {
      let temp = items.filter((item) => room === item.category_name);
      itemsByRoom.push({ room, items: temp });
    });
    return itemsByRoom;
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6" id="onebox">
          <div className=" rounded-box items-info-wrapper mt-2 ">
            <div className="info-header text-center">
              <h6 className="black-color">Previous Items List</h6>
            </div>
            <div className="info-detail-wrapper p-3 pt-20 ">
              {getItemsByRoom(request_item).map((singleRoom) => {
                if (singleRoom.room !== "Common List") {
                  return (
                    // item by room
                    <div className="col-12 col-sm-12">
                      <div className="sub-title">
                        {singleRoom.room + ` (${singleRoom.items.length}) `}
                      </div>
                      {singleRoom.items.map((item) => (
                        <div className="row pt-1 ">
                          <div className="col-9 text-left small-font-size ">
                            {item.name}
                          </div>
                          <div className="col-3 small-sub-title">
                            <span className="pr-2 ">{item.quantity}</span>
                            {item.request_item_image_models.length > 0 && (
                              <Tippy
                                content={item.request_item_image_models.map(
                                  (pic) => (
                                    <img
                                      style={{ height: 117, width: 117 }}
                                      className="p-1"
                                      src={s3baseUrl + pic.image_url}
                                    />
                                  )
                                )}
                                animation="scale"
                                delay={[300, 0]}
                                arrow={false}
                                placement="bottom"
                                maxWidth={400}
                              >
                                <Badge
                                  // key={i}
                                  color="primary"
                                  overlap="circle"
                                  badgeContent={
                                    <span
                                      style={{
                                        fontSize: 14,
                                        marginBottom: 0,
                                        cursor: "pointer",
                                      }}
                                      // onClick={() => handleImageCross(pic.preview)}
                                    >
                                      {item.request_item_image_models.length}
                                    </span>
                                  }
                                >
                                  <img
                                    viewBox="0 0 50 50"
                                    src={cameraImage}
                                    style={{
                                      margin: 5,
                                    }}
                                  ></img>
                                </Badge>
                              </Tippy>
                              // <span>
                              //   <img src={photoCountImage} />
                              // </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                } else {
                  return (
                    //items by common list
                    <>
                      <div className="sub-title col-12">{singleRoom.room}</div>

                      {singleRoom.items.map((item) => (
                        <div className="col-12 col-sm-12 col-lg-12">
                          <div className="row pt-1">
                            <div className="col-9 text-left small-font-size">
                              {item.name}
                            </div>
                            <div className="col-3 small-sub-title">
                              <span className="pr-2 align-bottom">
                                {item.quantity}
                              </span>
                              {item.request_item_image_models.length > 0 && (
                                <Tippy
                                  content={item.request_item_image_models.map(
                                    (pic) => (
                                      <img
                                        style={{ height: 117, width: 117 }}
                                        className="p-1"
                                        src={s3baseUrl + pic.image_url}
                                      />
                                    )
                                  )}
                                  animation="scale"
                                  delay={[300, 0]}
                                  arrow={false}
                                  placement="bottom"
                                  maxWidth={400}
                                >
                                  <Badge
                                    // key={i}
                                    color="primary"
                                    overlap="circle"
                                    badgeContent={
                                      <span
                                        style={{
                                          fontSize: 14,
                                          marginBottom: 0,
                                          cursor: "pointer",
                                        }}
                                        // onClick={() => handleImageCross(pic.preview)}
                                      >
                                        {item.request_item_image_models.length}
                                      </span>
                                    }
                                  >
                                    <img
                                      viewBox="0 0 50 50"
                                      src={cameraImage}
                                      style={{
                                        margin: 5,
                                      }}
                                    ></img>
                                  </Badge>
                                </Tippy>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 mt-4 mt-lg-0" id="twobox">
          <div className="rounded-box items-info-wrapper mt-2">
            <div className="info-header text-center">
              <h6 className="black-color">Changed Items List</h6>
            </div>
            {errMessage && (
              <Alert variant="filled" severity="error">
                {errMessage}
              </Alert>
            )}
            <div className="info-detail-wrapper p-3 pt-20">
              {getItemsByRoom(change_item).map((singleRoom) => {
                if (singleRoom.room !== "Common List") {
                  return (
                    // item by room
                    <div className="col-12 col-sm-12">
                      <div className="sub-title">
                        {singleRoom.room + ` (${singleRoom.items.length}) `}
                      </div>
                      {singleRoom.items.map((item) => (
                        <div className="row pt-1 ">
                          <div className="col-9 text-left small-font-size ">
                            {item.name}
                          </div>
                          <div className="col-3 small-sub-title">
                            <span className="pr-2 ">{item.quantity}</span>
                            {item.item_change_images.length > 0 && (
                              <Tippy
                                content={item.item_change_images.map((pic) => (
                                  <img
                                    style={{ height: 117, width: 117 }}
                                    className="p-1"
                                    src={s3baseUrl + pic.image_url}
                                  />
                                ))}
                                animation="scale"
                                delay={[300, 0]}
                                arrow={false}
                                placement="bottom"
                                maxWidth={400}
                              >
                                <Badge
                                  // key={i}
                                  color="primary"
                                  overlap="circle"
                                  badgeContent={
                                    <span
                                      style={{
                                        fontSize: 14,
                                        marginBottom: 0,
                                        cursor: "pointer",
                                      }}
                                      // onClick={() => handleImageCross(pic.preview)}
                                    >
                                      {item.item_change_images.length}
                                    </span>
                                  }
                                >
                                  <img
                                    viewBox="0 0 50 50"
                                    src={cameraImage}
                                    style={{
                                      margin: 5,
                                    }}
                                  ></img>
                                </Badge>
                              </Tippy>
                              // <span>
                              //   <img src={photoCountImage} />
                              // </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                } else {
                  return (
                    //items by common list
                    <>
                      <div className="sub-title col-12">{singleRoom.room}</div>

                      {singleRoom.items.map((item) => (
                        <div className="col-12 col-sm-12 col-lg-12">
                          <div className="row pt-1">
                            <div className="col-9 text-left small-font-size">
                              {item.name}
                            </div>
                            <div className="col-3 small-sub-title">
                              <span className="pr-2 align-bottom">
                                {item.quantity}
                              </span>
                              {item.item_change_images.length > 0 && (
                                <Tippy
                                  content={item.item_change_images.map(
                                    (pic) => (
                                      <img
                                        style={{ height: 117, width: 117 }}
                                        className="p-1"
                                        src={s3baseUrl + pic.image_url}
                                      />
                                    )
                                  )}
                                  animation="scale"
                                  delay={[300, 0]}
                                  arrow={false}
                                  placement="bottom"
                                  maxWidth={400}
                                >
                                  <Badge
                                    // key={i}
                                    color="primary"
                                    overlap="circle"
                                    badgeContent={
                                      <span
                                        style={{
                                          fontSize: 14,
                                          marginBottom: 0,
                                          cursor: "pointer",
                                        }}
                                        // onClick={() => handleImageCross(pic.preview)}
                                      >
                                        {item.item_change_images.length}
                                      </span>
                                    }
                                  >
                                    <img
                                      viewBox="0 0 50 50"
                                      src={cameraImage}
                                      style={{
                                        margin: 5,
                                      }}
                                    ></img>
                                  </Badge>
                                </Tippy>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  );
                }
              })}
            </div>
          </div>
        </div>

        <div className="col-3"></div>
        <div className="col-6 mt-4  justify-content-center d-flex">
          <button
            className="custom-btn-small custom-buton m-4"
            onClick={() => ItemsConfirmation("1")}
          >
            Accept
          </button>
          <button
            className="custom-btn-small custom-buton m-4"
            onClick={() => ItemsConfirmation("0")}
          >
            Reject
          </button>
        </div>
        <div className="col-3"></div>
      </div>
    </>
  );
}

export default ChangeInItems;
