import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import AddressCard from "./components/Address-Card/AddressCard";

function ChangeInAddress(props) {
  const { AddressConfirmation, errMessage } = props;

  const {
    request_address,
    delete_address_array,
    edit_address_array,
    new_address_array,
  } = props.requestData;

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12" id="onebox">
          <div className=" rounded-box items-info-wrapper mt-2">
            <div className="info-header text-center">
              <h6 className="black-color">Orignal Address</h6>
            </div>
            <div className="info-detail-wrapper p-3 pt-20">
              <div className="row">
                {request_address.map((address, i) => (
                  <AddressCard
                    icon={true}
                    action=""
                    key={i}
                    index={i}
                    address={address}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-12 mt-4" id="twobox">
          <div className="rounded-box items-info-wrapper mt-2">
            <div className="info-header text-center">
              <h6 className="black-color">Changed Address</h6>
            </div>
            {errMessage && (
              <Alert variant="filled" severity="error">
                {errMessage}
              </Alert>
            )}
            <div className="info-detail-wrapper p-3 pt-20">
              <div className="row">
                {new_address_array.map((address, i) => (
                  <AddressCard
                    icon={false}
                    action="Added"
                    key={i}
                    index={i}
                    address={address}
                  />
                ))}{" "}
                {edit_address_array.map((address, i) => (
                  <AddressCard
                    icon={false}
                    action="Edited"
                    key={i}
                    index={i}
                    address={address}
                  />
                ))}
                {delete_address_array.map((address, i) => (
                  <AddressCard
                    icon={false}
                    action="deleted"
                    key={i}
                    index={i}
                    address={address}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="col-3"></div>
        <div className="col-6 mt-4  justify-content-center d-flex">
          <button
            className="custom-btn-small custom-buton m-4"
            onClick={() => AddressConfirmation("1")}
          >
            Accept
          </button>
          <button
            className="custom-btn-small custom-buton m-4"
            onClick={() => AddressConfirmation("0")}
          >
            Reject
          </button>
        </div>
        <div className="col-3"></div>
      </div>
    </>
  );
}

export default ChangeInAddress;
