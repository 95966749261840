import React from "react";

function FilterChips({ titles }) {
  let filtered = titles.filter((title) => {
    return title !== "" && title !== undefined;
  });
  return filtered.map((title) => {
    return (
      <div
        className="float-right py-1 px-2 mb-1 ml-1"
        style={{ backgroundColor: "#e3b6ba", borderRadius: "20px" }}
      >
        {title}
        {/* <a onClick={handleClear}><i class="close fas fa-times ml-2 mt-1" style={{width:"16px", height:"16px"}} /></a> */}
      </div>
    );
  });
}

export default FilterChips;
