import React, { useState } from "react";
import NumberFormat from "react-number-format";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import { CircularProgress } from "@material-ui/core";

function AddExternalBank(props) {
  const { showErrorSnak, showSuccessSnak, FetchBankDetails } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState({
    account_holder_name: "",
    routing_number: "",
    account_number: "",
  });

  const handleChange = (e) => {
    console.log(e.target.id);
    setAccountDetails({
      ...accountDetails,
      [e.target.id]: e.target.value,
    });
  };

  const handleBankAccount = (e) => {
    e.preventDefault();
    let requestObj = {
      path: "/api/company/add_external_bank_account",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      account_holder_name: accountDetails.account_holder_name,
      routing_number: accountDetails.routing_number,
      account_number: accountDetails.account_number,
    };
    requestObj["postData"] = postData;

    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "ADD_ECTERNAL_BANK_RES");
      if (res.code === 200) {
        setIsLoading(false);
        showSuccessSnak(res.message);
        FetchBankDetails();
      } else {
        setIsLoading(false);
        //showErrorSnak(res.message);
        setIsLoading(false);
      }
    });
  };

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }

  return (
    <>
      <section className="main-content address-page">
        <div className="container">
          <div className="add-items-wrapper mb-m-90 mb-90 mt-3 mt-sm-4 ">
            <div className="text-center">
              <AccountBalanceIcon
                fontSize="large"
                style={{ color: "#c4314b" }}
              />
              <br />

              <b className="text-center text-uppercase mb-0">
                Add External Bank
              </b>
            </div>
            <div className="selected-room mt-4 mt-sm-4">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 mb-4 mb-sm-4">
                  <div className="rounded-box p-3">
                    <form onSubmit={handleBankAccount}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="card-number"
                              className="overline dark-grey-color"
                            >
                              Account Holder Name
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control small-font-size"
                              id="account_holder_name"
                              placeholder="Account Holder Name"
                              onChange={handleChange}
                              value={accountDetails.account_holder_name}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="card-holder-name"
                              className="overline dark-grey-color"
                            >
                              Routing Number
                            </label>
                            <NumberFormat
                              allowLeadingZeros
                              allowNegative={false}
                              className="form-control small-font-size"
                              id="routing_number"
                              placeholder="Routing Number"
                              onChange={handleChange}
                              value={accountDetails.routing_number}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="exp-date"
                              className="overline dark-grey-color"
                            >
                              Account Number
                            </label>
                            <NumberFormat
                              allowLeadingZeros
                              allowNegative={false}
                              className="form-control small-font-size"
                              id="account_number"
                              placeholder="Account Number"
                              onChange={handleChange}
                              value={accountDetails.account_number}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center pt-4">
                        <div className="col-12 col-sm-8 col-md-6">
                          <div className="form-group button-wrapper">
                            <a href="add-card-verified.html">
                              <button className="custom-buton custom-buton-large w-100">
                                Add External Bank
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddExternalBank;
