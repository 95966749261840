import React, { useEffect, useState } from "react";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import NumberFormat from "react-number-format";
import { invokeApi, PK_KEY } from "../../../../bl_libs/invokeApi";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import InfoIcon from "@material-ui/icons/Info";
const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

function AddBank(props) {
  const classes = useStyles();
  const {
    showErrorSnak,
    showSuccessSnak,
    profile,
    setCurrentScreen,
    FetchBankDetails,
  } = props;
  const { name, email, phone } = profile;
  console.log(profile, "PROFILE IN ADD");
  const [isLoading, setIsLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState({
    account_holder_first_name: "",
    account_holder_last_name: "",
    ein: "",
    id_number: "",
    routing_number: "",
    account_number: "",
    date_of_birth: "",
    social_security_number: "",
    city: "",
    address: "",
    postal_code: "",
    state: "",
    name: name,
    email: email,
    phone_number: phone,
    business_name: "",
    support_city: "",
    support_state: "",
    support_postal_code: "",
    support_email: "",
    support_phone_number: "",
    support_address: "",
    front_image: "",
    back_image: "",
  });

  const testingInputs = () => {
    setAccountDetails({
      account_holder_first_name: "Muneeb",
      account_holder_last_name: "Islam",
      ein: "000000000",
      id_number: "123456789",
      routing_number: "110000000",
      account_number: "000123456789",
      date_of_birth: "12/06/1996",
      social_security_number: "0000",
      city: "Cupertino",
      address: "1 Apple Park Way",
      postal_code: "93504",
      state: "California",
      name: name,
      email: email,
      phone_number: phone,
      front_image: "",
      back_image: "",
      // business_name: "testing",
      // support_city: "West Dennis",
      // support_state: "MA",
      // support_postal_code: "02670",
      // support_email: "nivic69705@wpfoo.com",
      // support_phone_number: "(232) 332-2232",
      // support_address: "Home",
    });
  };

  const handleChange = (e) => {
    console.log(e.target.id);
    setAccountDetails({
      ...accountDetails,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeImage = (e) => {
    if (parseInt(e.target.files[0].size) > 10485760) {
      showErrorSnak("Please upload images less then 10 MB");
      return;
    }
    // check image file type is valid or not.
    var t = e.target.files[0].type.split("/").pop().toLowerCase();
    if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "heic") {
      showErrorSnak("Please select a valid image file");
      return;
    }

    setAccountDetails({
      ...accountDetails,
      [e.target.id]: e.target.files[0],
    });
  };

  const getImagePreview = (img) => {
    return URL.createObjectURL(img);
  };
  const handleBankAccount = (e) => {
    e.preventDefault();
    console.log(accountDetails, "DETAILS");
    AddBankAccount();
  };

  const getStripeFormatNumber = (number) => {
    console.log(number, "NUMBER ORIGNAL");

    number = number.replace(" ", "");
    number = number.replace("-", "");
    number = number.replace("(", "");
    number = number.replace(")", "");

    console.log(number, "NUMBER");

    return "+1" + number;
  };

  const AddBankAccount = async () => {
    if (accountDetails.front_image === "") {
      showErrorSnak("Please upload Your Identity images.");
      return;
    }
    if (accountDetails.back_image === "") {
      showErrorSnak("Please upload Your Identity images.");
      return;
    }

    let requestObj = {
      path: "/api/company/add_bank_detail",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    const {
      date_of_birth,
      phone_number,
      // support_phone_number,
      ...rest
    } = accountDetails;

    const day = date_of_birth.split("/")[0];
    const month = date_of_birth.split("/")[1];
    const year = date_of_birth.split("/")[2];

    //validations

    if (
      parseInt(day) < 1 ||
      parseInt(day) > 31 ||
      parseInt(month) < 1 ||
      parseInt(month) > 12 ||
      parseInt(year) < 1000
    ) {
      showErrorSnak("Invalid date of birth");
      if (document.querySelector("#date_of_birth")) {
        document.querySelector("#date_of_birth").focus();
      }
      return;
    }

    let postData = {
      ...rest,
      day,
      month,
      year,
      phone_number: getStripeFormatNumber(phone_number),
      // support_phone_number: getStripeFormatNumber(support_phone_number),
    };

    console.log(postData, "ADD BANK POST DATA");

    setIsLoading(true);

    const identification_number_token = await getTokenForId(
      accountDetails.id_number
    );

    let formData = new FormData();
    formData.append(
      "account_holder_first_name",
      accountDetails.account_holder_first_name
    );
    formData.append(
      "account_holder_last_name",
      accountDetails.account_holder_last_name
    );
    formData.append("tax_id", accountDetails.ein);
    formData.append("identification_number", identification_number_token);
    formData.append("front_image", accountDetails.front_image);
    formData.append("back_image", accountDetails.back_image);
    formData.append("routing_number", accountDetails.routing_number);
    formData.append("account_number", accountDetails.account_number);
    formData.append("day", day);
    formData.append("month", month);
    formData.append("year", year);
    formData.append(
      "social_security_number",
      accountDetails.social_security_number
    );
    formData.append("city", accountDetails.city);
    formData.append("address", accountDetails.address);
    formData.append("postal_code", accountDetails.postal_code);
    formData.append("state", accountDetails.state);
    formData.append("name", accountDetails.name);
    formData.append("email", accountDetails.email);
    formData.append(
      "phone_number",
      getStripeFormatNumber(accountDetails.phone_number)
    );

    // formData.append("business_name", accountDetails.business_name);
    // formData.append("support_city", accountDetails.support_city);
    // formData.append("support_state", accountDetails.support_state);
    // formData.append("support_postal_code", accountDetails.support_postal_code);
    // formData.append("support_email", accountDetails.support_email);
    // formData.append(
    //   "support_phone_number",
    //   accountDetails.support_phone_number
    // );
    // formData.append("support_address", accountDetails.support_address);

    requestObj["postData"] = formData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "ADD_BANK_RES");
      if (res.code === 200) {
        setCurrentScreen("SHOW_ALL");
        FetchBankDetails();
        showSuccessSnak(res.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //showErrorSnak(res.message);
      }
    });
  };

  const getTokenForId = (data) => {
    return new Promise((resolve, reject) => {
      window.Stripe.setPublishableKey(PK_KEY);
      window.Stripe.token.create(
        {
          pii: { id_number: data },
        },
        (status, response) => {
          if (status === 200) {
            resolve(response.id);
          }
        }
      );
    });
  };

  useEffect(() => {
    console.log(accountDetails, "accountDetails");
  }, [accountDetails]);

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }
  return (
    <>
      <section className="main-content address-page">
        <div className="container">
          <div className="add-items-wrapper mb-m-90 mb-90 mt-3 mt-sm-4 ">
            <div className="text-center">
              <AccountBalanceIcon
                fontSize="large"
                style={{ color: "#c4314b" }}
                onClick={testingInputs}
              />
              <br />

              <b className="text-center text-uppercase mb-0">
                Add Bank Account
              </b>
            </div>
            <div className="selected-room mt-4 mt-sm-4">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 mb-4 mb-sm-4">
                  <div className="rounded-box p-3">
                    <form onSubmit={handleBankAccount}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="card-number"
                              className="overline dark-grey-color"
                            >
                              Account Holder First Name
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control small-font-size"
                              id="account_holder_first_name"
                              placeholder="First Name"
                              onChange={handleChange}
                              value={accountDetails.account_holder_first_name}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="card-number"
                              className="overline dark-grey-color"
                            >
                              Account Holder Last Name
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control small-font-size"
                              id="account_holder_last_name"
                              placeholder="Last Name"
                              onChange={handleChange}
                              value={accountDetails.account_holder_last_name}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="card-holder-name"
                              className="overline dark-grey-color"
                            >
                              Employer Identification Number (EIN)
                            </label>
                            <NumberFormat
                              allowLeadingZeros
                              allowNegative={false}
                              className="form-control small-font-size"
                              id="ein"
                              placeholder="Employer Identification Number"
                              onChange={handleChange}
                              value={accountDetails.ein}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="card-holder-name"
                              className="overline dark-grey-color"
                            >
                              identification Number
                            </label>
                            <NumberFormat
                              allowLeadingZeros
                              allowNegative={false}
                              className="form-control small-font-size"
                              id="id_number"
                              placeholder="Identification Number"
                              onChange={handleChange}
                              value={accountDetails.id_number}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="card-holder-name"
                              className="overline dark-grey-color"
                            >
                              Routing Number
                            </label>
                            <NumberFormat
                              allowLeadingZeros
                              allowNegative={false}
                              className="form-control small-font-size"
                              id="routing_number"
                              placeholder="Routing Number"
                              onChange={handleChange}
                              value={accountDetails.routing_number}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="exp-date"
                              className="overline dark-grey-color"
                            >
                              Account Number
                            </label>
                            <NumberFormat
                              allowLeadingZeros
                              allowNegative={false}
                              className="form-control small-font-size"
                              id="account_number"
                              placeholder="Account Number"
                              onChange={handleChange}
                              value={accountDetails.account_number}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="cvv"
                              className="overline dark-grey-color"
                            >
                              Date of Birth (dd/mm/yyyy)
                            </label>
                            <NumberFormat
                              className="form-control small-font-size"
                              id="date_of_birth"
                              placeholder="DD/MM/YYYY"
                              onChange={handleChange}
                              value={accountDetails.date_of_birth}
                              autoComplete="off"
                              format="##/##/####"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="address-1"
                              className="overline dark-grey-color"
                            >
                              Social Security Number
                            </label>
                            <NumberFormat
                              className="form-control small-font-size"
                              id="social_security_number"
                              placeholder="Last Four Digits"
                              onChange={handleChange}
                              value={accountDetails.social_security_number}
                              autoComplete="off"
                              format="####"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="city"
                              className="overline dark-grey-color"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="address"
                              placeholder="295 N Rodeo Dr"
                              onChange={handleChange}
                              value={accountDetails.address}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="city"
                              className="overline dark-grey-color"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="city"
                              placeholder="Beverly Hills"
                              onChange={handleChange}
                              value={accountDetails.city}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="state"
                              className="overline dark-grey-color"
                            >
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="state"
                              placeholder="CA"
                              onChange={handleChange}
                              value={accountDetails.state}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              Postal Code
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="postal_code"
                              placeholder="90210"
                              onChange={handleChange}
                              value={accountDetails.postal_code}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="name"
                              placeholder="Name"
                              onChange={handleChange}
                              value={accountDetails.name}
                              autoComplete="off"
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              email
                            </label>
                            <input
                              type="email"
                              className="form-control small-font-size"
                              id="email"
                              placeholder="Email"
                              onChange={handleChange}
                              value={accountDetails.email}
                              autoComplete="off"
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              phone number
                            </label>
                            <NumberFormat
                              className="form-control small-font-size"
                              id="phone_number"
                              placeholder="Phone number"
                              onChange={handleChange}
                              value={accountDetails.phone_number}
                              autoComplete="off"
                              format="(###) ###-####"
                              required
                              disabled
                            />
                          </div>
                        </div>
                        {/* <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              business name
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="business_name"
                              placeholder="Bussiness Name"
                              onChange={handleChange}
                              value={accountDetails.business_name}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support city
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="support_city"
                              placeholder="Support City"
                              onChange={handleChange}
                              value={accountDetails.support_city}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support state
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="support_state"
                              placeholder="Support State"
                              onChange={handleChange}
                              value={accountDetails.support_state}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support postal code
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="support_postal_code"
                              placeholder="Support Postal Code"
                              onChange={handleChange}
                              value={accountDetails.support_postal_code}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support email
                            </label>
                            <input
                              type="email"
                              className="form-control small-font-size"
                              id="support_email"
                              placeholder="Support Email"
                              onChange={handleChange}
                              value={accountDetails.support_email}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support phone number
                            </label>
                            <NumberFormat
                              className="form-control small-font-size"
                              id="support_phone_number"
                              placeholder="Support Phone Number"
                              onChange={handleChange}
                              value={accountDetails.support_phone_number}
                              autoComplete="off"
                              format="(###) ###-####"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support address
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="support_address"
                              placeholder="Support Address"
                              onChange={handleChange}
                              value={accountDetails.support_address}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div> */}
                        <div className="col-12 col-sm-12 col-md-12">
                          <label
                            htmlFor="zip"
                            className="overline dark-grey-color float-left"
                          >
                            Upload Your Identity
                            <Tooltip
                              className="pl-2"
                              title="An identity document (also called a piece of identification or ID, or colloquially as papers) is any document that may be used to prove a person's identity. If issued in a small, standard credit card size form, it is usually called an identity card (IC, ID card, citizen card), or passport card."
                              aria-label="add"
                              arrow
                              placement="right-start"
                            >
                              <InfoIcon style={{ color: "#c4314b" }} />
                            </Tooltip>
                          </label>
                        </div>
                        {/* Upload images front side */}
                        <div className="col-12 col-sm-12 col-md-6 text-center">
                          {!accountDetails.front_image ? (
                            <div className={classes.uploadroot}>
                              <input
                                accept="image/x-png,image/jpeg"
                                className={classes.input}
                                id="front_image"
                                type="file"
                                onChange={handleChangeImage}
                                value=""
                              />
                              <label htmlFor="front_image">
                                <Button
                                  style={{ margin: 10, height: 40, width: 160 }}
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                  startIcon={<AddAPhotoIcon />}
                                >
                                  <span
                                    style={{
                                      fontFamily: "Work Sans",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Front Side
                                  </span>
                                </Button>
                              </label>
                            </div>
                          ) : (
                            <>
                              <img
                                className="w-100"
                                src={getImagePreview(
                                  accountDetails.front_image
                                )}
                              />
                              <span
                                className="custom-link pointer-point"
                                onClick={() =>
                                  setAccountDetails((c) => {
                                    return { ...c, front_image: "" };
                                  })
                                }
                              >
                                Remove
                              </span>
                            </>
                          )}
                        </div>

                        {/* Upload images back side */}
                        <div className="col-12 col-sm-12 col-md-6 text-center">
                          {!accountDetails.back_image ? (
                            <div className={classes.uploadroot}>
                              <input
                                accept="image/x-png,image/jpeg"
                                className={classes.input}
                                id="back_image"
                                type="file"
                                onChange={handleChangeImage}
                                value=""
                              />
                              <label htmlFor="back_image">
                                <Button
                                  style={{ margin: 10, height: 40, width: 160 }}
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                  startIcon={<AddAPhotoIcon />}
                                >
                                  <span
                                    style={{
                                      fontFamily: "Work Sans",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Back Side
                                  </span>
                                </Button>
                              </label>
                            </div>
                          ) : (
                            <>
                              <img
                                className="w-100"
                                src={getImagePreview(accountDetails.back_image)}
                              />
                              <span
                                className="custom-link pointer-point"
                                onClick={() =>
                                  setAccountDetails((c) => {
                                    return { ...c, back_image: "" };
                                  })
                                }
                              >
                                Remove
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row justify-content-center pt-4">
                        <div className="col-12 col-sm-8 col-md-6">
                          <div className="form-group button-wrapper">
                            <a href="add-card-verified.html">
                              <button className="custom-buton custom-buton-large w-100">
                                Add Bank account
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddBank;
