import React, { useEffect, useState } from "react";
import { blackBackArrowImage, userIconImage } from "../../assets";
import { Redirect, withRouter } from "react-router-dom";
import Reques from "./components/Reques/Reques";
import Timeline from "./components/Timeline/Timeline";
import RequestInformation from "./components/Request-Information/RequestInformation";
import Comments from "./components/Comments/Comments";
import { CircularProgress } from "@material-ui/core";
import { invokeApi } from "../../bl_libs/invokeApi";
import { useSockets } from "../../Context/Context-Sockets/ContextSockets";
import TimelineDirectRequest from "./components/Timeline-Direct-Request/TimelineDirectRequest";
import WorkTime from "./components/Work-Time/WorkTime";

function RequestDetails(props) {
  const { handleRefreshChatList } = useSockets();
  const [requestData, setRequestData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchRequestDetails = (type) => {
    //1 /api/company/get_all_request_detail
    //2 /api/company/company_request_detail

    //for search detail screen we hit "1" for else we use "2"

    const currentPath = props.history.location.pathname;
    console.log(currentPath, currentPath.search("/company/search"), "path");

    let requestObj = {
      path:
        currentPath.search("/company/search") === 0
          ? "/api/company/get_all_request_detail"
          : "/api/company/company_request_detail",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${props.match.params.requestId}`,
    };

    requestObj["postData"] = postData;
    //if type "comment dont reload page"
    if (type === "comment") {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        //for refresh chat
        //when ever request details is fetched
        //so we have always updated chat list
        handleRefreshChatList();

        //if company cancel his bid it will move back
        //when it cancel bid his "bid_result" value become "2"
        //so we check this to know its cancel or not

        if (res.request_bid !== null) {
          if (res.request_bid?.bid_result === 2) {
            props.history.goBack();
            return;
          }
        }

        if (res.request.request_timeline_status === 11) {
          props.history.push("/company/company-requests");
        }

        //to switch api for fetch data from 2nd Api instead of 1st
        //because 1st Api does not update request status after "status 1"
        //other wise it shows "bid submitted" with timeline of "Bid Accepted"
        if (
          currentPath.search("/company/search") === 0 &&
          res.request.request_timeline_status === 1 &&
          res.request_timeline.length > 1
        ) {
          console.log(
            res.request.request_timeline_status,
            res.request_timeline.length,
            "CONDITION"
          );
          props.history.push("/company/company-requests/" + res.request.id);
          //recall this recursion other wise its set pervious state  and return this from here
          fetchRequestDetails();
          return;
        }
        //to switch api for fetch data from 1st Api instead of 2nd
        //if no company bid exist this will be null and we move this request to fetch data from 1st Api
        //other wise it shows error of "request_bid" undefined if it fetch data from 2nd API.
        if (
          (res.request_bid === null ||
            res.request_bid === "" ||
            res.request_bid === undefined) &&
          !res.request.request_type &&
          currentPath.search("/company/search") !== 0
        ) {
          props.history.push("/company/search/" + res.request.id);
          //recall this recursion other wise its set pervious state and return this from here
          fetchRequestDetails();
          return;
        }

        //manage feedback status for individual App
        //Based on res.status is 0 or 1,
        //if status===0 then feedback is pending move to timeline status 7
        //if  status===1 it means feedback given move to timeline status 8
        if (res.request.request_timeline_status === 8) {
          res = {
            ...res,
            request: {
              ...res.request,
              request_timeline_status: res.status === 0 ? 7 : 8,
            },
          };
        }

        setRequestData(res);
        setIsLoading(false);
      } else {
      }
    });
  };

  //------------------------ if trucks or foreman not added

  const _loading = "0";
  const _pleaseAdd = "1";
  const _available = "2";

  const [screen, setScreen] = useState(_loading);

  const fetchForemanListCount = () => {
    return new Promise(function (resolve, reject) {
      let requestObj = {
        path: "/api/company/get_foreman",
        method: "GET",
        headers: {
          "x-sh-auth": localStorage.getItem("token"),
        },
      };
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          console.log(res.data);
          resolve(res.data.allForeman.length);
        }
      });
    });
  };

  const fetchTruckListCount = () => {
    return new Promise(function (resolve, reject) {
      let requestObj = {
        path: "/api/company/get_truck",
        method: "GET",
        headers: {
          "x-sh-auth": localStorage.getItem("token"),
        },
      };
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          console.log(res.data);
          resolve(res.data.allTruck.length);
        }
      });
    });
  };

  const CheckForSearch = async () => {
    const foreman = await fetchForemanListCount();
    const truck = await fetchTruckListCount();
    if (foreman === 0 || truck === 0) {
      setScreen(_pleaseAdd);
      props.history.push("/company/search");
    } else {
      setScreen(_available);
    }
  };

  //-------------------------------------------------------
  useEffect(() => {
    CheckForSearch();
    fetchRequestDetails();
  }, []);

  if (isLoading || screen === _loading) {
    return (
      <section id="content-wrapper" className="content-wrapper rating-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point"
              src={blackBackArrowImage}
              onClick={() => props.history.goBack()}
            />
            <span>Request Page</span>
          </p>
          <div className="container-fluid">
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section id="content-wrapper" className="content-wrapper rating-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point"
              src={blackBackArrowImage}
              onClick={() => props.history.goBack()}
            />
            <span>Request Page</span>
          </p>
          <div className="rating-wrapper mt-40 mt-m-30">
            <div className="row">
              <div className="col-sm-12 col-12 col-md-5 col-xl-4">
                <Reques
                  requestData={requestData}
                  fetchRequestDetails={fetchRequestDetails}
                  setIsLoading={setIsLoading}
                />

                {requestData.request.request_timeline_status >= 6 && (
                  <WorkTime requestData={requestData} />
                )}
                {requestData.request.request_type ? (
                  <TimelineDirectRequest requestData={requestData} />
                ) : (
                  <Timeline requestData={requestData} />
                )}
              </div>
              <div className="col-sm-12 col-12 col-md-7 col-xl-8 mt-m-30">
                <RequestInformation
                  requestData={requestData}
                  fetchRequestDetails={fetchRequestDetails}
                />
                <Comments
                  requestData={requestData}
                  fetchRequestDetails={fetchRequestDetails}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(RequestDetails);
