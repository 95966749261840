import React, { useEffect } from "react";
import {
  addressARedIcon,
  addressBRedIcon,
  subtractImage,
  addImage,
  addressCRedIcon,
  addressDRedIcon,
  addressERedIcon,
  addressFRedIcon,
} from "../../../../assets";
import { useState } from "react";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import LocationCard from "./components/LocationCard";

function LocationDetails(props) {
  const {
    addressList,
    setAddressList,
    //move type list from api
    moveTypeList,
    isMoveTypeSelected,
    //choose move type
    moveType,
    setMoveType,
  } = props;

  useEffect(() => {
    let temp = addressList.filter((addr) => addr.address !== "");
    temp = temp.map((addr, i) => {
      return {
        ...addr,
        label:
          i === 0
            ? "A"
            : i === 1
            ? "B"
            : i === 2
            ? "C"
            : i === 3
            ? "D"
            : i === 4
            ? "E"
            : "F",
        icon:
          i === 0
            ? addressARedIcon
            : i === 1
            ? addressBRedIcon
            : i === 2
            ? addressCRedIcon
            : i === 3
            ? addressDRedIcon
            : i === 4
            ? addressERedIcon
            : addressFRedIcon,
      };
    });
    setAddressList(temp);
  }, []);

  console.log(addressList, "addressList");
  return (
    <>
      <section className="main-content address-page pt-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="text-uppercase">Location Details</h1>
            </div>
          </div>
          <div className="form-group">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6">
                <label htmlFor="distance" className="overline dark-grey-color">
                  Choose Move Size
                </label>
                <select
                  name=""
                  className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                  value={moveType}
                  id="choose_move_size"
                  onChange={(e) => setMoveType(e.target.value)}
                  style={{
                    border:
                      !isMoveTypeSelected && moveType === "" && "solid 1px red",
                  }}
                >
                  {moveType === "" && (
                    <option value=""> Select move size</option>
                  )}
                  {moveTypeList.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {
              <div className="address-info-wrapper">
                <div className="row pt-4">
                  <div className="col-12 col-sm-6">
                    <h3 className="text-uppercase">Add Address information</h3>
                  </div>
                </div>
                <div className="row">
                  {/* {using loop} */}
                  {addressList.map((location) => (
                    <LocationCard
                      key={location.id}
                      location={location}
                      setAddressList={setAddressList}
                      addressList={addressList}
                    />
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
}

export default LocationDetails;
