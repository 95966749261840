import React from "react";
import moment from "moment";

function ChatBody(props) {
  const { chat, selectedUser, profile } = props;

  const Recived = ({ msg, chat_id, time }) => (
    <div className="col-12 mt-40" id={chat_id}>
      <div className="">
        <div className="message-info">
          {selectedUser.type !== 0
            ? selectedUser.profile_data.first_name +
              " " +
              selectedUser.profile_data.last_name
            : selectedUser.profile_data.name}
          , {" " + moment(time).format("LLL")}
        </div>
        <div className="chat-message-box">
          <span className="message-text" style={{ color: "black" }}>
            {msg}
          </span>
        </div>
      </div>
    </div>
  );

  const Sended = ({ msg, chat_id, time }) => (
    <div className="col-12 mt-40" id={chat_id}>
      <div className="">
        <div className="message-info float-right">
          {profile.name} , {" " + moment(time).format("LLL")}
        </div>
        <br></br>
        <div
          className="chat-message-box float-right "
          style={{ backgroundColor: "rgb(10,132,255)" }}
        >
          <span className="message-text text-white">{msg}</span>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {chat.sender_type === 0 ? (
        <Sended
          msg={chat.message_text}
          chat_id={chat.id}
          time={chat.message_datatime}
        />
      ) : (
        <Recived
          msg={chat.message_text}
          chat_id={chat.id}
          time={chat.message_datatime}
        />
      )}
    </>
  );
}

export default ChatBody;
