import { CircularProgress, TablePagination } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import moment from "moment";

function BillingInfo(props) {
  const [requests, SetRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilter, setIsFilter] = useState(false);
  //------------------------------------------>  pagination settings
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);
  //----------------------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //////////////////////////////////////////////////////////////////
  const getStatusForCompany = (status) => {
    if (status === 0) {
      return "New Request";
    }
    if (status === 1) {
      return "Bid submitted";
    }
    if (status === 2) {
      return "Bid accepted";
    }
    if (status === 3) {
      return "Paid to Escrow";
    }
    if (status === 4) {
      return "Truck assigned and ready to move";
    }
    if (status === 5) {
      return "Move in progress";
    }
    if (status === 6) {
      return "Completed, waiting for confirmation";
    }
    if (status === 7) {
      return "Completed";
    }
    if (status === 8) {
      return "Feedback Given";
    }
  };

  const fetchBillingInfo = (type) => {
    let requestObj = {
      path: `/api/company/list_subscription`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        SetRequests(res.list_subscribtion_data);

        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchBillingInfo();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress ">
          <CircularProgress />
        </div>
      </>
    );
  }

  if (window.innerWidth < 500) {
    console.log(window.innerWidth, "Width");
    return (
      <>
        <div className="rounded-box pb-3">
          <div className="row">
            <div className="col-12">
              <table
                id="example"
                className="responsive table table-hover"
                style={{ width: "100%" }}
              >
                <thead>
                  <th className="small-sub-title dark-grey-color">
                    <div className="row">
                      <div className="col-6">Subscription Billing Info</div>
                    </div>
                  </th>
                </thead>
                <tbody>
                  {requests.map((request, i) => (
                    <tr key={i} className="border-bottom">
                      <div className="row pl-2">
                        <div className="col-4 small-sub-title dark-grey-color">
                          Subscription Date:
                        </div>
                        <div className="col-8">
                          {request.subscription_date
                            ? moment(request.subscription_date).format("LLLL")
                            : "-"}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className=""> Subscription End Date:</span>
                        </div>
                        <div className="col-8">
                          {request.subscription_end_date
                            ? moment(request.subscription_end_date).format(
                                "LLLL"
                              )
                            : "-"}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className=""> Subscription Cancel Date:</span>
                        </div>
                        <div className="col-8">
                          {request.subscription_cancel_date
                            ? moment(request.subscription_cancel_date).format(
                                "LLLL"
                              )
                            : "-"}
                        </div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className=""> Subscription Amount:</span>
                        </div>
                        <div className="col-8">
                          {request.amount ? request.amount : "-"}
                        </div>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="rounded-box pb-3">
        <div className="row">
          <div className="col-12">
            <table
              id="example"
              className="responsive table table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="small-sub-title dark-grey-color">
                    Subscription Date
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Subscription End Date
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Subscription Cancel Date
                  </th>

                  <th className="small-sub-title dark-grey-color">
                    Subscription Amount
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Subscription Plan
                  </th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request, i) => (
                  <tr key={i}>
                    <td>
                      {request.subscription_date
                        ? moment(request.subscription_date).format("LLLL")
                        : "-"}
                    </td>
                    <td>
                      {" "}
                      {request.subscription_end_date
                        ? moment(request.subscription_end_date).format("LLLL")
                        : "-"}
                    </td>
                    <td>
                      {request.subscription_cancel_date
                        ? moment(request.subscription_cancel_date).format(
                            "LLLL"
                          )
                        : "-"}
                    </td>
                    <td>{request.amount ? request.amount : "-"}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {request.plan ? request.plan : "-"}
                    </td>
                  </tr>
                ))}
                {/* loop Close */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(BillingInfo);
