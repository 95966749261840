import React from "react";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import {
  NewRequest,
  BidSubmitted,
  BidAccepted,
  PayedToEscrow,
  TruckAssigned,
  MoveInProgress,
  WaitingConfirmation,
  Completed,
  // direct requests
  NewDirectRequest,
  DirectRequestAccepted,
  TruckAssignedForDirectRequest,
} from "./components";

function Reques(props) {
  const { requestData, fetchRequestDetails, setIsLoading } = props;
  const { id, request_timeline_status, request_type } = requestData.request;

  const bidCancel = () => {
    let requestObj = {
      path: "/api/company/bid_cancel",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${id}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        document.getElementById("close-bid-cancel").click();
        fetchRequestDetails();
      }
    });
  };

  const handleCancelRequest = () => {
    let requestObj = {
      path: "/app_api/cancel_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${id}`,
    };

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        document.getElementById("close-bid-cancel").click();
        fetchRequestDetails();
      }
    });
  };

  // For direct request
  if (request_timeline_status === 0 && request_type) {
    return (
      <NewDirectRequest
        fetchRequestDetails={fetchRequestDetails}
        requestData={requestData}
        bidCancel={bidCancel}
        setIsLoadingPage={setIsLoading}
      />
    );
  }

  // For simple request
  if (request_timeline_status === 0) {
    return (
      <NewRequest
        fetchRequestDetails={fetchRequestDetails}
        requestData={requestData}
        bidCancel={bidCancel}
      />
    );
  }

  if (request_timeline_status === 1) {
    return (
      <BidSubmitted
        fetchRequestDetails={fetchRequestDetails}
        requestData={requestData}
        bidCancel={bidCancel}
      />
    );
  }
  if (request_timeline_status === 2) {
    return (
      <BidAccepted
        requestData={requestData}
        bidCancel={bidCancel}
        fetchRequestDetails={fetchRequestDetails}
      />
    );
  }
  if (request_timeline_status === 3 && request_type) {
    return (
      <DirectRequestAccepted
        requestData={requestData}
        fetchRequestDetails={fetchRequestDetails}
        bidCancel={handleCancelRequest}
        setIsLoadingPage={setIsLoading}
      />
    );
  }
  if (request_timeline_status === 3) {
    return (
      <PayedToEscrow
        requestData={requestData}
        fetchRequestDetails={fetchRequestDetails}
        bidCancel={bidCancel}
        setIsLoadingPage={setIsLoading}
      />
    );
  }
  if (request_timeline_status === 4) {
    return (
      <TruckAssigned
        requestData={requestData}
        bidCancel={bidCancel}
        handleCancelRequest={handleCancelRequest}
        setIsLoadingPage={setIsLoading}
        fetchRequestDetails={fetchRequestDetails}
      />
    );
  }
  if (request_timeline_status === 5) {
    return (
      <MoveInProgress
        fetchRequestDetails={fetchRequestDetails}
        setIsLoadingPage={setIsLoading}
        requestData={requestData}
        bidCancel={bidCancel}
      />
    );
  }
  if (request_timeline_status === 6) {
    return (
      <WaitingConfirmation requestData={requestData} bidCancel={bidCancel} />
    );
  }
  if (request_timeline_status === 7) {
    return (
      <Completed
        setIsLoadingPage={setIsLoading}
        fetchRequestDetails={fetchRequestDetails}
        requestData={requestData}
        bidCancel={bidCancel}
      />
    );
  }
  if (request_timeline_status === 8) {
    return (
      <Completed
        requestData={requestData}
        setIsLoadingPage={setIsLoading}
        fetchRequestDetails={fetchRequestDetails}
        bidCancel={bidCancel}
      />
    );
  }

  return <></>;
}

export default Reques;
