import React, { useState } from "react";
import {
  companyProfilePicImage,
  replyIconImage,
  startRatingImage,
  userIconImage,
} from "../../../../../assets";
import ReplyComment from "./ReplyComment";
import moment from "moment";
import { s3baseUrl } from "../../../../../bl_libs/invokeApi";
import { Rating } from "@material-ui/lab";
import { Avatar } from "@material-ui/core";
function MianComment(props) {
  const { main_comment, addComment, company_rating, customer_rating } = props;
  const { child_comments } = main_comment;
  const {
    comment,
    request_id,
    updated_at,
    id,
    sender_type,
  } = main_comment.parent_comment;

  const { name, profile_url } = main_comment.parent_comment_profile;

  const [replyInput, setReplyInput] = useState("");
  const [isReplyActive, setIsReplyActive] = useState(false);

  const handleReplyActive = () => {
    setIsReplyActive(!isReplyActive);
  };

  const handleReplySubmit = (e) => {
    e.preventDefault();
    addComment(request_id, replyInput, id, 0);
    setReplyInput("");
  };
  return (
    <>
      <div className="main-comment">
        <div className="comment-box">
          <div className="row">
            <div className="col-3 col-sm-1  ">
              <div className="profile-pic-small">
                {profile_url ? (
                  <img
                    src={s3baseUrl + profile_url}
                    className="rounded-circle circle-box circle-box-small img-46"
                  />
                ) : (
                  <Avatar>{name[0].toUpperCase()}</Avatar>
                )}
              </div>
            </div>
            <div className="col-9 col-sm-11  ">
              <div className="sub-title">
                <span className="pr-2 align-middle pl-md-3 pl-xl-0">
                  {name}
                </span>
                {/* <img src={startRatingImage} /> */}
                <Rating
                  className="align-bottom"
                  value={
                    sender_type === 0
                      ? main_comment.parent_comment_profile.company_rating
                      : main_comment.parent_comment_profile.customer_rating
                  }
                  precision={0.1}
                  readOnly
                />
              </div>
              <div className="request-date-line caption light-grey-color pt-1 pb-3">
                <div className="row">
                  {/* <div className="col-12 col-sm-3 pr-sm-0">
                    Request #{request_id}
                  </div> */}
                  <div className="col-12 col-sm-12 pl-sm-0 pl-md-4 pl-xl-3">
                    {moment(updated_at).format("LLL")}
                  </div>
                </div>
              </div>
              <div className="small-font-size">
                <p className="mb-2">{comment}</p>
                <p className="text-right mb-0">
                  <a className="caption dark-grey-color pointer-point">
                    <img src={replyIconImage} />
                    <span
                      className="pl-2 align-bottom"
                      onClick={handleReplyActive}
                    >
                      Reply
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {isReplyActive && (
          <div className="reply-box-wrapper mt-20 w-100">
            <form onSubmit={handleReplySubmit}>
              <div className="row">
                <div className="col-12 col-sm-10">
                  <div className="form-group">
                    <input
                      autoFocus="on"
                      autoComplete="off"
                      required
                      type="text"
                      name="reply"
                      className="form-control small-font-size"
                      placeholder="Reply"
                      id="reply-box"
                      value={replyInput}
                      onChange={(e) => setReplyInput(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-2">
                  <button
                    type="submit"
                    className="custom-btn-small red-btn w-100"
                  >
                    Reply
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        {child_comments.map((reply, i) => (
          <ReplyComment
            key={i}
            reply={reply}
            customer_rating={customer_rating}
            company_rating={company_rating}
          />
        ))}
      </div>
    </>
  );
}

export default MianComment;
