import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

function TimelineDirectRequest(props) {
  const {
    request_timeline,
    request,
    delete_address_array,
    edit_address_array,
    new_address_array,
    change_item_extra,
    change_item,
  } = props.requestData;

  const { request_timeline_status, request_type } = request;
  const { created_at, id } = request;

  const [extra, setExtra] = useState(false);

  const [item, setItem] = useState(false);

  const [address, setAddress] = useState(false);

  const showExtraLink = () => {
    if (request_timeline_status > 4) return;

    for (let i = 0; i < request_timeline.length; i++) {
      if (request_timeline[i].activity_title === "Change in extra items") {
        // console.log(request_timeline[i].activity_title, "=>", i, "LOOOP");
        setExtra(i);
        break;
      }
    }
  };

  const showAddressLink = () => {
    if (request_timeline_status > 4) return;
    for (let i = 0; i < request_timeline.length; i++) {
      if (request_timeline[i].activity_title === "Change in address") {
        // console.log(request_timeline[i].activity_title, "=>", i, "LOOOP");
        setAddress(i);
        break;
      }
    }
  };

  const showItemsLink = () => {
    if (request_timeline_status > 4) return;
    for (let i = 0; i < request_timeline.length; i++) {
      if (request_timeline[i].activity_title === "Change in items") {
        // console.log(request_timeline[i].activity_title, "=>", i, "LOOOP");
        setItem(i);
        break;
      }
    }
  };

  useEffect(() => {
    showExtraLink();
    showAddressLink();
    showItemsLink();
  }, []);

  return (
    <>
      <div className="rounded-box mt-4">
        <div className="heading-wrapper black-bg text-center">
          <h6 className="text-white">Timeline</h6>
        </div>
        <div className="request-left-box p-3 p-sm-4">
          {request_timeline.map((timeline, i) => {
            return (
              <div key={i} className="row timeline-row">
                <div className="col-12">
                  <div className="request-staus small-font-size">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="8"
                        cy="8"
                        r="8"
                        fill={i === 0 ? "#C4314B" : "#ECEBED"}
                      />
                    </svg>
                    {timeline.activity_title}
                    {/* //change in address */}
                    {/* {timeline.activity_title === "Change in address" &&
                      (edit_address_array.length !== 0 ||
                        new_address_array.length !== 0 ||
                        delete_address_array.length !== 0) &&
                      address === i && (
                        <span
                          onClick={() =>
                            props.history.push(
                              "/company/company-requests/change-request/address/" +
                                id
                            )
                          }
                          className="pointer-point light-red-color small-font-size pl-2 pr-2 "
                        >
                          More Details
                        </span>
                      )} */}
                    {/* //change in items */}
                    {/* {timeline.activity_title === "Change in items" &&
                      change_item.length !== 0 &&
                      item === i && (
                        <span
                          onClick={() =>
                            props.history.push(
                              "/company/company-requests/change-request/items/" +
                                id
                            )
                          }
                          className="pointer-point light-red-color small-font-size pl-2 pr-2 "
                        >
                          More Details
                        </span>
                      )} */}
                    {/* //change in Extra item */}
                    {/* {timeline.activity_title === "Change in extra items" &&
                      change_item_extra.length !== 0 &&
                      extra === i && (
                        <span
                          onClick={() =>
                            props.history.push(
                              "/company/company-requests/change-request/extra-items/" +
                                id
                            )
                          }
                          className="pointer-point light-red-color small-font-size pl-2 pr-2 "
                        >
                          More Details
                        </span>
                      )} */}
                  </div>
                </div>
                <div className="col-12 caption light-grey-color pt-1 timeline-date">
                  {moment(timeline.activity_datetime).format("LLL")}
                </div>
              </div>
            );
          })}
          <div className="row timeline-row">
            <div className="col-12">
              <div className="request-staus small-font-size">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill={request_timeline.length === 0 ? "#C4314B" : "#ECEBED"}
                  />
                </svg>{" "}
                New Request
              </div>
            </div>
            <div className="col-12 caption light-grey-color pt-1 timeline-date">
              {/* {created_at} */}
              {moment(created_at).format("LLL")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(TimelineDirectRequest);
