import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import { blackBackArrowImage } from "../../../../assets";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import { useSnakbar } from "../../../../Context/Context-Snakbar/ContextSnackbar";

function ChanegPhone(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const { profile, GetCompanyProfile, setMainScreen } = props;

  const handleSubmitPhone = (e) => {
    e.preventDefault();
    console.log(updatedPhone, "updatedPhone");

    if (updatedPhone === profile.phone) {
      showErrorSnak("You entered same phone number.");
      // return;
    }

    SendCodeOnPhone();
  };
  const handleSubmitCode = (e) => {
    e.preventDefault();
    verifyPhone();
  };

  const [updatedPhone, setUpdatedPhone] = useState("");

  const [isLoading, setIsLoading] = React.useState(false);

  const [code, setCode] = useState("");

  const [screen, setScreen] = React.useState("ENTER_PHONE");

  const UpdateCompanyProfile = async () => {
    let requestObj = {
      path: "/api/company/update_compony_profile",
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      name: profile.name.trim(),
      address: profile.address.trim(),
      state: profile.state,
      about_company: profile.about_company.trim(),
      thumbnail: profile.thumbnail,
      email_on_request: profile.email_on_request,
      phone: updatedPhone, //UPDATED PHONE NUMBER HERE
      about_company: profile.about_company.trim(),
      ca_number: profile.ca_number,
      dot_number: profile.dot_number,
      mtr_number: profile.mtr_number,
      pli_file_path: profile.pli_file_path,
      pdi_file_path: profile.pdi_file_path,
      cpi_file_path: profile.cpi_file_path,
      gli_file_path: profile.gli_file_path,
      wc_file_path: profile.wc_file_path,
      email: profile.email,
      profile_pic: profile.profile_picture,
      invoice: profile.invoice,
      confirmation_letter: profile.confirmation_letter,
    };
    setIsLoading(true);
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        GetCompanyProfile();
        setMainScreen(""); // show main screen
        showSuccessSnak("Phone number updated successfully.");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const SendCodeOnPhone = () => {
    let requestObj = {
      path: "/app_api/send_verification_code_on_ph_number",
      method: "POST",
      headers: {},
    };
    let postData = {
      phonenumber: `+1${updatedPhone}`,
      channel: "sms",
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setScreen("ENTER_CODE");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const verifyPhone = () => {
    let requestObj = {
      path: "/app_api/verify_code_send_on_phone_number",
      method: "POST",
      headers: {},
    };
    let postData = {
      phonenumber: `+1${updatedPhone}`,
      code: code,
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        UpdateCompanyProfile();
      } else {
        setIsLoading(false);
      }
    });
  };

  if (isLoading) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-foremanDefaultImagefluid">
            <p className="small-text-bold page-title">
              <img
                className="pointer-point pr-1"
                src={blackBackArrowImage}
                onClick={() => setMainScreen("")}
              />
              <span>Edit Phone</span>
            </p>
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </section>
      </>
    );
  }

  if (screen === "ENTER_CODE") {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">
              <img
                className="pointer-point pr-1"
                src={blackBackArrowImage}
                onClick={() => setMainScreen("")}
              />
              <span>Edit Phone</span>
            </p>
            <div className="calendar-wrapper">
              <div className="row justify-content-center pt-40">
                <div className="col-10 col-md-6 col-xl-5">
                  {/*  */}
                  <div className="form-box-wrapper">
                    <div className="form-box">
                      <h5 className="text-center">Check your Phone</h5>

                      <div className="evom-form login-form">
                        <form onSubmit={handleSubmitCode}>
                          <div className="row justify-content-center">
                            <div></div>
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  htmlFor="email"
                                  className="overline dark-grey-color"
                                >
                                  Enter code
                                </label>
                                <NumberFormat
                                  required
                                  onChange={(e) => {
                                    setCode(e.target.value);
                                  }}
                                  className="form-control small-font-size"
                                  id="phone"
                                  value={code}
                                  placeholder="Enter your 6 digit confirmation code"
                                  format="######"
                                  mask="-"
                                  allowLeadingZeros
                                  autoComplete="off"
                                />
                              </div>
                            </div>

                            <div className="col-12 col-sm-12">
                              <div className="form-group button-wrapper">
                                <button className="custom-buton custom-buton-large w-100">
                                  Verify
                                </button>
                              </div>

                              <div className="no-account-text-wrapper-custom text-center mt-4 pt-4">
                                <p className="light-grey-color">
                                  I didn't receive the code?{" "}
                                  <span
                                    onClick={SendCodeOnPhone}
                                    className="custom-link pointer-point"
                                  >
                                    Resend
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  if (screen === "ENTER_PHONE") {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">
              <img
                className="pointer-point pr-1"
                src={blackBackArrowImage}
                onClick={() => setMainScreen("")}
              />
              <span>Edit Phone</span>
            </p>
            <div className="calendar-wrapper">
              <div className="row justify-content-center pt-40">
                <div className="col-10 col-md-6 col-xl-5">
                  <div className="form-box-wrapper">
                    <div className="form-box">
                      <h5 className="text-center">Enter New Phone</h5>
                      <div className="evom-form login-form">
                        <form onSubmit={handleSubmitPhone}>
                          <div className="form-group">
                            <label
                              htmlFor="company_name"
                              className="overline dark-grey-color"
                            >
                              Phone Number
                            </label>
                            <NumberFormat
                              required
                              onChange={(e) => setUpdatedPhone(e.target.value)}
                              value={updatedPhone}
                              className="form-control small-font-size"
                              id="phone"
                              placeholder="(217) 555-0113"
                              format="(###) ###-####"
                              // mask="_"
                            />
                          </div>

                          <div className="form-group button-wrapper">
                            <button
                              type="submit"
                              className="custom-buton custom-buton-large w-100"
                            >
                              Update Phone
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return <>Invalid Screen</>;
}

export default withRouter(ChanegPhone);
