import { Badge } from "@material-ui/core";
import React from "react";
import { photoCountImage, cameraImage } from "../../../../../../assets";
import Tippy from "@tippy.js/react";
import { s3baseUrl } from "../../../../../../bl_libs/invokeApi";
import { withRouter } from "react-router";

function Items(props) {
  const { request_item, request } = props;
  console.log(request_item, "items");

  const getItemsByRoom = (items) => {
    let rooms = [];
    items.map((item) => {
      let found = rooms.find((room) => room === item.category_name);
      if (found === undefined) {
        rooms.push(item.category_name);
      }
    });

    let itemsByRoom = [];
    rooms.map((room) => {
      let temp = items.filter((item) => room === item.category_name);
      itemsByRoom.push({ room, items: temp });
    });
    return itemsByRoom;
  };

  // console.log(getItemsByRoom(request_item), "==>>>GET ITEM IN ROOM");
  return (
    <div className="info-detail-wrapper p-3 pt-20">
      <div className="row">
        {getItemsByRoom(request_item).map((singleRoom) => {
          if (singleRoom.room !== "Common List") {
            return (
              // item by room
              <div className="col-12 col-sm-5">
                <div className="sub-title">
                  {singleRoom.room + ` (${singleRoom.items.length}) `}
                </div>
                {singleRoom.items.map((item) => (
                  <div className="row pt-1 ">
                    <div className="col-9 text-left small-font-size ">
                      {item.name}
                    </div>
                    <div className="col-3 small-sub-title">
                      <span className="pr-2 ">{item.quantity}</span>
                      {item.request_item_image_models.length > 0 && (
                        <Tippy
                          content={item.request_item_image_models.map((pic) => (
                            <img
                              style={{ height: 117, width: 117 }}
                              className="p-1"
                              src={s3baseUrl + pic.image_url}
                            />
                          ))}
                          animation="scale"
                          delay={[300, 0]}
                          arrow={false}
                          placement="bottom"
                          maxWidth={400}
                        >
                          <Badge
                            // key={i}
                            color="primary"
                            overlap="circle"
                            badgeContent={
                              <span
                                style={{
                                  fontSize: 14,
                                  marginBottom: 0,
                                  cursor: "pointer",
                                }}
                                // onClick={() => handleImageCross(pic.preview)}
                              >
                                {item.request_item_image_models.length}
                              </span>
                            }
                          >
                            <img
                              viewBox="0 0 50 50"
                              src={cameraImage}
                              style={{
                                margin: 5,
                              }}
                            ></img>
                          </Badge>
                        </Tippy>
                        // <span>
                        //   <img src={photoCountImage} />
                        // </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            );
          } else {
            return (
              //items by common list
              <>
                <div className="sub-title col-12">{singleRoom.room}</div>

                {singleRoom.items.map((item) => (
                  <div className="col-12 col-sm-12 col-lg-6">
                    <div className="row pt-1">
                      <div className="col-9 text-left small-font-size">
                        {item.name}
                      </div>
                      <div className="col-3 small-sub-title">
                        <span className="pr-2 align-bottom">
                          {item.quantity}
                        </span>
                        {item.request_item_image_models.length > 0 && (
                          <Tippy
                            content={item.request_item_image_models.map(
                              (pic) => (
                                <img
                                  style={{ height: 117, width: 117 }}
                                  className="p-1"
                                  src={s3baseUrl + pic.image_url}
                                />
                              )
                            )}
                            animation="scale"
                            delay={[300, 0]}
                            arrow={false}
                            placement="bottom"
                            maxWidth={400}
                          >
                            <Badge
                              // key={i}
                              color="primary"
                              overlap="circle"
                              badgeContent={
                                <span
                                  style={{
                                    fontSize: 14,
                                    marginBottom: 0,
                                    cursor: "pointer",
                                  }}
                                  // onClick={() => handleImageCross(pic.preview)}
                                >
                                  {item.request_item_image_models.length}
                                </span>
                              }
                            >
                              <img
                                viewBox="0 0 50 50"
                                src={cameraImage}
                                style={{
                                  margin: 5,
                                }}
                              ></img>
                            </Badge>
                          </Tippy>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            );
          }
        })}
      </div>
      <div className="row mt-4 pb-2">
        <div className="col-12 justify-content-center d-flex">
          {request.request_type && request.request_timeline_status < 6 && (
            <button
              className="custom-btn-small custom-buton"
              onClick={() =>
                props.history.push(
                  "/company/my-requests/edit-items/" +
                    props.match.params.requestId
                )
              }
            >
              {request_item.length === 0 ? "Add" : "Edit"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Items);
