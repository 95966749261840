import { CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { Redirect, withRouter } from "react-router-dom";
import {
  editWhiteImage,
  crossEditImage,
  subtractImage,
  addImage,
} from "../../../../../../assets";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import moment from "moment";
import { getMovers, time_convert } from "../../../../../../ui_libs/Functions/functions";

function BidSubmitted(props) {
  const { requestData, fetchRequestDetails, bidCancel } = props;
  const {
    customer_budget,
    recommend_amount,
    id,
    s_date,
    s_time,
    schedule_time,
    request_timeline_status,
  } = requestData.request;
  const { request_timeline } = requestData;
  const { bid_amount, movers } = requestData.request_bid;

  const [isEdit, setIsEdit] = useState(false);

  const [inputs, setInputs] = useState({
    id: props.match.params.requestId,
    movers: getMovers(requestData),
    price: bid_amount,
    date: s_date,
    time: s_time,
  });

  const SaveChanges = () => {
    if (
      inputs.movers === movers &&
      (inputs.price.toString() === bid_amount.toString() || inputs.price === "")
    ) {
      setErrMessage("You're Not Making Changes.");
      return;
    }

    let requestObj = {
      path: "/api/company/edit_date_time_movers_price",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: inputs.id,
      schedule_time:
        moment(inputs.date).format("MMM Do YY") + " " + inputs.time,
      s_date: moment(inputs.date).format("MM-DD-YYYY"),
      s_time: inputs.time,
      price:
        inputs.price === "" ? bid_amount.toString() : inputs.price.toString(),
      movers: inputs.movers.toString(),
    };

    setIsLoadingBtn(true);

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "Api Response");

      if (res.code === 200) {
        fetchRequestDetails();
      } else {
        setErrMessage(res.message);
        setIsLoadingBtn(false);
      }
    });
  };

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [errMessage, setErrMessage] = React.useState("");

  const handleSubtract = () => {
    if (isLoadingBtn) return;
    if (inputs.movers <= 1) return;
    setInputs({ ...inputs, movers: inputs.movers - 1 });
  };

  const handleAdd = () => {
    if (isLoadingBtn) return;
    setInputs({ ...inputs, movers: inputs.movers + 1 });
  };

  const handleChangeInPrice = (e) => {
    setInputs({ ...inputs, price: e.target.value });
  };

  if (request_timeline_status === 1 && request_timeline.length > 1) {
    console.log(props, "ppp");

    // props.history.push("/company/company-requests/" + id);
    // window.location.reload();
    // window.location.replace("/company/company-requests/" + id);
  }

  useEffect(() => {
    setErrMessage("");
  }, [isEdit]);

  if (isEdit) {
    return (
      <>
        <div className="rounded-box">
          <div className="heading-wrapper black-bg text-center">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-8">
                <h6 className="text-white pt-1">Request #{id}</h6>
              </div>
              <div className="col-2 text-right">
                <img
                  className="pl-1 pointer-point"
                  src={crossEditImage}
                  onClick={() => setIsEdit(false)}
                ></img>
              </div>
            </div>
          </div>
          {errMessage && (
            <Alert variant="filled" severity="error">
              {errMessage}
            </Alert>
          )}
          <div className="request-left-box p-3 p-sm-4">
            <div className="row">
              <div className="col-8">
                <div className="request-staus small-font-size">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="8" fill="#C4314B" />
                  </svg>{" "}
                  Bid Submitted
                </div>
              </div>
              <div className="col-4 text-right">
                {/* <a
                  href=""
                  className="small-text-bold red-color add-new-btn"
                  data-toggle="modal"
                  data-target="#cancel-request"
                >
                  <span className="pointer-point">Cancel</span>
                </a> */}
              </div>
            </div>
            <div className="bid-form-wrapper pt-3">
              <div className="row pt-2">
                <div className="col-12">
                  <label htmlFor="email" className="overline dark-grey-color">
                    Movers
                  </label>
                </div>
                <div className="col-12">
                  <img
                    className="pointer-point"
                    onClick={handleSubtract}
                    src={subtractImage}
                  />
                  <span className="movers-count-box small-font-size black-color text-center">
                    {inputs.movers}
                  </span>

                  <img
                    className="pointer-point"
                    onClick={handleAdd}
                    src={addImage}
                  />
                </div>
              </div>

              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color pt-2">
                  <label
                    htmlFor="email"
                    className="overline dark-grey-color pt-1"
                  >
                    New price:
                  </label>
                </div>
                <div className="col-6 text-right black-color">
                  <NumberFormat
                    allowNegative={false}
                    disabled={isLoadingBtn}
                    allowLeadingZeros
                    value={inputs.price}
                    required
                    onChange={handleChangeInPrice}
                    type="text"
                    className="form-control small-font-size"
                    id="your-price"
                    placeholder="100"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="row pt-3 pb-3">
                <div className="col-3"></div>
                <div className="col-12 mt-4  justify-content-center d-flex">
                  <button
                    className="custom-btn-small custom-buton m-2"
                    onClick={SaveChanges}
                  >
                    Save
                  </button>
                  <button
                    className="custom-buton custom-btn-small custom-button-pink  m-2"
                    onClick={() => setIsEdit(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-3"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <h6 className="text-white pt-1">Request #{id}</h6>
            </div>
            <div className="col-2 text-right">
              <img
                className="pl-1 pointer-point"
                src={editWhiteImage}
                onClick={() => setIsEdit(true)}
              ></img>
            </div>
          </div>
        </div>
        <div className="request-left-box p-3 p-sm-4">
          <div className="row">
            <div className="col-8">
              <div className="request-staus small-font-size">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#C4314B" />
                </svg>{" "}
                Bid Submitted
              </div>
            </div>
            <div className="col-4 text-right">
              <a
                href=""
                className="small-text-bold red-color add-new-btn"
                data-toggle="modal"
                data-target="#cancel-request"
              >
                <span className="pointer-point">Cancel</span>
              </a>
            </div>
          </div>
          <div className="bid-form-wrapper pt-3">
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Start time:</div>
              <div className="col-6 text-right black-color">
              {/* {`${time_convert(s_time.slice(0,5))} to ${time_convert(s_time.slice(8,13))}`} */}
              {s_time && s_time !== "8AM - 12PM Morning" &&
                    s_time !== "12PM - 5PM Evening" &&
                    s_time !== "Entire day" &&
                    `${time_convert(
                      s_time.split('-')[0].trim()
                    )} to ${time_convert(s_time.split('-')[1].trim())}`}
                  {s_time && (s_time == "8AM - 12PM Morning" ||
                    s_time == "12PM - 5PM Evening" ||
                    s_time == "Entire day") && `${s_time}`}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Movers:</div>
              <div className="col-6 text-right black-color">
                {getMovers(requestData)}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Price:</div>
              <div className="col-6 text-right black-color">${bid_amount} </div>
            </div>
            <div className="row text-center pt-4">
              <div className="col-12 caption">
                Your bid is submitted and waiting for acceptance, then you can
                chat with the client.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Bid Placed --> */}
      <div
        className="modal fade"
        id="cancel-request"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancel-request-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-bid-cancel"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0 text-center">
                <h5 className="text-center" id="cancel-request-title">
                  Cancel request
                </h5>
                <p className="pt-3">
                  Are you sure you want to cancel the request?
                </p>
                <p className="text-left light-grey-color caption">
                  When cancelling request, you will be charged an additional fee
                  50%.
                </p>
                <div className="buttons-wrapper pt-3">
                  <div className="row justify-content-center text-center">
                    <div className="col-6">
                      <button
                        onClick={bidCancel}
                        className="custom-btn-small custom-buton float-right"
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        onClick={() =>
                          document.getElementById("close-bid-cancel").click()
                        }
                        className="custom-btn-small custom-buton custom-button-pink float-left"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(BidSubmitted);
