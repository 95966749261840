import { Avatar } from "@material-ui/core";
import React from "react";
import { companyProfilePicImage } from "../../../../../../assets";
import { s3baseUrl } from "../../../../../../bl_libs/invokeApi";

function SidebarHeader(props) {
  const { profile } = props;

  return (
    <>
      <div className="row pt-3">
        <div className="col-2">
          <div className="profile-pic-small">
            {profile.profile_picture ? (
              <img
                src={s3baseUrl + profile.profile_picture}
                className="rounded-circle circle-box circle-box-small img-46"
              />
            ) : (
              <Avatar style={{ width: 46, height: 46 }}>
                {profile.name && profile.name[0].toUpperCase()}
              </Avatar>
            )}
            <div className="status-active-small"></div>
          </div>
        </div>
        <div className="col-10 ">
          <div className="my-header-title">
            <span className="pl-1">{profile.name}</span>
          </div>
          <div className="under-text pl-1">Online</div>
        </div>
      </div>
    </>
  );
}

export default SidebarHeader;
