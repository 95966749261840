import React from "react";
import { addImage, subtractImage, infoIconImage } from "../../../../assets";
import Tippy from "@tippy.js/react";
import NumberFormat from "react-number-format";

function ExtraItemsScreen(props) {
  const { extraItemsList, setExtraItemList } = props;

  const handleKeyDown = (selectedItem, e) => {
    if (e.key === ".") {
      setTimeout(() => {
        let temp = extraItemsList.map((item) => {
          if (item.item_id === selectedItem.item_id) {
            return {
              ...item,
              value: 1,
            };
          }
          return item;
        });
        setExtraItemList(temp);
      }, 200);
    }
  };

  const handleChange = (selectedItem, e) => {
    if (!selectedItem.isSelected) return;
    let temp = extraItemsList.map((item) => {
      if (item.item_id === selectedItem.item_id) {
        if (e.target.value === "") {
          return {
            ...item,
            value: 1,
          };
        } else {
          return {
            ...item,
            value: parseInt(e.target.value),
          };
        }
      }
      return item;
    });
    setExtraItemList(temp);
  };

  const handleAdd = (selectedItem) => {
    if (!selectedItem.isSelected) return;
    let temp = extraItemsList.map((item) => {
      if (item.item_id === selectedItem.item_id) {
        return {
          ...item,
          value: item.value + 1,
        };
      }
      return item;
    });
    setExtraItemList(temp);
  };
  const handleSubtract = (selectedItem) => {
    if (selectedItem.value === 1 || !selectedItem.isSelected) return;
    let temp = extraItemsList.map((item) => {
      if (item.item_id === selectedItem.item_id) {
        return {
          ...item,
          value: item.value - 1,
        };
      }
      return item;
    });
    setExtraItemList(temp);
  };
  const handleCheckBox = (selectedItem) => {
    let temp = extraItemsList.map((item) => {
      if (item.item_id === selectedItem.item_id) {
        if (selectedItem.isSelected === false) {
          return {
            ...item,
            isSelected: !item.isSelected,
            value: 1,
          };
        } else {
          return {
            ...item,
            isSelected: !item.isSelected,
            value: 0,
          };
        }
      }
      return item;
    });
    setExtraItemList(temp);
  };
  return (
    <>
      <section className="main-content address-page pb-5">
        <div className="container">
          <div className="add-items-wrapper mb-m-90 mb-90 mt-3 mt-sm-5">
            <h1 className="text-center text-uppercase pt-3 pt-sm-4 pt-md-5 mb-0">
              Extra Items
            </h1>
            <div className="row justify-content-center pt-sm-5 pt-4">
              <div className="col-12 col-sm-8">
                <div className="row border-bottom no-gutters">
                  <div className="col-10">
                    <label className="dark-grey-color overline">
                      Packing And Moving Material
                    </label>
                  </div>
                  {/* <div className="col-2 text-right">
                    <Tippy
                      content="Packing kit contains 15 small boxes, 15 medium boxes, 15 large boxes, bubble roll, paper roll."
                      delay={[300, 0]}
                      arrow={false}
                      placement="bottom"
                      maxWidth={200}
                    >
                      <img src={infoIconImage} />
                    </Tippy>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="selected-room mt-4 mt-sm-5">
              {extraItemsList.length > 0 &&
                extraItemsList.map((item) => (
                  <div
                    key={item.item_id}
                    className="row justify-content-center"
                  >
                    <div className="col-12 col-sm-8 mb-4 mb-sm-4">
                      <div
                        className="rounded-box "
                        style={{
                          backgroundColor:
                            !item.isSelected && "rgb(236, 235, 237)",
                        }}
                      >
                        <div className="selected-items">
                          <div className="single-item">
                            <div className="row">
                              <div className="col-10">
                                <label className="dark-grey-color mb-0 custom-checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    name="storage"
                                    value={item.isSelected}
                                    checked={item.isSelected}
                                    onChange={() => handleCheckBox(item)}
                                  />
                                  <span className="custom-check-box"></span>
                                </label>
                                <div
                                  className="item-name pl-1"
                                  style={{ marginLeft: 35 }}
                                >
                                  {item.name}
                                </div>
                              </div>
                              <div className="col-2 text-right">
                                <Tippy
                                  content={item.desciption}
                                  animation="scale"
                                  delay={[300, 0]}
                                  arrow={false}
                                  placement="bottom"
                                  maxWidth={200}
                                >
                                  <img src={infoIconImage} />
                                </Tippy>
                              </div>
                            </div>
                            <div className="row pt-3">
                              <div className="col-9 small-sub-title items-count-wrapper">
                                <img
                                  className="pointer-point"
                                  onClick={() => handleSubtract(item)}
                                  src={subtractImage}
                                />
                                <NumberFormat
                                  maxLength={3}
                                  type="text"
                                  disabled={!item.isSelected}
                                  className="form-control small-font-size items-count"
                                  value={item.value}
                                  allowNegative={false}
                                  onChange={(e) => handleChange(item, e)}
                                  onKeyDown={(e) => handleKeyDown(item, e)}
                                />
                                <img
                                  className="pointer-point"
                                  onClick={() => handleAdd(item)}
                                  src={addImage}
                                />
                                <div className="clearfix"></div>
                              </div>
                              <div className="col-3 text-right">
                                <span
                                  className="extra-item-price-text"
                                  style={{
                                    color: !item.isSelected && "#747474",
                                  }}
                                >
                                  $
                                  {item.unit_price *
                                    (item.value === 0 ? 1 : item.value)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="next-prev">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-7 col-md-4">
                <div className="float-left">
                  <a
                    href="add-items-by-room.html"
                    className="dark-grey-color prev"
                  >
                    <img src="../images/grey-back-arrow.svg" /> Prev
                  </a>
                </div>
                <div className="float-right">
                  <a href="add-card.html" className="red-color">
                    Next
                    <img src="../images/red-next-arrow.svg" />
                  </a>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExtraItemsScreen;
