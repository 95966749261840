import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

function StatsFilter({ filter, setFilter, options, setOptions, setShowFilter, handleClear }) {

  const [customStartDate, setCustomStartDate] = useState(
    new Date(filter.start_date)
  );
  const [customEndDate, setCustomEndDate] = useState(new Date(filter.end_date));
  const [customDays, setCustomDays] = useState(
    moment(customEndDate).diff(moment(customStartDate), "days")
  );
  const [customType, setCustomType] = useState(
    customDays > 90 ? "years" : customDays > 30 ? "months" : "days"
  );
  const getFormattedDates = (day = 0, type = "days") => {
    const end_date = moment().subtract(0, "days").format("YYYY-MM-DD");
    const start_date = moment().subtract(day, "days").format("YYYY-MM-DD");
    return { start_date, end_date, day, type };
  };

  const handleFilter = () => {
    setShowFilter(true);
    if (options !== "custom") {
      const days = Number(options);
      if (days > 90) {
        const data = getFormattedDates(days, "years");
        setFilter(data);
      } else if (days > 30) {
        const data = getFormattedDates(days, "months");
        setFilter(data);
      } else {
        const data = getFormattedDates(days, "days");
        setFilter(data);
      }
    } else {
      const sDate = moment(customStartDate).format("YYYY-MM-DD");
      const eDate = moment(customEndDate).format("YYYY-MM-DD");
      const cDay = moment(customEndDate).diff(moment(customStartDate), "days");
      const cType = cDay > 90 ? "years" : cDay > 30 ? "months" : "days";
      setFilter({
        start_date: sDate,
        end_date: eDate,
        day: cDay,
        type: cType,
      });
    }
    console.log(filter, "filterxyz");
  };

  useEffect(() => {
    if (options !== "")
      localStorage.setItem("dashboardFilter", JSON.stringify({ filter, options }));
  }, [filter]);
  return (
    <div>
      {/* <!-- Change Filter --> */}
      <div
        className="modal fade pb-m-80 mt-m-80"
        id="stats-filter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="stats-filter-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-filter"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="change-filter-title">
                  Filter
                </h5>
                <div className="form-box pt-20">
                  <form>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <a
                            onClick={() => setOptions("7")}
                            style={{ width: 150 }}
                            className={
                              options === "7"
                                ? "custom-btn-small custom-button-extra-small filter-button active w-100 mb-2"
                                : "custom-btn-small custom-button-extra-small filter-button w-100 mb-2"
                            }
                          >
                            Last 7 Days
                          </a>
                        </div>
                        <div className="col-12 col-sm-6">
                          <a
                            onClick={() => setOptions("30")}
                            className={
                              options === "30"
                                ? "custom-btn-small custom-button-extra-small filter-button active w-100 mb-2"
                                : "custom-btn-small custom-button-extra-small filter-button w-100 mb-2"
                            }
                          >
                            Last 30 Days
                          </a>
                        </div>
                        <div className="col-12 col-sm-6">
                          <a
                            onClick={() => setOptions("90")}
                            className={
                              options === "90"
                                ? "custom-btn-small custom-button-extra-small filter-button active w-100 mb-2"
                                : "custom-btn-small custom-button-extra-small filter-button w-100 mb-2"
                            }
                          >
                            Last 3 Months
                          </a>
                        </div>
                        <div className="col-12 col-sm-6">
                          <a
                            onClick={() => setOptions("custom")}
                            className={
                              options === "custom"
                                ? "custom-btn-small custom-button-extra-small filter-button active w-100 mb-2"
                                : "custom-btn-small custom-button-extra-small filter-button w-100 mb-2"
                            }
                          >
                            Custom
                          </a>
                        </div>
                        {options === "custom" && (
                          <>
                            <div className="col-6 col-sm-6 pt-3">
                              <label
                                htmlFor="from-date"
                                className="overline dark-grey-color"
                              >
                                From Date
                              </label>
                              <br></br>

                              <DatePicker
                                className="form-control small-font-size date-picker"
                                id="to-date"
                                placeholderText="MM/DD/YYYY"
                                selected={customStartDate}
                                onChange={(date) => setCustomStartDate(date)}
                                autoComplete="off"
                              />
                            </div>
                            <div className="col-6 col-sm-6 pt-3">
                              <label
                                htmlFor="to-date"
                                className="overline dark-grey-color"
                              >
                                To Date
                              </label>
                              <br></br>
                              <DatePicker
                                className="form-control small-font-size date-picker"
                                id="to-date"
                                placeholderText="MM/DD/YYYY"
                                selected={customEndDate}
                                onChange={(date) => setCustomEndDate(date)}
                                autoComplete="off"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row"></div>
                    <div className="form-group pt-3">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-10">
                          <button
                            disabled={options === ""}
                            type="button"
                            className="red-btn custom-buton custom-buton-large w-100"
                            onClick={handleFilter}
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Use Filter
                          </button>
                        </div>

                        <div className="col-12 col-sm-10 mt-3 text-center">
                          <span
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClear}
                            className="custom-link pointer-point"
                          >
                            Clear Filter
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsFilter;
