import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import { Redirect, Link } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Alert } from "@material-ui/lab";
import NumberFormat from "react-number-format";
import { invokeApi } from "../../bl_libs/invokeApi";
import { uploadIconImage, loadingImage } from "../../assets";
import { validatePasswordStrength } from "../../ui_libs/Validations/validations";
import PhoneVarification from "./components/PhoneVarification";
import EmailVarification from "./components/EmailVarification";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
import { USAStates } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  uploadroot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

function getSteps() {
  return ["Main Info", "Licences", "Insurance", "Verification"];
}

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const { showErrorSnak } = useSnakbar();

  const [activeStep, setActiveStep] = React.useState(0);

  //---------------------------------------------------> for email and phone varification

  const [currentScreen, setCurrentScreen] = React.useState("PHONE");

  const jumpSteps = (step) => {
    setCurrentScreen("PHONE");
    setActiveStep(step);
  };

  const SendCodeOnPhone = () => {
    console.log("SendCodeOnPhone");

    let requestObj = {
      path: "/app_api/send_verification_code_on_ph_number",
      method: "POST",
      headers: {},
    };
    let postData = {
      phonenumber: `+1${inputs.phone_number}`,
      channel: "sms",
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setCurrentScreen("PHONE");
        setIsLoading(false);
        setErrMessage("");
      } else {
        setActiveStep(0);
        setErrMessage(res.message);
        setIsLoading(false);
        // //showErrorSnak(res.message);
      }
    });
  };

  const SendCodeOnEmail = () => {
    let requestObj = {
      path: "/app_api/send_verification_code_on_email",
      method: "POST",
      headers: {},
    };
    let postData = {
      email: inputs.email,
      name: inputs.company_name,
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setErrMessage("");
        setCurrentScreen("EMAIL");
        setIsLoading(false);
      } else {
        setErrMessage(res.message);
        setActiveStep(0);
        // //showErrorSnak(res.message);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {}, []);
  // -------------------------------------------------------------------------------------
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        //   return <>main</>;
        return (
          <>
            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                Company Name
              </label>
              <input
                style={
                  company_name_err1 === true ? {} : { border: "solid 1px red" }
                }
                onChange={handleChange}
                value={inputs.company_name}
                type="text"
                className="form-control small-font-size"
                id="company_name"
                placeholder="company name"
              />
              <p className="form-text text-danger " id="company_name_err">
                {company_name_err}
              </p>
            </div>
            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                Address
              </label>
              <input
                style={address_err1 === true ? {} : { border: "solid 1px red" }}
                onChange={handleChange}
                value={inputs.address}
                type="text"
                className="form-control small-font-size"
                id="address"
                placeholder="295 N Rodeo Dr, Beverly Hills, CA 90210"
              />
              <p className="form-text text-danger" id="address_err">
                {address_err}
              </p>
            </div>
            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                Phone Number
              </label>
              <NumberFormat
                style={
                  phone_number_err1 === true ? {} : { border: "solid 1px red" }
                }
                onChange={handleChange}
                value={inputs.phone_number}
                className="form-control small-font-size"
                id="phone_number"
                placeholder="(217) 555-0113"
                format="(###) ###-####"
                mask="_"
              />
              <p className="form-text text-danger" id="phone_number_err">
                {phone_number_err}
              </p>
            </div>

            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                Email
              </label>
              <input
                style={email_err1 === true ? {} : { border: "solid 1px red" }}
                onChange={handleChange}
                value={inputs.email}
                type="email"
                className="form-control small-font-size"
                id="email"
                placeholder="example@gmail.com"
              />
              <p className="form-text text-danger" id="email_err">
                {email_err}
              </p>
            </div>
            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                Password
              </label>
              <input
                style={
                  password_err1 === true ? {} : { border: "solid 1px red" }
                }
                onChange={handleChange}
                value={inputs.password}
                type="password"
                className="form-control small-font-size"
                id="password"
                placeholder="***********"
              />
              {passStrength.level > 0 && (
                <div className="progress" style={{ height: 2, marginTop: -3 }}>
                  <div
                    className={
                      "progress-bar " +
                      ((passStrength.level < 2 && "bg-danger") ||
                        // (passStrength.level === 2 && "bg-info") ||
                        (passStrength.level === 3 &&
                          inputs.password.length >= 8 &&
                          "bg-success"))
                    }
                    role="progressbar"
                    style={{ width: passStrength.level * 33.333 + "%" }}
                    aria-valuenow={passStrength.level}
                    aria-valuemin="0"
                    aria-valuemax="3"
                  ></div>
                </div>
              )}
              <p className="form-text text-danger" id="password_err">
                {password_err}
              </p>
            </div>

            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                State
              </label>
              <select
                style={state_err1 === true ? {} : { border: "solid 1px red" }}
                className="form-control small-font-size"
                name="where_did_you_find"
                onChange={handleChange}
                value={inputs.state}
                id="state"
                required
              >
                {!inputs.state && (
                  <option value="" selected="selected">
                    Select a State
                  </option>
                )}
                <USAStates />
              </select>
            </div>
            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                Where Did You Find Us
              </label>
              <select
                className="form-control small-font-size"
                name="where_did_you_find"
                onChange={handleChange}
                value={inputs.source}
                id="source"
              >
                <option value="google">Google</option>
                <option value="yahoo">Yahoo</option>
                <option value="facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="yelp">Yelp</option>
                <option value="Friend/Family">Friend / Family</option>
                <option value="other">Other</option>
              </select>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                Ca Number
              </label>
              <NumberFormat
                style={ca_err1 === true ? {} : { border: "solid 1px red" }}
                onChange={handleChange}
                value={inputs.ca_number}
                className="form-control small-font-size"
                id="ca_number"
                placeholder="1234567"
                maxLength={7}
                allowLeadingZeros
              />
              <p className="form-text text-danger" id="ca_number_err">
                {ca_err}
              </p>
            </div>
            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                Dot Number
              </label>
              <NumberFormat
                style={dot_err1 === true ? {} : { border: "solid 1px red" }}
                onChange={handleChange}
                value={inputs.dot_number}
                className="form-control small-font-size"
                id="dot_number"
                placeholder="1234567"
                maxLength={7}
                allowLeadingZeros
              />
              <p className="form-text text-danger" id="dot_number_err">
                {dot_err}
              </p>
            </div>
            <div className="form-group">
              <label
                htmlFor="company_name"
                className="overline dark-grey-color"
              >
                Mtr Number
              </label>
              <NumberFormat
                style={mtr_err1 === true ? {} : { border: "solid 1px red" }}
                onChange={handleChange}
                value={inputs.mtr_number}
                className="form-control small-font-size"
                id="mtr_number"
                placeholder="1234567"
                maxLength={7}
                allowLeadingZeros
              />
              <p className="form-text text-danger" id="mtr_number_err">
                {mtr_err}
              </p>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="form-card mt-20">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-12">
                  <div className="row pt-1">
                    <div className="col-12 col-sm-9 pt-3">
                      <div className="insurance_btn_text">
                        Personal Liability insurance
                      </div>
                      {upload1 === "" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Not uploaded yet
                        </div>
                      ) : upload1 === "uploaded" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Under review
                        </div>
                      ) : upload1 === "uploading" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Uploading . . .
                        </div>
                      ) : upload1 === "error" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          You can't upload same file again
                        </div>
                      ) : (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Something went wrong.
                        </div>
                      )}
                      <p className="form-text text-danger" id="mtr_number_err">
                        {pli_err}
                      </p>
                    </div>
                    <div className="col-12 col-sm-3 upload-btn">
                      <div className={classes.uploadroot}>
                        <input
                          accept="image/*,application/pdf,application/vnd.ms-excel"
                          className={classes.input}
                          id="personal_liability_insurance"
                          type="file"
                          onChange={handleUpload}
                          value=""
                        />

                        <label htmlFor="personal_liability_insurance">
                          <Button
                            style={{ margin: 10, height: 40, width: 120 }}
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<img src={uploadIconImage} />}
                          >
                            <span
                              style={{
                                fontFamily: "Work Sans",
                                fontWeight: 600,
                              }}
                            >
                              Upload
                            </span>
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="light-grey-bottom-border"></div>
                  <div className="row pt-1">
                    <div className="col-12 col-sm-9 pt-3">
                      <div className="insurance_btn_text">
                        Personal Damage Insurance
                      </div>
                      {upload2 === "" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Not uploaded yet
                        </div>
                      ) : upload2 === "uploaded" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Under review
                        </div>
                      ) : upload2 === "uploading" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Uploading . . .
                        </div>
                      ) : upload2 === "error" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          You can't upload same file again
                        </div>
                      ) : (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Something went wrong.
                        </div>
                      )}
                      <p className="form-text text-danger" id="mtr_number_err">
                        {pdi_err}
                      </p>
                    </div>
                    <div className="col-12 col-sm-3 upload-btn">
                      <div className={classes.uploadroot}>
                        <input
                          accept="image/*,application/pdf,application/vnd.ms-excel"
                          className={classes.input}
                          id="personal_damage_insurance"
                          type="file"
                          onChange={handleUpload}
                          value=""
                        />
                        <label htmlFor="personal_damage_insurance">
                          <Button
                            style={{ margin: 10, height: 40, width: 120 }}
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<img src={uploadIconImage} />}
                          >
                            <span
                              style={{
                                fontFamily: "Work Sans",
                                fontWeight: 600,
                              }}
                            >
                              Upload
                            </span>
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="light-grey-bottom-border"></div>
                  <div className="row pt-1">
                    <div className="col-12 col-sm-9 pt-3">
                      <div className="insurance_btn_text">
                        Cargo Policy Insurance
                      </div>
                      {upload3 === "" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Not uploaded yet
                        </div>
                      ) : upload3 === "uploaded" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Under review
                        </div>
                      ) : upload3 === "uploading" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Uploading . . .
                        </div>
                      ) : upload3 === "error" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          You can't upload same file again
                        </div>
                      ) : (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Something went wrong.
                        </div>
                      )}
                      <p className="form-text text-danger" id="mtr_number_err">
                        {cpi_err}
                      </p>
                    </div>
                    <div className="col-12 col-sm-3 upload-btn">
                      <div className={classes.uploadroot}>
                        <input
                          accept="image/*,application/pdf,application/vnd.ms-excel"
                          className={classes.input}
                          id="cargo_policy_insurance"
                          type="file"
                          onChange={handleUpload}
                          value=""
                        />
                        <label htmlFor="cargo_policy_insurance">
                          <Button
                            style={{ margin: 10, height: 40, width: 120 }}
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<img src={uploadIconImage} />}
                          >
                            <span
                              style={{
                                fontFamily: "Work Sans",
                                fontWeight: 600,
                              }}
                            >
                              Upload
                            </span>
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="light-grey-bottom-border"></div>
                  <div className="row pt-1">
                    <div className="col-12 col-sm-9 pt-3">
                      <div className="insurance_btn_text">
                        General Liability Insurance (Optional)
                      </div>
                      {upload4 === "" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Not uploaded yet
                        </div>
                      ) : upload4 === "uploaded" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Under review
                        </div>
                      ) : upload4 === "uploading" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Uploading . . .
                        </div>
                      ) : upload4 === "error" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          You can't upload same file again
                        </div>
                      ) : (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Something went wrong.
                        </div>
                      )}
                      <p className="form-text text-danger" id="mtr_number_err">
                        {gli_err}
                      </p>
                    </div>
                    <div className="col-12 col-sm-3 upload-btn">
                      <div className={classes.uploadroot}>
                        <input
                          accept="image/*,application/pdf,application/vnd.ms-excel"
                          className={classes.input}
                          id="general_liability_insurance"
                          type="file"
                          onChange={handleUpload}
                          value=""
                        />
                        <label htmlFor="general_liability_insurance">
                          <Button
                            style={{ margin: 10, height: 40, width: 120 }}
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<img src={uploadIconImage} />}
                          >
                            <span
                              style={{
                                fontFamily: "Work Sans",
                                fontWeight: 600,
                              }}
                            >
                              Upload
                            </span>
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="light-grey-bottom-border"></div>
                  <div className="row pt-1">
                    <div className="col-12 col-sm-9 pt-3">
                      <div className="insurance_btn_text">
                        Workers compensation(Optional)
                      </div>
                      {upload5 === "" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Not uploaded yet
                        </div>
                      ) : upload5 === "uploaded" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14">
                          Under review
                        </div>
                      ) : upload5 === "uploading" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Uploading . . .
                        </div>
                      ) : upload5 === "error" ? (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          You can't upload same file again
                        </div>
                      ) : (
                        <div className="insurance_status_text light-grey-color small-font-size line-height-14 red-color">
                          Something went wrong.
                        </div>
                      )}
                      <p className="form-text text-danger" id="mtr_number_err">
                        {wc_err}
                      </p>
                    </div>
                    <div className="col-12 col-sm-3 upload-btn">
                      <div className={classes.uploadroot}>
                        <input
                          accept="image/*,application/pdf,application/vnd.ms-excel"
                          className={classes.input}
                          id="workers_compensation"
                          type="file"
                          onChange={handleUpload}
                          value=""
                        />
                        <label htmlFor="workers_compensation">
                          <Button
                            style={{ margin: 10, height: 40, width: 120 }}
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<img src={uploadIconImage} />}
                          >
                            <span
                              style={{
                                fontFamily: "Work Sans",
                                fontWeight: 600,
                              }}
                            >
                              Upload
                            </span>
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="light-grey-bottom-border pt-4"></div>
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            {/*-----------------------------------> Phone varification screen */}

            {currentScreen === "PHONE" && (
              <PhoneVarification
                phone={inputs.phone_number}
                setCurrentScreen={setCurrentScreen}
                SendCodeOnEmail={SendCodeOnEmail}
                SendCodeOnPhone={SendCodeOnPhone}
                errMessage={errMessage}
                setErrMessage={setErrMessage}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                showErrorSnak={showErrorSnak}
                jumpSteps={jumpSteps}
              />
            )}

            {/*-----------------------------------> Email varification screen */}

            {currentScreen === "EMAIL" && (
              <EmailVarification
                handleSignUp={handleSubmit}
                email={inputs.email}
                setCurrentScreen={setCurrentScreen}
                SendCodeOnEmail={SendCodeOnEmail}
                errMessage={errMessage}
                setErrMessage={setErrMessage}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                showErrorSnak={showErrorSnak}
                jumpSteps={jumpSteps}
              />
            )}

            {/*-----------------------------------> Finish screen */}
            {currentScreen === "DONE" && (
              <div className="form-card mt-5">
                <div className="row justify-content-center text-center">
                  <div className="col-12 col-sm-9">
                    <div className="row">
                      <div className="col-12">
                        <img src={loadingImage} />
                      </div>
                    </div>
                    <div className="row pt-3 ">
                      <div className="col-12">
                        <p className="big-font-size">
                          We will notify you when we check all the documents.
                          After checking, you will have access to the
                          application.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const [errMessage, setErrMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  //validation step

  const [step1, setStep1] = React.useState(true);
  const [step2, setStep2] = React.useState(true);
  const [step3, setStep3] = React.useState(true);

  //for uploading status
  const [upload1, setUpload1] = React.useState("");
  const [upload2, setUpload2] = React.useState("");
  const [upload3, setUpload3] = React.useState("");
  const [upload4, setUpload4] = React.useState("");
  const [upload5, setUpload5] = React.useState("");
  //upload file urls
  const [upload_pli, setUpload_pli] = React.useState("");
  const [upload_pdi, setUpload_pdi] = React.useState("");
  const [upload_cpi, setUpload_cpi] = React.useState("");
  const [upload_gli, setUpload_gli] = React.useState("");
  const [upload_wc, setUpload_wc] = React.useState("");
  // //upload file slector
  // const [upload_pli_file, setUpload_pli_file] = React.useState("");
  // const [upload_pdi_file, setUpload_pdi_file] = React.useState("");
  // const [upload_cpi_file, setUpload_cpi_file] = React.useState("");
  // const [upload_gli_file, setUpload_gli_file] = React.useState("");
  // const [upload_wc_file, setUpload_wc_file] = React.useState("");

  const [inputs, setInputs] = React.useState({
    company_name: "",
    address: "",
    phone_number: "",
    email: "",
    password: "",
    state: "",
    source: "facebook",
    ca_number: "",
    dot_number: "",
    mtr_number: "",
    personal_liability_insurance: "",
    personal_damage_insurance: "",
    cargo_policy_insurance: "",
    general_liability_insurance: "",
    workers_compensation: "",
  });
  //for set error messages
  const [company_name_err, set_company_name_err] = React.useState("");
  const [address_err, set_address_err] = React.useState("");
  const [phone_number_err, set_phone_number_err] = React.useState("");
  const [email_err, set_email_err] = React.useState("");
  const [password_err, set_password_err] = React.useState("");
  const [ca_err, set_ca_err] = React.useState("");
  const [dot_err, set_dot_err] = React.useState("");
  const [mtr_err, set_mtr_err] = React.useState("");
  const [pli_err, set_pli_err] = React.useState("");
  const [pdi_err, set_pdi_err] = React.useState("");
  const [cpi_err, set_cpi_err] = React.useState("");
  const [gli_err, set_gli_err] = React.useState("");
  const [wc_err, set_wc_err] = React.useState("");
  ////////////////////////

  ///////////////////////////////////////////////////////////////////////////error message 2
  const [company_name_err1, set_company_name_err1] = React.useState(true);
  const [address_err1, set_address_err1] = React.useState(true);
  const [phone_number_err1, set_phone_number_err1] = React.useState(true);
  const [email_err1, set_email_err1] = React.useState(true);
  const [state_err1, set_state_err1] = React.useState(true);
  const [password_err1, set_password_err1] = React.useState(true);
  const [ca_err1, set_ca_err1] = React.useState(true);
  const [dot_err1, set_dot_err1] = React.useState(true);
  const [mtr_err1, set_mtr_err1] = React.useState(true);
  //////////////////////////////////////////////////////////////////////////////

  //
  //for validate password strength
  //
  const [passStrength, setPassStrength] = React.useState({
    color: "",
    strength: "",
    level: 0,
  });

  //////////////////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    if (e.target.id === "password") {
      setPassStrength(validatePasswordStrength(e.target.value));
      setInputs({
        ...inputs,
        [e.target.id]: e.target.value.trim(), //space not allowed so trim() every time
      });
      return;
    }
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
  };
  const handleUpload = (event) => {
    console.log(inputs, "handleUpload");

    let uploadId = event.target.id;
    let requestObj = {
      path: "/app_api/upload_s3_file",
      method: "POST",
      headers: {},
    };
    let formData = new FormData();
    formData.append("upload_file", event.target.files[0]);
    requestObj["postData"] = formData;

    let isDuplicated = false;
    if (
      event.target.value === inputs.personal_liability_insurance ||
      event.target.value === inputs.personal_damage_insurance ||
      event.target.value === inputs.cargo_policy_insurance ||
      event.target.value === inputs.general_liability_insurance ||
      event.target.value === inputs.workers_compensation
    ) {
      console.log("isDuplicated");
      isDuplicated = true;
    }

    ////////////////////////////////////////////////// 1. personal_liability_insurance

    if (uploadId === "personal_liability_insurance") {
      if (isDuplicated && upload_pli === "") {
        setUpload1("error");
        return;
      } else if (isDuplicated && upload_pli !== "") {
        set_pli_err("You can't upload same file again");
        return;
      } else {
        set_pli_err("");
      }
      setUpload1("uploading");
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          setUpload_pli(res.file_url);
          setUpload1("uploaded");
        } else {
          setUpload1("error");
        }
      });
    }
    //////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////// 2. personal_damage_insurance

    if (uploadId === "personal_damage_insurance") {
      if (isDuplicated && upload_pdi === "") {
        setUpload2("error");
        return;
      } else if (isDuplicated && upload_pdi !== "") {
        set_pdi_err("You can't upload same file again");
        return;
      } else {
        set_pdi_err("");
      }
      setUpload2("uploading");
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          setUpload_pdi(res.file_url);
          setUpload2("uploaded");
        } else {
          setUpload2("error");
        }
      });
    }
    //////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////// 3. cargo_policy_insurance

    if (uploadId === "cargo_policy_insurance") {
      if (isDuplicated && upload_cpi === "") {
        setUpload3("error");
        return;
      } else if (isDuplicated && upload_cpi !== "") {
        set_cpi_err("You can't upload same file again");
        return;
      } else {
        set_cpi_err("");
      }
      setUpload3("uploading");
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          setUpload_cpi(res.file_url);
          setUpload3("uploaded");
        } else {
          setUpload3("error");
        }
      });
    }
    //////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////// 4. general_liability_insurance

    if (uploadId === "general_liability_insurance") {
      if (isDuplicated && upload_gli === "") {
        setUpload4("error");
        return;
      } else if (isDuplicated && upload_gli !== "") {
        set_gli_err("You can't upload same file again");
        return;
      } else {
        set_gli_err("");
      }
      setUpload4("uploading");
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          setUpload_gli(res.file_url);
          setUpload4("uploaded");
        } else {
          setUpload4("error");
        }
      });
    }
    //////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////// 5. workers_compensation

    if (uploadId === "workers_compensation") {
      if (isDuplicated && upload_wc === "") {
        setUpload5("error");
        return;
      } else if (isDuplicated && upload_wc !== "") {
        set_wc_err("You can't upload same file again");
        return;
      } else {
        set_wc_err("");
      }
      setUpload5("uploading");
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          setUpload_wc(res.file_url);
          setUpload5("uploaded");
        } else {
          setUpload5("error");
        }
      });
    }
    //////////////////////////////////////////////////////////////

    setInputs({
      ...inputs,
      [event.target.id]: event.target.value,
    });
  };

  const alphaNumeric = (txt) => {
    var letterNumber = /^[a-zA-Z0-9 ]+$/;
    return letterNumber.test(txt);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validString = (string) => {
    const re = /^[a-z A-Z]+$/;
    return re.test(string);
  };

  const handleStep1 = () => {
    let isValid = true;
    //company name validation
    if (inputs.company_name === "") {
      set_company_name_err1(false);
      // set_company_name_err(" * Company name is required.");
      isValid = false;
    } else if (inputs.company_name.length < 3) {
      set_company_name_err1(false);
      set_company_name_err(
        " * Company name must have a minimum  3 characters."
      );

      isValid = false;
    } else if (
      !alphaNumeric(inputs.company_name) ||
      !isNaN(inputs.company_name) ||
      !validString(inputs.company_name)
    ) {
      set_company_name_err1(false);
      set_company_name_err(" * Company name must be a valid string.");
      isValid = false;
    } else {
      set_company_name_err1(true);
      set_company_name_err("");
    }

    //address validation
    if (inputs.address === "") {
      set_address_err1(false);
      // set_address_err(" * Address is required.");
      isValid = false;
    } else if (inputs.address.length < 3) {
      set_address_err1(false);
      set_address_err(" * Address must have a minimum  3 characters.");
      isValid = false;
    } else if (!isNaN(inputs.address)) {
      set_address_err1(false);
      set_address_err(" * Address must be a valid string.");
      isValid = false;
    } else {
      set_address_err1(true);
      set_address_err("");
    }

    //phone number validation
    if (inputs.phone_number === "") {
      set_phone_number_err1(false);
      // set_phone_number_err(" * Phone number is required.");
      isValid = false;
    } else if (inputs.phone_number <= 0) {
      set_phone_number_err1(false);
      set_phone_number_err(" * Please enter a valid phone number.");
      isValid = false;
    } else if (inputs.phone_number[13] === "_") {
      set_phone_number_err1(false);
      set_phone_number_err(" * Please enter a valid phone number.");
      isValid = false;
    } else {
      set_phone_number_err1(true);
      set_phone_number_err("");
    }

    //email validation
    if (inputs.email === "") {
      set_email_err1(false);
      // set_email_err(" * Email is required.");
      isValid = false;
    } else if (!validateEmail(inputs.email)) {
      set_email_err1(false);
      set_email_err(" * Please enter a valid email address.");
      isValid = false;
    } else {
      set_email_err1(true);
      set_email_err("");
    }

    //password validation
    if (inputs.password === "") {
      set_password_err1(false);
      // set_password_err(" * Password is required.");
      isValid = false;
    } else if (inputs.password.length < 8) {
      set_password_err1(false);
      set_password_err(" * Minimum 8 characters required for password");
      isValid = false;
    } else if (inputs.password.length > 40) {
      set_password_err1(false);
      set_password_err(" * Password should not be greater the 40.");
      isValid = false;
    } else if (passStrength.level < 3) {
      set_password_err1(false);
      set_password_err(
        "Please enter a strong password including UPPER/lowercase, numbers and special characters."
      );
      isValid = false;
    } else {
      set_password_err1(true);
      set_password_err("");
    }

    //email validation
    if (inputs.state === "") {
      set_state_err1(false);
      isValid = false;
    } else {
      set_state_err1(true);
    }

    setErrMessage("");

    //check flag
    if (isValid) {
      let requestObj = {
        path: "/app_api/validateEmailandPhone",
        method: "POST",
        headers: {},
      };
      let postData = {
        phone: inputs.phone_number,
        email: inputs.email,
      };
      requestObj["postData"] = postData;
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          set_phone_number_err("");
          set_email_err("");
        } else {
          if (res.message === "The email you entered is already Exits") {
            set_email_err("The email you entered is already exist");
          } else set_phone_number_err(res.message);
          isValid = false;
        }
        if (isValid) {
          handleNext();
        }
      });
    }
  };

  ////////////////////////////////////step 2
  const handleStep2 = () => {
    let isValid = true;

    //ca number validation
    if (inputs.ca_number === "") {
      set_ca_err1(false);
      // set_ca_err(" * CA Number is required.");
      isValid = false;
    } else if (inputs.ca_number <= 0) {
      set_ca_err1(false);
      set_ca_err(" * Please enter a valid CA Number.");
      isValid = false;
    } else if (inputs.ca_number.length < 6 || inputs.ca_number.length > 7) {
      set_ca_err1(false);
      set_ca_err(" * CA Number must have 6 or 7 digits.");
      isValid = false;
    } else {
      set_ca_err1(true);
      set_ca_err("");
    }

    //dot number validation

    // if (inputs.dot_number === "") {
    //   set_dot_err1(false);
    //   // set_dot_err(" * DOT Number is required.");
    //   isValid = false;
    // } else if (inputs.dot_number <= 0) {
    //   set_dot_err1(false);
    //   set_dot_err(" * Please enter a valid DOT Number.");
    //   isValid = false;
    // } else if (inputs.dot_number.length !== 7) {
    //   set_dot_err1(false);
    //   set_dot_err(" * DOT Number must have 7 digits.");
    //   isValid = false;
    // } else {
    //   set_dot_err1(true);
    //   set_dot_err("");
    // }

    //mtr number validation
    // if (inputs.mtr_number === "") {
    //   set_mtr_err1(false);
    //   // set_mtr_err(" * MTR Number is required.");
    //   isValid = false;
    // } else if (inputs.mtr_number <= 0) {
    //   set_mtr_err1(false);
    //   set_mtr_err(" * Please enter a valid MTR Number.");
    //   isValid = false;
    // } else if (inputs.mtr_number.length < 6 || inputs.mtr_number.length > 7) {
    //   set_mtr_err1(false);
    //   set_mtr_err(" * MTR Number must have 6 or 7 digits.");
    //   isValid = false;
    // } else {
    //   set_mtr_err1(true);
    //   set_mtr_err("");
    // }

    //chaeck flag
    if (isValid) {
      handleNext();
    }
  };

  ////////////////////////////////////step 2
  const handleStep3 = (e) => {
    let isValid = true;

    // if (upload_pli === "") {
    //   set_pli_err("This file is required");
    //   isValid = false;
    // } else {
    //   set_pli_err("");
    // }
    // if (upload_pdi === "") {
    //   set_pdi_err("This file is required");
    //   isValid = false;
    // } else {
    //   set_pdi_err("");
    // }
    // if (upload_cpi === "") {
    //   set_cpi_err("This file is required");
    //   isValid = false;
    // } else {
    //   set_cpi_err("");
    // }

    if (isValid) {
      // handleSubmit(e);
      handleNext();
      SendCodeOnPhone();
    }
  };

  const handleSubmit = () => {
    let requestObj = {
      path: "/api/company/register_company",
      method: "POST",
      headers: {},
    };
    let postData = {
      name: inputs.company_name.trim(),
      email: inputs.email.trim(),
      phone: inputs.phone_number,
      password: inputs.password,
      address: inputs.address.trim(),
      state: inputs.state,
      how_found_us: inputs.source,
      ca_number: inputs.ca_number,
      dot_number: inputs.dot_number,
      mtr_number: inputs.mtr_number,
      pli_file_path: upload_pli,
      pdi_file_path: upload_pdi,
      cpi_file_path: upload_cpi,
      gli_file_path: upload_gli,
      wc_file_path: upload_wc,
    };
    requestObj["postData"] = postData;
    console.log(postData, "=>>>");
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setErrMessage("");
        setCurrentScreen("DONE");
        setIsLoading(false);
      } else {
        console.log(res);
        setErrMessage(res.message);
        setIsLoading(false);
        //showErrorSnak(res.message);
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <h5 style={{ marginTop: 60 }} className="text-center ">
            Sign Up
          </h5>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              {errMessage && (
                <Alert variant="filled" severity="error">
                  {errMessage}
                </Alert>
              )}
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>

          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>

        <Grid item xs={0} md={2}></Grid>
        {/* button */}
        <Container
          component="main"
          maxWidth={activeStep === 2 || activeStep === 3 ? "md" : "xs"}
        >
          {/* //for screen 0 */}
          {activeStep === 0 && (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div style={{ marginBottom: 60 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                  style={{ background: "none" }}
                >
                  Back
                </Button>

                <Button
                  className="float-right "
                  style={{
                    background: "none",
                    boxShadow: "none",
                    color: "#c4314b",
                  }}
                  color="primary"
                  onClick={handleStep1}
                  // endIcon={<ArrowForwardIosIcon />}
                >
                  {" Next >"}
                </Button>
              </div>
            </div>
          )}

          {/* for step screen 1 */}

          {activeStep === 1 && (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div style={{ marginBottom: 60 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                  style={{ background: "none" }}
                >
                  Back
                </Button>

                <Button
                  className="float-right "
                  style={{
                    background: "none",
                    boxShadow: "none",
                    color: "#c4314b",
                  }}
                  color="primary"
                  onClick={handleStep2}
                  // endIcon={<ArrowForwardIosIcon />}
                >
                  {"Next >"}
                </Button>
              </div>
            </div>
          )}

          {/* //for step screen 2 */}

          {activeStep === 2 && (
            <div>
              <Grid container>
                <Grid item xs={0} md={1} style={{ marginRight: 50 }}></Grid>
                <Grid item xs={10} style={{ maxWidth: 700 }}>
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </Typography>
                  <div style={{ marginBottom: 60 }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                      style={{ background: "none" }}
                    >
                      Back
                    </Button>

                    <Button
                      className="custom-next-btn-my float-right"
                      variant="contained"
                      style={{
                        background: "none",
                        boxShadow: "none",
                        color: "#c4314b",
                        marginRight: 15,
                      }}
                      color="primary"
                      onClick={handleStep3}
                      // endIcon={<ArrowForwardIosIcon />}
                    >
                      {upload1 !== "uploading" &&
                        upload2 !== "uploading" &&
                        upload3 !== "uploading" &&
                        upload4 !== "uploading" &&
                        upload5 !== "uploading" &&
                        "Next >"}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={0} md={1}></Grid>
              </Grid>
            </div>
          )}

          {/* //for step screen 3 */}
          {activeStep === 3 && (
            <div>
              <Grid container justify="center">
                <Grid item xs={0} md={1}></Grid>
                <Grid
                  item
                  xs={10}
                  style={{ maxWidth: 700 }}
                  className="text-center"
                >
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </Typography>
                  {currentScreen === "DONE" && (
                    <div style={{ marginBottom: 60 }}>
                      <Button
                        className="custom-next-btn-my"
                        variant="contained"
                        style={{
                          background: "none",
                          boxShadow: "none",
                          color: "#c4314b",
                          marginRight: 15,
                        }}
                        color="primary"
                        onClick={handleNext}
                        // endIcon={<ArrowForwardIosIcon />}
                      >
                        {"Finish"}
                      </Button>
                    </div>
                  )}
                </Grid>
                <Grid item xs={0} md={1}></Grid>
              </Grid>
            </div>
          )}

          {/* //for last step */}
          {activeStep === steps.length && (
            <div>
              <Redirect to="/login"></Redirect>
            </div>
          )}
        </Container>
      </Grid>
    </div>
  );
}
