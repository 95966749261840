import React, { useEffect } from "react";
import { useState } from "react";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import { useSnakbar } from "../../../../../../Context/Context-Snakbar/ContextSnackbar";

function LeadSource(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const [isChanged, setIsChanged] = useState(false);
  const [leadSource, setLeadSource] = useState(props.lead_source);
  const [leadSourceOptions, setLeadSourceOptions] = useState([
    "Facebook",
    "Friend",
    "fb",
  ]);

  const handleCancel = () => {
    console.log(
      props.lead_source ? props.lead_source : "",
      "props.lead_source"
    );
    setLeadSource(props.lead_source ? props.lead_source : "");
    setIsChanged(false);
  };

  const handleChange = (e) => {
    setLeadSource(e.target.value);
    setIsChanged(true);
  };

  const handleSubmit = () => {
    let requestObj = {
      path: "/api/company/add_lead_source_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      request_id: `${props.request.id}`,
      lead_source: `${leadSource}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showSuccessSnak("Lead source updated successfully.");
        setIsChanged(false);
        props.fetchRequestDetails();
      } else {
      }
    });
  };

  const handleGetLeads = () => {
    let requestObj = {
      path: "/api/company/get_lead_source_text",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      console.log(res, "handleGetLeads");
      if (res.code === 200) {
        if (res.lead_source_text && JSON.parse(res.lead_source_text).length > 0) {
          setLeadSourceOptions(
            res.lead_source_text ? JSON.parse(res.lead_source_text) : []
          );
        } else {
          setLeadSourceOptions([
            "Google Maps",
            "Google Search",
            "Yelp",
            "Postcard",
            "Used your service already",
            "Facebook",
            "Instagram",
            "Recommendation",
            "Saw Your Truck",
            "Bing",
            "Other",
          ]);
        }
      } else {
      }
    });
  };

  useEffect(() => {
    handleGetLeads();
  }, []);

  return (
    <div className="info-detail-wrapper p-3 pt-20">
      <div className="row">
        <div className="col-12">
          {/* <label htmlFor="distance" className="overline dark-grey-color">
            Lead Source
          </label> */}
          <select
            name=""
            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
            id="choose_move_size"
            value={leadSource}
            onChange={(e) => handleChange(e)}
          >
            {!leadSource && (
              <option value="">
                Please choose lead source for this request
              </option>
            )}
            {leadSourceOptions.map((op, i) => (
              <option key={i} value={op}>
                {op}
              </option>
            ))}
          </select>
        </div>
        {isChanged && (
          <div className="col-12 mt-3 justify-content-center d-flex">
            <button
              onClick={handleSubmit}
              className="custom-btn-small custom-buton"
            >
              Update
            </button>
            <button onClick={handleCancel} className="custom-btn-small ml-2">
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default LeadSource;
