import { CircularProgress, TablePagination } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import moment from "moment";
import PaymentsFilter from "../Filter/PaymentsFilter";

function InReview(props) {
  const { showErrorSnak } = props;
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilters] = useState({
    from_date: "",
    to_date: "",
  });

  const [isFilter, setIsFilter] = useState(false);

  //------------------------------------------>  pagination settings

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);

  //----------------------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //////////////////////////////////////////////////////////////////

  const FetchPaymentData = (type) => {
    let requestObj = {
      path: `/api/company/paymet_history_list?page=${page}&limit=${rowsPerPage}`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {};
    if (type === "filter") {
      setIsFilter(true);
      postData = {
        type: "2",
        status: "1",
        from_date:
          filter.from_date === ""
            ? ""
            : moment(filter.from_date).format("MM-DD-YYYY"),
        to_date:
          filter.to_date === ""
            ? ""
            : moment(filter.to_date).format("MM-DD-YYYY"),
      };
    } else {
      setIsFilter(false);
      //if type is undefined
      //we are not passing filters in API
      postData = {
        type: "2",
        status: "0",
      };
    }

    console.log(postData, "POSTDATA");
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        setData(res.payment_history_list);
        setTotalCount(res.count);
        setIsLoading(false);
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  useEffect(() => {
    if (isFilter) {
      FetchPaymentData("filter");
    } else {
      FetchPaymentData();
    }
  }, [page, rowsPerPage]);

  const getPaymentButton = (status, with_draw_status) => {
    // in progress
    if (parseInt(status) === 0 && parseInt(with_draw_status) === 0) {
      return (
        <div className="status-buttons">
          <span
            style={{ cursor: "default" }}
            className="custom-btn-small custom-button-extra-small req-status-button filter-button req-cancelled  active"
          >
            In Progress
          </span>
        </div>
      );
    }

    // in Review
    if (parseInt(status) === 1 && parseInt(with_draw_status) === 0) {
      return (
        <div className="status-buttons">
          <span
            style={{ cursor: "default" }}
            className="custom-btn-small custom-button-extra-small req-status-button req-active active"
          >
            In Review
          </span>
        </div>
      );
    }

    // Available for withdraw
    if (parseInt(status) === 1 && parseInt(with_draw_status) === 1) {
      return (
        <div className="status-buttons">
          <span
            style={{ cursor: "default" }}
            className="custom-btn-small custom-button-extra-small req-status-button filter-button req-done active"
          >
            Available
          </span>
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="rounded-box-payment pb-40">
          <div className="row"></div>
          <div className="Circular-Progress mb-40 pb-40">
            <CircularProgress />
          </div>
        </div>
      </>
    );
  }

  if (window.innerWidth < 500) {
    return (
      <>
        <div className="rounded-box-payment pb-3">
          <div className="row">
            <div className="col-12">
              <table
                id="example"
                className="responsive table table-hover"
                style={{ width: "100%" }}
              >
                <thead>
                  <th className="small-sub-title dark-grey-color">
                    <div className="row">
                      <div className="col-6 small-sub-title dark-grey-color">
                        In Review Payments
                      </div>
                      <div className="col-6">
                        {/* #{request.request_data.id} */}
                      </div>
                    </div>
                  </th>
                </thead>
                <tbody>
                  {data.map((payment, i) => (
                    <tr key={i} className="pointer-point border-bottom">
                      <div className="row pl-2">
                        <div className="col-4 small-sub-title dark-grey-color">
                          Request No.
                        </div>
                        <div className="col-8 small-sub-title dark-grey-color">
                          #{payment.request_id}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Start date:</span>
                        </div>
                        <div className="col-8">
                          {payment.s_date}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Start time:</span>
                        </div>
                        <div className="col-8">
                          {payment.schedule_time.slice(
                            11,
                            payment.schedule_time.length
                          )}
                        </div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Request Summ:</span>
                        </div>
                        <div className="col-8">${payment.final_amount}</div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Fee:</span>
                        </div>
                        <div className="col-8">${payment.fee}</div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Amount:</span>
                        </div>
                        <div className="col-8">${payment.amount}</div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Last update:</span>
                        </div>
                        <div className="col-8">
                          <span className="light-grey-color extra-small-font-size">
                            Updated {moment(payment.updated_at).fromNow()}
                          </span>
                        </div>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={
                  <span className="pagination-text">Rows:</span>
                }
              />
            </div>
          </div>
        </div>
        <PaymentsFilter
          FetchPaymentData={FetchPaymentData}
          filter={filter}
          setFilters={setFilters}
          setPage={setPage}
        />
      </>
    );
  }

  return (
    <>
      <div className="rounded-box-payment pb-3">
        <div className="row">
          <div className="col-12">
            <table
              id="example"
              className="responsive table table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="small-sub-title dark-grey-color">
                    Request number
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Date & Time
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Request Summ
                  </th>
                  <th className="small-sub-title dark-grey-color">Fee</th>
                  <th className="small-sub-title dark-grey-color">Amount</th>
                  <th className="small-sub-title dark-grey-color">Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((payment, i) => (
                  <tr key={i}>
                    <td>
                      #{payment.request_id}
                      <br />
                      <span className="light-grey-color extra-small-font-size">
                        Updated {moment(payment.updated_at).fromNow()}
                      </span>
                    </td>
                    <td>{payment.schedule_time}</td>
                    <td>${payment.final_amount}</td>
                    <td>${payment.fee}</td>
                    <td>${payment.amount}</td>
                    <td>
                      {getPaymentButton(
                        payment.status,
                        payment.with_draw_status
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={<span className="pagination-text">Rows:</span>}
            />
          </div>
        </div>
      </div>
      <PaymentsFilter
        FetchPaymentData={FetchPaymentData}
        filter={filter}
        setFilters={setFilters}
        setPage={setPage}
      />
    </>
  );
}

export default InReview;
