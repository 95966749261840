import { CircularProgress, Divider } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { editImage } from "../../../../assets";
import { invokeApi } from "../../../../bl_libs/invokeApi";

function CurrentSubscription(props) {
  const { userSubscription, isLoading, CancelSubscription } = props;
  if (isLoading) {
    return (
      <>
        <div className="text-center">
          <CircularProgress />
        </div>
      </>
    );
  }

  if (!userSubscription.subscription_status) {
    return (
      <>
        <div className="row">
          <div className="col-12 text-center">
            <button
              onClick={() => {
                props.history.push("/subscriptions-plan");
              }}
              className="custom-btn-small red-btn"
            >
              Choose a plan
            </button>
          </div>
        </div>
      </>
    );
  }

  if (userSubscription?.subscription_obj.subscription_id === "free trial") {
    return (
      <div>
        <div className="rounded-box">
          <div className="row">
            <div className="col-12">
              <table id="example" className=" table " style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="small-sub-title dark-grey-color">
                      Subscription Plan
                    </th>

                    <th className="small-sub-title dark-grey-color">
                      Subscription Amount
                    </th>

                    <th className="small-sub-title dark-grey-color">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-capitalize">
                      {/* {userSubscription?.subscription_obj.subscription_id} */}
                      2 Months Free Trial
                    </td>
                    <td>$0</td>
                    <td>
                      <div className="dropdown dropleft">
                        <img
                          className="dropdown-toggle pointer-point"
                          data-toggle="dropdown"
                          src={editImage}
                        />
                        <div
                          className="dropdown-menu "
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            className="dropdown-item"
                            style={{
                              color: "#747474",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={CancelSubscription}
                          >
                            Cancel Subscription
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const { amount, interval, currency } = userSubscription.plan;
  return (
    <div>
      <div className="rounded-box">
        <div className="row">
          <div className="col-12">
            <table id="example" className=" table " style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="small-sub-title dark-grey-color">
                    Subscription Plan
                  </th>

                  <th className="small-sub-title dark-grey-color">
                    Subscription Amount
                  </th>

                  <th className="small-sub-title dark-grey-color">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-capitalize">{interval}</td>
                  <td>
                    {" "}
                    ${amount / 100}/{interval}
                  </td>
                  <td>
                    <div className="dropdown dropleft">
                      <img
                        className="dropdown-toggle pointer-point"
                        data-toggle="dropdown"
                        src={editImage}
                      />
                      <div
                        className="dropdown-menu "
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item"
                          style={{
                            color: "#747474",
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                          onClick={CancelSubscription}
                        >
                          Cancel Subscription
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CurrentSubscription);
