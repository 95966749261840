import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FullCalendar from "./components/FullCalender";
import {
  cancelledIconImage,
  activeIconImage,
  doneTickImage,
} from "../../assets";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import RequestsSlider from "./components/RequestsSlider";
import GoogleAuth from "./components/GoogleAuth";
import CalenderSettings from "./components/CalenderSetting";
import { invokeApi } from "../../bl_libs/invokeApi";

function Calender() {
  const [calenderSettings, setCalenderSettings] = React.useState({});
  const [isConnect, setIsConnect] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [tab, setTab] = React.useState("ACTIVE");

  const [date, setDate] = useState("");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const anchor = "right";

  const hadleSliderOpen = (date, tab) => {
    if (tab) {
      setTab(tab);
    } else {
      setTab("ACTIVE");
    }
    setDate(date);
    setState({ ...state, right: true });
  };

  const get_calender_settings = () => {
    let requestObj = {
      path: "/api/company/get_calendar_setting",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      console.log(res, "get_calender_settings");
      if (res.code === 200) {
        setCalenderSettings(res.data.check_google_account);
        setIsConnect(res.data.sync_status);
      } else {
      }
    });
  };

  const connect_with_google = (code) => {
    let requestObj = {
      path: "/api/company/add_google_account",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    requestObj["postData"] = {
      code: code,
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        get_calender_settings();
      } else {
      }
    });
  };

  useEffect(() => {
    get_calender_settings();
  }, []);

  return (
    <>
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 calender-screen">
              <p className="small-text-bold page-title ml-2 pt-4">Calender</p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 pb-40">
              {/* main card */}
              <div>
                <Container>
                  <div className="row">
                    <div className="col-12 text-right">
                      {isConnect ? (
                        <CalenderSettings
                          isConnect={isConnect}
                          calenderSettings={calenderSettings}
                          connect_with_google={connect_with_google}
                          get_calender_settings={get_calender_settings}
                        />
                      ) : (
                        <GoogleAuth connect_with_google={connect_with_google} />
                      )}
                    </div>
                    <div className="col-12">
                      <FullCalendar hadleSliderOpen={hadleSliderOpen} />
                    </div>
                    <div className="col-12 text-right pt-3 pb-4">
                      <span className="done pr-2">Done</span>
                      <span>
                        <img className="mr-2" src={doneTickImage} />
                      </span>
                      <span className="active pr-2">Active</span>
                      <span>
                        <img className="mr-2" src={activeIconImage} />
                      </span>
                      <span className="cancelled pr-2">Cancelled</span>
                      <span>
                        <img className="mr-2" src={cancelledIconImage} />
                      </span>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </>

      <SwipeableDrawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        <RequestsSlider
          toggleDrawer={toggleDrawer}
          date={date}
          tab={tab}
          setTab={setTab}
        />
      </SwipeableDrawer>
    </>
  );
}

export default Calender;
