import React from "react";
import { Chart } from "react-google-charts";
function BarChart() {
  return (
    <div>
      <Chart
        width={"100%"}
        height={"400px"}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ["Pizza", "Popularity"],
          ["Market Place", 33],
          ["CRM", 26],
        ]}
        options={{
          title: "",
          sliceVisibilityThreshold: 0.2, // 20%
        }}
        rootProps={{ "data-testid": "7" }}
      />
    </div>
  );
}

export default BarChart;
