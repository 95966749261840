import { invokeApi } from "../../bl_libs/invokeApi";

export const _sales_team_listing = async (postData) => {
  let requestObj = {
    path: "/api/company/get_sale_team",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  requestObj["postData"] = postData;
  return invokeApi(requestObj);
};
