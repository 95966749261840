import React, { useState } from "react";

function AreaNotFound(props) {
  const { zip, email, AddInWishList } = props;
  const [inputs, setInputs] = useState({
    zip,
    email,
  });

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    AddInWishList(inputs);
  };

  return (
    <>
      <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
        <div className="modal-body p-0">
          <h6 id="area-not-found-title">
            We are not in your area yet. But will be there very soon, please
            share with us your Zipcode and Email and will let you know once
            there.
          </h6>
          <div className="form-box">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="password" className="overline dark-grey-color">
                  Selected Address ZipCode
                </label>
                <input
                  type="text"
                  className="form-control small-font-size"
                  id="zip"
                  placeholder="Zip code"
                  value={inputs.zip}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="re-enter-password"
                  className="overline dark-grey-color"
                >
                  Your Email
                </label>
                <input
                  type="email"
                  className="form-control small-font-size"
                  id="email"
                  placeholder="Enter your email"
                  value={inputs.email}
                  onChange={handleChange}
                  disabled
                  required
                />
              </div>
              <div className="form-group pt-3">
                <button
                  type="submit"
                  className="red-btn custom-buton custom-buton-large w-100"
                >
                  Share
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AreaNotFound;
