import React from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { invokeApi } from "../../bl_libs/invokeApi";
import { getStatusForCompany } from "../../ui_libs/Functions/functions";

function SearchableDropdown(props) {
  const handleRoute = (request) => {
    props.history.push("/company/direct-requests/" + request.id);
  };

  const handleInputChange = (newValue) => {
    // const inputValue = newValue.replace(/\W/g, "");
    return newValue;
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="row border-bottom w-100 p-0 m-0 search-menu"
        onClick={() => handleRoute(data.request)}
      >
        <div className="col-2">#{data.request.id}</div>
        <div className="col-3">
          {data.request.customer_first_name +
            " " +
            data.request.customer_last_name}
        </div>
        <div className="col-4">{data.request.schedule_time}</div>
        <div className="col-3 text-center">
          {data.request.request_status === 0 && (
            <span className="active-text-color">Active</span>
          )}

          {data.request.request_status === 1 && (
            <span className="done-text-color">Done</span>
          )}

          {data.request.request_status === 2 && (
            <span className="cancelled-text-color">Cancelled</span>
          )}
        </div>
      </div>
    );
  };

  const loadOptions = (input, callback) => {
    let requestObj = {
      path: "/app_api/get_request_suggestion_on_search",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${input}`,
    };

    if (!input) {
      callback([]);
      return;
    }

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        const data = res.request_data.map((req) => {
          return { label: `${req.id}`, value: req.id, request: req };
        });
        callback(data);
      } else {
      }
    });
  };

  return (
    <>
      <div>
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          placeholder="Search..."
          components={{ Option: CustomOption }}
          onInputChange={handleInputChange}
          select={() => console.log("object")}
        />
      </div>
    </>
  );
}

export default withRouter(SearchableDropdown);
