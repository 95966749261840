import React from "react";
import moment from "moment";
import {
  companyProfilePicImage,
  startRatingImage,
} from "../../../../../assets";
import { s3baseUrl } from "../../../../../bl_libs/invokeApi";
import { Rating } from "@material-ui/lab";
import { Avatar } from "@material-ui/core";
function ReplyComment(props) {
  const { reply, company_rating, customer_rating } = props;
  const { comment, updated_at, sender_type } = reply.child_comment;
  const { name, profile_url } = reply.child_comment_profile;
  return (
    <>
      <div className="reply-box">
        <div className="row justify-content-end">
          <div className="col-11">
            <div className="comment-box">
              <div className="row">
                <div className="col-3 col-sm-1">
                  <div className="profile-pic-small">
                    {profile_url ? (
                      <img
                        src={s3baseUrl + profile_url}
                        className="rounded-circle circle-box circle-box-small img-46"
                      />
                    ) : (
                      <Avatar>{name[0].toUpperCase()}</Avatar>
                    )}
                  </div>
                </div>
                <div className="col-9 col-sm-11">
                  <div className="sub-title">
                    <span className="pr-2 align-middle pl-md-3 pl-xl-0">
                      {name}{" "}
                    </span>
                    {/* <img src={startRatingImage} /> */}
                    <Rating
                      className="align-bottom"
                      value={
                        sender_type === 0
                          ? reply.child_comment_profile.company_rating
                          : reply.child_comment_profile.customer_rating
                      }
                      precision={0.1}
                      readOnly
                    />
                  </div>
                  <div className="request-date-line caption light-grey-color pt-1 pb-3">
                    <div className="row">
                      <div className="col-12  pl-sm-0 pl-md-4 pl-xl-3">
                        {moment(updated_at).format("LLL")}
                      </div>
                    </div>
                  </div>
                  <div className="small-font-size">
                    <p className="mb-2">{comment}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReplyComment;
