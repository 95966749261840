import { CircularProgress, TablePagination } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { invokeApi } from "../../../bl_libs/invokeApi";
import SearchFilter from "./SearchFilter";
import moment from "moment";
function SearchList(props) {
  const [requests, SetRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [moveSizeList, setMoveSizeList] = useState([]);

  const [filters, setFilters] = useState({
    from_date: "",
    to_date: "",
    distance: "",
    move_type: "",
    price: "",
    zip_code: "",
    range: [0, 0],
  });

  const [isFilter, setIsFilter] = useState(false);

  //------------------------------------------>  pagination settings

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);

  //----------------------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //////////////////////////////////////////////////////////////////

  const fetchSearchList = (type) => {
    let requestObj = {
      path: `/api/company/get_all_request?page=${page}&limit=${rowsPerPage}`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData;
    if (type === "filter") {
      setIsFilter(true);
      postData = {
        filter_status: "0",
        from_date:
          filters.from_date === ""
            ? ""
            : moment(filters.from_date).format("MM-DD-YYYY"),
        to_date:
          filters.to_date === ""
            ? ""
            : moment(filters.to_date).format("MM-DD-YYYY"),
        min_distance:
          filters.distance === "" ? "" : filters.distance.split("-")[0],
        max_distance:
          filters.distance === "" ? "" : filters.distance.split("-")[1],
        move_type: filters.move_type,
        price: filters.price,
        // zip_code: filters.zip_code,
        zip_code: filters.zip_code,
        distance: filters.range[1].toString(),
      };
    } else {
      setIsFilter(false);
      postData = {
        filter_status: "",
        from_date: "",
        to_date: "",
        min_distance: "",
        max_distance: "",
        move_type: "",
        price: "",
        zip_code: "",
      };
    }

    setIsLoading(true);
    console.log(postData, "FILTER POST DATA");
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        SetRequests(res.all_request_data);
        setTotalCount(res.get_request_count);
        setIsLoading(false);
      }
    });
  };

  const fetchListForMoverType = () => {
    let requestObj = {
      path: "/api/customer/get_move_type",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "MOVE SIZE RESPONSCE");
      if (res.code === 200) {
        setMoveSizeList(res.data.moveTypeItems);
      }
    });
  };

  useEffect(() => {
    if (isFilter) {
      fetchSearchList("filter");
    } else {
      fetchSearchList();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchListForMoverType();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress ">
          <CircularProgress />
        </div>
      </>
    );
  }
  // if (requests.length === 0) {
  //   return <div>No request found</div>;
  // }

  if (window.innerWidth < 500) {
    console.log(window.innerWidth, "Width");
    return (
      <>
        <div className="rounded-box pb-3">
          <div className="row">
            <div className="col-12">
              <table
                id="example"
                className="responsive table table-hover"
                style={{ width: "100%" }}
              >
                <thead>
                  <th className="small-sub-title dark-grey-color">
                    <div className="row">
                      <div className="col-6 small-sub-title dark-grey-color">
                        Search Requests
                      </div>
                      <div className="col-6">
                        {/* #{request.request_data.id} */}
                      </div>
                    </div>
                  </th>
                </thead>
                <tbody>
                  {requests.map((request, i) => (
                    <tr
                      key={request.request_data.id}
                      className="pointer-point border-bottom"
                      onClick={() =>
                        props.history.push(
                          "/company/search/" + request.request_data.id
                        )
                      }
                    >
                      <div className="row pl-2">
                        <div className="col-4 small-sub-title dark-grey-color">
                          Request No.
                        </div>
                        <div className="col-8 small-sub-title dark-grey-color">
                          #{request.request_data.id}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Start date:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.s_date}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Start time:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.schedule_time.slice(
                            11,
                            request.request_data.schedule_time.length
                          )}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Location A:</span>
                        </div>
                        <div className="col-8">
                          {request.request_address.length > 0
                            ? request.request_address[0].zip_code
                            : "N/A"}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Location B:</span>
                        </div>
                        <div className="col-8">
                          {request.request_address.length > 1
                            ? request.request_address[1].zip_code
                            : "N/A"}
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Distance:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.net_distance} mi
                        </div>
                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Move Type:</span>
                        </div>
                        <div className="col-8">
                          {request.request_data.move_type}
                        </div>

                        <div className="col-4 small-sub-title dark-grey-color">
                          <span className="">Price:</span>
                        </div>
                        <div className="col-8">
                          ${request.request_data.customer_budget}
                        </div>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={
                  <span className="pagination-text">Rows:</span>
                }
              />
            </div>
          </div>
        </div>
        <SearchFilter
          filters={filters}
          setFilters={setFilters}
          moveSizeList={moveSizeList}
          setPage={setPage}
          fetchSearchList={fetchSearchList}
        />
      </>
    );
  }

  return (
    <>
      <div className="rounded-box pb-3">
        <div className="row">
          <div className="col-12">
            <table
              id="example"
              className="responsive table table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="small-sub-title dark-grey-color">Date</th>
                  <th className="small-sub-title dark-grey-color">
                    Location A
                  </th>
                  <th className="small-sub-title dark-grey-color">
                    Location B
                  </th>
                  <th className="small-sub-title dark-grey-color">Distance</th>
                  <th className="small-sub-title dark-grey-color">Move Type</th>
                  <th className="small-sub-title dark-grey-color">Price</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request, i) => (
                  <tr
                    key={i}
                    className="pointer-point"
                    onClick={() =>
                      props.history.push(
                        "/company/search/" + request.request_data.id
                      )
                    }
                  >
                    <td>
                      {request.request_data.s_date}
                      <br />
                      {request.request_data.schedule_time.slice(
                        11,
                        request.request_data.schedule_time.length
                      )}
                    </td>
                    <td>
                      {request.request_address.length > 0
                        ? request.request_address[0].zip_code
                        : "N/A"}
                    </td>
                    <td>
                      {" "}
                      {request.request_address.length > 1
                        ? request.request_address[1].zip_code
                        : "N/A"}
                    </td>
                    <td>{request.request_data.net_distance} mi</td>
                    <td>{request.request_data.move_type}</td>
                    <td>${request.request_data.customer_budget}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={
                <span className="pagination-text">Rows per page:</span>
              }
            />
          </div>
        </div>
      </div>
      <SearchFilter
        filters={filters}
        setFilters={setFilters}
        moveSizeList={moveSizeList}
        setPage={setPage}
        fetchSearchList={fetchSearchList}
      />
    </>
  );
}

export default withRouter(SearchList);
