import React from "react";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";

function EmailSended() {
  return (
    <section className="main-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-0 col-md-3 col-xl-4"></div>
          <div className="col-12 col-md-6 col-xl-4">
            <div className="form-box-wrapper">
              <div className="form-box ">
                <Avatar
                  alt=""
                  style={{
                    backgroundColor: "#c43a4b",
                    width: 100,
                    height: 100,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <DoneOutlineIcon />
                </Avatar>

                <h5 className="text-center mt-4">Check your email</h5>
                <div className=" text-center evom-form login-form">
                  You will receive an email with instructions for changing your
                  password.
                </div>
                <div
                  style={{ color: "#c43a4b" }}
                  className="text-center evom-form login-form "
                >
                  <Link to="/login">Returns to Login</Link>
                  {/* <Link
                    to="/forget-password"
                    className="caption dark-grey-color underline-text"
                  >
                    Returns to Login
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-0 col-md-3 col-xl-4"></div>
        </div>
      </div>
    </section>
  );
}

export default EmailSended;
