import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { Container, Divider } from "@material-ui/core";
import { useRef } from "react";
import { useEffect } from "react";

class GoogleMapTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.address.address,
      lat: this.props.address.lat,
      lng: this.props.address.lng,
      zip_code: this.props.address.zip_code,
    };
  }

  handleChange = (address) => {
    this.setState({ ...this.state, address });
    this.props.setAddress({ ...this.props.address, address });
  };

  componentDidMount() {
    setTimeout(() => {
      if (document.getElementById("input-for-search")) {
        document.getElementById("input-for-search").focus();
      }
    }, 500);
  }

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        // console.log(results[0], "result address");

        let temp = { formated_address: results[0].formatted_address };
        results[0].address_components.map((res) => {
          if (res.types[0] === "administrative_area_level_1") {
            temp = {
              ...temp,
              state: { long: res.long_name, short: res.short_name },
            };
          }
          if (res.types[0] === "locality") {
            temp = {
              ...temp,
              city: res.long_name,
            };
          } else
            temp = {
              ...temp,
              [res.types[0]]: res.long_name,
            };
        });
        console.log(temp, "key value");
        //set values in state
        this.setState({
          ...this.state,
          ...temp,
          address: temp.formated_address,
          zip_code: temp.hasOwnProperty("postal_code") ? temp.postal_code : "",
        });

        console.log(this.state, "this state");
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        this.setState({ ...this.state, lat: latLng.lat, lng: latLng.lng });
        this.props.setAddress(this.state);
        console.log("Success", latLng);
        console.log(this.state, "state");
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        highlightFirstSuggestion
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              id="input-for-search"
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "form-control small-font-size",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div></div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#ccc", cursor: "pointer" }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                    };
                // console.log(suggestion, "sug");
                // if (suggestion.description.search("USA") === -1) {
                //   // console.log(suggestion.description.search("USA"), "USA");
                //   return;
                // }
                return (
                  <div
                    key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                    <Divider />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default GoogleMapTracking;

//////////////////////////////////////////////////////////////////////////////////////////////

// import React from "react";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng
// } from "react-places-autocomplete";

// export default function App() {
//   const [address, setAddress] = React.useState("");
//   const [coordinates, setCoordinates] = React.useState({
//     lat: null,
//     lng: null
//   });

//   const handleSelect = async value => {
//     const results = await geocodeByAddress(value);
//     const latLng = await getLatLng(results[0]);
//     setAddress(value);
//     setCoordinates(latLng);
//   };

//   return (
//     <div>
//       <PlacesAutocomplete
//         value={address}
//         onChange={setAddress}
//         onSelect={handleSelect}
//       >
//         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//           <div>
//             <p>Latitude: {coordinates.lat}</p>
//             <p>Longitude: {coordinates.lng}</p>

//             <input {...getInputProps({ placeholder: "Type address" })} />

//             <div>
//               {loading ? <div>...loading</div> : null}

//               {suggestions.map(suggestion => {
//                 const style = {
//                   backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
//                 };

//                 return (
//                   <div {...getSuggestionItemProps(suggestion, { style })}>
//                     {suggestion.description}
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         )}
//       </PlacesAutocomplete>
//     </div>
//   );
// }
