import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { invokeApi } from "../../bl_libs/invokeApi";

import { AddCard, ShowCard } from "./components";
function MyCards() {
  const [cardInputs, setCardInputs] = useState({
    card_number: "",
    card_holder_name: "",
    exp_date: "",
    cvv: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
    isVarified: false,
  });

  const [cards, setCards] = useState([]);
  const [isLoadingCard, setIsLoadingCard] = React.useState(true);

  const fetchCardsList = () => {
    let requestObj = {
      path: "/api/customer/checking_card_exist",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {};
    requestObj["postData"] = postData;
    setIsLoadingCard(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "CARD RESPONSE");
      if (res.code === 200) {
        //store cards in state
        if (res.retrive_stripe_card) {
          setCards(res.retrive_stripe_card.data);
        }
      } else {
        // remain this empty
      }
      setIsLoadingCard(false);
    });
  };

  const handleDeleteCard = () => {
    let requestObj = {
      path: "/api/customer/delete_card",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {};
    requestObj["postData"] = postData;
    setIsLoadingCard(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "CARD RESPONSE");
      if (res.code === 200) {
        fetchCardsList();
        setCards([]);
        setCardInputs({
          card_number: "",
          card_holder_name: "",
          exp_date: "",
          cvv: "",
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          zip: "",
          isVarified: false,
        });
      } else {
        // remain this empty
        setIsLoadingCard(false);
      }
    });
  };

  useEffect(() => {
    fetchCardsList();
  }, []);

  if (isLoadingCard) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">My Cards</p>
            <div className="container-fluid">
              <div className="Circular-Progress">
                <CircularProgress />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return (
    <section id="content-wrapper" className="content-wrapper calendar-page">
      <div className="container-fluid">
        <p className="small-text-bold page-title">My Cards</p>

        <AddCard
          cardInputs={cardInputs}
          setCardInputs={setCardInputs}
          cards={cards}
          fetchCardsList={fetchCardsList}
          isLoadingCard={isLoadingCard}
          setIsLoadingCard={setIsLoadingCard}
          handleDeleteCard={handleDeleteCard}
        />
      </div>
    </section>
  );
}

export default MyCards;
