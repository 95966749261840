import React from "react";
import { editImage } from "../../../../assets";
import { Button, Divider } from "@material-ui/core";
function ExternalBank(props) {
  const { bank, count, setSelectedAccount } = props;
  const { account_holder_name, routing_number, account_number, status } = bank;

  const handleSelector = () => {
    setSelectedAccount(false);
    setTimeout(() => {
      setSelectedAccount(bank);
      console.log("object");
    }, 200);
  };
  return (
    <div className="request-link mt-4">
      <div className="rounded-box p-3 pb-30  ">
        <>
          <div className="req-id-status-wrapper pb-2 pb-sm-3 border-bottom">
            <div className="row">
              <div className="col-8 font-weight-bold">
                External Bank #{count + 1}
              </div>
              <div className="col-4 text-right">
                {status === "0" ? (
                  <div className="dropdown dropleft">
                    <img
                      className="dropdown-toggle pointer-point"
                      data-toggle="dropdown"
                      src={editImage}
                    />
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item"
                        style={{
                          color: "#747474",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        data-target="#defaultBank"
                        data-toggle="modal"
                        onClick={handleSelector}
                      >
                        Set default
                      </a>
                      <Divider />
                      <a
                        className="dropdown-item"
                        style={{
                          color: "#747474",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        data-toggle="modal"
                        data-target="#deletBank"
                        onClick={handleSelector}
                      >
                        Delete
                      </a>
                    </div>
                  </div>
                ) : (
                  <span style={{ color: "#c4314b", fontSize: 12 }}>
                    Default
                  </span>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="request-info pt-2 pt-sm-3 pb-sm-1 ">
              <div className="row req-date-time-info no-gutters pb-2">
                <div className="col-12 col-sm-6 col-md-7 col-lg-7 req-date-time-info-box mb-0">
                  <div className="row no-gutters">
                    <div className="col-12 col-sm-6 pb-2">
                      {/* <img className="pr-2" src={dateIconImage} /> */}
                    </div>
                    <div className="col-12 col-sm-6 pb-2 req-info-text">
                      {/* <img src={timeIconImage} className="pr-2" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="request-detail-price-wrapper">
              <div className="row">
                <div className="col-12 col-md-3">
                  <span className="small-font-size dark-grey-color overline">
                    Account Holder Name :
                  </span>
                </div>{" "}
                <div className="col-12 col-md-3">
                  <span className="font-12">{account_holder_name}</span>
                </div>
                <div className="col-12 col-md-3 ">
                  <span className="small-font-size dark-grey-color overline">
                    Routing Number :
                  </span>
                </div>{" "}
                <div className="col-12 col-md-3">
                  <span className="font-12">{routing_number}</span>
                </div>
                <div className="col-12 col-md-3">
                  <span className="small-font-size dark-grey-color overline">
                    Account Number :
                  </span>
                </div>{" "}
                <div className="col-12 col-md-3">
                  <span className="font-12"> {account_number} </span>
                </div>
              </div>
            </div>
            <div className="request-address-wrapper pt-3 pt-sm-4 ">
              <div className="row"></div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default ExternalBank;
