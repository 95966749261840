import React from "react";
import { editImage } from "../../../../assets";

function ConnectedAccount(props) {
  const { profile, setCurrentScreen } = props;
  const {
    account_holder_name,
    account_holder_type,
    account_number,
    address,
    bank_name,
    business_name,
    city,
    company_id,
    country,
    created_at,
    currency,
    customer,
    day,
    external_id,
    extra_info,
    fingerprint,
    id,
    is_active,
    ssn,
    month,
    postal_code,
    routing_number,
    state,
    status,
    support_address,
    support_city,
    support_email,
    support_phone_number,
    support_postal_code,
    support_state,
    updated_at,
    year,
    name,
    email,
    phone,
  } = profile;
  return (
    <div>
      <div className="request-link mt-4">
        <div className="rounded-box p-3 pb-30  ">
          <>
            <div className="req-id-status-wrapper pb-2 pb-sm-3 border-bottom">
              <div className="row">
                <div className="col-10 font-weight-bold">Connected Account</div>
                <div className="col-2 text-right">
                  <div className="dropdown dropleft">
                    <img
                      className="dropdown-toggle pointer-point"
                      data-toggle="dropdown"
                      src={editImage}
                    />
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item"
                        style={{
                          color: "#747474",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCurrentScreen("EDIT_C_A");
                        }}
                      >
                        Edit Connected Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="request-info pt-2 pt-sm-3 pb-sm-1 ">
                <div className="row req-date-time-info no-gutters pb-2">
                  <div className="col-12 col-sm-6 col-md-7 col-lg-7 req-date-time-info-box mb-0">
                    <div className="row no-gutters">
                      <div className="col-12 col-sm-6 pb-2">
                        {/* <img className="pr-2" src={dateIconImage} /> */}
                      </div>
                      <div className="col-12 col-sm-6 pb-2 req-info-text">
                        {/* <img src={timeIconImage} className="pr-2" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="request-detail-price-wrapper">
                <div className="row">
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      Date of Birth :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    {/* <span className="font-12">{`${day}/${month}/${year} `}</span> */}
                    <span className="font-12">{`**/**/****`}</span>
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      Social Security Number :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{"****"}</span>
                    {/* <span className="font-12">{ssn}</span> */}
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      Address :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{address}</span>
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      City :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{city}</span>
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      State :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{state}</span>
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      Postal Code :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{"******"}</span>
                    {/* <span className="font-12">{postal_code}</span> */}
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      Name :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{name}</span>
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      email :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{email} </span>
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      phone number :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12"> {phone}</span>
                  </div>
                  {/* <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      business name :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{business_name}</span>
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      support state :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{support_state}</span>
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      support postal code :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{support_postal_code}</span>
                  </div>
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      support email :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{support_email}</span>
                  </div>{" "}
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      support phone number :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{support_phone_number}</span>
                  </div>{" "}
                  <div className="col-12 col-md-3">
                    <span className="small-font-size dark-grey-color overline">
                      support address :
                    </span>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="font-12">{support_address}</span>
                  </div> */}
                </div>
              </div>
              <div className="request-address-wrapper pt-3 pt-sm-4 ">
                <div className="row"></div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default ConnectedAccount;
