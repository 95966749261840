import React from "react";

function ClientComment(props) {
  return (
    <div className="info-detail-wrapper p-3 pt-20">
      <div className="row">
        <div className="col-12 small-font-size">
          <p>{props.final_comment}</p>
        </div>
      </div>
    </div>
  );
}

export default ClientComment;
