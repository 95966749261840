import React, { useEffect, useState } from "react";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import NumberFormat from "react-number-format";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import { CircularProgress } from "@material-ui/core";

const re =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function EditBank(props) {
  const { showErrorSnak, showSuccessSnak, account, profile, FetchBankDetails } =
    props;

  const {
    account_holder_name,
    account_holder_type,
    account_number,
    address,
    bank_name,
    business_name,
    city,
    company_id,
    country,
    created_at,
    currency,
    customer,
    day,
    external_id,
    extra_info,
    fingerprint,
    id,
    is_active,
    ssn,
    month,
    postal_code,
    routing_number,
    state,
    status,
    support_address,
    support_city,
    support_email,
    support_phone_number,
    support_postal_code,
    support_state,
    updated_at,
    year,
    name,
    email,
    phone,
  } = profile;

  const [isLoading, setIsLoading] = useState(false);

  const [accountDetails, setAccountDetails] = useState({
    account_holder_name: "",
    routing_number: "",
    account_number: "",
    date_of_birth: "",
    social_security_number: "",
    city: "",
    address: "",
    postal_code: "",
    state: "",
    name: "",
    email: "",
    phone_number: "",
    business_name: "",
    support_city: "",
    support_state: "",
    support_postal_code: "",
    support_email: "",
    support_phone_number: "",
    support_address: "",
  });

  const setInputs = () => {
    setAccountDetails({
      account_holder_name: account_holder_name,
      routing_number: routing_number,
      account_number: account_number,
      date_of_birth: `${day}/${month}/${year}`,
      social_security_number: ssn,
      city: city,
      address: address,
      postal_code: postal_code,
      state: state,
      name: name,
      email: email,
      phone_number: phone,
      // business_name: business_name,
      // support_city: support_city,
      // support_state: support_state,
      // support_postal_code: support_postal_code,
      // support_email: support_email,
      // support_phone_number: support_phone_number,
      // support_address: support_address,
    });
  };

  const handleChange = (e) => {
    console.log(e.target.id);
    setAccountDetails({
      ...accountDetails,
      [e.target.id]: e.target.value,
    });
  };

  const handleBankAccount = (e) => {
    e.preventDefault();
    console.log(accountDetails, "DETAILS");
    EditBankAccount();
  };

  const getStripeFormatNumber = (number) => {
    if (number[0] === "+") {
      return number;
    }
    console.log(number, "NUMBER ORIGNAL");

    number = number.replace(" ", "");
    number = number.replace("-", "");
    number = number.replace("(", "");
    number = number.replace(")", "");

    console.log(number, "NUMBER");

    return "+1" + number;
  };

  const EditBankAccount = () => {
    let requestObj = {
      path: "/api/company/edit_bank_detail",
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    const {
      date_of_birth,
      phone_number,
      // support_phone_number,
      ...rest
    } = accountDetails;

    const day = date_of_birth.split("/")[0];
    const month = date_of_birth.split("/")[1];
    const year = date_of_birth.split("/")[2];

    //validations

    if (
      parseInt(day) < 1 ||
      parseInt(day) > 31 ||
      parseInt(month) < 1 ||
      parseInt(month) > 12 ||
      parseInt(year) < 1000
    ) {
      showErrorSnak("Invalid date of birth");
      if (document.querySelector("#date_of_birth")) {
        document.querySelector("#date_of_birth").focus();
      }
      return;
    }

    let postData = {
      ...rest,
      day,
      month,
      year,
      phone_number: getStripeFormatNumber(phone_number),
      // support_phone_number: getStripeFormatNumber(support_phone_number),
    };

    console.log(postData, "ADD BANK POST DATA");

    setIsLoading(true);

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "ADD_BANK_RES");
      if (res.code === 200) {
        setIsLoading(false);
        showSuccessSnak(res.message);
        FetchBankDetails();
      } else {
        setIsLoading(false);
        //showErrorSnak(res.message);
      }
    });
  };

  useEffect(() => {
    console.log(accountDetails, "accountDetails");
    setInputs();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }
  return (
    <>
      <section className="main-content address-page">
        <div className="container">
          <div className="add-items-wrapper mb-m-90 mb-90 mt-3 mt-sm-4 ">
            <div className="text-center">
              <AccountBalanceIcon
                fontSize="large"
                style={{ color: "#c4314b" }}
              />
              <br />

              <b className="text-center text-uppercase mb-0">
                Edit Connected Account
              </b>
            </div>
            <div className="selected-room mt-4 mt-sm-4">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 mb-4 mb-sm-4">
                  <div className="rounded-box p-3">
                    <form onSubmit={handleBankAccount}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="cvv"
                              className="overline dark-grey-color"
                            >
                              Date of Birth (dd/mm/yyyy)
                            </label>
                            <NumberFormat
                              className="form-control small-font-size"
                              id="date_of_birth"
                              placeholder="dd/mm/yyyy"
                              onChange={handleChange}
                              value={accountDetails.date_of_birth}
                              autoComplete="off"
                              format="##/##/####"
                              required
                              type="password"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="address-1"
                              className="overline dark-grey-color"
                            >
                              Social Security Number
                            </label>
                            <NumberFormat
                              className="form-control small-font-size"
                              id="social_security_number"
                              placeholder="Last Four Digits"
                              onChange={handleChange}
                              value={accountDetails.social_security_number}
                              autoComplete="off"
                              format="####"
                              type="password"
                              disabled
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="city"
                              className="overline dark-grey-color"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="address"
                              placeholder="295 N Rodeo Dr"
                              onChange={handleChange}
                              value={accountDetails.address}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="city"
                              className="overline dark-grey-color"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="city"
                              placeholder="Beverly Hills"
                              onChange={handleChange}
                              value={accountDetails.city}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="state"
                              className="overline dark-grey-color"
                            >
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="state"
                              placeholder="CA"
                              onChange={handleChange}
                              value={accountDetails.state}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              Postal Code
                            </label>
                            <input
                              className="form-control small-font-size"
                              id="postal_code"
                              placeholder="Postal Code"
                              onChange={handleChange}
                              value={accountDetails.postal_code}
                              autoComplete="off"
                              type="password"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="name"
                              placeholder="Name"
                              onChange={handleChange}
                              value={accountDetails.name}
                              autoComplete="off"
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              email
                            </label>
                            <input
                              type="email"
                              className="form-control small-font-size"
                              id="email"
                              placeholder="Email"
                              onChange={handleChange}
                              value={accountDetails.email}
                              autoComplete="off"
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              phone number
                            </label>
                            <NumberFormat
                              className="form-control small-font-size"
                              id="phone_number"
                              placeholder="Phone number"
                              onChange={handleChange}
                              value={accountDetails.phone_number}
                              autoComplete="off"
                              format="(###) ###-####"
                              required
                              disabled
                            />
                          </div>
                        </div>
                        {/* <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              business name
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="business_name"
                              placeholder="Bussiness Name"
                              onChange={handleChange}
                              value={accountDetails.business_name}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support city
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="support_city"
                              placeholder="Support City"
                              onChange={handleChange}
                              value={accountDetails.support_city}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support state
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="support_state"
                              placeholder="Support State"
                              onChange={handleChange}
                              value={accountDetails.support_state}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support postal code
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="support_postal_code"
                              placeholder="Support Postal Code"
                              onChange={handleChange}
                              value={accountDetails.support_postal_code}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support email
                            </label>
                            <input
                              type="email"
                              className="form-control small-font-size"
                              id="support_email"
                              placeholder="Support Email"
                              onChange={handleChange}
                              value={accountDetails.support_email}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support phone number
                            </label>
                            <NumberFormat
                              className="form-control small-font-size"
                              id="support_phone_number"
                              placeholder="Support Phone Number"
                              onChange={handleChange}
                              value={accountDetails.support_phone_number}
                              autoComplete="off"
                              format="(###) ###-####"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              support address
                            </label>
                            <input
                              type="text"
                              className="form-control small-font-size"
                              id="support_address"
                              placeholder="Support Address"
                              onChange={handleChange}
                              value={accountDetails.support_address}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="row justify-content-center pt-4">
                        <div className="col-12 col-sm-8 col-md-6">
                          <div className="form-group button-wrapper">
                            <a href="add-card-verified.html">
                              <button className="custom-buton custom-buton-large w-100">
                                Update Connected Account
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditBank;
