import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { DirectLinkUrl, invokeApi } from "../../bl_libs/invokeApi";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
function SharePopup(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const { company_direct_access_link } = JSON.parse(
    localStorage.getItem("user")
  );

  const [isLoading, setIsLoading] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const handleSubmitEmail = (e) => {
    e.preventDefault();

    setIsLoading(true);
    let requestObj = {
      path: "/api/company/send_company_url",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      type: "email",
      contact: `${emailValue}`,
      url: DirectLinkUrl + company_direct_access_link,
    };
    requestObj["postData"] = postData;

    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        document.getElementById("close-share-link-email") &&
          document.getElementById("close-share-link-email").click();
        showSuccessSnak("Direct link has been sent successfully");
        setEmailValue("");
      } else {
        showErrorSnak(res.message);
      }
      setIsLoading(false);
    });
  };

  const handleSubmitPhone = (e) => {
    e.preventDefault();

    setIsLoading(true);
    let requestObj = {
      path: "/api/company/send_company_url",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      type: "phone_number",
      contact: `${phoneValue}`,
      url: DirectLinkUrl + company_direct_access_link,
    };
    requestObj["postData"] = postData;

    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        document.getElementById("close-share-link-phone") &&
          document.getElementById("close-share-link-phone").click();
        showSuccessSnak("Direct link has been sent successfully");
        setPhoneValue("");
      } else {
        showErrorSnak(res.message);
      }
      setIsLoading(false);
    });
  };
  return (
    <>
      {/* <!-- Change Filter Email --> */}

      <div
        className="modal fade pb-m-80 mt-m-80"
        id="share-link-email"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-filter-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-share-link-email"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="change-filter-title">
                  Share Direct Link
                </h5>
                <div className="form-box pt-20">
                  <form onSubmit={handleSubmitEmail}>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <label
                            htmlFor="from"
                            className="overline dark-grey-color"
                          >
                            Enter Email Address
                          </label>

                          <input
                            required
                            type="email"
                            id="max_price"
                            value={emailValue}
                            onChange={(e) => setEmailValue(e.target.value)}
                            placeholder="Enter Email Address"
                            name=""
                            disabled={isLoading}
                            className="form-control form-control-sm small-font-size"
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="row"></div>
                    <div className="form-group pt-3">
                      <div className="row justify-content-center">
                        <div className="col-12">
                          {isLoading ? (
                            <div className="buttons-wrapper pt-2">
                              <div className="row pt-1 pb-3">
                                <div className="col-12 text-center">
                                  <CircularProgress />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <button
                              type="submit"
                              className="custom-buton custom-buton-large w-100"
                            >
                              Share
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Change Filter Phone --> */}
      <div
        className="modal fade pb-m-80 mt-m-80"
        id="share-link-phone"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-filter-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-share-link-phone"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="change-filter-title">
                  Share Direct Link
                </h5>
                <div className="form-box pt-20">
                  <form onSubmit={handleSubmitPhone}>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <label
                            htmlFor="from"
                            className="overline dark-grey-color"
                          >
                            Enter Phone Number
                          </label>
                          <NumberFormat
                            value={phoneValue}
                            onChange={(e) => setPhoneValue(e.target.value)}
                            className="form-control small-font-size"
                            id="phone_number"
                            placeholder="(217) 555-0113"
                            format="(###) ###-####"
                            mask="_"
                            disabled={isLoading}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row"></div>
                    <div className="form-group pt-3">
                      <div className="row justify-content-center">
                        <div className="col-12">
                          {isLoading ? (
                            <div className="buttons-wrapper pt-2">
                              <div className="row pt-1 pb-3">
                                <div className="col-12 text-center">
                                  <CircularProgress />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <button
                              type="submit"
                              className="custom-buton custom-buton-large w-100"
                            >
                              Share
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SharePopup;
