import { Avatar } from "@material-ui/core";
import React from "react";
import { companyProfilePicImage } from "../../../../../../assets";
import { s3baseUrl } from "../../../../../../bl_libs/invokeApi";

function User(props) {
  const { user, handleSelectUser, handleActiveRoom, setScreen } = props;

  const { request_id, room_id, profile_data, messages, type, unread_messages } =
    user;

  if (!room_id) {
    return <></>;
  }
  return (
    <>
      <div
        className={
          handleActiveRoom(user)
            ? "row pt-1 chat-contact-box active-room"
            : "row pt-1 chat-contact-box pointer-point"
        }
        onClick={() => {
          setScreen("chat");
          handleSelectUser(user);
        }}
      >
        <div className="col-2">
          <div className="profile-pic-small ">
            {type === 0 ? (
              //company
              profile_data.profile_picture ? (
                // company has image
                <img
                  src={s3baseUrl + profile_data.profile_picture}
                  className="rounded-circle circle-box circle-box-small img-46"
                />
              ) : (
                // company has no image
                <Avatar style={{ width: 45, height: 45 }}>
                  {profile_data.name[0].toUpperCase()}
                </Avatar>
              )
            ) : type === 2 ? (
              //foreman
              profile_data.profile_pic ? (
                //foreman has image
                <img
                  src={s3baseUrl + profile_data.profile_pic}
                  className="rounded-circle circle-box circle-box-small img-46"
                />
              ) : (
                //foreman no image
                <Avatar style={{ width: 45, height: 45 }}>
                  {profile_data.first_name[0].toUpperCase()}
                </Avatar>
              )
            ) : //customer
            profile_data.profile_picture ? (
              //customer has image
              <img
                src={s3baseUrl + profile_data.profile_picture}
                className="rounded-circle circle-box circle-box-small img-46"
              />
            ) : (
              // customer has no image
              <Avatar style={{ width: 45, height: 45 }}>
                {profile_data.first_name[0].toUpperCase()}
              </Avatar>
            )}

            {profile_data.online_status === 0 ? (
              <div className="status-inactive-small"></div>
            ) : (
              <div className="status-active-small"></div>
            )}
          </div>
        </div>
        <div className="col-8 ">
          <div className="my-header-title">
            {type !== 0 ? (
              <span className="pl-1">
                {profile_data.first_name + " " + profile_data.last_name}
              </span>
            ) : (
              <span className="pl-1">{profile_data.name}</span>
            )}
          </div>
          <div className="under-text pl-1">Request #{request_id}</div>
        </div>
        <div className="col-2 pt-2">
          {unread_messages !== 0 && (
            <div className="new-message-count">
              <span className="message-count">{unread_messages}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default User;
