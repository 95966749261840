import { Avatar, CircularProgress, Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  blackBackArrowImage,
  companyProfilePicImage,
  editIconImage,
} from "../../assets";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { invokeApi, s3baseUrl } from "../../bl_libs/invokeApi";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
import ChangeEmail from "./components/Change-Email/ChangeEmail";
import ChangePhone from "./components/Change-Phone/ChanegPhone";
import { CkEditor, CustomEditor, USAStates } from "../../components";
import InfoIcon from "@material-ui/icons/Info";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

function EditProfile(props) {
  const classes = useStyles();
  const editorRefInvoice = useRef(null);
  const editorRefConfirmation = useRef(null);
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  //for loading

  const [isLoading, setIsLoading] = React.useState(true);

  const [screen, setScreen] = React.useState("");

  //for show error

  const [errMessage, setErrMessage] = React.useState("");

  const [profile, setProfile] = useState();
  const [image, setImage] = useState({
    file: "",
    preview: "",
  });

  const GetCompanyProfile = (type) => {
    let requestObj = {
      path: "/api/company/profile_company",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setProfile(res.profile);
        localStorage.setItem(
          "user",
          JSON.stringify({ ...res.profile, company_rating: res.company_rating })
        );
        if (type === "done") {
          props.history.push("/company");
        }
      } else {
        //showErrorSnak(res.message);
      }
      setIsLoading(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    UpdateCompanyProfile();
  };

  const handleUpload = (event) => {
    // S3UploadFile(event.target.files[0]);
    setImage({
      file: event.target.files[0],
      preview: URL.createObjectURL(event.target.files[0]),
    });
  };

  const S3UploadFile = (file) => {
    return new Promise(function (resolve, reject) {
      let requestObj = {
        path: "/app_api/upload_s3_file",
        method: "POST",
        headers: {},
      };
      let formData = new FormData();
      formData.append("upload_file", file);
      formData.append("upload_type", "profile");
      requestObj["postData"] = formData;
      invokeApi(requestObj).then((res) => {
        console.log(res);
        if (res.code === 200) {
          resolve(res.file_url);
        } else {
          setErrMessage(res.message);
          //showErrorSnak(res.message);
          setIsLoading(false);
        }
      });
    });
  };

  const S3UploadFileThumbnail = (file) => {
    return new Promise(function (resolve, reject) {
      let requestObj = {
        path: "/app_api/upload_s3_file",
        method: "POST",
        headers: {},
      };
      let formData = new FormData();
      formData.append("upload_file", file);
      formData.append("upload_type", "company");
      requestObj["postData"] = formData;
      invokeApi(requestObj).then((res) => {
        console.log(res);
        if (res.code === 200) {
          resolve(res.file_url);
        } else {
          setErrMessage(res.message);
          //showErrorSnak(res.message);
          setIsLoading(false);
        }
      });
    });
  };
  const validString = (string) => {
    const re = /^[a-z A-Z]+$/;
    return re.test(string);
  };

  const UpdateCompanyProfile = async () => {
    if (profile.name.trim().length < 2) {
      showErrorSnak("Name length must be grater then 2.");
      return;
    }

    // if (!validString(profile.name.trim())) {
    //   showErrorSnak("Company name must be a valid string");
    //   return;
    // }

    setIsLoading(true);
    let imageLink;
    let thumbnailLink;

    if (image.preview !== "") {
      imageLink = await S3UploadFile(image.file);
      thumbnailLink = await S3UploadFileThumbnail(image.file);
    }

    const edit_company_api = "/api/company/update_compony_profile";
    const edit_manager_api = "/api/manager/edit_manager";

    let requestObj = {
      path: profile.role === "company" ? edit_company_api : edit_manager_api,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let confirmation_letter = profile.confirmation_letter;
    let invoice = profile.invoice;

    if (editorRefConfirmation.current && profile.role === "company") {
      confirmation_letter = editorRefConfirmation.current.getContent();
    } else {
    }

    if (editorRefInvoice.current && profile.role === "company") {
      invoice = editorRefInvoice.current.getContent();
    } else {
    }

    // for company
    let postData = {
      name: profile.name.trim(),
      address: profile.address.trim(),
      phone: profile.phone,
      about_company: profile.about_company.trim(),
      state: profile.state,
      ca_number: profile.ca_number,
      dot_number: profile.dot_number ? profile.dot_number : "N/A",
      mtr_number: profile.mtr_number ? profile.mtr_number : "N/A",
      pli_file_path: profile.pli_file_path,
      pdi_file_path: profile.pdi_file_path,
      cpi_file_path: profile.cpi_file_path,
      gli_file_path: profile.gli_file_path,
      wc_file_path: profile.wc_file_path,
      email: profile.email,
      profile_pic: image.preview === "" ? profile.profile_picture : imageLink,
      thumbnail: image.preview === "" ? profile.thumbnail : thumbnailLink,
      email_on_request: profile.email_on_request,
      invoice,
      confirmation_letter,
    };

    if (profile.role === "manager") {
      postData = {
        name: profile.name.trim(),
        phone: profile.phone,
        email: profile.email,
        profile_pic: image.preview === "" ? profile.profile_picture : imageLink,
      };
    }

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        setImage({
          file: "",
          preview: "",
        });
        GetCompanyProfile("done");
        showSuccessSnak("Profile updated successfully");
        setErrMessage("");
      } else {
        setIsLoading(false);
        setErrMessage(res.message);
        //showErrorSnak(res.message);
      }
    });
  };

  const handleEditorChange = (data) => {
    setProfile({ ...profile, email_on_request: data });
  };

  useEffect(() => {
    GetCompanyProfile();
  }, []);

  if (isLoading) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">
              <img
                className="pointer-point pr-1"
                src={blackBackArrowImage}
                onClick={() => props.history.goBack()}
              />
              <span>Edit Profile</span>
            </p>
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </section>
      </>
    );
  }

  if (screen === "CHANGE_EMAIL") {
    return (
      <ChangeEmail
        GetCompanyProfile={GetCompanyProfile}
        setIsLoading={setIsLoading}
        profile={profile}
        setMainScreen={setScreen}
      />
    );
  }

  if (screen === "CHANGE_PHONE") {
    return (
      <ChangePhone
        GetCompanyProfile={GetCompanyProfile}
        setIsLoading={setIsLoading}
        profile={profile}
        setMainScreen={setScreen}
      />
    );
  }

  return (
    <>
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title">
            <img
              className="pointer-point pr-1"
              src={blackBackArrowImage}
              onClick={() => props.history.goBack()}
            />
            <span>Edit Profile</span>
          </p>
          <div className="calendar-wrapper">
            <div className="row justify-content-center pt-40">
              <div className="col-10  col-lg-8">
                {errMessage && (
                  <Alert variant="filled" severity="error">
                    {errMessage}
                  </Alert>
                )}
                <div className="form-box-wrapper">
                  <div className="form-box">
                    <h5 className="text-center">Edit Profile</h5>
                    <div className="evom-form login-form">
                      <form className="row" onSubmit={handleSubmit}>
                        <div className="form-group text-center col-12">
                          {image.preview === "" ? (
                            //if preview is empty show default
                            profile.profile_picture ? (
                              //if profile picture is available show profile
                              <img
                                src={s3baseUrl + profile.profile_picture}
                                className="circle-box rounded-circle edit-profile-img"
                              />
                            ) : (
                              //else show default picture
                              <Avatar
                                style={{
                                  width: 150,
                                  height: 150,
                                  fontSize: 22,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              ></Avatar>
                            )
                          ) : (
                            //show preview
                            <img
                              src={image.preview}
                              className="circle-box rounded-circle edit-profile-img"
                            />
                          )}

                          <div className="edit-profile-icon">
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="icon-button-file"
                              type="file"
                              value=""
                              onChange={handleUpload}
                            />
                            <label htmlFor="icon-button-file">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <PhotoCamera style={{ color: "#c4314b" }} />
                              </IconButton>
                            </label>
                          </div>
                        </div>
                        <div className="form-group col-12 col-lg-6">
                          <label
                            htmlFor="company_name"
                            className="overline dark-grey-color"
                          >
                            Email:
                          </label>

                          <span className="dark-grey-color pl-2">
                            {profile.email}
                          </span>

                          <img
                            className="float-right pointer-point"
                            sizes="10"
                            onClick={() => {
                              GetCompanyProfile();
                              setScreen("CHANGE_EMAIL");
                            }}
                            src={editIconImage}
                          ></img>
                        </div>
                        <div className="form-group col-12 col-lg-6">
                          <label
                            htmlFor="company_name"
                            className="overline dark-grey-color"
                          >
                            Phone:
                          </label>
                          <span className="dark-grey-color pl-2">
                            {profile.phone}
                          </span>
                          <img
                            onClick={() => {
                              GetCompanyProfile();
                              setScreen("CHANGE_PHONE");
                            }}
                            className="float-right pointer-point"
                            sizes="10"
                            src={editIconImage}
                          ></img>
                        </div>
                        {profile.role === "manager" && (
                          <div className="form-group col-12">
                            <label
                              htmlFor="company_name"
                              className="overline dark-grey-color"
                            >
                              Manager NAME
                            </label>
                            <input
                              onChange={(e) =>
                                setProfile({
                                  ...profile,
                                  name: e.target.value,
                                })
                              }
                              required
                              value={profile.name}
                              type="text"
                              className="form-control small-font-size"
                              id="company_name"
                              placeholder="Company name"
                              autoComplete="off"
                            />
                          </div>
                        )}
                        {profile.role === "company" && (
                          <>
                            <div className="form-group col-12 col-lg-6">
                              <label
                                htmlFor="company_name"
                                className="overline dark-grey-color"
                              >
                                COMPANY NAME
                              </label>
                              <input
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    name: e.target.value,
                                  })
                                }
                                required
                                value={profile.name}
                                type="text"
                                className="form-control small-font-size"
                                id="company_name"
                                placeholder="Company name"
                                autoComplete="off"
                              />
                            </div>

                            <div className="form-group col-12 col-lg-6">
                              <label
                                htmlFor="about_company"
                                className="overline dark-grey-color"
                              >
                                ABOUT COMPANY
                              </label>
                              <input
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    about_company: e.target.value,
                                  })
                                }
                                autoComplete="off"
                                value={profile.about_company}
                                type="text"
                                className="form-control small-font-size"
                                id="about_company"
                                placeholder="About company"
                              />
                            </div>

                            <div className="form-group col-12 col-lg-6">
                              <label
                                htmlFor="about_company"
                                className="overline dark-grey-color"
                              >
                                Address
                              </label>
                              <input
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    address: e.target.value,
                                  })
                                }
                                autoComplete="off"
                                value={profile.address}
                                type="text"
                                className="form-control small-font-size"
                                id="address"
                                placeholder="About company"
                              />
                            </div>
                            <div className="form-group col-12 col-lg-6">
                              <label
                                htmlFor="company_name"
                                className="overline dark-grey-color"
                              >
                                State
                              </label>
                              <select
                                className="form-control small-font-size"
                                name="where_did_you_find"
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    state: e.target.value,
                                  })
                                }
                                value={profile.state}
                                id="state"
                                required
                              >
                                {!profile.state && (
                                  <option value="" selected="selected">
                                    Select a State
                                  </option>
                                )}
                                <USAStates />
                              </select>
                            </div>
                          </>
                        )}
                        {profile.role === "company" && (
                          <>
                            <div className="col-12">
                              <label
                                htmlFor="company_name"
                                className="overline dark-grey-color"
                              >
                                Confirmation Letter
                                <Tooltip
                                  className=""
                                  title="
                              Available tags are company_image, drop_address_array, pick_address_array, item_array, extra_item_array,
 today_date,
 move_type,
 request_movers,
 request_schedule_time,
 customer_phone_number,
 customer_last_name,
 customer_first_name,
 company_address,
 company_mtr_number,
 company_phone_number,
 company_name,
 minimum_hours,
 card_payment_rate,
 cash_payment_rate, recommend_amount,
                              "
                                  aria-label="add"
                                  arrow
                                  placement="right-start"
                                >
                                  <InfoIcon style={{ color: "#c4314b" }} />
                                </Tooltip>
                              </label>
                              <CustomEditor
                                data={
                                  profile.confirmation_letter
                                    ? profile.confirmation_letter
                                    : ""
                                }
                                editorRef={editorRefConfirmation}
                              />
                            </div>
                            {/* invoice */}
                            <div className="col-12 mt-3">
                              <label
                                htmlFor="company_name"
                                className="overline dark-grey-color"
                              >
                                Invoice{" "}
                                <Tooltip
                                  className=""
                                  title="
                              Available tags are company_image, drop_address_array, pick_address_array, item_array, extra_item_array,
 today_date,
 move_type,
 request_movers,
 request_schedule_time,
 customer_phone_number,
 customer_last_name,
 customer_first_name,
 company_address,
 company_mtr_number,
 company_phone_number,
 company_name,
 minimum_hours,
 card_payment_rate,
 cash_payment_rate, recommend_amount,
                              "
                                  aria-label="add"
                                  arrow
                                  placement="right-start"
                                >
                                  <InfoIcon style={{ color: "#c4314b" }} />
                                </Tooltip>
                              </label>
                              <CustomEditor
                                data={profile.invoice ? profile.invoice : ""}
                                editorRef={editorRefInvoice}
                              />
                            </div>
                          </>
                        )}

                        <div className="form-group button-wrapper col-12">
                          <div className="row justify-content-center">
                            <div className="col-12 col-lg-6">
                              <button
                                type="submit"
                                className="custom-buton custom-buton-large w-100"
                              >
                                Update Profile
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(EditProfile);
