import React, { useState } from "react";
import GoogleMapTracking from "./google-Map-Tracking/GoogleMapTracking";
import AreaNotFound from "./AreaNotFound";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import { useSnakbar } from "../../../../Context/Context-Snakbar/ContextSnackbar";
import { CircularProgress } from "@material-ui/core";
import Done from "./Done";
function PickUpAddress(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  console.log(props.selectedAddress, "selected address");
  const { selectedAddress, addressList, setAddressList } = props;
  const [isFound, setIsFound] = useState(true);
  const [address, setAddress] = useState({
    zip_code: selectedAddress.zip_code,
    address: selectedAddress.address,
    lat: selectedAddress.lat,
    lng: selectedAddress.lng,
  });
  const [isDone, setIsDone] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const checkZipCode = (zip) => {
    return new Promise(function (resolve, reject) {
      // do a thing, possibly async, then…
      let requestObj = {
        path: "/api/customer/check_service_area",
        method: "POST",
        headers: {
          "x-sh-auth": localStorage.getItem("token"),
        },
      };
      let postData = {
        zip_code: zip,
      };
      requestObj["postData"] = postData;

      invokeApi(requestObj).then((res) => {
        console.log(res, "CHECK_ZIP_CODE");
        resolve(res);
      });
    });

    // let requestObj = {
    //   path: "/api/customer/checking_card_exist",
    //   method: "POST",
    //   headers: {
    //     "x-sh-auth": localStorage.getItem("token"),
    //   },
    // };
    // let postData = {
    //   zip_code: "90010",
    // };
    // requestObj["postData"] = postData;
    // invokeApi(requestObj).then((res) => {
    //   console.log(res, "CHECK_ZIP_CODE");
    //   if (res.code === 200) {
    //   }
    // });
  };

  const handleClick = async () => {
    if (address.address === "") {
      let temp = addressList.map((addr) => {
        if (addr.id === selectedAddress.id) {
          return {
            ...addr,
            zip_code: "",
            address: "",
            lat: "",
            lng: "",
          };
        } else {
          return addr;
        }
      });
      setAddressList(temp);
      props.closePopUp();
      props.setAddressListRefresh(address);
      return;
    }
    console.log(address);
    let temp = addressList.map((addr) => {
      if (addr.id === selectedAddress.id) {
        return {
          ...addr,
          ...address,
        };
      } else {
        return addr;
      }
    });
    console.log(address, "FINAL ADDRESS");
    console.log(temp, "FINAL ADDRESS ARRAY");

    setIsLoading(true);
    const result = await checkZipCode(address.zip_code);
    console.log(result, "RESULT");
    if (result.code === 200) {
      //service available in this area
      setAddressList(temp);
      props.closePopUp();
      props.setAddressListRefresh(address);
    } else {
      setIsFound(false);
    }
    setIsLoading(false);
  };

  const AddInWishList = (inputs) => {
    let requestObj = {
      path: "/api/customer/add_wishlist_area",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      email: inputs.email,
      zip_code: inputs.zip,
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "ADD IN WISHLIST");
      if (res.code === 200) {
        showSuccessSnak("Zipcode added into our waitlist.");
        setIsDone(true);
      } else {
        //showErrorSnak(res.message);
      }
      setIsLoading(false);
    });
  };

  const getEmail = () => {
    return JSON.parse(localStorage.getItem("user"))?.email;
  };

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }

  if (isDone) {
    return <Done closePopUp={props.closePopUp} />;
  }

  if (isFound) {
    return (
      <AreaNotFound
        zip={selectedAddress.postal_code}
        email={getEmail()}
        AddInWishList={AddInWishList}
      />
    );
  }

  return (
    <>
      <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
        <div className="modal-body p-0">
          {/* <h5 className="text-center" id="change-password-title">
                Pickup Address
              </h5> */}
          <div className="form-box">
            <div className="" style={{}}>
              <GoogleMapTracking address={address} setAddress={setAddress} />
            </div>
            <div className="form-group pt-3">
              <button
                onClick={handleClick}
                type="button"
                className="red-btn custom-buton custom-buton-large w-100"
              >
                Set
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PickUpAddress;
