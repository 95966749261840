import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { addressARedIcon, subtractImage, addImage } from "../../../assets";
import AddressPickUp from "./AddressPickUp";

function LocationCard(props) {
  const { location, setLocation, addNewAddress, errMessage, isDirectRequest } =
    props;

  const handleChange = (e) => {
    setLocation({ ...location, [e.target.id]: e.target.value });
    console.log(location, "LOCATION");
  };

  const [reRender, setReRender] = useState(false);

  const handleSubtract = () => {
    setLocation({ ...location, flights: location.flights - 1 });
  };
  const handleAdd = () => {
    setLocation({ ...location, flights: location.flights + 1 });
  };

  const [addressPicker, setAddressPicker] = useState({
    zip_code: "",
    address: "",
    lat: "",
    lng: "",
  });

  const handleSelectAddress = () => {
    console.log(addressPicker, "ADDRESSPICKED");

    setLocation({ ...location, ...addressPicker });

    document.getElementById("address-a-close").click();
  };

  return (
    <div className="col-12 col-sm-12 col-md-6 mb-4 d-flex">
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <h6 className="text-white">Add Address</h6>
        </div>
        {errMessage && (
          <Alert variant="filled" severity="error">
            {errMessage}
          </Alert>
        )}
        <div className="request-left-box p-3 p-sm-4">
          <div className="add-info-wrapper">
            <div className="row">
              {/* <div className="col-1">
                <p className="small-font-size font-weight-bold">
                  <span>
                    <img src={location.icon} />
                  </span>
                </p>
              </div> */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="" className="overline dark-grey-color">
                    Select your address
                  </label>
                  <input
                    autoComplete="off"
                    data-toggle="modal"
                    data-target="#address-a-picker-model"
                    type="text"
                    value={location.formated_address}
                    onChange={handleChange}
                    className="form-control small-font-size"
                    placeholder="Select Your Address"
                    onClick={() => {
                      setReRender(false);
                      setTimeout(() => {
                        setReRender(true);
                      }, 200);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-6">
                <div className="form-group">
                  <label
                    htmlFor="unitNumber"
                    className="overline dark-grey-color"
                  >
                    Unit or apartment number
                  </label>
                  <input
                    type="text"
                    value={location.unitNumber}
                    onChange={handleChange}
                    className="form-control small-font-size"
                    id="unitNumber"
                    placeholder="123"
                  />
                </div>
              </div>
              <div className="col-12 col-xl-6">
                <div className="form-group">
                  <label
                    htmlFor="zip_code"
                    className="overline dark-grey-color"
                  >
                    Zip or Postal code
                  </label>
                  <input
                    type="text"
                    value={location.zip_code}
                    onChange={handleChange}
                    style={{
                      border: location.isZipEmpty && "solid 1px red",
                    }}
                    className="form-control small-font-size"
                    id="zip_code"
                    placeholder="zip code"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label
                    htmlFor="addressDetails"
                    className="overline dark-grey-color"
                  >
                    Address Details
                  </label>
                  <input
                    type="text"
                    value={location.addressDetails}
                    onChange={handleChange}
                    className="form-control small-font-size"
                    id="addressDetails"
                    placeholder="Details"
                  />
                </div>
                <div className="helper-hardwood-floor border-bottom mb-3 ">
                  <div className="row">
                    <div className="col-12 pb-2">
                      <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                        {" "}
                        Pick up address
                        <input
                          type="radio"
                          id="pick-up"
                          className="custom-radio"
                          value="pick-up"
                          checked={location.type === "pick-up" && true}
                          onChange={() =>
                            setLocation({ ...location, type: "pick-up" })
                          }
                        />
                        <span className="custom-radio-box"></span>
                      </label>
                    </div>
                    <div className="col-12">
                      <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                        {" "}
                        Drop off address
                        <input
                          type="radio"
                          id="drop-off"
                          className="custom-radio"
                          value="drop-off"
                          checked={location.type === "drop-off" && true}
                          onChange={() =>
                            setLocation({ ...location, type: "drop-off" })
                          }
                        />
                        <span className="custom-radio-box"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <label className="dark-grey-color mb-0 custom-checkbox-wrapper">
                  Storage
                  <input
                    id="isStorage"
                    type="checkbox"
                    name="storage"
                    value={location.isStorage}
                    checked={location.isStorage}
                    onChange={() =>
                      setLocation({
                        ...location,
                        isStorage: !location.isStorage,
                      })
                    }
                  />
                  <span className="custom-check-box"></span>
                </label>
              </div>
            </div>
            <hr />
            <div className="helper-elevator border-bottom pt-1">
              <div className="row">
                <div className="col-12">
                  <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                    {" "}
                    Mover(s) can use elevator
                    <input
                      type="radio"
                      id="liftType2"
                      className="custom-radio"
                      value="elevator"
                      checked={location.liftType === "elevator" && true}
                      onChange={() =>
                        setLocation({ ...location, liftType: "elevator" })
                      }
                    />
                    <span className="custom-radio-box"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="helper-stairs border-bottom pt-3">
              <div className="row">
                <div className="col-12">
                  <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                    Mover(s) need to use stairs
                    <input
                      type="radio"
                      className="custom-radio"
                      value="stairs"
                      id="liftType1"
                      checked={location.liftType === "stairs" && true}
                      onChange={() =>
                        setLocation({ ...location, liftType: "stairs" })
                      }
                    />
                    <span className="custom-radio-box"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label htmlFor="trucks" className="overline dark-grey-color">
                    Number of flights
                  </label>
                  <div className="flights-count">
                    <img
                      style={{ cursor: "pointer" }}
                      src={subtractImage}
                      onClick={() => {
                        if (
                          location.flights > 0 &&
                          location.liftType === "stairs"
                        ) {
                          handleSubtract();
                        }
                      }}
                    />
                    <span
                      className="movers-count-box small-font-size black-color text-center"
                      style={{
                        color: location.liftType !== "stairs" && "#C4C4C4",
                      }}
                    >
                      {location.flights==0?"Ground Floor":location.flights}
                    </span>
                    <img
                      style={{ cursor: "pointer" }}
                      src={addImage}
                      onClick={() => {
                        if (location.liftType === "stairs") {
                          handleAdd();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="helper-hardwood-floor border-bottom pt-3">
              <div className="row">
                <div className="col-12">
                  <label className="dark-grey-color mb-0 custom-checkbox-wrapper">
                    Has hardwood floors
                    <input
                      id="isHardwoodFloor"
                      type="checkbox"
                      name="storage"
                      value={location.isHardwoodFloor}
                      checked={location.isHardwoodFloor}
                      onChange={() =>
                        setLocation({
                          ...location,
                          isHardwoodFloor: !location.isHardwoodFloor,
                        })
                      }
                    />
                    <span className="custom-check-box"></span>
                  </label>
                </div>
              </div>
            </div> */}

            <div className="parking-info pt-3">
              <div className="row">
                <div className="col-12 col-sm-12">
                  <div className="form-group">
                    <label
                      htmlFor="parkingInfo"
                      className="overline dark-grey-color"
                    >
                      Parking and building info
                    </label>
                    <input
                      type="text"
                      className="form-control small-font-size"
                      id="parkingInfo"
                      placeholder="Parking and building info"
                      value={location.parkingInfo}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* save or cancell */}

            <div className="row justify-content-center ">
              <div className="col-6 col-md-3 ">
                <button
                  onClick={addNewAddress}
                  className="custom-btn-small custom-buton"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Address picker popup --> */}
      <div
        className="modal fade add-item popup-bottom full-height"
        id="address-a-picker-model"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="area-not-found-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30 mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="address-a-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {reRender && (
              <AddressPickUp
                isDirectRequest={isDirectRequest}
                address={addressPicker}
                setAddress={setAddressPicker}
                handleSelectAddress={handleSelectAddress}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationCard;
