import React from "react";
import {
  addressARedIcon,
  subtractImage,
  addImage,
} from "../../../../../assets";
import { set } from "date-fns";

function LocationCard(props) {
  const { location, addressList, setAddressList } = props;
  console.log(location, "location");

  const handleChange = (e) => {
    let temp = addressList.map((address) => {
      if (address.id === location.id) {
        //for address type
        if (e.target.id === "pick-up" || e.target.id === "drop-off") {
          return {
            ...address,
            type: e.target.value,
          };
        }

        //if its call for isStorage
        if (e.target.id === "isStorage") {
          return {
            ...address,
            [e.target.id]: !address.isStorage,
          };
        }
        //isHardwoodFloor
        if (e.target.id === "isHardwoodFloor") {
          return {
            ...address,
            [e.target.id]: !address.isHardwoodFloor,
          };
        }
        //if it calls for radio button 1
        if (e.target.id === "liftType1") {
          return {
            ...address,
            liftType: e.target.value,
          };
        }
        //if it calls for radio button 2
        if (e.target.id === "liftType2") {
          return {
            ...address,
            liftType: e.target.value,
          };
        }

        return {
          ...address,
          [e.target.id]: e.target.value,
        };
      } else {
        return address;
      }
    });
    setAddressList(temp);
  };
  //addition
  const handleAdd = () => {
    let temp = addressList.map((addr) => {
      if (location.id === addr.id) {
        return {
          ...addr,
          flights: addr.flights + 1,
        };
      } else {
        return addr;
      }
    });
    setAddressList(temp);
  };
  //subtrct
  const handleSubtract = () => {
    let temp = addressList.map((addr) => {
      if (location.id === addr.id) {
        return {
          ...addr,
          flights: addr.flights - 1,
        };
      } else {
        return addr;
      }
    });
    setAddressList(temp);
  };

  return (
    <div className="col-12 col-sm-12 col-md-6 mb-4 d-flex">
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <h6 className="text-white">Address {location.label}</h6>
        </div>
        <div className="request-left-box p-3 p-sm-4">
          <div className="add-info-wrapper">
            <div className="row">
              <div className="col-12">
                <p className="small-font-size font-weight-bold">
                  <span>
                    <img src={location.icon} />
                  </span>
                  <span>{"  " + location.address}</span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-6">
                <div className="form-group">
                  <label
                    htmlFor="unitNumber"
                    className="overline dark-grey-color"
                  >
                    Unit or apartment number
                  </label>
                  <input
                    type="text"
                    value={location.unitNumber}
                    onChange={handleChange}
                    className="form-control small-font-size"
                    id="unitNumber"
                    placeholder="123"
                  />
                </div>
              </div>
              <div className="col-12 col-xl-6">
                <div className="form-group">
                  <label
                    htmlFor="zip_code"
                    className="overline dark-grey-color"
                  >
                    Zip or Postal code
                  </label>
                  <input
                    type="text"
                    value={location.zip_code}
                    onChange={handleChange}
                    style={{
                      border: location.isZipEmpty && "solid 1px red",
                    }}
                    className="form-control small-font-size"
                    id="zip_code"
                    placeholder="zip code"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label
                    htmlFor="addressDetails"
                    className="overline dark-grey-color"
                  >
                    Address Details
                  </label>
                  <input
                    type="text"
                    value={location.addressDetails}
                    onChange={handleChange}
                    className="form-control small-font-size"
                    id="addressDetails"
                    placeholder="Details"
                  />
                </div>
                <div className="helper-hardwood-floor border-bottom mb-3 ">
                  <div className="row">
                    <div className="col-12 pb-2">
                      <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                        {" "}
                        Pick up address
                        <input
                          type="radio"
                          id="pick-up"
                          className="custom-radio"
                          value="pick-up"
                          onChange={handleChange}
                          checked={location.type === "pick-up" && true}
                        />
                        <span className="custom-radio-box"></span>
                      </label>
                    </div>
                    <div className="col-12 ">
                      <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                        {" "}
                        Drop off address
                        <input
                          type="radio"
                          id="drop-off"
                          className="custom-radio"
                          value="drop-off"
                          onChange={handleChange}
                          checked={location.type === "drop-off" && true}
                        />
                        <span className="custom-radio-box"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <label className="dark-grey-color mb-0 custom-checkbox-wrapper">
                  Storage
                  <input
                    id="isStorage"
                    type="checkbox"
                    name="storage"
                    value={location.isStorage}
                    checked={location.isStorage}
                    onChange={handleChange}
                  />
                  <span className="custom-check-box"></span>
                </label>
              </div>
            </div>
            <hr />
            <div className="helper-elevator border-bottom pt-1">
              <div className="row">
                <div className="col-12">
                  <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                    {" "}
                    Mover(s) can use elevator
                    <input
                      type="radio"
                      id="liftType2"
                      className="custom-radio"
                      value="elevator"
                      onChange={handleChange}
                      checked={location.liftType === "elevator" && true}
                    />
                    <span className="custom-radio-box"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="helper-stairs border-bottom pt-3">
              <div className="row">
                <div className="col-12">
                  <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                    Mover(s) need to use stairs
                    <input
                      type="radio"
                      className="custom-radio"
                      value="stairs"
                      id="liftType1"
                      onChange={handleChange}
                      checked={location.liftType === "stairs" && true}
                    />
                    <span className="custom-radio-box"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label htmlFor="trucks" className="overline dark-grey-color">
                    Number of flights
                  </label>
                  <div className="flights-count">
                    <img
                      style={{ cursor: "pointer" }}
                      src={subtractImage}
                      onClick={() => {
                        if (
                          location.flights > 0 &&
                          location.liftType === "stairs"
                        ) {
                          handleSubtract();
                        }
                      }}
                    />
                    <span
                      className="movers-count-box small-font-size black-color text-center"
                      style={{
                        color: location.liftType !== "stairs" && "#C4C4C4",
                      }}
                    >
                      {location.flights==0?"Ground Floor":location.flights}
                    </span>
                    <img
                      style={{ cursor: "pointer" }}
                      src={addImage}
                      onClick={() => {
                        if (location.liftType === "stairs") {
                          handleAdd();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="helper-hardwood-floor border-bottom pt-3">
              <div className="row">
                <div className="col-12">
                  <label className="dark-grey-color mb-0 custom-checkbox-wrapper">
                    Has hardwood floors
                    <input
                      id="isHardwoodFloor"
                      type="checkbox"
                      name="storage"
                      value={location.isHardwoodFloor}
                      checked={location.isHardwoodFloor}
                      onChange={handleChange}
                    />
                    <span className="custom-check-box"></span>
                  </label>
                </div>
              </div>
            </div> */}

            <div className="parking-info pt-3">
              <div className="row">
                <div className="col-12 col-sm-9">
                  <div className="form-group">
                    <label
                      htmlFor="parkingInfo"
                      className="overline dark-grey-color"
                    >
                      Parking and building info
                    </label>
                    <input
                      type="text"
                      className="form-control small-font-size"
                      id="parkingInfo"
                      placeholder="Parking and building info"
                      value={location.parkingInfo}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationCard;
