import React, { useEffect } from "react";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { invokeApi } from "../../bl_libs/invokeApi";
import { validatePasswordStrength } from "../../ui_libs/Validations/validations";
function SetPassword(props) {
  const [errMessage, setErrMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [token, setToken] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputs, setInputs] = React.useState({
    email: props.email,
    password1: "",
    password2: "",
  });
  const [passStrength, setPassStrength] = React.useState({
    color: "",
    strength: "",
    level: 0,
  });

  const handleChange = (e) => {
    if (e.target.id === "password1") {
      setPassStrength(validatePasswordStrength(e.target.value));
    }
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //validations step for password
    if (inputs.password1.length < 8) {
      setErrMessage("Minimum 8 characters required for password");
      return;
    }
    //
    //check password strength
    //
    if (passStrength.level < 3) {
      setErrMessage(
        "A minimum 8 characters password contains a combination of uppercase letter and number. And at least one special character are required."
      );
      return;
    }
    //match password

    if (inputs.password1 !== inputs.password2) {
      setErrMessage("Your password and confirmation password does not match");
      return;
    }
    let requestObj = {
      path: "/app_api/change_password",
      method: "POST",
      headers: {},
    };
    let postData = {
      password: inputs.password1,
      confirm_password: inputs.password1,
      jwt_token: props.match.params.token,
    };
    console.log(postData);
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res);
        setErrMessage("");
        // setIsLoading(false);
        setSuccessMessage("Password updated successfully");

        setTimeout(() => {
          props.history.push("/login");
        }, 2000);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
    // props.history.push("/dashboard");
  };

  useEffect(() => {}, []);

  if (isLoading) {
    return (
      <>
        {successMessage && (
          <Alert variant="filled" severity="success">
            {successMessage}
          </Alert>
        )}
        <>
          <div className="Circular-Progress">
            <CircularProgress />
          </div>
        </>
      </>
    );
  }
  return (
    <section className="main-content ">
      <div className="container-fluid">
        <div className="row">
          <div className="col-0 col-md-3 col-xl-4"></div>
          <div className="col-12 col-md-6 col-xl-4">
            {errMessage && (
              <Alert variant="filled" severity="error">
                {errMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert variant="filled" severity="success">
                {successMessage}
              </Alert>
            )}

            <div className="form-box-wrapper">
              <div className="form-box">
                <h5 className="text-center">Set password</h5>
                <div className="evom-form login-form">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="overline dark-grey-color"
                      >
                        New Password
                      </label>
                      <input
                        required
                        type="password"
                        value={inputs.password1}
                        onChange={handleChange}
                        className="form-control small-font-size"
                        id="password1"
                        placeholder="*********"
                      />
                      {passStrength.level > 0 && (
                        <div
                          className="progress"
                          style={{ height: 2, marginTop: -3 }}
                        >
                          <div
                            className={
                              "progress-bar " +
                              ((passStrength.level < 2 && "bg-danger") ||
                                // (passStrength.level === 2 && "bg-info") ||
                                (passStrength.level === 3 &&
                                  inputs.password1.length >= 8 &&
                                  "bg-success"))
                            }
                            role="progressbar"
                            style={{ width: passStrength.level * 33.333 + "%" }}
                            aria-valuenow={passStrength.level}
                            aria-valuemin="0"
                            aria-valuemax="3"
                          ></div>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="overline dark-grey-color"
                      >
                        Re-enter New Password
                      </label>
                      <input
                        required
                        value={inputs.password2}
                        onChange={handleChange}
                        type="password"
                        className="form-control small-font-size"
                        id="password2"
                        placeholder="*********"
                      />
                    </div>
                    <div className="form-group button-wrapper">
                      <button
                        type="submit"
                        className="custom-buton custom-buton-large w-100"
                      >
                        Confirm New Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-0 col-md-3 col-xl-4"></div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(SetPassword);
