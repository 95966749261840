import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { invokeApi } from "../../../bl_libs/invokeApi";
import { useSnakbar } from "../../../Context/Context-Snakbar/ContextSnackbar";
import AreaNotFound from "./AreaNotFound";
import Done from "./Done";
import GoogleMapTracking from "./google-Map-Tracking/GoogleMapTracking";
function AddressPickUp(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();

  const { handleSelectAddress, address, setAddress, isDirectRequest } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("address");

  const checkZipCode = (zip) => {
    return new Promise(function (resolve, reject) {
      // do a thing, possibly async, then…
      let requestObj = {
        path: "/api/customer/check_service_area",
        method: "POST",
        headers: {
          "x-sh-auth": localStorage.getItem("token"),
        },
      };
      let postData = {
        zip_code: zip,
      };
      requestObj["postData"] = postData;

      invokeApi(requestObj).then((res) => {
        console.log(res, "CHECK_ZIP_CODE");
        resolve(res);
      });
    });
  };

  const handleClick = async () => {
    console.log(address.address);
    if (address.address === "") {
      return;
    }
    console.log(address, "ADDRESS");
    setIsLoading(true);
    let result = { code: 200 };

    if (!isDirectRequest) {
      result = await checkZipCode(address.zip_code);
    }
    console.log(result, "RESULT");
    if (result.code === 200) {
      //service available in this area
      handleSelectAddress();
    } else {
      setCurrentScreen("not_in_your_area");
    }
    setIsLoading(false);
  };

  const AddInWishList = (inputs) => {
    let requestObj = {
      path: "/api/customer/add_wishlist_area",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      email: inputs.email,
      zip_code: inputs.zip,
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "ADD IN WISHLIST");
      if (res.code === 200) {
        showSuccessSnak("Zipcode added into our waitlist.");
        setCurrentScreen("done");
        setAddress({
          zip_code: "",
          address: "",
          lat: "",
          lng: "",
        });
      } else {
        //showErrorSnak(res.message);
      }
      setIsLoading(false);
    });
  };

  const getEmail = () => {
    return JSON.parse(localStorage.getItem("user")).email;
  };

  const closePopUp = () => {
    document.getElementById("address-a-close").click();
  };

  if (isLoading || window.map) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }

  if (currentScreen === "done") {
    return (
      <>
        <Done closePopUp={closePopUp} />
      </>
    );
  }

  if (currentScreen === "not_in_your_area") {
    return (
      <>
        <AreaNotFound
          zip={address.zip_code}
          email={getEmail()}
          AddInWishList={AddInWishList}
        />
      </>
    );
  }

  return (
    <>
      <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
        <div className="modal-body p-0">
          {/* <h5 className="text-center" id="change-password-title">
            Pickup Address
          </h5> */}
          <div className="form-box">
            <div className="" style={{}}>
              <GoogleMapTracking address={address} setAddress={setAddress} />
            </div>
            <div className="form-group pt-3">
              <button
                onClick={handleClick}
                type="button"
                className="red-btn custom-buton custom-buton-large w-100"
              >
                Set
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddressPickUp;
