import React, { useState } from "react";
import AddressCard from "./components/Address-Card/AddressCard";
import {
  userIconImage,
  dateIconImage,
  timeIconImage,
  roadIconImage,
  truckIconImage,
  mapImage,
  addressAImage,
  addressBImage,
  photoCountImage,
} from "../../../../../../assets";
import GoogleMaps from "./components/google-Map-Tracking/GoogleMaps";
import { useSockets } from "../../../../../../Context/Context-Sockets/ContextSockets";
import { withRouter } from "react-router-dom";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import { Alert } from "@material-ui/lab";
import { time_convert } from "../../../../../../ui_libs/Functions/functions";

function Addresses(props) {
  const { OpenChatFromRequest } = useSockets();

  const {
    request_address,
    request,
    request_customer,
    requestData,
    fetchRequestDetails,
  } = props;
  const { first_name, last_name } = request_customer;
  const {
    request_timeline_status,
    s_time,
    net_distance,
    move_type,
    request_type,
    id,
    customer_first_name,
    customer_last_name,
    start_window_from,
    start_window_to,
    s_date,
  } = request;

  const [selected, setSelected] = useState("");
  const [popup, setPopup] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  console.log(request, "request...");
  const handleModel = (address) => {
    console.log(address, "handleModel");
    setErrMessage("");
    setPopup(false);
    setSelected(address);
    setTimeout(() => {
      setPopup(true);
      document.getElementById("model-div").click();
    }, 200);
  };

  const closeModel = () => {
    if (document.getElementById("close-delete-address")) {
      document.getElementById("close-delete-address").click();
    }
  };

  const DeleteAddress = () => {
    if (request_address.length < 3) {
      return setErrMessage(
        "Minimum two address is required. If you want to change please edit your address or add new address then delete this."
      );
    }

    const editAddressUrl = requestData.request.request_type
      ? "/app_api/delete_address"
      : "/api/customer/delete_address";

    let requestObj = {
      path: editAddressUrl,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      request_id: `${props.match.params.requestId}`,
      address_id: `${selected.id}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        closeModel();
        fetchRequestDetails();
      } else {
        setErrMessage(res.message);
      }
    });
  };
  const handleOpenChat = () => {
    if (request_timeline_status < 2) {
      return;
    }
    OpenChatFromRequest(requestData.chat_data);
    props.history.push("/company/chats");
  };
  return (
    <>
      <div className="request-info p-3 pt-4">
        <div className="row">
          <div className="col-12">
            <span className="user-icon">
              <img src={userIconImage} />
            </span>
            <span className="light-red-color small-font-size pl-2 pr-2">
              {first_name && first_name + " " + last_name}
              {!first_name && customer_first_name + " " + customer_last_name}
            </span>
            <span className="chat-button-wrapper">
              <button
                disabled={
                  request_timeline_status < 3 || request_timeline_status > 6
                    ? true
                    : false
                }
                className={
                  request_timeline_status < 3 || request_timeline_status > 6
                    ? "disabled-chat chat-button custom-btn-small light-grey-color"
                    : "chat-button custom-btn-small red-btn light-grey-color"
                }
                onClick={handleOpenChat}
              >
                Chat
              </button>
            </span>
            {/* //not show this if chat is enable */}
            {(request_timeline_status < 2 || request_timeline_status < 3) &&
              !request_type && (
                <span className="light-grey-color caption pl-2">
                  You will have access to the chat after paid to escrow.
                </span>
              )}
          </div>
        </div>
        <div className="row req-date-time-info pt-20 no-gutters pb-sm-4">
          <div className="col-6 col-xl-3 req-date-time-info-box">
            <div className="row no-gutters small-font-size">
              <div className="col-2">
                <img src={dateIconImage} />
              </div>
              <div className="col-10 pl-1 pl-sm-0 pr-1 req-info-text">
                {/* {schedule_time.slice(0, 11)} */}
                {s_date}
              </div>
            </div>
          </div>
          <div className="col-6 col-xl-3 req-date-time-info-box small-font-size">
            <div className="row no-gutters">
              <div className="col-2">
                <img src={timeIconImage} />
              </div>
              <div className="col-10 pl-1 pl-sm-0 pr-1 req-info-text">
                {/* {`${time_convert(s_time.slice(0,5))} to ${time_convert(s_time.slice(8,13))}`} */}
                {s_time &&
                  s_time !== "8AM - 12PM Morning" &&
                  s_time !== "12PM - 5PM Evening" &&
                  s_time !== "Entire day" &&
                  `${time_convert(
                    s_time.split("-")[0].trim()
                  )} to ${time_convert(s_time.split("-")[1].trim())}`}
                {s_time &&
                  (s_time == "8AM - 12PM Morning" ||
                    s_time == "12PM - 5PM Evening" ||
                    s_time == "Entire day") &&
                  `${s_time}`}
                {/* <br /> */}
                {/* {(start_window_from || start_window_to) &&
                  `${
                    start_window_from ? time_convert(start_window_from) : "-"
                  } to ${
                    start_window_to ? time_convert(start_window_to) : "-"
                  }`} */}
              </div>
            </div>
          </div>
          <div className="col-6 col-xl-3 req-date-time-info-box small-font-size">
            <div className="row no-gutters">
              <div className="col-2">
                <img src={roadIconImage} />
              </div>
              <div className="col-10 pl-1 pl-sm-0 pr-1 req-info-text">
                {net_distance} miles
              </div>
            </div>
          </div>
          <div className="col-6 col-xl-3 req-date-time-info-box small-font-size">
            <div className="row no-gutters">
              <div className="col-2">
                <img src={truckIconImage} />
              </div>
              <div className="col-10 pl-1 pl-sm-0 pr-1 req-info-text">
                {move_type}
              </div>
            </div>
          </div>
        </div>
        <div className="row req-map">
          <div className="col-12">
            {request_address.length !== 0 && (
              <GoogleMaps addressList={request_address} />
            )}
          </div>
        </div>
        <div className="req-addresses-wrapper row mt-4">
          {request_address.map((address, i) => (
            <AddressCard
              key={i}
              index={i}
              address={address}
              request_id={id}
              handleModel={handleModel}
              request_timeline_status={request_timeline_status}
              request_type={request_type}
            />
          ))}
        </div>

        <div className="row mt-2 pb-2">
          <div className="col-12 justify-content-center d-flex">
            {request.request_type && request.request_timeline_status < 6 && (
              <button
                className="custom-btn-small custom-buton"
                onClick={() =>
                  props.history.push("/company/my-requests/add-address/" + id)
                }
              >
                Add new
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        data-toggle="modal"
        data-target="#delete-address"
        id="model-div"
      ></div>
      <div
        className="modal fade"
        id="delete-address"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="logout-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            {errMessage && (
              <Alert variant="filled" severity="error">
                {errMessage}
              </Alert>
            )}
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-delete-address"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {popup && (
              <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
                <div className="modal-body p-0">
                  <h5 className="text-center" id="logout-title">
                    Delete Address
                  </h5>

                  <p className="text-center sub-title pb-2 mb-1 pt-4">
                    Are your sure you want to delete this address?
                  </p>
                  <div className="text-center pb-4">
                    <span className="black-color text-center ">
                      "{selected.complete_address}"
                    </span>
                  </div>
                  <div className="row justify-content-center text-center">
                    <div className="col-6">
                      <button
                        onClick={DeleteAddress}
                        className="custom-btn-small custom-buton float-right"
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="custom-btn-small custom-buton custom-button-pink float-left"
                        // onClick={closeModel}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Addresses);
