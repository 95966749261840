import React from "react";

function RequestInfo({ request }) {
  //  request_by_company_link: 0,
  // 	request_by_company: 1,
  // 	request_by_salesman_link: 2,
  // 	request_by_salesman: 3,
  // 	request_by_manager_link: 4,
  // 	request_by_manager: 5
  const get_sub_string = () => {
    if (
      request.request_data.created_by === 0 &&
      !request.request_data.request_type
    ) {
      return (
        <span className=" extra-small-font-size ">
          Created by{" "}
          <span className="light-red-color">
            {request.customer_profile?.first_name +
              " " +
              request.customer_profile?.last_name}
          </span>{" "}
          in <span className="light-red-color">Market Place</span>.
        </span>
      );
    }

    /* by company link */
    if (
      request.request_data.created_by === 0 &&
      request.request_data.request_type
    ) {
      return (
        <span className=" extra-small-font-size ">
          Created by{" "}
          <span className="light-red-color">
            {request.customer_profile?.first_name +
              " " +
              request.customer_profile?.last_name}
          </span>{" "}
          using{" "}
          <span className="light-red-color">
            {request.company_profile?.name}
          </span>{" "}
          link.
        </span>
      );
    }

    /*created by company */
    if (request.request_data.created_by === 1) {
      return (
        <span className=" extra-small-font-size ">
          Created by company{" "}
          <span className="light-red-color">
            {request.company_profile?.name}
          </span>
          .
        </span>
      );
    }

    /* by salesman link */
    if (request.request_data.created_by === 2) {
      return (
        <span className=" extra-small-font-size ">
          Created by{" "}
          <span className="light-red-color">
            {request.customer_profile?.first_name +
              " " +
              request.customer_profile?.last_name}
          </span>{" "}
          using{" "}
          <span className="light-red-color">
            {request.sale_man?.first_name + " " + request.sale_man?.last_name}
          </span>{" "}
          link.
        </span>
      );
    }

    /* by manager's link */
    if (request.request_data.created_by === 4) {
      return (
        <span className=" extra-small-font-size ">
          Created by{" "}
          <span className="light-red-color">
            {request.customer_profile?.first_name +
              " " +
              request.customer_profile?.last_name}
          </span>{" "}
          using{" "}
          <span className="light-red-color">{request.manager_id?.name}</span>{" "}
          link.
        </span>
      );
    }

    /*created by salesman */
    if (request.request_data.created_by === 3) {
      return (
        <span className=" extra-small-font-size ">
          Created by{" "}
          <span className="light-red-color">
            {request.sale_man?.first_name + " " + request.sale_man?.last_name}
          </span>{" "}
          against{" "}
          <span className="light-red-color">
            {request.customer_profile?.first_name +
              " " +
              request.customer_profile?.last_name}
          </span>
          .
        </span>
      );
    }

    /*created by company */
    if (request.request_data.created_by === 5) {
      return (
        <span className=" extra-small-font-size ">
          Created by manager{" "}
          <span className="light-red-color">{request.manager_id?.name}</span>
        </span>
      );
    }
  };

  return <>{get_sub_string()}</>;
}

export default RequestInfo;
