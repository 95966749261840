import React, { useEffect, useState } from "react";
import SubscriptionCard from "./components/Subscription-Card/SubscriptionCard";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { invokeApi } from "../../bl_libs/invokeApi";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
import { CircularProgress } from "@material-ui/core";
import SubscriptionSuccess from "./components/Subscription-Success/SubscriptionSuccess";
import SubscriptionPayment from "./components/Subscription-Payment/SubscriptionPayment";
function ShowSubscriptions(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const [isLoading, setIsLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const [allPlans, selectAllPlans] = useState([]);
  const [userSubscription, setUserSubscription] = useState({});

  const HitSubscription = (id) => {
    let requestObj = {
      path: "/api/company/create_subscription",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      subscription_id: `${id}`,
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showSuccessSnak("you're subscribed successfully.");
        GetCompanyProfile();
        setIsSubscribed(true);
      }
      setIsLoading(false);
    });
  };

  const HitFreeTrail = () => {
    let requestObj = {
      path: "/api/company/create_free_trial_subscription",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showSuccessSnak("you're subscribed free trail successfully.");
        GetCompanyProfile();
        setIsSubscribed(true);
      }
      setIsLoading(false);
    });
  };

  const GetCompanyProfile = (type) => {
    let requestObj = {
      path: "/api/company/profile_company",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({ ...res.profile, company_rating: res.company_rating })
        );
        if (type === "done") {
          props.history.push("/company");
        }
      } else {
        //showErrorSnak(res.message);
      }
      setIsLoading(false);
    });
  };

  const CheckForSubscription = () => {
    let requestObj = {
      path: "/api/company/get_subscription",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setUserSubscription(res.subscribtion_data);
        setIsLoading(false);
      }
    });
  };

  const fetchPlans = () => {
    let requestObj = {
      path: "/api/admin/list_subscription_available",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        selectAllPlans(res.Subscription_program_list);
        CheckForSubscription();
      }
    });
  };

  const handleSelectPlan = (plan) => {
    // if (plan.amount === "0") {
    //   props.history.push("/");
    //   return;
    // }

    setSelectedPlan(plan);
    setIsPayment(true);
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  if (isLoading) {
    return (
      <div className="Circular-Progress w-100">
        <CircularProgress />
      </div>
    );
  }

  if (isSubscribed) {
    return (
      <div className="w-100">
        <SubscriptionSuccess />
      </div>
    );
  }

  if (isPayment) {
    return (
      <div className="w-100">
        <SubscriptionPayment
          plan={selectedPlan}
          HitSubscription={HitSubscription}
          HitFreeTrail={HitFreeTrail}
        />
      </div>
    );
  }

  return (
    <div className="w-100">
      <div className=" mt-5 pt-4 pb-4">
        <div className="text-center mt-3 mt-lg-0">
          <span className="choose-plan-text">Choose a plan</span>
        </div>
        <div className="planContainer pb-5">
          {allPlans.map((plan, i) => (
            <SubscriptionCard
              plan={plan}
              key={i}
              handleSelectPlan={handleSelectPlan}
              userSubscription={userSubscription}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShowSubscriptions;
