import React, { useState } from "react";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
//   DatePicker,
// } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dateFilterIconImage } from "../../../../assets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SORT_BY_OPTIONS } from "../../../../ui_libs/constants";
function CancelRequestsFilter(props) {
  const {
    filters,
    setFilters,
    moveSizeList,
    truckList,
    fetchCancelledRequests,
    setPage,
    handleClearFilter,
  } = props;

  const handleFilter = () => {
    fetchCancelledRequests("filter");
    setPage(0);
    document.getElementById("close-filter").click();
    localStorage.setItem("directReqCancelFilter", JSON.stringify(filters));
  };

  const handleClear = () => {
    document.getElementById("close-filter").click();
    handleClearFilter();
    fetchCancelledRequests();
  };

  return (
    <>
      {/* <!-- Change Filter --> */}
      <div
        className="modal fade pb-m-80 mt-m-80"
        id="change-filter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-filter-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-filter"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="change-filter-title">
                  Filter
                </h5>
                <div className="form-box pt-20">
                  <form>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 d-flex">
                          <a
                            onClick={() =>
                              setFilters({ ...filters, date_range: "month" })
                            }
                            style={{ width: 150 }}
                            className={
                              filters.date_range === "month"
                                ? "custom-btn-small custom-button-extra-small filter-button active"
                                : "custom-btn-small custom-button-extra-small filter-button "
                            }
                          >
                            Current Month
                          </a>

                          <a
                            onClick={() =>
                              setFilters({ ...filters, date_range: "year" })
                            }
                            className={
                              filters.date_range === "year"
                                ? "custom-btn-small custom-button-extra-small filter-button active"
                                : "custom-btn-small custom-button-extra-small filter-button "
                            }
                          >
                            Current Year
                          </a>

                          <a
                            onClick={() =>
                              setFilters({ ...filters, date_range: "custom" })
                            }
                            className={
                              filters.date_range === "custom"
                                ? "custom-btn-small custom-button-extra-small filter-button active"
                                : "custom-btn-small custom-button-extra-small filter-button "
                            }
                          >
                            Custom
                          </a>
                        </div>
                        {filters.date_range === "custom" && (
                          <>
                            <div className="col-6 col-sm-6 pt-3">
                              <label
                                htmlFor="from-date"
                                className="overline dark-grey-color"
                              >
                                From Date
                              </label>
                              <br></br>

                              <DatePicker
                                className="form-control small-font-size date-picker"
                                id="to-date"
                                placeholderText="MM/DD/YYYY"
                                selected={filters.from_date}
                                onChange={(date) =>
                                  setFilters({ ...filters, from_date: date })
                                }
                                autoComplete="off"
                              />
                            </div>
                            <div className="col-6 col-sm-6 pt-3">
                              <label
                                htmlFor="to-date"
                                className="overline dark-grey-color"
                              >
                                To Date
                              </label>
                              <br></br>
                              <DatePicker
                                className="form-control small-font-size date-picker"
                                id="to-date"
                                placeholderText="MM/DD/YYYY"
                                selected={filters.to_date}
                                onChange={(date) =>
                                  setFilters({ ...filters, to_date: date })
                                }
                                autoComplete="off"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <label
                            htmlFor="from"
                            className="overline dark-grey-color"
                          >
                            Time
                          </label>
                          <select
                            id="from"
                            name=""
                            value={filters.schedule}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                schedule: e.target.value,
                              })
                            }
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm small-font-size"
                          >
                            <option value="">Select Time</option>
                            <option value="8AM - 12PM Morning">
                              {"8AM - 12PM Morning"}
                            </option>
                            <option value="12PM - 5PM Evening">
                              {"12PM - 5PM Evening"}
                            </option>
                            <option value="Entire day">{" Entire day"}</option>
                          </select>
                        </div>
                        <div className="col-12 col-sm-6">
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          >
                            Distance
                          </label>
                          <select
                            name=""
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={filters.distance}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                distance: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Distance</option>
                            <option value="1-2">1-2 miles</option>
                            <option value="3-5">3-5 miles</option>
                            <option value="6-10">6-10 miles</option>
                            <option value="11-20">11-20 miles</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <label
                            htmlFor="move-type"
                            className="overline dark-grey-color"
                          >
                            Move Size
                          </label>
                          <select
                            name="move-type"
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={filters.move_type}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                move_type: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Move Size</option>
                            {moveSizeList.map((size) => (
                              <option value={size.name}>{size.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label
                              htmlFor="move-type"
                              className="overline dark-grey-color"
                            >
                              Truck Name
                            </label>
                            <select
                              name="move-type"
                              className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                              value={filters.truck_name}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  truck_name: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Truck</option>
                              {truckList.map((truck) => (
                                <option value={truck.id}>{truck.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label
                              htmlFor="move-type"
                              className="overline dark-grey-color"
                            >
                              Sort By
                            </label>
                            <select
                              name="move-type"
                              className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                              value={filters.sort_by}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  sort_by: e.target.value,
                                })
                              }
                            >
                              {SORT_BY_OPTIONS.map((option) => (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>{" "}
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label
                              htmlFor="move-type"
                              className="overline dark-grey-color"
                            >
                              Order
                            </label>
                            <select
                              name="move-type"
                              className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                              value={filters.sorting_way}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  sorting_way: e.target.value,
                                })
                              }
                            >
                              <option value="ASC">Ascending</option>
                              <option value="DESC">Descending</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row"></div>
                    <div className="form-group pt-3">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-10">
                          <button
                            type="button"
                            className="red-btn custom-buton custom-buton-large w-100"
                            onClick={handleFilter}
                          >
                            Use Filter
                          </button>
                        </div>
                        <div className="col-12 col-sm-10 mt-3 text-center">
                          <span
                            onClick={handleClear}
                            className="custom-link pointer-point"
                          >
                            Clear Filter
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancelRequestsFilter;
