import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { invokeApi } from "../../bl_libs/invokeApi";
import { useSockets } from "../../Context/Context-Sockets/ContextSockets";

export default function LoginForm(props) {
  const [errMessage, setErrMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const { socketON, socketOFF } = useSockets();

  //auth flag
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const [inputs, setInputs] = React.useState({
    email: "",
    password: "",
  });
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
  };

  const fetchForemanListCount = (token) => {
    return new Promise(function (resolve, reject) {
      let requestObj = {
        path: "/api/company/get_foreman",
        method: "GET",
        headers: {
          "x-sh-auth": token,
        },
      };
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          console.log(res.data);
          resolve(res.data.allForeman.length);
        }
      });
    });
  };

  const fetchTruckListCount = (token) => {
    return new Promise(function (resolve, reject) {
      let requestObj = {
        path: "/api/company/get_truck",
        method: "GET",
        headers: {
          "x-sh-auth": token,
        },
      };
      invokeApi(requestObj).then((res) => {
        if (res.code === 200) {
          console.log(res.data);
          resolve(res.data.allTruck.length);
        }
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //validations step for email
    if (!validateEmail(inputs.email)) {
      setErrMessage("Please enter a valid email");
      return;
    }
    //validations step for password
    if (inputs.password.length < 6) {
      setErrMessage("Minimum 6 characters required for password");
      return;
    }

    let requestObj = {
      path: "/app_api/login",
      method: "POST",
      headers: {},
    };
    let postData = {
      email: inputs.email,
      password: inputs.password,
      user_type: "0",
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then(async (res) => {
      if (res.code === 200) {
        console.log(res);
        setIsAuthenticated(true);

        const welcome = localStorage.getItem("welcome");
        // if user already see welcome screen
        // Send company to app without extra API calling for count
        if (welcome === "false") {
          localStorage.setItem("token", res.data.auth_token);
          // localStorage.setItem("user", res.data.user);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          socketON();
          props.history.push("/company/calender");
        } else {
          // if there is no previous welcome screen info
          // now we decide based on user truck and foreman count
          const foreman = await fetchForemanListCount(res.data.auth_token);
          const truck = await fetchTruckListCount(res.data.auth_token);

          // if there is no count availabe for company

          if (foreman === 0 && truck === 0) {
            //have no count
            //show welcome screen
            localStorage.setItem("token", res.data.auth_token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            socketON();
            props.history.push("/welcome");
            localStorage.setItem("welcome", false);
          } else {
            // have some count
            // dont show welcom screen
            localStorage.setItem("token", res.data.auth_token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            socketON();
            props.history.push("/company/calender");
            localStorage.setItem("welcome", false);
          }
        }
      } else {
        setErrMessage(res.message);
        if (
          res.message === "Invalid email or password" ||
          res.message === "Invalid Email or Password"
        ) {
          setErrMessage(
            "The email and password you entered did not match our records."
          );
        }
        setIsLoading(false);
        setIsAuthenticated(false);
        console.log(res);
      }
    });
  };
  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }
  return (
    <section className="main-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-0 col-md-3 col-xl-4 "></div>
          <div className="col-12  col-md-6 col-xl-4">
            {errMessage && (
              <Alert variant="filled" severity="error">
                {errMessage}
              </Alert>
            )}
            <div className="form-box-wrapper">
              <div className="form-box">
                <h5 className="text-center">Log In</h5>
                <div className="evom-form login-form">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group ">
                      <label
                        htmlFor="email"
                        className="overline dark-grey-color"
                      >
                        Email
                      </label>
                      <input
                        onChange={handleChange}
                        required
                        value={inputs.email}
                        type="email"
                        className="form-control small-font-size"
                        id="email"
                        placeholder="example@gmail.com"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="overline dark-grey-color"
                      >
                        Password
                      </label>
                      <input
                        onChange={handleChange}
                        type="password"
                        required
                        value={inputs.password}
                        className="form-control small-font-size"
                        id="password"
                        placeholder="*********"
                      />
                    </div>
                    <div className="form-group">
                      <p className="text-right">
                        <Link
                          to="/forget-password"
                          className="caption dark-grey-color underline-text"
                        >
                          Forgot password?
                        </Link>
                      </p>
                    </div>
                    <div className="form-group button-wrapper">
                      <button
                        type="submit"
                        className="custom-buton custom-buton-large w-100"
                      >
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-0 col-md-3 col-xl-4 "></div>
        </div>
        <div className="no-account-text-wrapper-custom text-center mt-4 pt-4 mt-sm-5 pt-sm-5">
          <p className="light-grey-color pb-5">
            Don't have an account?{" "}
            <Link to="/quick-advice" className="dark-grey-color">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
}
