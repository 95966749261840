export const getMovers = (requestData) => {
  if (requestData.request.request_type) {
    return requestData.request.movers;
  } else {
    return requestData.request_bid.movers;
  }
};

export const getStatusForCompany = (status, additional_status) => {
  if (status === 0) {
    return "New Request";
  }
  if (status === 1) {
    return "Bid submitted";
  }
  if (status === 2) {
    return "Bid accepted";
  }
  if (status === 3 && additional_status === 0) {
    return "Awaiting Customer Response";
  }
  if (status === 3 && additional_status === 1) {
    return "Direct Request Accepted";
  }
  if (status === 4) {
    return "Truck assigned and ready to move";
  }
  if (status === 5) {
    return "Move in progress";
  }
  if (status === 6) {
    return "Completed, waiting for confirmation";
  }
  if (status === 7) {
    return "Completed";
  }
  if (status === 8) {
    return "Feedback Given";
  }
};

export function time_convert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }

  return time.join(""); // return adjusted time or original string
}

export const CUSTOM_TIME_DROPDOWN = () => {
  let hours_list = [];

  for (let i = 0; i <= 23; i++) {
    let hour = i;

    if (i < 10) {
      hour = "0" + i;
    }
    const mins = ["00", "30"];

    const hours = mins.map((m) => `${hour}:${m}`);

    hours_list = hours_list.concat(hours);
  }
  return hours_list;
};
