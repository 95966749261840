import React from "react";
import { toDoImage } from "../../../../assets";

function AddItemsOption(props) {
  const { addItemsOptionType } = props;
  return (
    <>
      <section className="main-content address-page">
        <div className="container">
          <div className="add-items-wrapper mb-m-90" style={{paddingBottom:"90px"}}>
            <h1 className="text-center text-uppercase pt-100 pt-m-60">
              How do you want to add items?
            </h1>
            <div className="to-do-img text-center pt-40 pb-40">
              <img src={toDoImage} />
            </div>
            <div className="row text-center justify-content-center">
              <div className="col-12 col-sm-8 light-grey-color small-font-size text-center">
                You can add items on the behalf of the customer. You do not have
                to enter the entire inventory list for your move, this is
                completely optional and helps us to provide the customer a more
                accurate quote as well as suggests your moving crew plan on what
                to expect on the move date itself.
              </div>
            </div>
            <div className="row pt-30 justify-content-center">
              <div className="col-6 col-sm-3">
                <a>
                  <button
                    onClick={() => {
                      props.setScreen(3);
                      props.setAddItemsOptionType("by_room");
                    }}
                    className={
                      addItemsOptionType === "by_room"
                        ? "custom-buton custom-buton-large w-100"
                        : "custom-buton custom-buton-large custom-button-pink w-100"
                    }
                  >
                    By Room
                  </button>
                </a>
              </div>
              <div className="col-6 col-sm-3">
                <a>
                  <button
                    onClick={() => {
                      props.setScreen(4);
                      props.setAddItemsOptionType("by_common_list");
                    }}
                    className={
                      addItemsOptionType === "by_common_list"
                        ? "custom-buton custom-buton-large w-100"
                        : "custom-buton custom-buton-large custom-button-pink w-100"
                    }
                  >
                    Common List
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddItemsOption;
