import { CircularProgress, TablePagination } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { invokeApi } from "../../bl_libs/invokeApi";
import moment from "moment";

function EmailTemplates(props) {
  const [requests, SetRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilter, setIsFilter] = useState(false);
  //------------------------------------------>  pagination settings
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);
  //----------------------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //////////////////////////////////////////////////////////////////
  const getStatusForCompany = (status) => {
    if (status === 0) {
      return "New Request";
    }
    if (status === 1) {
      return "Bid submitted";
    }
    if (status === 2) {
      return "Bid accepted";
    }
    if (status === 3) {
      return "Paid to Escrow";
    }
    if (status === 4) {
      return "Truck assigned and ready to move";
    }
    if (status === 5) {
      return "Move in progress";
    }
    if (status === 6) {
      return "Completed, waiting for confirmation";
    }
    if (status === 7) {
      return "Completed";
    }
    if (status === 8) {
      return "Feedback Given";
    }
  };

  const fetchActiveRequests = (type) => {
    let requestObj = {
      path: `/api/company/list_email_template?page=${page}&limit=${rowsPerPage}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        SetRequests(res.emailtemplate);
        setIsLoading(false);
      }
    });
  };

  //filter state management
  const [filters, setFilters] = useState({
    from_date: "",
    to_date: "",
    schedule: "",
    distance: "",
    move_type: "",
    truck_name: "",
  });

  const [moveSizeList, setMoveSizeList] = useState([]);
  const [truckList, setTruckList] = useState([]);

  //fetch and refresh truck list data from api

  const fetchTruckList = () => {
    let requestObj = {
      path: "/api/company/get_truck",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        setTruckList(res.data.allTruck);
      }
    });
  };

  const fetchListForMoverType = () => {
    let requestObj = {
      path: `/api/customer/get_move_type?page=${page}&limit=${rowsPerPage}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "MOVE SIZE RESPONSCE");
      if (res.code === 200) {
        setMoveSizeList(res.data.moveTypeItems);
      }
    });
  };

  useEffect(() => {
    fetchActiveRequests();
  }, [page, rowsPerPage]);

  if (isLoading) {
    return (
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title"> Company Email Templates</p>
          <div className="container-fluid">
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid min-width-650">
            <p className="small-text-bold page-title">
              Company Email Templates
            </p>
            <div className="calendar-wrapper mt-40 mt-m-20 pb-30">
              <div className="row"></div>
              <div className="rounded-box pb-3">
                <div className="row">
                  <div className="col-12">
                    <table
                      id="example"
                      className="responsive table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th className="small-sub-title dark-grey-color">#</th>
                          <th className="small-sub-title dark-grey-color">
                            Email Title
                          </th>
                          <th className="small-sub-title dark-grey-color">
                            Email Type
                          </th>
                          <th className="small-sub-title dark-grey-color">
                            Email Subject
                          </th>
                          <th className="small-sub-title dark-grey-color">
                            Last Update
                          </th>

                          {/* <th className="small-sub-title dark-grey-color">
                            Status
                          </th> */}

                          <th className="small-sub-title dark-grey-color"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((email, i) => (
                          <tr
                            key={i}
                            className="pointer-point"
                            onClick={() =>
                              props.history.push(
                                "/email-templates/edit-email-template/" +
                                  email.id
                              )
                            }
                          >
                            <td>{i + 1}</td>
                            <td>{email.title}</td>
                            <td>{email.type}</td>
                            <td>{email.email_subject}</td>
                            <td>{moment(email.updated_at).fromNow()}</td>

                            <td></td>
                          </tr>
                        ))}
                        {/* loop Close */}
                      </tbody>
                    </table>
                    {/* <TablePagination
                      component="div"
                      count={totalCount}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage={
                        <span className="pagination-text">Rows per page:</span>
                      }
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {requestType === "active" && <ActiveFilter />} */}
        {/* {requestType === "cancelled" && <CancelRequestsFilter />} */}
        {/* {requestType === "done" && <DoneRequestFilter />} */}
      </>
    </>
  );
}

export default withRouter(EmailTemplates);
