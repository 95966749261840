import React, { useEffect, useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ContextSocket } from "../Context/Context-Sockets/ContextSockets";
import {
  Company,
  Calender,
  CompanyRequests,
  Chats,
  PaymentHistory,
  AboutUs,
  SearchScreen,
  RequestPage,
  EditRequestAcceptance,
  BankAccount,
  Transactions,
  WithdrawScreen,
  EditProfile,
  MyCards,
  DirectRequest,
  AddAddress,
  EditAddress,
  EditRequestItems,
  EditRequestExtraItems,
  ShowSubscriptions,
  SubscriptionBilling,
  CompanyStats,
} from "../pages";

function Routes(props) {
  return (
    <Switch>
      <Route exact path="/company" component={Company} />
      <Route exact path="/company/edit-profile" component={EditProfile} />
      <Route exact path="/company/search" component={SearchScreen} />
      <Route path="/company/search/:requestId" component={RequestPage} />
      <Route path="/company/calender" component={Calender} />
      <Route
        path="/company/company-requests"
        exact
        component={CompanyRequests}
      />
      <Route
        exact
        path="/company/company-requests/:requestId"
        component={RequestPage}
      />
      <Route path="/company/direct-requests" exact component={DirectRequest} />
      <Route
        exact
        path="/company/direct-requests/:requestId"
        component={RequestPage}
      />

      <Route
        exact
        path="/company/my-requests/add-address/:requestId"
        component={AddAddress}
      />
      <Route
        exact
        path="/company/my-requests/edit-address/:requestId/:addressId"
        component={EditAddress}
      />
      <Route
        exact
        path="/company/my-requests/edit-items/:requestId"
        component={EditRequestItems}
      />
      <Route
        exact
        path="/company/my-requests/edit-extra-items/:requestId"
        component={EditRequestExtraItems}
      />
      <Route
        exact
        path="/company/company-requests/change-request/:type/:requestId"
        component={EditRequestAcceptance}
      />
      <Route path="/company/chats" component={Chats} />
      <Route path="/company/my-cards" component={MyCards} />
      <Route path="/company/bank-account" component={BankAccount} />
      <Route path="/company/payment-history" component={PaymentHistory} />
      <Route path="/company/transactions" component={Transactions} />
      <Route path="/company/withdraw" component={WithdrawScreen} />
      <Route path="/company/about-us" component={AboutUs} />
      <Route path="/company/stats" component={CompanyStats} />
      <Route
        exact
        component={SubscriptionBilling}
        path="/company/subscriptions-info"
      />
      {/* subscription */}
      <Route exact component={ShowSubscriptions} path="/subscriptions-plan" />
    </Switch>
  );
}

export default Routes;
