import React, { useState } from "react";
import { useEffect } from "react";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  addImage,
  crossEditImage,
  editWhiteImage,
  subtractImage,
} from "../../../../../../assets";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  CUSTOM_TIME_DROPDOWN,
  getMovers,
  getStatusForCompany,
  time_convert,
} from "../../../../../../ui_libs/Functions/functions";

function EditRequest(props) {
  const { requestData, fetchRequestDetails, setIsLoadingPage, setIsEdit } =
    props;

  const {
    additional_status,
    customer_budget,
    recommend_amount,
    id,
    schedule_time,
    final_amount,
    s_date,
    s_time,
    movers,
    move_type,
    request_timeline_status,
    card_payment_rate,
    cash_payment_rate,
    customer_phone_number,
    request_type,
    rate_method,
    cash_max_flat_rate,
    cash_min_flat_rate,
    card_max_flat_rate,
    card_min_flat_rate,
    start_window_from,
    start_window_to,
    minimum_hours,
    customer_first_name,
    customer_last_name,
    customer_email,
  } = requestData.request;

  const { truck_array, get_foreman_of_company, sale_team } = requestData;

  //animation
  const animatedComponents = makeAnimated();

  // to store foremans and truck list fr om api

  const [foremanList, setForemanList] = useState([]);
  const [salesmanList, setSalesmanList] = useState([]);
  const [truckList, setTruckList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = React.useState("");
  const [warning, setWarning] = useState("");

  const [isLoadingTrucks, setIsLoadingTruck] = useState(true);
  const [isLoadingForeman, setIsLoadingForeman] = useState(true);
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(true);

  const [selectedTrucks, setSelectedTruck] = useState([]);
  const [selectedForeman, setSelectedForeman] = useState("");
  const [selectedSalesman, setSelectedSalesman] = useState("");

  const [moveTypeList, setMoveTypeList] = useState([]);

  //handle change functions
  const handleTruck = (e) => {
    setSelectedTruck(e);
  };

  const handleForeman = (e) => {
    setSelectedForeman(e);
  };

  const handleSalesman = (e) => {
    setSelectedSalesman(e);
  };
  ////

  //fetch and refresh foreman list data from api

  const fetchForemanList = () => {
    let requestObj = {
      path: "/api/company/get_foreman",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        let temp = res.data.allForeman.map((foreman) => {
          return {
            value: foreman.user_model.id,
            label: foreman.first_name + " " + foreman.last_name,
            color: "#c4314b",
          };
        });
        setForemanList(temp);
        setIsLoadingForeman(false);
      }
    });
  };

  //fetch and refresh Salesman list data from api

  const fetchSalesmanList = () => {
    let requestObj = {
      path: "/api/company/get_sale_team",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        let temp = res.sale_team.map((salesman) => {
          return {
            value: salesman.user_model.id,
            label: salesman.first_name + " " + salesman.last_name,
            color: "#c4314b",
          };
        });

        setSalesmanList(temp);

        setIsLoadingSalesman(false);

        const salesman = {
          value: sale_team.user_id,
          label: sale_team.first_name + " " + sale_team.last_name,
        };
        if (sale_team) {
          setSelectedSalesman(salesman);
        }
      }
    });
  };

  //fetch and refresh truck list data from api

  const fetchTruckList = () => {
    let requestObj = {
      path: "/api/company/get_truck",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        let temp = res.data.allTruck.map((truck) => {
          return {
            value: truck.id,
            label: truck.name,
            color: "#c4314b",
          };
        });
        console.log(temp, "TEMP");

        setTruckList(temp);
        setIsLoadingTruck(false);
      }
    });
  };

  const assignForeman = (req_id, foreman) => {
    let requestObj = {
      path: "/api/company/add_foreman_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${req_id}`,
      foreman_id: `${foreman.value}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        assignTrucks(id, selectedTrucks);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const assignSalesman = (req_id, salesman) => {
    let requestObj = {
      path: "/api/company/add_sale_team_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${req_id}`,
      sale_team_id: `${salesman ? salesman.value : ""}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        fetchRequestDetails();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const assignTrucks = (req_id, trucks) => {
    let requestObj = {
      path: "/api/company/add_truck_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let truckIds = trucks.map((truck) => {
      return { id: `${truck.value}` };
    });
    let postData = {
      request_id: `${req_id}`,
      truck_id_array: truckIds,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        assignSalesman(id, selectedSalesman);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const handleClick = () => {
    setErrMessage("");
    setIsLoading(true);
    assignForeman(id, selectedForeman);
  };

  //for handle edit
  let start_from = start_window_from;
  let start_to = start_window_to;
  if (
    s_time !== "8AM - 12PM Morning" ||
    s_time !== "12PM - 5PM Evening" ||
    s_time !== "Entire day"
  ) {
    start_from = s_time.split("-")[0].trim();
    if (start_from !== "null") {
      start_to = s_time.split("-")[1].trim();
    } else {
      start_from = start_window_from;
    }
  } 
  // else if (s_time == "8AM - 12PM Morning"){
  //   start_from = "08:00";
  //   start_to = "12:00"
  // } else if(s_time == "12PM - 5PM Evening" ){
  //   start_from = "12:00";
  //   start_to = "17:00"
  // } else if(s_time == "Entire day"){
  //   start_from = "00:00";
  //   start_to = "11:30"
  // }
  const [inputs, setInputs] = useState({
    id: id,
    date: new Date(s_date),
    time: s_time,
    movers: movers,
    price: final_amount,
    move_type: move_type,
    cash_payment_rate: cash_payment_rate,
    card_payment_rate: card_payment_rate,
    cash_flat_min: cash_min_flat_rate,
    cash_flat_max: cash_max_flat_rate,
    card_flat_min: card_min_flat_rate,
    card_flat_max: card_max_flat_rate,
    customer_phone_number: customer_phone_number,
    start_window_from: start_from,
    start_window_to: start_to,
    minimum_hours: minimum_hours,
    customer_first_name: customer_first_name,
    customer_last_name: customer_last_name,
    customer_email: customer_email,
  });
  console.log(inputs.start_window_from, "startwindaowfrom");
  const SaveChanges = () => {
    if (request_timeline_status > 3) {
      if (!selectedTrucks?.length) {
        setErrMessage("Please select trucks");
        return;
      }
      if (selectedForeman === "") {
        setErrMessage("Please select foreman");
        return;
      }
    }
    if(inputs.start_window_from == "" || !inputs.start_window_from || inputs.start_window_to == "" || !inputs.start_window_to ){
      setErrMessage("Please select valid time");
      return;
    }

    setErrMessage("");
    let requestObj = {
      path: "/app_api/change_in_date_time",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: inputs.id.toString(),
      schedule_time:
        moment(inputs.date).format("MMM Do YY") + " " + inputs.time,
      s_date: moment(inputs.date).format("MM-DD-YYYY"),
      s_time: `${inputs.start_window_from} - ${inputs.start_window_to}`,
      movers: inputs.movers.toString(),
      customer_phone_number: inputs.customer_phone_number,
      start_window_from: inputs.start_window_from,
      start_window_to: inputs.start_window_to,
      customer_first_name: inputs.customer_first_name,
      customer_last_name: inputs.customer_last_name,
      customer_email: inputs.customer_email,
      move_type: inputs.move_type,
    };
    // For CRM also take cash and card price
    // Hourly data update in case of hourly rate
    if (request_type && rate_method === "Hourly") {
      postData.cash_amount = inputs.cash_payment_rate;
      postData.card_amount = inputs.card_payment_rate;
      postData.minimum_hours = inputs.minimum_hours;
    }
    // Flat data update in case of flat rate
    if (request_type && rate_method === "Flat") {
      postData.cash_min_flat_rate = inputs.cash_flat_min;
      postData.cash_max_flat_rate = inputs.cash_flat_max;
      postData.card_min_flat_rate = inputs.card_flat_min;
      postData.card_max_flat_rate = inputs.card_flat_max;
    }

    // return console.log(postData);
    setIsLoadingPage(true);
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "Api Response");
      if (res.code === 200) {
        if (request_timeline_status > 3) {
          handleClick();
        } else {
          fetchRequestDetails();
        }
      } else {
        setErrMessage(res.message);
        setIsLoadingPage(false);
      }
    });
  };

  const handleSubtract = () => {
    if (inputs.movers <= 1) return;
    setInputs({ ...inputs, movers: inputs.movers - 1 });
  };

  const handleAdd = () => {
    setInputs({ ...inputs, movers: inputs.movers + 1 });
  };

  const handleChangeInPrice = (e) => {
    setInputs({ ...inputs, [e.target.id]: e.target.value });
  };

  const getDefaultValues = () => {
    const foreman = {
      value: get_foreman_of_company.user_id,
      label:
        get_foreman_of_company.first_name +
        " " +
        get_foreman_of_company.last_name,
    };

    const trucks = truck_array.map((truck) => {
      return {
        value: truck.id,
        label: truck.name,
      };
    });

    const salesman = {
      value: sale_team.user_id,
      label: sale_team.first_name + " " + sale_team.last_name,
    };
    if (sale_team) {
      setSelectedSalesman(salesman);
    }

    setSelectedTruck(trucks);

    setSelectedForeman(foreman);
  };

  const fetchListForMoverType = () => {
    let requestObj = {
      path: "/api/customer/get_move_type",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      // console.log(res, "MOVE SIZE RESPONSCE");
      if (res.code === 200) {
        setMoveTypeList(res.data.moveTypeItems);
      }
    });
  };

  const get_split_time = (_time) => {
    let _start_window_from = "";
    let _start_window_to = "";
    if (_time === "8AM - 12PM Morning") {
      _start_window_from = "08:00";
      _start_window_to = "12:00";
    } else if (_time === "12PM - 5PM Evening") {
      _start_window_from = "12:00";
      _start_window_to = "17:00";
    } else if (_time === "Entire day") {
      _start_window_from = "08:00";
      _start_window_to = "17:00";
    }

    return { _start_window_from, _start_window_to };
  };

  useEffect(() => {
    if (request_timeline_status > 3) {
      getDefaultValues();
    }
  }, [foremanList, truckList, salesmanList]);

  useEffect(() => {
    fetchForemanList();
    fetchTruckList();
    fetchSalesmanList();
    fetchListForMoverType();
  }, []);

  useEffect(() => {
    setInputs({
      ...inputs,
      time: inputs.start_window_from + " - " + inputs.start_window_to,
    });
  }, [inputs.start_window_from, inputs.start_window_to]);
  console.log(new Date(), "newDate");
  console.log(moment(inputs.date).format("DD/MM/YYYY"), "newDate 2");
  return (
    <>
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <h6 className="text-white pt-1">Request #{id}</h6>
            </div>
            <div className="col-2 text-right">
              <img
                className="pl-1 pointer-point"
                src={crossEditImage}
                onClick={() => setIsEdit(false)}
              ></img>
            </div>
          </div>
        </div>
        {errMessage && (
          <Alert variant="filled" severity="error">
            {errMessage}
          </Alert>
        )}
        <div className="request-left-box p-3 p-sm-4">
          <div className="bid-form-wrapper pt-3">
            <div className="row">
              <div className="col-4 mt-2">
                <label htmlFor="to-date" className="overline dark-grey-color">
                  date:
                </label>
              </div>
              <div className="col-8">
                {inputs.date && (
                  <DatePicker
                    minDate={new Date()}
                    className="form-control small-font-size date-picker"
                    id="to-date"
                    placeholderText="MM/DD/YYYY"
                    selected={inputs.date}
                    onChange={(date) => setInputs({ ...inputs, date })}
                    autoComplete="off"
                  />
                )}
              </div>
              {console.log(s_date, "s_date")}
              {/* <div className="col-4 mt-3">
                {" "}
                <label htmlFor="from" className="overline dark-grey-color">
                  Time
                </label>
              </div>
              <div className="col-8 mt-2">
                <select
                  id="from"
                  name=""
                  value={inputs.time}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      time: e.target.value,
                      start_window_from: get_split_time(e.target.value)
                        ._start_window_from,
                      start_window_to: get_split_time(e.target.value)
                        ._start_window_to,
                    })
                  }
                  className="custom-select custom-select-lg custom-select-sm form-control form-control-sm small-font-size"
                >
                  <option value="8AM - 12PM Morning">
                    {"8AM - 12PM Morning"}
                  </option>
                  <option value="12PM - 5PM Evening">
                    {"12PM - 5PM Evening"}
                  </option>
                  <option value="Entire day">{"Entire day"}</option>
                </select>
              </div> */}

              <div className="col-4 mt-3">
                <label htmlFor="distance" className="overline dark-grey-color">
                  Move Size
                </label>
              </div>
              <div className="col-8 mt-2">
                <select
                  name=""
                  className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                  value={inputs.move_type}
                  id="move_type"
                  onChange={(e) =>
                    setInputs({ ...inputs, move_type: e.target.value })
                  }
                >
                  {moveTypeList.length === 0 && (
                    <option value="">Loading...</option>
                  )}

                  {moveTypeList.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              <dic className="col-12 mt-3">
                <label htmlFor="from" className="overline dark-grey-color">
                  Time window:
                </label>
              </dic>

              <div className="col-12 col-lg-6">
                <label htmlFor="from" className="overline dark-grey-color">
                  From
                </label>

                <select
                  id="from"
                  name=""
                  value={inputs.start_window_from}
                  onChange={(e) =>
                    setInputs({ ...inputs, start_window_from: e.target.value })
                  }
                  className="custom-select custom-select-lg custom-select-sm form-control form-control-sm small-font-size"
                >
                  <option value="">--:-- --</option>
                  {CUSTOM_TIME_DROPDOWN().map((t) => (
                    <option value={t}>{time_convert(t)}</option>
                  ))}
                </select>
              </div>

              <div className="col-12 col-lg-6 pt-md-0">
                <label htmlFor="from" className="overline dark-grey-color">
                  To
                </label>
                <select
                  id="from"
                  name=""
                  value={inputs.start_window_to}
                  onChange={(e) =>
                    setInputs({ ...inputs, start_window_to: e.target.value })
                  }
                  className="custom-select custom-select-lg custom-select-sm form-control form-control-sm small-font-size"
                >
                  <option value="">--:-- --</option>
                  {CUSTOM_TIME_DROPDOWN().map((t) => (
                    <option value={t}>{time_convert(t)}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-12">
                <label htmlFor="email" className="overline dark-grey-color">
                  Movers:
                </label>
              </div>
              <div className="col-12">
                <img
                  className="pointer-point"
                  onClick={handleSubtract}
                  src={subtractImage}
                />
                <span className="movers-count-box small-font-size black-color text-center">
                  {inputs.movers}
                </span>

                <img
                  className="pointer-point"
                  onClick={handleAdd}
                  src={addImage}
                />
              </div>
            </div>
            {request_type && rate_method === "Hourly" && (
              <>
                <div className="row pt-3 small-font-size">
                  <div className="col-6 pt-3 text-left overline dark-grey-color">
                    Cash hour rate:
                  </div>
                  <div className="col-6 text-right black-color">
                    <NumberFormat
                      allowNegative={false}
                      allowLeadingZeros
                      value={inputs.cash_payment_rate}
                      required
                      onChange={handleChangeInPrice}
                      type="text"
                      className="form-control small-font-size"
                      id="cash_payment_rate"
                      placeholder="100"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="row small-font-size pt-3">
                  <div className="col-6 pt-3 text-left overline dark-grey-color">
                    Card hour rate:
                  </div>
                  <div className="col-6 text-right black-color">
                    <NumberFormat
                      allowNegative={false}
                      allowLeadingZeros
                      value={inputs.card_payment_rate}
                      required
                      onChange={handleChangeInPrice}
                      type="text"
                      className="form-control small-font-size"
                      id="card_payment_rate"
                      placeholder="120"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="row small-font-size pt-3">
                  <div className="col-6 pt-3 text-left overline dark-grey-color">
                    Minimum Hours:
                  </div>
                  <div className="col-6 text-right black-color">
                    <NumberFormat
                      allowNegative={false}
                      allowLeadingZeros
                      value={inputs.minimum_hours}
                      required
                      onChange={handleChangeInPrice}
                      type="text"
                      className="form-control small-font-size"
                      id="minimum_hours"
                      placeholder="4"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </>
            )}

            {request_type && rate_method === "Flat" && (
              <>
                <div className="row small-font-size">
                  <div className="col-12 pt-3 pb-2 text-left overline dark-grey-color">
                    Cash flat rate:
                  </div>
                  <div className="col-2 pt-3 text-right overline dark-grey-color">
                    Min:
                  </div>
                  <div className="col-4 text-right black-color">
                    <NumberFormat
                      allowNegative={false}
                      allowLeadingZeros
                      value={inputs.cash_flat_min}
                      required
                      onChange={handleChangeInPrice}
                      type="text"
                      className="form-control small-font-size"
                      id="cash_flat_min"
                      placeholder="100"
                      autoComplete="off"
                    />
                  </div>

                  <div className="col-2 pt-3 text-right overline dark-grey-color">
                    Max:
                  </div>
                  <div className="col-4 text-right black-color">
                    <NumberFormat
                      allowNegative={false}
                      allowLeadingZeros
                      value={inputs.cash_flat_max}
                      required
                      onChange={handleChangeInPrice}
                      type="text"
                      className="form-control small-font-size"
                      id="cash_flat_max"
                      placeholder="120"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row pt-3 small-font-size">
                  <div className="col-12 pt-3 pb-2 text-left overline dark-grey-color">
                    Card flat rate:
                  </div>
                  <div className="col-2 pt-3 text-right overline dark-grey-color">
                    Min:
                  </div>
                  <div className="col-4 text-right black-color">
                    <NumberFormat
                      allowNegative={false}
                      allowLeadingZeros
                      value={inputs.card_flat_min}
                      required
                      onChange={handleChangeInPrice}
                      type="text"
                      className="form-control small-font-size"
                      id="card_flat_min"
                      placeholder="100"
                      autoComplete="off"
                    />
                  </div>

                  <div className="col-2 pt-3 text-right overline dark-grey-color">
                    Max:
                  </div>
                  <div className="col-4 text-right black-color">
                    <NumberFormat
                      allowNegative={false}
                      allowLeadingZeros
                      value={inputs.card_flat_max}
                      required
                      onChange={handleChangeInPrice}
                      type="text"
                      className="form-control small-font-size"
                      id="card_flat_max"
                      placeholder="120"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row small-font-size pt-3 pb-2">
              <div className="col-6 pt-3 text-left overline dark-grey-color">
                Customer Phone #:
              </div>
              <div className="col-6 text-right black-color">
                <NumberFormat
                  onChange={handleChangeInPrice}
                  value={inputs.customer_phone_number}
                  className="form-control small-font-size"
                  id="customer_phone_number"
                  placeholder="(217) 555-0113"
                  format="(###) ###-####"
                  mask="_"
                />
              </div>
            </div>

            <div className="row small-font-size pt-2">
              <div className="col-12 pt-3 text-left overline dark-grey-color">
                Customer First Name:
              </div>
              <div className="col-12 text-right black-color mt-2">
                <input
                  onChange={handleChangeInPrice}
                  value={inputs.customer_first_name}
                  className="form-control small-font-size"
                  id="customer_first_name"
                  placeholder=""
                />
              </div>
            </div>

            <div className="row small-font-size pt-2">
              <div className="col-12 pt-3 text-left overline dark-grey-color">
                Customer Last Name:
              </div>
              <div className="col-12 text-right black-color mt-2">
                <input
                  onChange={handleChangeInPrice}
                  value={inputs.customer_last_name}
                  className="form-control small-font-size"
                  id="customer_last_name"
                  placeholder=""
                />
              </div>
            </div>

            <div className="row small-font-size pt-2">
              <div className="col-12 pt-3 text-left overline dark-grey-color">
                Customer Email:
              </div>
              <div className="col-12 text-right black-color mt-2">
                <input
                  onChange={handleChangeInPrice}
                  value={inputs.customer_email}
                  className="form-control small-font-size"
                  id="customer_email"
                  type="email"
                  placeholder=""
                />
              </div>
            </div>

            {request_timeline_status > 3 && (
              <div className="row">
                <div className="col-12 pt-3">
                  <div className="form-group">
                    <label
                      htmlFor="from-time"
                      className="overline dark-grey-color"
                    >
                      choose Trucks Names
                    </label>

                    <Select
                      defaultValue={selectedTrucks}
                      isMulti
                      value={selectedTrucks}
                      components={animatedComponents}
                      name="name"
                      options={truckList}
                      isLoading={isLoadingTrucks}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleTruck}
                      isDisabled={isLoading}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label
                      htmlFor="from-time"
                      className="overline dark-grey-color"
                    >
                      choose foreman Name
                    </label>

                    <Select
                      defaultValue={selectedForeman}
                      value={selectedForeman}
                      name="name"
                      options={foremanList}
                      isLoading={isLoadingForeman}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleForeman}
                      isDisabled={isLoading}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label
                      htmlFor="from-time"
                      className="overline dark-grey-color"
                    >
                      choose Salesman Name
                    </label>

                    <Select
                      defaultValue={selectedSalesman}
                      value={selectedSalesman}
                      options={salesmanList}
                      isLoading={isLoadingSalesman}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSalesman}
                      isDisabled={isLoading}
                      isClearable
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="row pt-3 pb-3">
              <div className="col-3"></div>
              <div className="col-12 mt-4  justify-content-center d-flex">
                <button
                  className="custom-btn-small custom-buton m-2"
                  onClick={SaveChanges}
                >
                  Save
                </button>
                <button
                  className="custom-buton custom-btn-small custom-button-pink  m-2"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </button>
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditRequest;
