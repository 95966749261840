// common business logic related util methods
import axios from "axios";
import { store } from "react-notifications-component";

axios.defaults.headers.post["Content-Type"] = "application/json";

export const base_uri = process.env.REACT_APP_API_BASE_URL;

export const PK_KEY = process.env.REACT_APP_STRIPE_P_KEY;

export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const googleAnalyticsID = process.env.REACT_APP_GA_ID;

export const DirectLinkUrl = process.env.REACT_APP_DIRECT_LINK_URL;

export const TinyMceEditorKey = process.env.REACT_APP_EDITOR_KEY;

export const googleAuthClientID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

const toastError = (message) => {
  const title = "";
  store.addNotification({
    title,
    message,
    type: "danger",
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export async function invokeApi({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  postData = {},
}) {
  let reqObj = {
    method: method,
    url: base_uri + path,
    headers: headers,
  };

  reqObj["params"] = queryParams;

  if (method === "POST") {
    reqObj["data"] = postData;
  }
  if (method === "PUT") {
    reqObj["data"] = postData;
  }

  let results = undefined;
  console.log("REQUEST OBJECT==>", reqObj);
  try {
    results = await axios(reqObj);
    console.log("RESULT==>", results);
    return results.data;
  } catch (error) {
    //log error
    console.log("<===Api-Error===>", error);

    // toastError(error.response.config.url);
    toastError(error.response.data.message);

    //handle 401 unautherized error
    if (error.response.status === 401) {
      const welcome = localStorage.getItem("welcome");
      localStorage.clear();
      localStorage.setItem("welcome", welcome);
      window.location.reload();
    }

    return {
      ...error.response.data,
      code: error.response.status,
      message: error.response.data.message ? error.response.data.message : "",
    };

    // if (error.response.status === 499) throw new Error("Permission denied");

    // if (error.response.status === 401) {
    //   localStorage.removeItem("auth_token");
    //   localStorage.removeItem("auth_user");
    //   window.location.reload();
    // }

    // if (
    //   !(error.response && error.response.data && error.response.data.message)
    // ) {
    //   toastError("NETWORK ERROR : Some Thing Went Wrong!");
    //   throw new Error("NETWORK ERROR : Some Thing Went Wrong!");
    // } else {
    //   toastError(error.response.data.message);
    //   throw new Error(error.response.data.message);
    // }
  }
}
