import { Button, CircularProgress, TablePagination } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import { Radio } from "@material-ui/core";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import Confirmation from "./components/Confirmation/Confirmation";
import Congratulations from "./components/Congratulations/Congratulations";

function Available(props) {
  const {
    showErrorSnak,
    balance,
    currentScreen,
    setCurrentScreen,
    FetchPaymentTotal,
  } = props;

  const [bankList, setBankList] = useState([]);

  const [isLoadingBank, setIsLoadingBank] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [partialAmount, setPartialAmount] = useState("");

  const [withdrawAmount, setWithdrawAmount] = useState();

  const [isAll, setIsAll] = useState(true);

  const handleBankChange = (bank) => {
    const currentBank = getDefaultOne();
    console.log(currentBank, bank);

    if (currentBank) {
      if (currentBank.value === bank.value) {
        return;
      }
    }

    MakeAccountDefault(bank.value);
  };

  const MakeAccountDefault = (external_id) => {
    let requestObj = {
      path: "/api/company/make_deafult_external_bank_account",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      external_bank_id: external_id,
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "ADD_ECTERNAL_BANK_RES");
      if (res.code === 200) {
        FetchBankDetails();
      } else {
        //showErrorSnak(res.message);
        setIsLoading(false);
      }
    });
  };

  const FetchBankDetails = () => {
    let requestObj = {
      path: "/api/company/get_bank_detail",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setIsLoading(true);

    invokeApi(requestObj).then((res) => {
      console.log(res, "BANK RES");

      if (res.code === 200) {
        let temp = res.stripe_bank.map((bank) => {
          return {
            value: bank.external_id,
            label:
              bank.account_holder_name +
              ` (...xxx${bank.account_number.slice(
                bank.account_number.length - 4,
                bank.account_number.length
              )})`,
            status: bank.status,
          };
        });

        setBankList(temp);
        setIsLoading(false);
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  const WidthdrawPayment = () => {
    // Validations
    if (!isAll) {
      if (parseFloat(partialAmount) === 0 || partialAmount === "") {
        showErrorSnak("Please enter a valid amount");
        return;
      }

      if (parseFloat(partialAmount) > parseFloat(balance)) {
        showErrorSnak(
          "Please enter a valid amount less then Available Balance"
        );
        return;
      }
    }

    let requestObj = {
      path: "/api/company/withdraw_payment",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      amount: isAll ? balance.toString() : partialAmount.toString(),
    };

    console.log(postData);
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "ADD_ECTERNAL_BANK_RES");
      if (res.code === 200) {
        setIsLoading(false);
        setCurrentScreen("congratulations");
        FetchPaymentTotal();
      } else {
        //showErrorSnak(res.message);
        setIsLoading(false);
        setCurrentScreen("withdraw");
        FetchPaymentTotal();
      }
    });
  };

  const handleDoneClick = () => {
    setIsAll(true);
    setPartialAmount("");
    setCurrentScreen("withdraw");
  };

  const handleGetPaid = () => {
    // Validations
    if (!isAll) {
      if (parseFloat(partialAmount) === 0 || partialAmount === "") {
        showErrorSnak("Please enter a valid amount");
        return;
      }

      if (parseFloat(partialAmount) > parseFloat(balance)) {
        showErrorSnak(
          "Please enter a valid amount less then Available Balance"
        );
        return;
      }
    }
    setWithdrawAmount(isAll ? balance.toString() : partialAmount.toString());
    setCurrentScreen("confirmation");
  };

  const getDefaultOne = () => {
    let defaultBank = bankList.find((bank) => bank.status === "1");
    return defaultBank;
  };

  useEffect(() => {
    setCurrentScreen("withdraw");
    FetchBankDetails();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="rounded-box-payment pb-40">
          <div className="row"></div>
          <div className="Circular-Progress mb-40 pb-40">
            <CircularProgress />
          </div>
        </div>
      </>
    );
  }

  if (bankList.length === 0) {
    return (
      <div className="rounded-box-payment pb-4">
        <div className="row justify-content-center pt-40 text-left pl-4 ">
          <div className="col-12 pt-4 pb-4 text-center">
            {bankList.length === 0 && (
              <>
                <span className="">Please add your bank account first. </span>
                <br></br>
                <Button
                  onClick={() => props.history.push("/company/bank-account")}
                  variant="outlined"
                  className="mt-4"
                  color="primary"
                >
                  Add Bank Account
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (currentScreen === "withdraw") {
    return (
      <>
        <div>
          <div className="rounded-box-payment pb-4">
            <div className="row justify-content-center pt-40 text-left pl-4 ">
              <div className="col-12 col-md-5 pb-3 sub-title black-color">
                Withdraw Bank :
              </div>
              <div className="col-12 col-sm-5 col-md-5 pb-3">
                <Select
                  name="bank-list"
                  options={bankList}
                  isLoading={isLoadingBank}
                  className="basic-multi-select pr-3"
                  classNamePrefix="select"
                  defaultValue={getDefaultOne()}
                  onChange={handleBankChange}
                />
              </div>
              <div className="col-12 col-sm-2 col-md-2"></div>
              <div className="col-12 col-sm-5 pb-3 sub-title black-color">
                Amount :
              </div>
              <div className="col-12 col-sm-7 pb-3">
                <Radio
                  onClick={() => setIsAll(true)}
                  checked={isAll}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
                <span>Available Balance: ${balance}</span>
                <br />
                <Radio
                  onClick={() => setIsAll(false)}
                  checked={!isAll}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />

                <span>A partial amount...</span>
                {!isAll && (
                  <div>
                    <NumberFormat
                      style={{ width: 200 }}
                      onChange={(e) => setPartialAmount(e.target.value)}
                      value={partialAmount}
                      className="form-control small-font-size ml-5 mt-2"
                      id="amount"
                      placeholder="$"
                      allowLeadingZeros={true}
                      allowNegative={false}
                      autoComplete="off"
                      autoFocus
                    />
                  </div>
                )}
              </div>

              <div className="col-12 pt-40">
                <div className="row justify-content-center">
                  <div className="status-buttons">
                    <span
                      onClick={handleGetPaid}
                      className="custom-btn-small custom-button-extra-small req-status-button filter-button req-done active"
                    >
                      Get Paid Now
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (currentScreen === "confirmation") {
    return (
      <Confirmation
        amount={withdrawAmount}
        bank={getDefaultOne()}
        WidthdrawPayment={WidthdrawPayment}
      />
    );
  }

  if (currentScreen === "congratulations") {
    return (
      <Congratulations
        amount={withdrawAmount}
        bank={getDefaultOne()}
        handleDoneClick={handleDoneClick}
      />
    );
  }
}

export default withRouter(Available);
