import React, { useEffect, useState } from "react";
import {
  addImage,
  crossEditImage,
  editWhiteImage,
  subtractImage,
} from "../../../../../../assets";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import { getMovers, time_convert } from "../../../../../../ui_libs/Functions/functions";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Alert from "@material-ui/lab/Alert";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import EditRequest from "../Edit-Request/EditRequest";

function TruckAssigned(props) {
  const {
    requestData,
    bidCancel,
    fetchRequestDetails,
    setIsLoadingPage,
    handleCancelRequest,
  } = props;
  const { truck_array, get_foreman_of_company, sale_team } = requestData;
  const {
    customer_budget,
    recommend_amount,
    id,
    schedule_time,
    final_amount,
    s_date,
    s_time,
    movers,
    request_timeline_status,
    card_payment_rate,
    cash_payment_rate,
    request_type,
    rate_method,
    cash_max_flat_rate,
    cash_min_flat_rate,
    card_max_flat_rate,
    card_min_flat_rate,
    foreman_ids,
    customer_email,
    truck_ids,
  } = requestData.request;

  const { first_name, last_name, user_model } =
    requestData.get_foreman_of_company;
  const { phone, email } = user_model;

  //animation
  const animatedComponents = makeAnimated();

  // to store foremans and truck list from api

  const [foremanList, setForemanList] = useState([]);
  const [salesmanList, setSalesmanList] = useState([]);
  const [truckList, setTruckList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = React.useState("");
  const [warning, setWarning] = useState("");

  const [isLoadingTrucks, setIsLoadingTruck] = useState(true);
  const [isLoadingForeman, setIsLoadingForeman] = useState(true);
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(true);

  const [selectedTrucks, setSelectedTruck] = useState([]);
  const [selectedForeman, setSelectedForeman] = useState("");
  const [selectedSalesman, setSelectedSalesman] = useState("");

  //handle change functions
  const handleTruck = (e) => {
    setSelectedTruck(e);
  };
  //
  const handleForeman = (e) => {
    setSelectedForeman(e);
  };

  const handleSalesman = (e) => {
    setSelectedSalesman(e);
  };
  ////

  //fetch and refresh foreman list data from api

  const fetchForemanList = () => {
    let requestObj = {
      path: "/api/company/get_foreman",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        let temp = res.data.allForeman.map((foreman) => {
          return {
            value: foreman.user_model.id,
            label: foreman.first_name + " " + foreman.last_name,
          };
        });

        setForemanList(temp);
        setIsLoadingForeman(false);
      }
    });
  };

  //fetch and refresh truck list data from api

  const fetchTruckList = () => {
    let requestObj = {
      path: "/api/company/get_truck",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        let temp = res.data.allTruck.map((truck) => {
          return {
            value: truck.id,
            label: truck.name,
          };
        });
        console.log(temp, "123truck");

        setTruckList(temp);
        setIsLoadingTruck(false);
      }
    });
  };

  //fetch and refresh Salesman list data from api

  const fetchSalesmanList = () => {
    let requestObj = {
      path: "/api/company/get_sale_team",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        let temp = res.sale_team.map((salesman) => {
          return {
            value: salesman.user_model.id,
            label: salesman.first_name + " " + salesman.last_name,
            color: "#c4314b",
          };
        });

        setSalesmanList(temp);
        setIsLoadingSalesman(false);
      }
    });
  };

  const assignForeman = (req_id, foreman) => {
    let requestObj = {
      path: "/api/company/add_foreman_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${req_id}`,
      foreman_id: `${foreman.value}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        assignTrucks(id, selectedTrucks);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const assignTrucks = (req_id, trucks) => {
    let requestObj = {
      path: "/api/company/add_truck_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let truckIds = trucks.map((truck) => {
      return { id: `${truck.value}` };
    });
    let postData = {
      request_id: `${req_id}`,
      truck_id_array: truckIds,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        assignSalesman(id, selectedSalesman);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const assignSalesman = (req_id, salesman) => {
    let requestObj = {
      path: "/api/company/add_sale_team_for_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${req_id}`,
      sale_team_id: `${salesman ? salesman.value : ""}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        fetchRequestDetails();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const handleClick = () => {
    if (selectedTrucks.length === 0) {
      setErrMessage("Please select trucks");
      return;
    }
    if (selectedForeman === "") {
      setErrMessage("Please select foreman");
      return;
    }
    setErrMessage("");
    setIsLoading(true);
    assignForeman(id, selectedForeman);
  };

  useEffect(() => {
    fetchForemanList();
    fetchTruckList();
    fetchSalesmanList();
  }, []);

  //for handle edit

  const [isEdit, setIsEdit] = useState(false);

  const [inputs, setInputs] = useState({
    id: id,
    date: new Date(s_date),
    time: s_time,
    movers: movers,
    price: final_amount,
    cash_payment_rate: cash_payment_rate,
    card_payment_rate: card_payment_rate,
    cash_flat_min: cash_min_flat_rate,
    cash_flat_max: cash_max_flat_rate,
    card_flat_min: card_min_flat_rate,
    card_flat_max: card_max_flat_rate,
  });

  const SaveChanges = () => {
    // if (
    //   inputs.time === s_time &&
    //   moment(inputs.date).format("MMM Do YY") ===
    //     moment(s_date).format("MMM Do YY") &&
    //   inputs.movers === movers &&
    //   inputs.cash_payment_rate == cash_payment_rate &&
    //   inputs.card_payment_rate == card_payment_rate
    // ) {
    //   setErrMessage("You're not making any changes.");
    //   return;
    // }

    setErrMessage("");

    let requestObj = {
      path: "/app_api/change_in_date_time",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: inputs.id.toString(),
      schedule_time:
        moment(inputs.date).format("MMM Do YY") + " " + inputs.time,
      s_date: moment(inputs.date).format("MM-DD-YYYY"),
      s_time: inputs.time,
      movers: inputs.movers.toString(),
    };
    // For CRM also take cash and card price
    // Hourly data update in case of hourly rate
    if (request_type && rate_method === "Hourly") {
      postData.cash_amount = inputs.cash_payment_rate;
      postData.card_amount = inputs.card_payment_rate;
    }
    // Flat data update in case of flat rate
    if (request_type && rate_method === "Flat") {
      postData.cash_min_flat_rate = inputs.cash_flat_min;
      postData.cash_max_flat_rate = inputs.cash_flat_max;
      postData.card_min_flat_rate = inputs.card_flat_min;
      postData.card_max_flat_rate = inputs.card_flat_max;
    }

    // return console.log(postData);
    setIsLoadingPage(true);
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "Api Response");
      if (res.code === 200) {
        fetchRequestDetails();
      } else {
        setErrMessage(res.message);
        setIsLoadingPage(false);
      }
    });
  };

  const handleSubtract = () => {
    if (inputs.movers <= 1) return;
    setInputs({ ...inputs, movers: inputs.movers - 1 });
  };

  const handleAdd = () => {
    setInputs({ ...inputs, movers: inputs.movers + 1 });
  };

  const handleChangeInPrice = (e) => {
    setInputs({ ...inputs, [e.target.id]: e.target.value });
  };

  const getDefaultValues = () => {
    const foreman = {
      value: get_foreman_of_company.user_id,
      label:
        get_foreman_of_company.first_name +
        " " +
        get_foreman_of_company.last_name,
    };

    const trucks = truck_array.map((truck) => {
      return {
        value: truck.id,
        label: truck.name,
      };
    });

    const salesman = {
      value: sale_team.user_id,
      label: sale_team.first_name + " " + sale_team.last_name,
    };
    if (sale_team) {
      setSelectedSalesman(salesman);
    }

    setSelectedTruck(trucks);

    setSelectedForeman(foreman);
  };

  if (isEdit) {
    return (
      <EditRequest
        requestData={requestData}
        fetchRequestDetails={fetchRequestDetails}
        setIsLoadingPage={setIsLoadingPage}
        setIsEdit={setIsEdit}
      />
    );
  }
  return (
    <div>
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <h6 className="text-white pt-1">Request #{id}</h6>
            </div>
            <div className="col-2 text-right">
              <img
                className="pl-1 pointer-point"
                src={editWhiteImage}
                onClick={() => setIsEdit(true)}
              ></img>
            </div>
          </div>
        </div>
        <div className="request-left-box p-3 p-sm-4">
          <div className="row">
            <div className="col-6">
              <div className="request-staus small-font-size">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#C4314B" />
                </svg>{" "}
                Truck Assigned
              </div>
            </div>
            <div className="col-6 text-right">
              <a
                href=""
                className="small-text-bold red-color add-new-btn"
                data-toggle="modal"
                data-target="#cancel-request"
              >
                <span>Cancel</span>
              </a>
            </div>
          </div>
          <div className="bid-form-wrapper pt-3">
            <div className="row small-font-size">
              <div className="col-6 text-left dark-grey-color">Start time:</div>
              <div className="col-6 text-right black-color">
              {/* {`${time_convert(s_time.slice(0,5))} to ${time_convert(s_time.slice(8,13))}`} */}
              {s_time && s_time !== "8AM - 12PM Morning" &&
                    s_time !== "12PM - 5PM Evening" &&
                    s_time !== "Entire day" &&
                    `${time_convert(
                      s_time.split('-')[0].trim()
                    )} to ${time_convert(s_time.split('-')[1].trim())}`}
                  {s_time && (s_time == "8AM - 12PM Morning" ||
                    s_time == "12PM - 5PM Evening" ||
                    s_time == "Entire day") && `${s_time}`}
              </div>
            </div>

            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Movers:</div>
              <div className="col-6 text-right black-color">
                {getMovers(requestData)}
              </div>
            </div>

            {request_type && (
              <>
                {/* Hourly rate details */}
                {requestData.request.rate_method === "Hourly" && (
                  <>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Cash Hour Rate:
                      </div>
                      <div className="col-6 text-right black-color">
                        ${requestData.request.cash_payment_rate}
                      </div>
                    </div>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Card Hour Rate:
                      </div>
                      <div className="col-6 text-right black-color">
                        ${requestData.request.card_payment_rate}
                      </div>
                    </div>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Minimum Hours:
                      </div>
                      <div className="col-6 text-right black-color">
                        {requestData.request.minimum_hours}
                      </div>
                    </div>
                  </>
                )}
                {/* Flat rate details */}
                {requestData.request.rate_method === "Flat" && (
                  <>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Cash Flat Rate:
                      </div>
                      <div className="col-6 text-right black-color">
                        ${requestData.request.cash_min_flat_rate} - $
                        {requestData.request.cash_max_flat_rate}
                      </div>
                    </div>
                    <div className="row small-font-size pt-3">
                      <div className="col-6 text-left dark-grey-color">
                        Card Flat Rate:
                      </div>
                      <div className="col-6 text-right black-color">
                        ${requestData.request.card_min_flat_rate} - $
                        {requestData.request.card_max_flat_rate}
                      </div>
                    </div>
                  </>
                )}

                {requestData.sale_team && (
                  <div className="row small-font-size pt-3">
                    <div className="col-6 text-left dark-grey-color">
                      Salesman Name:
                    </div>
                    <div className="col-6 text-right light-red-color">
                      {requestData.sale_team?.first_name +
                        " " +
                        requestData.sale_team?.last_name}
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Truck Name:</div>
              <div className="col-6 text-right light-red-color">
                {truck_array.map((truck, i) => {
                  return (
                    truck.name + (i === truck_array.length - 1 ? "" : ", ")
                  );
                })}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">
                Foreman name:
              </div>
              <div className="col-6 text-right light-red-color">
                {first_name + " " + last_name}
              </div>
            </div>
            {!request_type && (
              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color">Price:</div>
                <div className="col-6 text-right black-color">
                  ${final_amount}
                </div>
              </div>
            )}

            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">
                Customer Phone #:
              </div>
              <div className="col-6 text-right black-color">
                {requestData.request.customer_phone_number}
              </div>
            </div>

            <div className="row small-font-size pt-3">
              <div className="col-4 text-left dark-grey-color">
                Customer Email:
              </div>
              <div className="col-8 text-right black-color">
                <a
                  href={`mailto:${customer_email}`}
                  className="light-red-color"
                >
                  {customer_email}
                </a>
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">
                {" "}
                Foreman Phone #:
              </div>
              <div className="col-6 text-right black-color">{phone}</div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-4 text-left dark-grey-color">
                Foreman Email:
              </div>
              <div className="col-8 text-right black-color">
                <a href={`mailto:${email}`} className="light-red-color">
                  {email}
                </a>
              </div>
            </div>

            {requestData.sale_team && (
              <>
                <div className="row small-font-size pt-3">
                  <div className="col-6 text-left dark-grey-color">
                    {" "}
                    Salesman Phone #:
                  </div>
                  <div className="col-6 text-right black-color">
                    {requestData.sale_team.user_model.phone}
                  </div>
                </div>
                <div className="row small-font-size pt-3">
                  <div className="col-4 text-left dark-grey-color">
                    Salesman Email:
                  </div>
                  <div className="col-8 text-right black-color">
                    <a
                      href={`mailto:${requestData.sale_team.user_model.email}`}
                      className="light-red-color"
                    >
                      {requestData.sale_team.user_model.email}
                    </a>
                  </div>
                </div>
              </>
            )}

            {/* <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Address:</div>
              <div className="col-6 text-right black-color">
                LA 12345, Mersedes-Benz, 2015, Van, 15 ft
              </div>
            </div> */}
            <div className="row text-center pt-3">
              <div className="col-12 caption">Ready to move</div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Bid Placed --> */}
      <div
        className="modal fade"
        id="cancel-request"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancel-request-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-bid-cancel"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0 text-center">
                <h5 className="text-center" id="cancel-request-title">
                  Cancel request
                </h5>
                <p className="pt-3">
                  Are you sure you want to cancel the request?
                </p>
                {!request_type && (
                  <p className="text-left light-grey-color caption">
                    When cancelling request, you will be charged an additional
                    fee 50%.
                  </p>
                )}
                <div className="buttons-wrapper pt-3">
                  <div className="row justify-content-center text-center">
                    <div className="col-6">
                      <button
                        onClick={request_type ? handleCancelRequest : bidCancel}
                        className="custom-btn-small custom-buton float-right"
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        onClick={() =>
                          document.getElementById("close-bid-cancel").click()
                        }
                        className="custom-btn-small custom-buton custom-button-pink float-left"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TruckAssigned;
