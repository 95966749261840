import React, { useEffect } from "react";
import { addressARedIcon, blackBackArrowImage } from "../../assets";
import { useState } from "react";

import LocationCard from "./components/LocationCard";
import { invokeApi } from "../../bl_libs/invokeApi";
import { drop_off, pick_up } from "../../ui_libs/constants";
import { CircularProgress } from "@material-ui/core";

function LocationDetails(props) {
  const [requestData, setRequestData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  const [location, setLocation] = useState({
    label: "A",
    icon: addressARedIcon,
    type: "pick-up",
    id: "",
    zip_code: "",
    address: "",
    street_number: "",
    route: "",
    city: "",
    country: "",
    formated_address: "",
    lat: "",
    lng: "",
    moveType: "",
    unitNumber: "",
    addressDetails: "",
    isStorage: false,
    liftType: "elevator",
    flights: 1,
    isHardwoodFloor: false,
    parkingInfo: "",
    placeholder: "Pickup Address",
    order: 1,
    isEmpty: false,
    isZipEmpty: false,
  });

  const EditAddress = () => {
    console.log(location, "Add new address");
    if (location.zip_code === "") {
      setErrMessage("Please enter your zip code.");
      return;
    }
    if (location.lat === "") {
      setErrMessage("Please enter a valid address.");
      return;
    }

    let address_array = requestData.request_address.map((address) => address);

    address_array = {
      street_address: location.street_number ? location.street_number : "N/A",
      apartment_number: location.unitNumber,
      address_detail: location.addressDetails,
      is_storage: location.isStorage ? "1" : "0",
      complete_address: location.formated_address,
      zip_code: `${location.zip_code}`,
      city: location.city ? location.city : "N/A",
      state: location.state.short
        ? location.state.short
        : location.state
        ? location.state
        : "N/A",
      country: location.country ? location.country : "N/A",
      is_stairs: location.liftType === "stairs" ? "1" : "0",
      is_elevator: location.liftType === "stairs" ? "0" : "1",
      is_hardwood_floor: location.isHardwoodFloor ? "1" : "0",
      number_of_flights:
        location.liftType === "stairs" ? `${location.flights}` : null,
      parking_and_building_info: location.parkingInfo,
      latitude: `${location.lat}`,
      longitude: `${location.lng}`,
      address_type: location.type === "pick-up" ? pick_up : drop_off, //constants
    };

    const editAddressUrl = requestData.request.request_type
      ? "/app_api/edit_address"
      : "/api/customer/edit_address";

    let requestObj = {
      path: editAddressUrl,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      request_id: `${props.match.params.requestId}`,
      address_id: `${props.match.params.addressId}`,
      ...address_array,
    };
    console.log(postData, "POSTDATA=>");

    requestObj["postData"] = postData;

    console.log(requestObj, "REQ-OBJ");
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        props.history.goBack();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  const fetchRequestDetails = () => {
    let requestObj = {
      path: "/api/company/company_request_detail",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${`${props.match.params.requestId}`}`,
    };

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        setRequestData(res);

        let address = res.request_address.find(
          (addr) => addr.id === parseInt(props.match.params.addressId)
        );
        setLocation({
          ...location,
          ...address,
          formated_address: address.complete_address,
          unitNumber: address.apartment_number,
          zip_code: address.zip_code,
          addressDetails: address.address_detail,
          type: address.address_type === 1 ? "pick-up" : "drop-off",
          isStorage: address.is_storage,
          liftType: address.is_stairs ? "stairs" : "elevator",
          flights: address.number_of_flights ? address.number_of_flights : 13,
          isHardwoodFloor: address.is_hardwood_floor,
          parkingInfo: address.parking_and_building_info,
          lat: address.latitude,
          lng: address.longitude,
        });
        console.log(address, "Filter address");
      } else {
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchRequestDetails();
  }, []);

  if (isLoading) {
    return (
      <section id="content-wrapper" className="content-wrapper rating-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point"
              src={blackBackArrowImage}
              onClick={() => props.history.goBack()}
            />
            <span>Request DETAIL</span>
          </p>
          <div className="container-fluid">
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section id="content-wrapper" className="content-wrapper rating-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point"
              src={blackBackArrowImage}
              onClick={() => props.history.goBack()}
            />
            <span>Request DETAIL</span>
          </p>
        </div>
        <div className="container-fluid justify-center">
          <div className="row justify-content-center">
            <LocationCard
              isDirectRequest={requestData.request.request_type}
              location={location}
              setLocation={setLocation}
              EditAddress={EditAddress}
              errMessage={errMessage}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default LocationDetails;
