import { CircularProgress, StepButton } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import AddBank from "./components/Add-Bank/AddBank";
import ShowBank from "./components/Show-Bank/ShowBank";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
import { invokeApi } from "../../bl_libs/invokeApi";
import EditBank from "./components/Edit-Bank/EditBank";
import { blackBackArrowImage } from "../../assets";
import AddExternalBank from "./components/Add-External-Bank/AddExternalBank";

function BankAccount() {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();

  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState();
  const [account, setAccount] = useState();

  const [currentScreen, setCurrentScreen] = useState("");

  //   ADD_ALL
  //   SHOW_ALL
  //   EDIT_C_A
  //   ADD_E_A

  const FetchBankDetails = () => {
    let requestObj = {
      path: "/api/company/get_bank_detail",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setIsLoading(true);

    invokeApi(requestObj).then((res) => {
      console.log(res, "FETCH_BANK_DETAILS");
      if (res.code === 200) {
        setAccount(res.stripe_bank);
        setProfile({ ...res.company_profile, ...res.User_data });
        if (res.stripe_bank.length === 0) {
          setCurrentScreen("ADD_ALL");
        } else {
          setCurrentScreen("SHOW_ALL");
          // setCurrentScreen("ADD_ALL");
        }
        setIsLoading(false);
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  useEffect(() => {
    FetchBankDetails();
  }, []);

  if (isLoading) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">Bank Account</p>
            <div className="container-fluid">
              <div className="Circular-Progress">
                <CircularProgress />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  if (currentScreen === "EDIT_C_A") {
    return (
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point mr-2"
              src={blackBackArrowImage}
              onClick={() => {
                setCurrentScreen("SHOW_ALL");
              }}
            />
            Bank Account
          </p>
          <EditBank
            showErrorSnak={showErrorSnak}
            showSuccessSnak={showSuccessSnak}
            account={account}
            profile={profile}
            FetchBankDetails={FetchBankDetails}
          />
        </div>
      </section>
    );
  }

  if (currentScreen === "SHOW_ALL") {
    return (
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title">Bank Account</p>

          <ShowBank
            banks={account}
            profile={profile}
            setCurrentScreen={setCurrentScreen}
            showErrorSnak={showErrorSnak}
            showSuccessSnak={showSuccessSnak}
            FetchBankDetails={FetchBankDetails}
          />
        </div>
      </section>
    );
  }

  if (currentScreen === "ADD_ALL") {
    return (
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            {/* <img
              className="pointer-point mr-2"
              src={blackBackArrowImage}
              onClick={() => {
                setCurrentScreen("SHOW_ALL");
              }}
            /> */}
            Bank Account
          </p>
          <AddBank
            showErrorSnak={showErrorSnak}
            showSuccessSnak={showSuccessSnak}
            account={account}
            profile={profile}
            FetchBankDetails={FetchBankDetails}
            setCurrentScreen={setCurrentScreen}
          />
        </div>
      </section>
    );
  }

  if (currentScreen === "ADD_E_A") {
    return (
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point mr-2"
              src={blackBackArrowImage}
              onClick={() => {
                setCurrentScreen("SHOW_ALL");
              }}
            />
            Bank Account
          </p>
          <AddExternalBank
            showErrorSnak={showErrorSnak}
            showSuccessSnak={showSuccessSnak}
            FetchBankDetails={FetchBankDetails}
          />
        </div>
      </section>
    );
  }
}

export default BankAccount;
