import { Skeleton } from "@material-ui/lab";
import moment from "moment/moment";
import React from "react";
import { withRouter } from "react-router-dom";
import { infoGreyImage } from "../../../assets";
import { getStatusForCompany } from "../../../ui_libs/Functions/functions";

function RequestCard(props) {
  const { request } = props;
  const {
    request_id,
    net_distance,
    movers,
    move_type,
    foreman,
    request_status,
    request_timeline_status,
    additional_status,
    customer_name,
    customer_phone_number,
    truck_array,
    move_start_time,
  } = request;
  const handleInfoClick = () => {
    props.history.push("/company/company-requests/" + request_id);
  };

  console.log(request, "c_request");
  return (
    <>
      <div className="row pt-3 pl-2 border-bottom pb-2" style={{ width: 340 }}>
        <div className="col-12 slider-req-id">
          <span className="pl-2">Request #{request_id}</span>
          <img
            className="pointer-point"
            onClick={handleInfoClick}
            src={infoGreyImage}
          />
        </div>
        <div className="col-12">
          <div className="row pt-2">
            <div className="col-5">
              <span className="slider-req-title">Status:</span>
            </div>
            <div className="col-7">
              <span className="slider-req-value">
                {getStatusForCompany(
                  request_timeline_status,
                  additional_status
                )}
              </span>
            </div>
            {/*Customer:  */}
            <div className="col-5">
              <span className="slider-req-title">Customer:</span>
            </div>
            <div className="col-7">
              <span className=" light-red-color small-font-size">
                {customer_name ? customer_name : "N/A"}
              </span>
            </div>
            <div className="col-5">
              <span className="slider-req-title">Contact:</span>
            </div>
            <div className="col-7">
              <span className=" light-red-color small-font-size">
                {customer_phone_number ? customer_phone_number : "N/A"}
              </span>
            </div>
            {/*Foreman:  */}
            <div className="col-5">
              <span className="slider-req-title">Foreman:</span>
            </div>
            <div className="col-7">
              <span className=" light-red-color small-font-size">
                {foreman ? foreman : "N/A"}
              </span>
            </div>
            {/*Movers::  */}
            <div className="col-5">
              <span className="slider-req-title">Movers:</span>
            </div>
            <div className="col-7">
              <span className="slider-req-value">{movers}</span>
            </div>
            {/*Distance::  */}
            <div className="col-5">
              <span className="slider-req-title">Distance:</span>
            </div>
            <div className="col-7">
              <span className="slider-req-value">{net_distance}mi</span>
            </div>
            {/*Move type:::  */}
            <div className="col-5">
              <span className="slider-req-title">Move size:</span>
            </div>
            <div className="col-7">
              <span className="slider-req-value">{move_type}</span>
            </div>
            <div className="col-5">
              <span className="slider-req-title">Trucks:</span>
            </div>
            <div className="col-7">
              {truck_array && truck_array.length>0 && 
              <span className="slider-req-value">
                {truck_array.map((truck)=>{
                  return (truck.name+", ")
                })}
              </span>
              }
              {truck_array && truck_array.length==0 && 
              <span className="slider-req-value">N/A</span>
              }
            </div>
            <div className="col-5">
              <span className="slider-req-title">Start time:</span>
            </div>
            <div className="col-7">
              <span className="slider-req-value">
                {move_start_time ? moment(move_start_time).format("lll") : "N/A"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(RequestCard);
