import React from "react";
import { getMovers, time_convert } from "../../../../../../ui_libs/Functions/functions";

function WaitingConfirmation(props) {
  const { requestData, bidCancel } = props;
  const { truck_array } = requestData;
  const {
    customer_budget,
    recommend_amount,
    id,
    final_amount,
    s_time,
    request_type,
  } = requestData.request;
  const { first_name, last_name, user_model } =
    requestData.get_foreman_of_company;
  const { phone, email } = user_model;

  return (
    <>
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <h6 className="text-white">Reques #{id}</h6>
        </div>
        <div className="request-left-box p-3 p-sm-4">
          <div className="row">
            <div className="col-12">
              <div className="request-staus small-font-size">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#C4314B" />
                </svg>{" "}
                Completed, waiting for confirmation
              </div>
            </div>
            {/* <div className="col-3 text-right">
              <a
                href=""
                className="small-text-bold red-color add-new-btn"
                data-toggle="modal"
                data-target="#cancel-request"
              >
                <span>Cancel</span>
              </a>
            </div> */}
          </div>
          <div className="bid-form-wrapper pt-3">
            <div className="row small-font-size">
              <div className="col-6 text-left dark-grey-color">Start time:</div>
              <div className="col-6 text-right black-color">
              {/* {`${time_convert(s_time.slice(0,5))} to ${time_convert(s_time.slice(8,13))}`} */}
              {s_time && s_time !== "8AM - 12PM Morning" &&
                    s_time !== "12PM - 5PM Evening" &&
                    s_time !== "Entire day" &&
                    `${time_convert(
                      s_time.split('-')[0].trim()
                    )} to ${time_convert(s_time.split('-')[1].trim())}`}
                  {s_time && (s_time == "8AM - 12PM Morning" ||
                    s_time == "12PM - 5PM Evening" ||
                    s_time == "Entire day") && `${s_time}`}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Movers:</div>
              <div className="col-6 text-right black-color">
                {getMovers(requestData)}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Truck Name:</div>
              <div className="col-6 text-right light-red-color">
                {truck_array.map((truck, i) => {
                  return (
                    truck.name + (i === truck_array.length - 1 ? "" : ", ")
                  );
                })}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">
                Foreman name:
              </div>
              <div className="col-6 text-right light-red-color">
                {first_name + " " + last_name}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Price:</div>
              <div className="col-6 text-right black-color">
                ${final_amount}
              </div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Phone #:</div>
              <div className="col-6 text-right black-color">{phone}</div>
            </div>
            <div className="row small-font-size pt-3">
              <div className="col-4 text-left dark-grey-color">Email:</div>
              <div className="col-8 text-right black-color">
                <a
                  href="mailto:companyname@example.com"
                  className="light-red-color"
                >
                  {email}
                </a>
              </div>
            </div>
            {/* <div className="row small-font-size pt-3">
              <div className="col-6 text-left dark-grey-color">Address:</div>
              <div className="col-6 text-right black-color">
                LA 12345, Mersedes-Benz, 2015, Van, 15 ft
              </div>
            </div> */}
            <div className="row text-center pt-3">
              <div className="col-12 caption">
                Completed, waiting for confirmation
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Bid Placed --> */}
      <div
        className="modal fade"
        id="cancel-request"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancel-request-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-bid-cancel"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0 text-center">
                <h5 className="text-center" id="cancel-request-title">
                  Cancel request
                </h5>
                <p className="pt-3">
                  Are you sure you want to cancel the request?
                </p>
                {!request_type && (
                  <p className="text-left light-grey-color caption">
                    When cancelling request, you will be charged an additional
                    fee 50%.
                  </p>
                )}
                <div className="buttons-wrapper pt-3">
                  <div className="row justify-content-center text-center">
                    <div className="col-6">
                      <button
                        onClick={bidCancel}
                        className="custom-btn-small custom-buton float-right"
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        onClick={() =>
                          document.getElementById("close-bid-cancel").click()
                        }
                        className="custom-btn-small custom-buton custom-button-pink float-left"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WaitingConfirmation;
