import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { DirectLinkUrl } from "../../../../bl_libs/invokeApi";
import { CopyToClipboard } from "../../../../components";
import ShareIcon from "@material-ui/icons/Share";
import { CircularProgress } from "@material-ui/core";
function ShareLink(props) {
  const { subscribtion } = props;
  const { company_direct_access_link } = JSON.parse(
    localStorage.getItem("user")
  );

  const [isLoading, setIsLoading] = useState();
  const [value, setValue] = useState("");

  const [target, setTarget] = useState("email");

  if (subscribtion === "") {
    return (
      <>
        <div className="Circular-Progress ">
          <CircularProgress />
        </div>
      </>
    );
  }
  return (
    <>
      <section className="main-content address-page">
        <div className="container">
          <div className="add-items-wrapper pt-100 pb-5">
            <div className="row text-center justify-content-center">
              <div className="col-12 col-sm-8 small-font-size text-center">
                <h4 className="text-center text-uppercase"></h4>
                {subscribtion.subscription_status ? (
                  <p
                    className="light-grey-color font-weight-bod"
                    style={{ fontSize: 20, lineHeight: 1.5 }}
                  >
                    In order to see your direct requests and start moving with
                    clients directly please share your direct link with client.
                  </p>
                ) : (
                  <p
                    className="light-grey-color font-weight-bod"
                    style={{ fontSize: 20, lineHeight: 1.5 }}
                  >
                    In order to see your direct requests and start moving with
                    clients directly please choose a subscription plan.
                  </p>
                )}
              </div>
            </div>
            {/* popups are in header file */}
            <div className="row pt-30 justify-content-center">
              {subscribtion.subscription_status && (
                <div className="col-12 text-center">
                  <CopyToClipboard
                    content={DirectLinkUrl + company_direct_access_link}
                  />
                  <button
                    style={{ color: "white", outline: "none", width: 210 }}
                    className="custom-btn-small red-btn ml-1 mr-1 mt-2"
                    data-toggle="modal"
                    data-target="#share-link-email"
                  >
                    Share Via Email <ShareIcon className="ml-1" />
                  </button>

                  <button
                    style={{ color: "white", outline: "none", width: 210 }}
                    className="custom-btn-small red-btn ml-1 mr-1 mt-2"
                    data-toggle="modal"
                    data-target="#share-link-phone"
                  >
                    Share Via Phone <ShareIcon className="ml-1" />
                  </button>
                </div>
              )}

              {!subscribtion.subscription_status && (
                <div className="col-12 text-center">
                  <button
                    onClick={() => {
                      props.history.push("/subscriptions-plan");
                    }}
                    className="custom-btn-small red-btn"
                  >
                    Choose a plan
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(ShareLink);
