import moment from "moment";
import React from "react";
import { Chart } from "react-google-charts";
import { monthNames } from "../../../../ui_libs/constants";
function BarChart({ move_request, filter }) {
  //   crm_request_count: 0
  // date: "2021-10-01"
  // market_place_request_count: 0

  const formatted_move_requests = (move_request) => {
    console.log(move_request, "move_request");
    console.log(filter, "filter");

    if (filter.type === "days") {
      const headings = ["", "Market Place", "CRM"];
      const formatted = move_request.map((request) => {
        return [
          moment(request.date, "YYYY-MM-DD").format("LL"),
          request.market_place_request_count,
          request.crm_request_count,
        ];
      });

      // Remove 0 request date from the array

      const filtered_formatted = formatted.filter(
        (req) => req[1] > 0 || req[2] > 0
      );

      return [headings, ...filtered_formatted.reverse()];
    }

    if (filter.type === "months") {
      const headings = ["", "Market Place", "CRM"];
      const formatted = move_request.map((request) => {
        const d = new Date(request.start_date);
        let month = d.getMonth();
        month = monthNames[month];
        return [
          month,
          request.market_place_request_count,
          request.crm_request_count,
        ];
      });

      return [headings, ...formatted];
    }

    if (filter.type === "years") {
      const headings = ["", "Market Place", "CRM"];
      const formatted = move_request.map((request) => {
        return [
          `${request.year}`,
          request.market_place_request_count,
          request.crm_request_count,
        ];
      });

      return [headings, ...formatted];
    }
  };

  const getSubTitle = () => {
    let sub_title = `Requests, ${moment(filter.start_date, "YYYY-MM-DD").format(
      "LL"
    )} to ${moment(filter.end_date, "YYYY-MM-DD").format("LL")}`;

    if (filter.type === "years") {
      sub_title = `Requests, All Times`;
    }
    return sub_title;
  };

  return (
    <div>
      <Chart
        width={"98%"}
        height={"350px"}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={formatted_move_requests(move_request)}
        options={{
          // Material design options
          chart: {
            title: "Company Performance",
            subtitle: getSubTitle(),
          },
        }}
        // For tests
        rootProps={{ "data-testid": "2" }}
      />
    </div>
  );
}

export default BarChart;
