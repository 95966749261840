import { CircularProgress } from "@material-ui/core";
import React from "react";
import { companyProfilePicImage } from "../../../../assets";
import ChatBody from "./components/Chat-Body/ChatBody";
import ChatHeader from "./components/Chat-Header/ChatHeader";

function ChatBox(props) {
  const {
    chats,
    message,
    setMessage,
    handleSendMessage,
    selectedUser,
    chatLoading,
    profile,
    userList,
  } = props;
  const count = [1, 2];
  const handleMessage = (e) => {
    e.preventDefault();
    handleSendMessage(message);
    setMessage("");
  };

  const isChatAvailable = () => {
    const user = userList.filter(
      (user) => user.room_id === selectedUser.room_id
    );
    if (user) {
      return true;
    } else {
      return false;
    }
  };

  if (chatLoading) {
    return (
      <>
        <div className="row">
          <div className="col-12  border-bottom chat-my-header">
            <ChatHeader selectedUser={selectedUser} />
          </div>

          <div className="col-12 scrolling chat-vh-chat">
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-12  border-bottom chat-my-header">
          <ChatHeader selectedUser={selectedUser} />
        </div>

        <div
          className="col-12 scrolling chat-vh-chat"
          style={window.innerHeight > 1000 ? { height: "75vh" } : {}}
        >
          <div className="row pt-1">
            {selectedUser &&
              chats.map((chat, i) => (
                <ChatBody
                  key={i}
                  chat={chat}
                  selectedUser={selectedUser}
                  profile={profile}
                />
              ))}
            <div className="col-12 mt-40" id="div-for-scroll"></div>
          </div>
        </div>

        {/*  */}
        {selectedUser && (
          <div className="chat-reply-box-wrapper mt-20 w-100">
            <form onSubmit={handleMessage}>
              {isChatAvailable() && (
                <div className="row bg-white pt-2">
                  <div className="col-7 col-xl-10">
                    <div className="pb-1">
                      <input
                        autoFocus="on"
                        autoComplete="off"
                        required
                        type="text"
                        name="reply"
                        className="form-control small-font-size"
                        placeholder="Type your message..."
                        id="reply-box"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-5 col-xl-2">
                    <button
                      type="submit"
                      className="custom-btn-small red-btn w-100"
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        )}
        {/*  */}
      </div>
    </>
  );
}

export default ChatBox;
