import React, { Component } from "react";
import not_found from "../../assets/images/404.svg";

function NotFound(props) {
  return (
    <section className="main-content error-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-0 col-sm-4"></div>
          <div className="col-12 col-sm-4 text-center">
            <img src={not_found} alt="404 Error" title="404 Error" />
            <div className="form-group button-wrapper">
              <button
                onClick={() => props.history.goBack()}
                className="custom-buton custom-buton-large w-100"
              >
                Go Back
              </button>
            </div>
          </div>
          <div className="col-0 col-sm-4"></div>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
