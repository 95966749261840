import React from "react";
import Calendar from "react-calendar";
import { useState } from "react";
import moment from "moment";
import {
  CUSTOM_TIME_DROPDOWN,
  time_convert,
} from "../../../../ui_libs/Functions/functions";
import { useEffect } from "react";

function DateAndTimePicker(props) {
  //date

  const [date, setDate] = useState(props.date == "" ? minDate() : props.date);
  const [emptyFromTime, setEmptyFromTime] = useState(false);
  const [emptyToTime, setEmptyToTime] = useState(false);

  //get time range
  const [timeRange, setTimeRange] = useState({
    // from: props.timeRange.from === "" ? "09:00 AM" : props.timeRange.from,
    // to: props.timeRange.to === "" ? "05:00 PM" : props.timeRange.to,
    move_duration: props.timeRange.move_duration,
    start_window_from: props.timeRange.start_window_from,
    start_window_to: props.timeRange.start_window_to,
  });
  useEffect(() => {
    if (date !== "" && moment(date).format("MM-DD-YYYY") !== moment().format("MM-DD-YYYY")) {
      setTimeRange({
        ...timeRange,
        start_window_from: "08:00",
        start_window_to: "09:00",
      });
    } else {
      props.getCustomToTime(props.customFromTime[0]);
      setTimeRange({
        ...timeRange,
        start_window_from: props.customFromTime[0],
        start_window_to: props.customToTime[1],
      });
    }
  }, [date]);
  const handleSet = () => {
    if (timeRange.start_window_from === "") {
      setEmptyFromTime(true);
      return;
    } else if (timeRange.start_window_to === "") {
      setEmptyToTime(true);
      return;
    } else {
      props.setDate(date);
      props.setTimeRange({
        ...timeRange,
        move_duration: `${timeRange.start_window_from} - ${timeRange.start_window_to}`,
      });
      if (date !== "") {
        props.setIsDateEmpty(false);
      }
      props.closePopUp();
    }
  };

  function minDate() {
    const today = new Date();
    // to return the date number(1-31) for the specified date
    console.log("today => ", today);

    let tomorrow = new Date();

    tomorrow.setDate(today.getDate() + 1);
    //returns the tomorrow date
    console.log("tomorrow => ", tomorrow);

    const currentTime = today.getHours();
    console.log(currentTime);
    if (currentTime >= 22) {
      return tomorrow;
    }
    return today;
  }

  const get_split_time = (_time) => {
    let _start_window_from = "";
    let _start_window_to = "";
    if (_time === "8AM - 12PM Morning") {
      _start_window_from = "08:00";
      _start_window_to = "12:00";
    } else if (_time === "12PM - 5PM Evening") {
      _start_window_from = "12:00";
      _start_window_to = "17:00";
    } else if (_time === "Entire day") {
      _start_window_from = "08:00";
      _start_window_to = "17:00";
    }

    return { _start_window_from, _start_window_to };
  };

  const availableTime = () => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    if (date.getDate() === currentDate.getDate()) {
      return <option value="12PM - 5PM Evening">{"12PM - 5PM Evening"}</option>;
    }
    return (
      <>
        <option value="8AM - 12PM Morning">{"8AM - 12PM Morning"}</option>
        <option value="12PM - 5PM Evening">{"12PM - 5PM Evening"}</option>
        <option value="Entire day">{" Entire day"}</option>
      </>
    );
  };
console.log(timeRange,"timerangeeeeee")
  return (
    <>
      {timeRange.start_window_from && timeRange.start_window_from !== "" && (
        <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
          <div className="modal-body p-0">
            <div className="form-box">
              <div className="p-sm-4" style={{}}>
                <Calendar
                  minDate={minDate()}
                  value={new Date(date)}
                  onChange={(val) => setDate(val)}
                />
              </div>

              <div className="form-group p-sm-3 ">
                <div className="row">
                  {/* <div className="col-12 col-sm-12 pt-4 pt-md-0">
                  <label htmlFor="from" className="overline dark-grey-color">
                    Around what time would you want to start your movE? Please
                    select a window
                  </label>
                  <select
                    id="from"
                    name=""
                    value={timeRange.move_duration}
                    onChange={(e) =>
                      setTimeRange({
                        ...timeRange,
                        move_duration: e.target.value,
                        start_window_from: get_split_time(e.target.value)
                          ._start_window_from,
                        start_window_to: get_split_time(e.target.value)
                          ._start_window_to,
                      })
                    }
                    className="custom-select custom-select-lg custom-select-sm form-control form-control-sm small-font-size"
                  >
                    {availableTime()}
                  </select>
                </div> */}

                  {/* <div className="col-12 mt-3">
                  <label htmlFor="from" className="overline dark-grey-color">
                    Time window:
                  </label>
                </div> */}

                  <div className="col-12 col-lg-6">
                    <label htmlFor="from" className="overline dark-grey-color">
                      From
                    </label>
                    <select
                      style={{ border: emptyFromTime && "solid 1px red" }}
                      id="from"
                      name=""
                      value={timeRange.start_window_from}
                      onChange={(e) => {
                        setTimeRange({
                          ...timeRange,
                          start_window_from: e.target.value,
                        });
                        props.getCustomToTime(e.target.value);
                        setEmptyFromTime(false);
                      }}
                      className="custom-select custom-select-lg custom-select-sm form-control form-control-sm small-font-size"
                    >
                      {/* <option value="">--:-- --</option> */}
                      {moment(date).format("MM-DD-YYYY") !== moment().format("MM-DD-YYYY") &&
                        CUSTOM_TIME_DROPDOWN().map((t, index) => (
                          <option value={t}>{time_convert(t)}</option>
                        ))}
                      {moment(date).format("MM-DD-YYYY") == moment().format("MM-DD-YYYY") &&
                        props.customFromTime.map((t, index) => (
                          <option value={t}>{time_convert(t)}</option>
                        ))}
                    </select>
                  </div>

                  <div className="col-12 col-lg-6 pt-md-0">
                    <label htmlFor="from" className="overline dark-grey-color">
                      To
                    </label>
                    <select
                      style={{ border: emptyToTime && "solid 1px red" }}
                      id="start_window_to"
                      name=""
                      value={timeRange.start_window_to}
                      onChange={(e) => {
                        setTimeRange({
                          ...timeRange,
                          start_window_to: e.target.value,
                        });
                        setEmptyToTime(false);
                      }}
                      className="custom-select custom-select-lg custom-select-sm form-control form-control-sm small-font-size"
                    >
                      {/* <option value="">--:-- --</option> */}
                      {moment(date).format("MM-DD-YYYY") !== moment().format("MM-DD-YYYY") &&
                        CUSTOM_TIME_DROPDOWN().map((t, index) => (
                          <option value={t}>{time_convert(t)}</option>
                        ))}
                      {moment(date).format("MM-DD-YYYY") == moment().format("MM-DD-YYYY") &&
                        props.customToTime.map((t) => (
                          <option value={t}>{time_convert(t)}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group pt-1">
                <button
                  type="button"
                  onClick={handleSet}
                  className="red-btn custom-buton custom-buton-large w-100"
                >
                  Set
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DateAndTimePicker;
