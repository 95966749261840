import React from "react";

import {
  Addresses,
  ClientComment,
  CompanyNotes,
  ExtraItems,
  Items,
  LeadSource,
} from "./components";

function RequestInformation(props) {
  const { fetchRequestDetails } = props;
  let {
    request_address,
    extra_request_items,
    request_item,
    request,
    request_customer,
  } = props.requestData;
  const { final_comment, company_notes, lead_source } =
    props.requestData.request;

  //filter 0 quantity items
  extra_request_items = extra_request_items.filter((item) => item.quantity > 0);
  return (
    <>
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center position-relative">
          <h6 className="text-white">Request Information</h6>
        </div>
        <Addresses
          request_customer={request_customer}
          request_address={request_address}
          request={request}
          requestData={props.requestData}
          fetchRequestDetails={fetchRequestDetails}
        />
        <div className="items-info-wrapper mt-2">
          <div className="info-header text-center">
            <h6 className="black-color">Items({request_item.length})</h6>
          </div>
          <Items request={request} request_item={request_item} />
        </div>
        <div className="items-info-wrapper mt-2">
          <div className="info-header text-center">
            <h6 className="black-color">
              Extra Items({extra_request_items.length})
            </h6>
          </div>
          <ExtraItems
            request={request}
            extra_request_items={extra_request_items}
          />
        </div>
        <div className="items-info-wrapper mt-2">
          <div className="info-header text-center">
            <h6 className="black-color">Client Comment</h6>
          </div>
          <ClientComment final_comment={final_comment} />
        </div>
        <div className="items-info-wrapper mt-2">
          <div className="info-header text-center">
            <h6 className="black-color">Company Notes</h6>
          </div>
          <CompanyNotes
            request={request}
            final_comment={company_notes}
            fetchRequestDetails={fetchRequestDetails}
          />
        </div>

        <div className="items-info-wrapper mt-2">
          <div className="info-header text-center">
            <h6 className="black-color">Lead Source</h6>
          </div>
          <LeadSource
            request={request}
            lead_source={lead_source}
            fetchRequestDetails={fetchRequestDetails}
          />
        </div>
      </div>
    </>
  );
}

export default RequestInformation;
