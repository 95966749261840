import React, { useState } from "react";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
function valuetext(value) {
  return `${value}°C`;
}
const useStyles = makeStyles({
  root: {
    width: 300,
  },
});
function SearchFilter(props) {
  const { filters, setFilters, moveSizeList, fetchSearchList, setPage } = props;

  const handleChange = (event, newValue) => {
    setFilters({ ...filters, range: [0, newValue[1]] });
  };

  const handleFilter = () => {
    fetchSearchList("filter");
    setPage(0);
    document.getElementById("close-filter").click();
  };
  return (
    <>
      <div
        className="modal fade pb-m-80 mt-m-80"
        id="change-filter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-filter-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-filter"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="change-filter-title">
                  Filter
                </h5>
                <div className="form-box pt-20">
                  <form>
                    <div className="form-group">
                      <label
                        htmlFor="price-buttons"
                        className="overline dark-grey-color"
                      >
                        Price Sorting
                      </label>
                      <div className="price-buttons mb-3">
                        <a
                          onClick={() => setFilters({ ...filters, price: "" })}
                          className={
                            filters.price === ""
                              ? "custom-btn-small custom-button-extra-small filter-button active"
                              : "custom-btn-small custom-button-extra-small filter-button"
                          }
                        >
                          No sorting
                        </a>
                        <a
                          onClick={() =>
                            setFilters({ ...filters, price: "ASC" })
                          }
                          className={
                            filters.price === "ASC"
                              ? "custom-btn-small custom-button-extra-small filter-button active"
                              : "custom-btn-small custom-button-extra-small filter-button"
                          }
                        >
                          Min Price
                        </a>
                        <a
                          onClick={() =>
                            setFilters({ ...filters, price: "DESC" })
                          }
                          className={
                            filters.price === "DESC"
                              ? "custom-btn-small custom-button-extra-small filter-button active"
                              : "custom-btn-small custom-button-extra-small filter-button"
                          }
                        >
                          Max Price
                        </a>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 col-sm-4">
                          <label
                            htmlFor="zipcode"
                            className="overline dark-grey-color"
                          >
                            Zip Code
                          </label>
                          <input
                            value={filters.zip_code}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                zip_code: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control small-font-size"
                            id="zipcode"
                            placeholder="12345"
                          />
                        </div>
                        <div className="col-12 col-sm-8">
                          <label
                            htmlFor="near-zipcode pb-2"
                            className="overline dark-grey-color"
                          >
                            locations near zip code
                          </label>
                          <div id="slider-range" className="mt-0">
                            <Slider
                              value={filters.range}
                              onChange={handleChange}
                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                              getAriaValueText={valuetext}
                            />
                            <div className="col-12 caption dark-grey-color">
                              <span id="slider-range-value1">
                                {filters.range[0]}
                              </span>
                              -
                              <span id="slider-range-value2">
                                {filters.range[1]}
                              </span>{" "}
                              miles
                            </div>
                          </div>

                          <div className="row slider-labels pt-3"></div>

                          <div className="row">
                            <div className="col-sm-12">
                              <form>
                                <input
                                  type="hidden"
                                  name="min-value"
                                  value=""
                                />
                                <input
                                  type="hidden"
                                  name="max-value"
                                  value=""
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-6 col-sm-6">
                          <label
                            htmlFor="from-date"
                            className="overline dark-grey-color"
                          >
                            From
                          </label>
                          <DatePicker
                            className="form-control small-font-size date-picker"
                            id="to-date"
                            placeholderText="MM/DD/YYYY"
                            selected={filters.from_date}
                            onChange={(date) =>
                              setFilters({ ...filters, from_date: date })
                            }
                            autoComplete="off"
                          />
                          <span className="glyphicon glyphicon-remove form-control-feedback"></span>
                        </div>
                        <div className="col-6 col-sm-6">
                          <label
                            htmlFor="to-date"
                            className="overline dark-grey-color"
                          >
                            To
                          </label>
                          <DatePicker
                            className="form-control small-font-size date-picker"
                            id="to-date"
                            placeholderText="MM/DD/YYYY"
                            selected={filters.to_date}
                            onChange={(date) =>
                              setFilters({ ...filters, to_date: date })
                            }
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          >
                            Distance
                          </label>
                          <select
                            name=""
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={filters.distance}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                distance: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Distance</option>
                            <option value="1-2">1-2 miles</option>
                            <option value="3-5">3-5 miles</option>
                            <option value="6-10">6-10 miles</option>
                            <option value="11-20">11-20 miles</option>
                          </select>
                        </div>
                        <div className="col-12 col-sm-6">
                          <label
                            htmlFor="move-type"
                            className="overline dark-grey-color"
                          >
                            Move Size
                          </label>
                          <select
                            name="move-type"
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={filters.move_type}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                move_type: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Move Size</option>
                            {moveSizeList.map((size) => (
                              <option value={size.name}>{size.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group pt-3">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-10">
                          <button
                            type="button"
                            onClick={handleFilter}
                            className="red-btn custom-buton custom-buton-large w-100"
                          >
                            Use Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchFilter;
