import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { dateFilterIconImage } from "../../../../assets";
function DoneRequestFilter(props) {
  const {
    filters,
    setFilters,
    moveSizeList,
    truckList,
    foremanList,
    fetchDoneRequests,
    setPage,
  } = props;

  const handleFilter = () => {
    fetchDoneRequests("filter");
    setPage(0);
    document.getElementById("close-filter").click();
  };
  return (
    <>
      <div
        className="modal fade pb-m-80 mt-m-80"
        id="change-filter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-filter-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-filter"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="change-filter-title">
                  Filter
                </h5>
                <div className="form-box pt-20">
                  <form name="" method="post" action="">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-6 col-sm-6 pt-3">
                          <label
                            htmlFor="from-date"
                            className="overline dark-grey-color"
                          >
                            From Date
                          </label>
                          <br></br>

                          <DatePicker
                            className="form-control small-font-size date-picker"
                            id="to-date"
                            placeholderText="MM/DD/YYYY"
                            selected={filters.from_date}
                            onChange={(date) =>
                              setFilters({ ...filters, from_date: date })
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-6 col-sm-6 pt-3">
                          <label
                            htmlFor="to-date"
                            className="overline dark-grey-color"
                          >
                            To Date
                          </label>
                          <br></br>
                          <DatePicker
                            className="form-control small-font-size date-picker"
                            id="to-date"
                            placeholderText="MM/DD/YYYY"
                            selected={filters.to_date}
                            onChange={(date) =>
                              setFilters({ ...filters, to_date: date })
                            }
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          >
                            Distance
                          </label>
                          <select
                            name=""
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={filters.distance}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                distance: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Distance</option>
                            <option value="1-2">1-2 miles</option>
                            <option value="3-5">3-5 miles</option>
                            <option value="6-10">6-10 miles</option>
                            <option value="11-20">11-20 miles</option>
                          </select>
                        </div>
                        <div className="col-12 col-sm-6">
                          <label
                            htmlFor="move-type"
                            className="overline dark-grey-color"
                          >
                            Move Type
                          </label>
                          <select
                            name="move-type"
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={filters.move_type}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                move_type: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Move Size</option>
                            {moveSizeList.map((size) => (
                              <option value={size.name}>{size.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="move-type"
                            className="overline dark-grey-color"
                          >
                            Truck Name
                          </label>
                          <select
                            name="move-type"
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={filters.truck_name}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                truck_name: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Truck</option>
                            {truckList.map((truck) => (
                              <option value={truck.id}>{truck.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="move-type"
                            className="overline dark-grey-color"
                          >
                            Foreman Name
                          </label>
                          <select
                            name="move-type"
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            value={filters.foreman_name}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                foreman_name: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Foreman</option>
                            {foremanList.map((foreman) => (
                              <option value={foreman.id}>
                                {foreman.first_name + " " + foreman.last_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group pt-3">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-10">
                          <button
                            type="button"
                            className="red-btn custom-buton custom-buton-large w-100"
                            onClick={handleFilter}
                          >
                            Use Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoneRequestFilter;
