import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  makeStyles,
  Badge,
  Avatar,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  companyProfilePicImage,
  mapIconImage,
  emailIconImaage,
  phoneIconImage,
  editIconImage,
  addIconImage,
  uploadIconImage,
} from "../../assets";
import { Alert, Rating } from "@material-ui/lab";
import { base_uri, invokeApi, s3baseUrl } from "../../bl_libs/invokeApi";
import EditForeman from "./Components/EditForeman";
import EditTruck from "./Components/EditTruck";
import NumberFormat from "react-number-format";
import AddTruck from "./Components/AddTruck";
import { validatePasswordStrength } from "../../ui_libs/Validations/validations";
import { saveAs } from "file-saver";
import Axios from "axios";
import { _sales_team_listing } from "../../DAL/sales_team/sales_team";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
import EditSalesman from "./Components/EditSalesman";
import { _managers_listing } from "../../DAL/managers/managers";
import EditManager from "./Components/EditManager";
import ManageLeadSource from "./Components/ManageLeadSource";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
}));

function Company() {
  const classes = useStyles();
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const {
    email,
    name,
    phone,
    profile_picture,
    address,
    company_rating,
    ca_number,
    dot_number,
    mtr_number,
    company_booklet_link,
    role,
  } = JSON.parse(localStorage.getItem("user"));

  //for loading

  const [isLoading, setIsLoading] = React.useState(false);

  const [isLoadingBooklet, setIsLoadingBooklet] = React.useState(false);

  //for show error

  const [errMessage, setErrMessage] = React.useState("");

  //for add foreman inputs

  const [foremanInputs, setForemanInputs] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    pass: "",
    confirm_pass: "",
    profile_pic: "",
  });

  //for add salesman inputs

  const [salesmanInputs, setSalesmanInputs] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    pass: "",
    confirm_pass: "",
    profile_pic: "",
  });

  //for add manager inputs

  const [managerInputs, setManagerInputs] = useState({
    name: "",
    phone_number: "",
    email: "",
    pass: "",
    confirm_pass: "",
    profile_pic: "",
  });

  ///////////////////////////////////////////////////////////////update password section

  const [updatePassword, setUpdatePassword] = useState({
    password: "",
    confirm_password: "",
  });
  const [updatePasswordSuccessMessage, setUpdatePasswordSuccessMessage] =
    useState("");

  const [passStrength, setPassStrength] = React.useState({
    color: "",
    strength: "",
    level: 0,
  });

  const handleUpdatePasswordInputs = (e) => {
    if (e.target.id === "password") {
      setPassStrength(validatePasswordStrength(e.target.value));
    }
    setUpdatePassword({
      ...updatePassword,
      [e.target.id]: e.target.value.trim(),
    });
  };

  const handleUpdatePasswordSubmit = (e) => {
    e.preventDefault();

    //validations step for password
    if (updatePassword.password.length < 8) {
      setErrMessage("Minimum 8 characters required for password");
      return;
    }
    //
    //check password strength

    if (passStrength.level < 3) {
      setErrMessage(
        "A minimum 8 characters password contains a combination of uppercase letter and number. And at least one special character are required."
      );
      return;
    }

    //match password
    if (updatePassword.password !== updatePassword.confirm_password) {
      setErrMessage("Your password and confirmation password does not match");
      return;
    }

    //Api call
    let requestObj = {
      path: "/app_api/update_password",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = updatePassword;
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setErrMessage("");
        setUpdatePasswordSuccessMessage("Password updated successfully");
        setTimeout(() => {
          document.getElementById("close-change-password").click();
          setTimeout(() => {
            setUpdatePasswordSuccessMessage("");
          }, 500);
        }, 1000);
      } else {
        setErrMessage(res.message);
      }
    });
  };

  ///////////////////////////////////////////////////////////////////////////////////

  //current foreman and truck for edit

  const [selectedForeman, setSelectedForeman] = useState(false);
  const [selectedTruck, setSelectedTruck] = useState(false);
  const [selectedSalesman, setSelectedSalesman] = useState(false);
  const [selectedManager, setSelectedManager] = useState(false);

  const [addTruckComponent, setAddTruckComponent] = useState(false);

  // to store foremans and truck list from api

  const [foremanList, setForemanList] = useState([]);
  const [truckList, setTruckList] = useState([]);
  const [salesTeamList, setSalesTeamList] = useState([]);
  const [managerList, setManagerList] = useState([]);

  //handle change in foreman inputs

  const handleChangeForemanInputs = (e) => {
    if (e.target.id === "pass") {
      setPassStrength(validatePasswordStrength(e.target.value));
    }
    setForemanInputs({
      ...foremanInputs,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeSalesmanInputs = (e) => {
    if (e.target.id === "pass") {
      setPassStrength(validatePasswordStrength(e.target.value));
    }
    setSalesmanInputs({
      ...salesmanInputs,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeManagerInputs = (e) => {
    if (e.target.id === "pass") {
      setPassStrength(validatePasswordStrength(e.target.value));
    }
    setManagerInputs({
      ...managerInputs,
      [e.target.id]: e.target.value,
    });
  };

  //preview image from pic url

  const [preview, setPreview] = useState("");

  //randle upload files

  // const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;

  const handleUpload = (event) => {
    let requestObj = {
      path: "/app_api/upload_s3_file",
      method: "POST",
      headers: {},
    };

    let formData = new FormData();
    formData.append("upload_file", event.target.files[0]);
    formData.append("upload_type", "profile");
    requestObj["postData"] = formData;
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        setForemanInputs({
          ...foremanInputs,
          profile_pic: res.file_url,
        });

        setPreview(res.file_url);
      } else {
        setErrMessage(res.message);
      }
    });
  };

  //general functions for validation

  const alphaNumeric = (txt) => {
    var letterNumber = /^[a-zA-Z0-9 ]+$/;
    return letterNumber.test(txt);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validString = (string) => {
    const re = /^[a-z A-Z]+$/;
    return re.test(string);
  };

  //handle submit for foreman

  const handleForemanSubmit = (e) => {
    e.preventDefault();

    //first name validation

    if (foremanInputs.first_name.length < 2) {
      setErrMessage("First name must have 2 characters.");
      return;
    } else if (foremanInputs.first_name.length > 35) {
      setErrMessage("First name must be less the 35 characters.");
      return;
    } else if (
      !alphaNumeric(foremanInputs.first_name) ||
      !isNaN(foremanInputs.first_name) ||
      !isNaN(foremanInputs.first_name[0]) ||
      !validString(foremanInputs.first_name.trim())
    ) {
      setErrMessage("Please enter a valid first name.");
      return;
    } else {
      setErrMessage("");
    }

    //last name validation

    if (foremanInputs.last_name.length < 1) {
      setErrMessage("Last name must have 1 characters");
      return;
    } else if (foremanInputs.last_name.length > 35) {
      setErrMessage("Last name must be less the 35 characters.");
      return;
    } else if (
      !alphaNumeric(foremanInputs.last_name) ||
      !isNaN(foremanInputs.last_name) ||
      !isNaN(foremanInputs.last_name[0]) ||
      !validString(foremanInputs.last_name.trim())
    ) {
      setErrMessage("Please enter a valid last name.");
      return;
    } else {
      setErrMessage("");
    }

    //phone number validation

    if (foremanInputs.phone_number[13] === "_") {
      setErrMessage("Please enter a valid phone number.");
      return;
    } else {
      setErrMessage("");
    }

    //email validation

    if (!validateEmail(foremanInputs.email)) {
      setErrMessage("Please enter a valid email address.");
      return;
    } else {
      setErrMessage("");
    }

    //password validation

    if (foremanInputs.pass.length < 8) {
      setErrMessage(" Minimum 8 characters required for password.");
      return;
    } else if (foremanInputs.pass.length > 40) {
      setErrMessage(" Password should not be greater the 40.");
      return;
    }
    //
    //check password strength
    if (passStrength.level < 3) {
      setErrMessage(
        "A minimum 8 characters password contains a combination of uppercase letter and number. And at least one special character are required."
      );
      return;
    }

    //confirm password validation

    if (foremanInputs.pass !== foremanInputs.confirm_pass) {
      setErrMessage("Password and confirm password does not match.");
      return;
    }

    //Api call
    let requestObj = {
      path: "/api/company/add_foreman",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      first_name: foremanInputs.first_name.trim(),
      last_name: foremanInputs.last_name.trim(),
      email: foremanInputs.email.trim(),
      phone: foremanInputs.phone_number,
      password: foremanInputs.pass,
      // profile_pic: foremanInputs.profile_pic,
      profile_pic: foremanInputs.profile_pic,
      confirm_password: foremanInputs.confirm_pass,
    };
    console.log(postData);
    requestObj["postData"] = postData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res);
        //for refresh foreman list
        fetchForemanList();
        document.getElementById("close_foreman").click();
      } else {
        console.log(res);
        setErrMessage(res.message);
      }
    });
  };
  //handle submit for foreman

  const handleSalesmanSubmit = (e) => {
    e.preventDefault();

    //first name validation

    if (salesmanInputs.first_name.length < 2) {
      setErrMessage("First name must have 2 characters.");
      return;
    } else if (salesmanInputs.first_name.length > 35) {
      setErrMessage("First name must be less the 35 characters.");
      return;
    } else if (
      !alphaNumeric(salesmanInputs.first_name) ||
      !isNaN(salesmanInputs.first_name) ||
      !isNaN(salesmanInputs.first_name[0]) ||
      !validString(salesmanInputs.first_name.trim())
    ) {
      setErrMessage("Please enter a valid first name.");
      return;
    } else {
      setErrMessage("");
    }

    //last name validation

    if (salesmanInputs.last_name.length < 1) {
      setErrMessage("Last name must have 1 characters");
      return;
    } else if (salesmanInputs.last_name.length > 35) {
      setErrMessage("Last name must be less the 35 characters.");
      return;
    } else if (
      !alphaNumeric(salesmanInputs.last_name) ||
      !isNaN(salesmanInputs.last_name) ||
      !isNaN(salesmanInputs.last_name[0]) ||
      !validString(salesmanInputs.last_name.trim())
    ) {
      setErrMessage("Please enter a valid last name.");
      return;
    } else {
      setErrMessage("");
    }

    //phone number validation

    if (salesmanInputs.phone_number[13] === "_") {
      setErrMessage("Please enter a valid phone number.");
      return;
    } else {
      setErrMessage("");
    }

    //email validation

    if (!validateEmail(salesmanInputs.email)) {
      setErrMessage("Please enter a valid email address.");
      return;
    } else {
      setErrMessage("");
    }

    //password validation

    if (salesmanInputs.pass.length < 8) {
      setErrMessage(" Minimum 8 characters required for password.");
      return;
    } else if (salesmanInputs.pass.length > 40) {
      setErrMessage(" Password should not be greater the 40.");
      return;
    }
    //
    //check password strength
    if (passStrength.level < 3) {
      setErrMessage(
        "A minimum 8 characters password contains a combination of uppercase letter and number. And at least one special character are required."
      );
      return;
    }

    //confirm password validation

    if (salesmanInputs.pass !== salesmanInputs.confirm_pass) {
      setErrMessage("Password and confirm password does not match.");
      return;
    }

    //Api call
    let requestObj = {
      path: "/api/company/add_sale_team",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      first_name: salesmanInputs.first_name.trim(),
      last_name: salesmanInputs.last_name.trim(),
      email: salesmanInputs.email.trim(),
      phone: salesmanInputs.phone_number,
      password: salesmanInputs.pass,
      // profile_pic: salesmanInputs.profile_pic,
      profile_pic: salesmanInputs.profile_pic,
      confirm_password: salesmanInputs.confirm_pass,
    };
    console.log(postData);
    requestObj["postData"] = postData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res);
        //for refresh foreman list
        fetchSalesTeamList();
        showSuccessSnak("Salesman added successfully");
        document.getElementById("close_salesman").click();
      } else {
        console.log(res);
        setErrMessage(res.message);
      }
    });
  };

  //handle submit for manager

  const handleManagerSubmit = (e) => {
    e.preventDefault();

    //first name validation

    if (managerInputs.name.length < 2) {
      setErrMessage("Name must have 2 characters.");
      return;
    } else if (managerInputs.name.length > 35) {
      setErrMessage("Name must be less the 35 characters.");
      return;
    } else if (
      !alphaNumeric(managerInputs.name) ||
      !isNaN(managerInputs.name) ||
      !isNaN(managerInputs.name[0]) ||
      !validString(managerInputs.name.trim())
    ) {
      setErrMessage("Please enter a valid first name.");
      return;
    } else {
      setErrMessage("");
    }

    //phone number validation

    if (managerInputs.phone_number[13] === "_") {
      setErrMessage("Please enter a valid phone number.");
      return;
    } else {
      setErrMessage("");
    }

    //email validation

    if (!validateEmail(managerInputs.email)) {
      setErrMessage("Please enter a valid email address.");
      return;
    } else {
      setErrMessage("");
    }

    //password validation

    if (managerInputs.pass.length < 8) {
      setErrMessage(" Minimum 8 characters required for password.");
      return;
    } else if (managerInputs.pass.length > 40) {
      setErrMessage(" Password should not be greater the 40.");
      return;
    }
    //
    //check password strength
    if (passStrength.level < 3) {
      setErrMessage(
        "A minimum 8 characters password contains a combination of uppercase letter and number. And at least one special character are required."
      );
      return;
    }

    //confirm password validation

    if (managerInputs.pass !== managerInputs.confirm_pass) {
      setErrMessage("Password and confirm password does not match.");
      return;
    }

    //Api call
    let requestObj = {
      path: "/api/company/add_manager",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      name: managerInputs.name.trim(),
      email: managerInputs.email.trim(),
      phone: managerInputs.phone_number,
      password: managerInputs.pass,
      profile_pic: managerInputs.profile_pic,
      confirm_password: managerInputs.confirm_pass,
    };
    console.log(postData);
    requestObj["postData"] = postData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res);
        //for refresh foreman list
        fetchManagersList();
        showSuccessSnak("Manager added successfully");
        document.getElementById("close_manager").click();
      } else {
        console.log(res);
        setErrMessage(res.message);
      }
    });
  };

  //fetch and refresh foreman list data from api

  const fetchForemanList = () => {
    let requestObj = {
      path: "/api/company/get_foreman",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        setForemanList(res.data.allForeman);
      }
    });
  };

  //fetch and refresh truck list data from api

  const fetchTruckList = () => {
    let requestObj = {
      path: "/api/company/get_truck",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        setTruckList(res.data.allTruck);
      }
    });
  };

  //fetch and refresh foreman list data from api

  const fetchSalesTeamList = async () => {
    const resp = await _sales_team_listing();
    if (resp.code === 200) {
      setSalesTeamList(resp.sale_team);
    } else {
      showErrorSnak(resp.message);
    }
  };

  const fetchManagersList = async () => {
    const resp = await _managers_listing();
    if (resp.code === 200) {
      setManagerList(resp.manager);
    } else {
      showErrorSnak(resp.message);
    }
  };

  const GetCompanyProfile = (type) => {
    let requestObj = {
      path: "/api/company/profile_company",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({ ...res.profile, company_rating: res.company_rating })
        );
        setIsLoadingBooklet(false);
      } else {
      }
    });
  };

  const handleUploadBooklet = (event) => {
    setIsLoadingBooklet(true);
    let requestObj = {
      path: "/api/company/add_booklet",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let formData = new FormData();
    formData.append("booklet", event.target.files[0]);
    requestObj["postData"] = formData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        GetCompanyProfile();
      } else {
        setIsLoadingBooklet(false);
      }
    });
  };

  const handleViewBooklet = () => {
    Axios(`${base_uri}/api/company/get_booklet`, {
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchForemanList();
    fetchTruckList();
    fetchSalesTeamList();
    fetchManagersList();
  }, []);

  return (
    <>
      <section id="content-wrapper" className="content-wrapper profile-page">
        <div className="container-fluid">
          <p className="small-text-bold">Company Information</p>
          <div className="company-profile-wrapper">
            <div className="change-pwd-wrapper">
              <span
                className="border-0 bg-transparent red-color caption pointer-point m-2"
                onClick={() => {
                  setErrMessage("");
                  setUpdatePasswordSuccessMessage("");
                  setUpdatePassword({
                    password: "",
                    confirm_password: "",
                  });
                }}
                data-toggle="modal"
                data-target="#change-password"
              >
                Change password
              </span>
            </div>
            <div className="row pt-3 pt-sm-0 pb-sm-0 pb-3">
              <div className="col-5 col-sm-3 col-md-2 col-lg-2 pr-0 text-right">
                {profile_picture ? (
                  //if profile picture is available show profile
                  <img
                    src={s3baseUrl + profile_picture}
                    className="circle-box rounded-circle edit-profile-img"
                  />
                ) : (
                  //else show default picture
                  <Avatar
                    style={{
                      width: 110,
                      height: 110,
                      fontSize: 22,
                      float: "right",
                    }}
                  ></Avatar>
                )}
                {/* <img
                  style={{ width: 120, height: 120 }}
                  src={
                    profile_picture
                      ? s3baseUrl + profile_picture
                      : companyProfilePicImage
                  }
                  className="circle-box rounded-circle full-width-height"
                /> */}
              </div>
              <div className="col-7 col-sm-9 col-md-10 col-lg-10 half-grid-padding-left pt-4 pt-lg-40">
                <h5
                // style={{ paddingLeft: 20 }}
                >
                  {name}
                </h5>

                <div className="">
                  <span
                    // style={{ paddingLeft: 20 }}
                    className="dark-grey-color caption"
                  >
                    Rating: {company_rating}
                  </span>

                  <Rating
                    className="pl-3 align-bottom"
                    value={company_rating}
                    precision={0.1}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="address-licence-wrapper-2">
            <div className="row justify-content-end">
              <div className="col-12 col-sm-11 half-grid-padding-left">
                <div className="row address-licence-wrapper">
                  <div className="col-sm-6 col-12">
                    {role === "company" && (
                      <div className="row mt-20">
                        <div className="col-2 col-sm-2 col-xl-1 text-right">
                          <img src={mapIconImage} />
                        </div>
                        <div className="col-10 col-sm-10 col-xl-11 small-font-size pl-0">
                          {address}
                        </div>
                      </div>
                    )}
                    <div className="row mt-20">
                      <div className="col-2 col-sm-2 col-xl-1  text-right">
                        <img src={emailIconImaage} />
                      </div>
                      <div className="col-10 col-sm-10 col-xl-11 small-font-size pl-0">
                        {email}
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-2 col-sm-2 col-xl-1 text-right">
                        <img src={phoneIconImage} />
                      </div>
                      <div className="col-10 col-sm-10 col-xl-11 small-font-size pl-0">
                        {phone}
                      </div>
                    </div>
                  </div>
                  {role === "company" && (
                    <div className="col-sm-6 col-12 mt-4">
                      <h6>
                        CA number:
                        <span className="font-weight-normal pl-1">
                          {ca_number}
                        </span>
                      </h6>
                      <h6 className="mt-20">
                        DOT number:
                        <span className="font-weight-normal pl-1">
                          {dot_number}
                        </span>
                      </h6>
                      <h6 className="mt-20">
                        MTR number:
                        <span className="font-weight-normal pl-1">
                          {mtr_number}
                        </span>
                      </h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-20"></div>
          <div className="truck-foreman-wrapper mt-30">
            <div className="row">
              <div className="col-12 col-lg-6 truck-box pt-4 border-top border-bottom">
                <div className="small-text-bold mb-3">Booklet Document</div>
                {isLoadingBooklet ? (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <a className="lh-m-16 custom-link">
                      {company_booklet_link ? (
                        <Link
                          // className="custom-link"
                          onClick={handleViewBooklet}
                        >
                          View Booklet
                        </Link>
                      ) : (
                        "No booklet uploaded yet"
                      )}
                    </a>
                    <div className={classes.uploadroot + " float-right"}>
                      <input
                        accept="application/pdf"
                        className={classes.input}
                        id="booklet"
                        type="file"
                        onChange={handleUploadBooklet}
                        value=""
                      />

                      <label htmlFor="booklet">
                        <span className="small-text-bold red-color add-new-btn pointer-point">
                          <img src={addIconImage} /> <span>Add New</span>
                        </span>
                      </label>
                    </div>
                  </>
                )}
              </div>
              <div className="col-12 col-lg-6 half-grid-padding-left pt-4 border-top border-bottom">
                <ManageLeadSource />
              </div>
            </div>
          </div>
          {/* <!--Trucks & Foreman Box--> */}
          <div className="truck-foreman-wrapper mt-30">
            <div className="row">
              <div className="col-12 col-sm-6 half-grid-padding-right truck-box">
                <p className="small-text-bold">Trucks</p>
                {truckList.length === 0
                  ? ""
                  : truckList.map((truck, i) => (
                      <div key={i} className="truck-row mt-30 mt-m-20">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-xl-6">
                            <div className="row">
                              <div className="col-2">
                                <img
                                  onClick={() => {
                                    setSelectedTruck(false);
                                    setTimeout(() => {
                                      setSelectedTruck(truck);
                                    }, 100);
                                  }}
                                  data-toggle="modal"
                                  data-target="#edit-truck"
                                  style={{ cursor: "pointer" }}
                                  src={editIconImage}
                                />
                              </div>
                              <div className="col-10 pl-0">
                                <p className="lh-m-16">{truck.name}</p>
                                <p className="light-grey-color small-font-size">
                                  {truck.plate_number}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-xl-6 mt-sm-0 mt-3">
                            <div className="row justify-content-end">
                              {truck.truck_image_models.map((pic, i) => (
                                <div key={i} className="col-3 truck-img">
                                  <img src={s3baseUrl + pic.image_url} />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                <div className="row pt-2 justify-content-end">
                  <div className="col-6 text-right">
                    <a
                      onClick={() => {
                        setAddTruckComponent(false);
                        setTimeout(() => {
                          setAddTruckComponent(true);
                        }, 100);
                      }}
                      href=""
                      className="small-text-bold red-color add-new-btn"
                      data-toggle="modal"
                      data-target="#add-truck"
                    >
                      <img src={addIconImage} /> <span>Add New</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 half-grid-padding-left foreman-box mt-m-20">
                <p className="small-text-bold">Foremen</p>
                {foremanList.map((foreman) => (
                  <div key={foreman.id} className="foreman-row mt-30 mt-m-20">
                    <div className="row">
                      <div className="col-12 col-sm-7">
                        <div className="row">
                          <div className="col-2">
                            <img
                              onClick={() => {
                                setSelectedForeman(false);
                                setTimeout(() => {
                                  setSelectedForeman(foreman);
                                }, 100);
                              }}
                              className="border-0 bg-transparent red-color caption"
                              data-toggle="modal"
                              data-target="#edit-foreman"
                              style={{ cursor: "pointer" }}
                              src={editIconImage}
                            />
                          </div>
                          <div className="col-10 pl-0">
                            <p className="lh-m-16">
                              {foreman.first_name + " " + foreman.last_name}
                            </p>
                              <p className="light-grey-color small-font-size">
                                {foreman.user_model.email}
                              </p>
                          </div>
                        </div>
                      </div>
                        <div className="col-10 col-sm-5 offset-2 offset-sm-0 pl-0 text-right text-left-m">
                          <p>{foreman.user_model.phone}</p>
                        </div>
                    </div>
                  </div>
                ))}

                <div className="row pt-2 justify-content-end">
                  <div className="col-6 text-right">
                    <a
                      onClick={() => {
                        setErrMessage("");
                        setForemanInputs({
                          first_name: "",
                          last_name: "",
                          phone_number: "",
                          email: "",
                          pass: "",
                          confirm_pass: "",
                          profile_pic: "",
                        });
                        setPreview("");
                      }}
                      href=""
                      className="small-text-bold red-color add-new-btn"
                      data-toggle="modal"
                      data-target="#add-foreman"
                    >
                      <img src={addIconImage} /> <span>Add New</span>
                    </a>
                  </div>
                </div>
              </div>
              {/* Sales Team */}
              <div className="col-12 col-sm-6 mt-3 half-grid-padding-right truck-box">
                <p className="small-text-bold">Sales Team</p>
                {salesTeamList.map((salesman) => (
                  <div key={salesman.id} className="foreman-row mt-30 mt-m-20">
                    <div className="row">
                      <div className="col-12 col-sm-7">
                        <div className="row">
                          <div className="col-2">
                            <img
                              onClick={() => {
                                setSelectedSalesman(false);
                                setTimeout(() => {
                                  setSelectedSalesman(salesman);
                                }, 100);
                              }}
                              className="border-0 bg-transparent red-color caption"
                              data-toggle="modal"
                              data-target="#edit-salesman"
                              style={{ cursor: "pointer" }}
                              src={editIconImage}
                            />
                          </div>
                          <div className="col-10 pl-0">
                            <p className="lh-m-16">
                              {salesman.first_name + " " + salesman.last_name}
                            </p>
                            <p className="light-grey-color small-font-size">
                              {salesman.user_model.email}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-10 col-sm-5 offset-2 offset-sm-0 pl-0 text-right text-left-m">
                        <p>{salesman.user_model.phone}</p>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="row pt-2 justify-content-end">
                  <div className="col-6 text-right">
                    <a
                      onClick={() => {
                        setErrMessage("");
                        setForemanInputs({
                          first_name: "",
                          last_name: "",
                          phone_number: "",
                          email: "",
                          pass: "",
                          confirm_pass: "",
                          profile_pic: "",
                        });
                        setPreview("");
                      }}
                      href=""
                      className="small-text-bold red-color add-new-btn"
                      data-toggle="modal"
                      data-target="#add-salesman"
                    >
                      <img src={addIconImage} /> <span>Add New</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* Managers */}
              <div className="col-12 col-sm-6 half-grid-padding-left foreman-box mt-m-20">
                <p className="small-text-bold">Managers</p>
                {managerList.map((manager) => (
                  <div key={manager.id} className="foreman-row mt-30 mt-m-20">
                    <div className="row">
                      <div className="col-12 col-sm-7">
                        <div className="row">
                          <div className="col-2">
                            {role === "company" && (
                              <img
                                onClick={() => {
                                  setSelectedManager(false);
                                  setTimeout(() => {
                                    setSelectedManager(manager);
                                  }, 100);
                                }}
                                className="border-0 bg-transparent red-color caption"
                                data-toggle="modal"
                                data-target="#edit-manager"
                                style={{ cursor: "pointer" }}
                                src={editIconImage}
                              />
                            )}
                          </div>
                          <div className="col-10 pl-0">
                            <p className="lh-m-16">{manager.name}</p>
                            <p className="light-grey-color small-font-size">
                              {manager.user_model?.email}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-10 col-sm-5 offset-2 offset-sm-0 pl-0 text-right text-left-m">
                        <p>{manager.user_model?.phone}</p>
                      </div>
                    </div>
                  </div>
                ))}

                {role === "company" && (
                  <div className="row pt-2 justify-content-end">
                    <div className="col-6 text-right">
                      <a
                        onClick={() => {
                          setErrMessage("");
                          setPreview("");
                          setManagerInputs({});
                        }}
                        href=""
                        className="small-text-bold red-color add-new-btn"
                        data-toggle="modal"
                        data-target="#add-manager"
                      >
                        <img src={addIconImage} /> <span>Add New</span>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* // <!-- Change Password --> */}
      <div
        className="modal fade"
        id="change-password"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-password-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="close-change-password"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {updatePasswordSuccessMessage !== "" ? (
              <Alert variant="filled" severity="success">
                {updatePasswordSuccessMessage}
              </Alert>
            ) : (
              <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
                <div className="modal-body p-0">
                  <h5 className="text-center" id="change-password-title">
                    Change password
                  </h5>
                  {errMessage && (
                    <Alert variant="filled" severity="error">
                      {errMessage}
                    </Alert>
                  )}

                  <div className="form-box pt-20">
                    <form onSubmit={handleUpdatePasswordSubmit}>
                      <div className="form-group">
                        <label
                          htmlFor="password"
                          className="overline dark-grey-color"
                        >
                          New Password
                        </label>
                        <input
                          required
                          type="password"
                          className="form-control small-font-size"
                          id="password"
                          value={updatePassword.password}
                          onChange={handleUpdatePasswordInputs}
                          placeholder="*****************"
                        />
                        {passStrength.level > 0 && updatePassword.password && (
                          <div
                            className="progress"
                            style={{ height: 2, marginTop: -3 }}
                          >
                            <div
                              className={
                                "progress-bar " +
                                ((passStrength.level < 2 && "bg-danger") ||
                                  // (passStrength.level === 2 && "bg-info") ||
                                  (passStrength.level === 3 &&
                                    updatePassword.password.length >= 8 &&
                                    "bg-success"))
                              }
                              role="progressbar"
                              style={{
                                width: passStrength.level * 33.333 + "%",
                              }}
                              aria-valuenow={passStrength.level}
                              aria-valuemin="0"
                              aria-valuemax="3"
                            ></div>
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="confirm_password"
                          className="overline dark-grey-color"
                        >
                          Re-Enter New Password
                        </label>
                        <input
                          required
                          type="password"
                          className="form-control small-font-size"
                          id="confirm_password"
                          value={updatePassword.confirm_password}
                          onChange={handleUpdatePasswordInputs}
                          placeholder="*****************"
                        />
                      </div>
                      <div className="form-group pt-3">
                        <button
                          type="submit"
                          className="red-btn custom-buton custom-buton-large w-100"
                        >
                          Save Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- Add Foreman --> */}
      <div
        className="modal fade mt-m-80 pb-m-80 "
        id="add-foreman"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="add-foreman-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg "
          role="document"
        >
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close_foreman"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="add-foreman-title">
                  Add foreman
                </h5>
                {errMessage && (
                  <Alert variant="filled" severity="error">
                    {errMessage}
                  </Alert>
                )}
                <div className="form-box pt-20">
                  <form onSubmit={handleForemanSubmit}>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="first_name"
                            className="overline dark-grey-color"
                          >
                            First Name
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control small-font-size"
                            id="first_name"
                            value={foremanInputs.first_name}
                            onChange={handleChangeForemanInputs}
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="last_name"
                            className="overline dark-grey-color"
                          >
                            Last name
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control small-font-size"
                            id="last_name"
                            value={foremanInputs.last_name}
                            onChange={handleChangeForemanInputs}
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="phone_number"
                            className="overline dark-grey-color"
                          >
                            Phone Number
                          </label>

                          <NumberFormat
                            required
                            onChange={handleChangeForemanInputs}
                            value={foremanInputs.phone_number}
                            className="form-control small-font-size"
                            id="phone_number"
                            placeholder="(217) 555-0113"
                            format="(###) ###-####"
                            mask="_"
                          />
                          {/* <label
                            htmlFor="phone_number"
                            className="overline dark-grey-color"
                          >
                            Phone Number
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control small-font-size"
                            id="phone_number"
                            value={foremanInputs.phone_number}
                            onChange={handleChangeForemanInputs}
                            placeholder="Phone Number"
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className="overline dark-grey-color"
                          >
                            Email
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control small-font-size"
                            id="email"
                            value={foremanInputs.email}
                            onChange={handleChangeForemanInputs}
                            placeholder="example@gmail.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="pass"
                            className="overline dark-grey-color"
                          >
                            foreman account password
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control small-font-size"
                            id="pass"
                            value={foremanInputs.pass}
                            onChange={handleChangeForemanInputs}
                            placeholder="*****************"
                          />
                          {passStrength.level > 0 && foremanInputs.pass && (
                            <div
                              className="progress"
                              style={{ height: 2, marginTop: -3 }}
                            >
                              <div
                                className={
                                  "progress-bar " +
                                  ((passStrength.level < 2 && "bg-danger") ||
                                    // (passStrength.level === 2 && "bg-info") ||
                                    (passStrength.level === 3 &&
                                      foremanInputs.pass.length >= 8 &&
                                      "bg-success"))
                                }
                                role="progressbar"
                                style={{
                                  width: passStrength.level * 33.333 + "%",
                                }}
                                aria-valuenow={passStrength.level}
                                aria-valuemin="0"
                                aria-valuemax="3"
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="confirm_pass"
                            className="overline dark-grey-color"
                          >
                            re-enter password
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control small-font-size"
                            id="confirm_pass"
                            value={foremanInputs.confirm_pass}
                            onChange={handleChangeForemanInputs}
                            placeholder="*****************"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label
                          htmlFor="re-enter-password"
                          className="overline dark-grey-color"
                        >
                          Profile Pic
                        </label>
                        {preview === "" ? (
                          <>
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="icon-button-file"
                              onChange={handleUpload}
                              type="file"
                              value=""
                            />
                            <label htmlFor="icon-button-file">
                              <svg
                                style={{ cursor: "pointer", marginLeft: 5 }}
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="rounded-circle"
                              >
                                <rect
                                  width="50"
                                  height="50"
                                  rx="6"
                                  fill="#F2F2F2"
                                />
                                <path
                                  d="M16 17V14H18V17H21V19H18V22H16V19H13V17H16ZM19 23V20H22V17H29L30.83 19H34C35.1 19 36 19.9 36 21V33C36 34.1 35.1 35 34 35H18C16.9 35 16 34.1 16 33V23H19ZM26 32C28.76 32 31 29.76 31 27C31 24.24 28.76 22 26 22C23.24 22 21 24.24 21 27C21 29.76 23.24 32 26 32ZM22.8 27C22.8 28.77 24.23 30.2 26 30.2C27.77 30.2 29.2 28.77 29.2 27C29.2 25.23 27.77 23.8 26 23.8C24.23 23.8 22.8 25.23 22.8 27Z"
                                  fill="#C4C4C4"
                                />
                              </svg>
                            </label>
                          </>
                        ) : (
                          <>
                            <Badge
                              color="secondary"
                              overlap="circle"
                              badgeContent={
                                <span
                                  style={{
                                    fontSize: 16,
                                    marginBottom: 2,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPreview("");
                                    setForemanInputs({
                                      ...foremanInputs,
                                      profile_pic: "",
                                    });
                                  }}
                                >
                                  x
                                </span>
                              }
                            >
                              <img
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                src={s3baseUrl + preview}
                                style={{
                                  margin: 5,
                                  borderRadius: "50%",
                                }}
                              ></img>
                            </Badge>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group pt-3">
                          <button
                            type="submit"
                            className="red-btn custom-buton custom-buton-large w-100"
                          >
                            Add foreman
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add Salesman --> */}
      <div
        className="modal fade mt-m-80 pb-m-80 "
        id="add-salesman"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="add-salesman-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg "
          role="document"
        >
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close_salesman"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="add-foreman-title">
                  Add Salesman
                </h5>
                {errMessage && (
                  <Alert variant="filled" severity="error">
                    {errMessage}
                  </Alert>
                )}
                <div className="form-box pt-20">
                  <form onSubmit={handleSalesmanSubmit}>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="first_name"
                            className="overline dark-grey-color"
                          >
                            First Name
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control small-font-size"
                            id="first_name"
                            value={salesmanInputs.first_name}
                            onChange={handleChangeSalesmanInputs}
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="last_name"
                            className="overline dark-grey-color"
                          >
                            Last name
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control small-font-size"
                            id="last_name"
                            value={salesmanInputs.last_name}
                            onChange={handleChangeSalesmanInputs}
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="phone_number"
                            className="overline dark-grey-color"
                          >
                            Phone Number
                          </label>

                          <NumberFormat
                            required
                            onChange={handleChangeSalesmanInputs}
                            value={salesmanInputs.phone_number}
                            className="form-control small-font-size"
                            id="phone_number"
                            placeholder="(217) 555-0113"
                            format="(###) ###-####"
                            mask="_"
                          />
                          {/* <label
                            htmlFor="phone_number"
                            className="overline dark-grey-color"
                          >
                            Phone Number
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control small-font-size"
                            id="phone_number"
                            value={foremanInputs.phone_number}
                            onChange={handleChangeForemanInputs}
                            placeholder="Phone Number"
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className="overline dark-grey-color"
                          >
                            Email
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control small-font-size"
                            id="email"
                            value={salesmanInputs.email}
                            onChange={handleChangeSalesmanInputs}
                            placeholder="example@gmail.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="pass"
                            className="overline dark-grey-color"
                          >
                            Salesman account password
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control small-font-size"
                            id="pass"
                            value={salesmanInputs.pass}
                            onChange={handleChangeSalesmanInputs}
                            placeholder="*****************"
                          />
                          {passStrength.level > 0 && salesmanInputs.pass && (
                            <div
                              className="progress"
                              style={{ height: 2, marginTop: -3 }}
                            >
                              <div
                                className={
                                  "progress-bar " +
                                  ((passStrength.level < 2 && "bg-danger") ||
                                    // (passStrength.level === 2 && "bg-info") ||
                                    (passStrength.level === 3 &&
                                      salesmanInputs.pass.length >= 8 &&
                                      "bg-success"))
                                }
                                role="progressbar"
                                style={{
                                  width: passStrength.level * 33.333 + "%",
                                }}
                                aria-valuenow={passStrength.level}
                                aria-valuemin="0"
                                aria-valuemax="3"
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="confirm_pass"
                            className="overline dark-grey-color"
                          >
                            re-enter password
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control small-font-size"
                            id="confirm_pass"
                            value={salesmanInputs.confirm_pass}
                            onChange={handleChangeSalesmanInputs}
                            placeholder="*****************"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label
                          htmlFor="re-enter-password"
                          className="overline dark-grey-color"
                        >
                          Profile Pic
                        </label>
                        {preview === "" ? (
                          <>
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="icon-button-file"
                              onChange={handleUpload}
                              type="file"
                              value=""
                            />
                            <label htmlFor="icon-button-file">
                              <svg
                                style={{ cursor: "pointer", marginLeft: 5 }}
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="rounded-circle"
                              >
                                <rect
                                  width="50"
                                  height="50"
                                  rx="6"
                                  fill="#F2F2F2"
                                />
                                <path
                                  d="M16 17V14H18V17H21V19H18V22H16V19H13V17H16ZM19 23V20H22V17H29L30.83 19H34C35.1 19 36 19.9 36 21V33C36 34.1 35.1 35 34 35H18C16.9 35 16 34.1 16 33V23H19ZM26 32C28.76 32 31 29.76 31 27C31 24.24 28.76 22 26 22C23.24 22 21 24.24 21 27C21 29.76 23.24 32 26 32ZM22.8 27C22.8 28.77 24.23 30.2 26 30.2C27.77 30.2 29.2 28.77 29.2 27C29.2 25.23 27.77 23.8 26 23.8C24.23 23.8 22.8 25.23 22.8 27Z"
                                  fill="#C4C4C4"
                                />
                              </svg>
                            </label>
                          </>
                        ) : (
                          <>
                            <Badge
                              color="secondary"
                              overlap="circle"
                              badgeContent={
                                <span
                                  style={{
                                    fontSize: 16,
                                    marginBottom: 2,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPreview("");
                                    setSalesmanInputs({
                                      ...salesmanInputs,
                                      profile_pic: "",
                                    });
                                  }}
                                >
                                  x
                                </span>
                              }
                            >
                              <img
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                src={s3baseUrl + preview}
                                style={{
                                  margin: 5,
                                  borderRadius: "50%",
                                }}
                              ></img>
                            </Badge>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group pt-3">
                          <button
                            type="submit"
                            className="red-btn custom-buton custom-buton-large w-100"
                          >
                            Add Salesman
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add Manager --> */}
      <div
        className="modal fade mt-m-80 pb-m-80 "
        id="add-manager"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="add-manager-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg "
          role="document"
        >
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close_manager"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="add-foreman-title">
                  Add Manager
                </h5>
                {errMessage && (
                  <Alert variant="filled" severity="error">
                    {errMessage}
                  </Alert>
                )}
                <div className="form-box pt-20">
                  <form onSubmit={handleManagerSubmit}>
                    <div className="row">
                      <div className="col-12 ">
                        <div className="form-group">
                          <label
                            htmlFor="name"
                            className="overline dark-grey-color"
                          >
                            Full Name
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control small-font-size"
                            id="name"
                            value={managerInputs.name}
                            onChange={handleChangeManagerInputs}
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="phone_number"
                            className="overline dark-grey-color"
                          >
                            Phone Number
                          </label>

                          <NumberFormat
                            required
                            onChange={handleChangeManagerInputs}
                            value={managerInputs.phone_number}
                            className="form-control small-font-size"
                            id="phone_number"
                            placeholder="(217) 555-0113"
                            format="(###) ###-####"
                            mask="_"
                          />
                          {/* <label
                            htmlFor="phone_number"
                            className="overline dark-grey-color"
                          >
                            Phone Number
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control small-font-size"
                            id="phone_number"
                            value={foremanInputs.phone_number}
                            onChange={handleChangeForemanInputs}
                            placeholder="Phone Number"
                          /> */}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className="overline dark-grey-color"
                          >
                            Email
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control small-font-size"
                            id="email"
                            value={managerInputs.email}
                            onChange={handleChangeManagerInputs}
                            placeholder="example@gmail.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="pass"
                            className="overline dark-grey-color"
                          >
                            Manager account password
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control small-font-size"
                            id="pass"
                            value={managerInputs.pass}
                            onChange={handleChangeManagerInputs}
                            placeholder="*****************"
                          />
                          {passStrength.level > 0 && managerInputs.pass && (
                            <div
                              className="progress"
                              style={{ height: 2, marginTop: -3 }}
                            >
                              <div
                                className={
                                  "progress-bar " +
                                  ((passStrength.level < 2 && "bg-danger") ||
                                    // (passStrength.level === 2 && "bg-info") ||
                                    (passStrength.level === 3 &&
                                      managerInputs.pass.length >= 8 &&
                                      "bg-success"))
                                }
                                role="progressbar"
                                style={{
                                  width: passStrength.level * 33.333 + "%",
                                }}
                                aria-valuenow={passStrength.level}
                                aria-valuemin="0"
                                aria-valuemax="3"
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="confirm_pass"
                            className="overline dark-grey-color"
                          >
                            re-enter password
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control small-font-size"
                            id="confirm_pass"
                            value={managerInputs.confirm_pass}
                            onChange={handleChangeManagerInputs}
                            placeholder="*****************"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label
                          htmlFor="re-enter-password"
                          className="overline dark-grey-color"
                        >
                          Profile Pic
                        </label>
                        {preview === "" ? (
                          <>
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="icon-button-file"
                              onChange={handleUpload}
                              type="file"
                              value=""
                            />
                            <label htmlFor="icon-button-file">
                              <svg
                                style={{ cursor: "pointer", marginLeft: 5 }}
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="rounded-circle"
                              >
                                <rect
                                  width="50"
                                  height="50"
                                  rx="6"
                                  fill="#F2F2F2"
                                />
                                <path
                                  d="M16 17V14H18V17H21V19H18V22H16V19H13V17H16ZM19 23V20H22V17H29L30.83 19H34C35.1 19 36 19.9 36 21V33C36 34.1 35.1 35 34 35H18C16.9 35 16 34.1 16 33V23H19ZM26 32C28.76 32 31 29.76 31 27C31 24.24 28.76 22 26 22C23.24 22 21 24.24 21 27C21 29.76 23.24 32 26 32ZM22.8 27C22.8 28.77 24.23 30.2 26 30.2C27.77 30.2 29.2 28.77 29.2 27C29.2 25.23 27.77 23.8 26 23.8C24.23 23.8 22.8 25.23 22.8 27Z"
                                  fill="#C4C4C4"
                                />
                              </svg>
                            </label>
                          </>
                        ) : (
                          <>
                            <Badge
                              color="secondary"
                              overlap="circle"
                              badgeContent={
                                <span
                                  style={{
                                    fontSize: 16,
                                    marginBottom: 2,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPreview("");
                                    setManagerInputs({
                                      ...managerInputs,
                                      profile_pic: "",
                                    });
                                  }}
                                >
                                  x
                                </span>
                              }
                            >
                              <img
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                src={s3baseUrl + preview}
                                style={{
                                  margin: 5,
                                  borderRadius: "50%",
                                }}
                              ></img>
                            </Badge>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group pt-3">
                          <button
                            type="submit"
                            className="red-btn custom-buton custom-buton-large w-100"
                          >
                            Add Manager
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* // <!-- Add Truck --> */}
      <div
        className="modal fade mt-m-80 pb-m-80"
        id="add-truck"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="add-truck-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close_truck"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {addTruckComponent && <AddTruck fetchTruckList={fetchTruckList} />}
          </div>
        </div>
      </div>

      {/* // <!-- Edit foreman--> */}
      <div
        className="modal fade mt-m-80 pb-m-80 "
        id="edit-foreman"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-password-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg "
          role="document"
        >
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close_foreman_edit"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              {selectedForeman && (
                <EditForeman
                  setSelectedForeman={setSelectedForeman}
                  selectedForeman={selectedForeman}
                  fetchForemanList={fetchForemanList}
                  closePopUp={() =>
                    document.getElementById("close_foreman_edit").click()
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* // <!-- Edit Truck--> */}
      <div
        className="modal fade mt-m-80 pb-m-80 "
        id="edit-truck"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-password-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg "
          role="document"
        >
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close_truck_edit"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              {selectedTruck && (
                <EditTruck
                  truck={selectedTruck}
                  fetchTruckList={fetchTruckList}
                  closePopUp={() =>
                    document.getElementById("close_truck_edit").click()
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* // <!-- Edit Salesman--> */}
      <div
        className="modal fade mt-m-80 pb-m-80 "
        id="edit-salesman"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-password-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg "
          role="document"
        >
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close_salesman_edit"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              {selectedSalesman && (
                <EditSalesman
                  setSelectedSalesman={setSelectedSalesman}
                  selectedSalesman={selectedSalesman}
                  fetchSalesmanList={fetchSalesTeamList}
                  closePopUp={() =>
                    document.getElementById("close_salesman_edit").click()
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* // <!-- Edit Manager--> */}
      <div
        className="modal fade mt-m-80 pb-m-80 "
        id="edit-manager"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change-password-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg "
          role="document"
        >
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="close_manager_edit"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              {selectedManager && (
                <EditManager
                  setSelectedManager={setSelectedManager}
                  selectedManager={selectedManager}
                  fetchManagersList={fetchManagersList}
                  closePopUp={() =>
                    document.getElementById("close_manager_edit").click()
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Company);
