import React from "react";
import { useState } from "react";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import MianComment from "./components/MianComment";

function Comments(props) {
  const { requestData, fetchRequestDetails } = props;
  const { comments_array, company_rating, customer_rating } = requestData;
  const { id } = requestData.request;

  const [commentInput, setCommentInput] = useState("");

  const handleMainComment = (e) => {
    e.preventDefault();
    addComment(id, commentInput, 0, 0);
    setCommentInput("");
  };

  const addComment = (request_id, comment, parent_id, type) => {
    let requestObj = {
      path: "/app_api/add_comments",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${request_id}`,
      comment: `${comment}`,
      parent_id: `${parent_id}`,
      type: `${type}`,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "COMMENT RESPONSE");
      if (res.code === 200) {
        fetchRequestDetails("comment");
      }
    });
  };

  return (
    <>
      <div className="rounded-box mt-4">
        <div className="heading-wrapper black-bg text-center position-relative">
          <h6 className="text-white">Comments ({comments_array.length})</h6>
          {/* <div className="caption position-absolute text-white show-all-text">
            Show all
          </div> */}
        </div>
        <div className="comments-section-wrapper p-3 pt-4">
          {comments_array.map((comment, i) => (
            <MianComment
              key={i}
              main_comment={comment}
              addComment={addComment}
              customer_rating={customer_rating}
              company_rating={company_rating}
            />
          ))}

          {/* Your comment section */}
          <div className="reply-box-wrapper mt-20 w-100">
            <form onSubmit={handleMainComment}>
              <div className="row">
                <div className="col-12">
                  <span className="dark-grey-color small-sub-title">
                    Your comment
                  </span>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      required
                      rows="5"
                      multiple
                      autoComplete="off"
                      type="textarea"
                      name="reply"
                      className="form-control small-font-size"
                      placeholder="Enter your comment here..."
                      value={commentInput}
                      onChange={(e) => setCommentInput(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-8 col-xl-10"></div>
                <div className="col-12 col-md-4 col-xl-2">
                  <button
                    type="submit"
                    className="custom-btn-small red-btn w-100"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Comments;
