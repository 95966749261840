import React from "react";
import { useEffect } from "react";
import { settingsIconImage, editImage, addIconImage } from "../../../assets";
import { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import EditRoomName from "./EditRoomName";
import { invokeApi } from "../../../bl_libs/invokeApi";
import DeteleRoom from "./DeteleRoom";
import AddItemsInRoom from "./AddItemsInRoom";
import ItemDetailsInRoom from "./ItemDetailsInRoom";
import DeleteItemFromRoom from "./DeleteItemFromRoom";

function AddItemsByRoom(props) {
  const { UpdateItemsByRoom, rooms, setRooms, roomsCopy } = props;
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");

  const [roomList, setRoomList] = useState([]);

  const [roomType, setRoomType] = useState("");

  const [isEdited, setIsEdited] = useState(false);

  const setEdited = () => setIsEdited(true);

  const check_list = () => {
    let items = [];
    rooms.map((room) => {
      room.itemList.map((item) => {
        if (item.value > 0) {
          items.push(item);
        }
      });
    });
    return items;
  };

  useEffect(() => {
    console.log(roomType, "effect");
  }, [roomType]);

  //Add new room card
  const handleAddRoom = () => {
    let temp = rooms.map((room) => room);
    let getId = "";
    for (let i = 0; i < roomList.length; i++) {
      if (roomList[i].name === roomType) {
        getId = roomList[i].id;
        break;
      }
    }

    console.log(roomList);

    //if room type is dummy its means its on "Please select a room" option
    // so we return this function here otherwise continue
    if (roomType === "") return;

    let count = 1;
    temp.map((room) => {
      if (room.id === getId) {
        count++;
      }
    });

    console.log(count, "COUNT");
    temp.push({
      room_name: count > 1 ? roomType + " #" + count : roomType,
      //we are not want 1st room with #1 so we skip count 1
      room_id: uuidv4(),
      id: getId,
      itemList: [],
    });
    console.log(temp);
    setRooms(temp);
    setEdited();
  };

  //delete roomm
  const handleDeleteRoom = (id) => {
    let temp = rooms.filter((room) => room.room_id !== id);
    console.log(temp);
    setRooms(temp);
    setEdited();
  };

  //delete item from room

  const handleDeleteFromRoom = (itemId, roomId) => {
    let tempRooms = rooms.map((room) => room);
    let roomIndex = "";
    let itemIndex = "";
    for (let i = 0; i < tempRooms.length; i++) {
      if (tempRooms[i].room_id === roomId) {
        console.log("room found");
        roomIndex = i; //room index found
        break;
      }
    }

    let tempItems = tempRooms[roomIndex].itemList.filter(
      (item) => item.item_id !== itemId
    );

    tempRooms[roomIndex].itemList = tempItems;
    setRooms(tempRooms);
    setEdited();
  };

  //fetch data form Apis

  const fetchItemCategories = () => {
    let requestObj = {
      path: "/api/customer/get_item_categories_by_type",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      type: "1",
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setIsLoading(false);
        setRoomList(res.data.items);
        console.log(res.data.items);
        javaScriptForDropDown();
      }
    });
  };

  //handle update room name

  const handleUpdateRoomName = (updated) => {
    console.log(updated);
    let temp = rooms.map((room) => {
      if (room.room_id === updated.room_id) {
        return {
          ...room,
          room_name: updated.room_name,
          itemList: room.itemList.map((item) => {
            return {
              ...item,
              category_name: updated.room_name,
            };
          }),
        };
      }
      return room;
    });
    setRooms(temp);
    setEdited();
  };

  const handleUpdateItemDetailsInRoom = (updatedItem, roomId) => {
    console.log(updatedItem, roomId);

    let tempRooms = rooms.map((room) => room);
    let roomIndex = "";
    let itemIndex = "";
    for (let i = 0; i < tempRooms.length; i++) {
      if (tempRooms[i].room_id === roomId) {
        console.log("room found");
        roomIndex = i; //found room index
        break;
      }
    }
    let tempRoom = tempRooms[roomIndex];
    console.log(tempRoom, "room ");

    for (let i = 0; i < tempRoom.itemList.length; i++) {
      if (tempRoom.itemList[i].item_id === updatedItem.item_id) {
        console.log("item found");
        itemIndex = i; //index of item found
      }
    }
    console.log(tempRoom.itemList[itemIndex], "item ");
    tempRooms[roomIndex].itemList[itemIndex] = updatedItem;

    console.log(tempRooms, "finl");
    setRooms(tempRooms);
    setEdited();
  };

  const handleAddItemsInRoom = (newItems, roomId) => {
    console.log(newItems, roomId);
    let temp = rooms.map((room) => {
      if (room.room_id === roomId) {
        let temp2 = room.itemList.map((item) => item);
        newItems.map((item) => {
          let count = 1;
          temp2.map((oldItem) => {
            if (oldItem.id === item.id) {
              count++;
            }
          });
          temp2.push({
            ...item,
            category_name: room.room_name,
            name:
              count > 1 && item.id !== ""
                ? item.name + " #" + count
                : item.name,
            //we are not want 1st item with #1 so we skip count 1
          });
        });

        return {
          ...room,
          itemList: temp2,
        };
      }
      return room;
    });

    setRooms(temp);
    setEdited();
  };

  //material pop up menu section
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    fetchItemCategories();
  }, []);
  // javaScript to enable select room menu
  const javaScriptForDropDown = () => {
    var x, i, j, l, ll, selElmnt, a, b, c;
    /* Look for any elements with the class "custom-select": */
    x = document.getElementsByClassName("custom-dropdown");
    l = x.length;
    for (i = 0; i < l; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      console.log(x[i]);
      ll = selElmnt.length;
      /* For each element, create a new DIV that will act as the selected item: */
      a = document.createElement("DIV");

      a.setAttribute("class", "select-selected");

      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;

      x[i].appendChild(a);
      /* For each element, create a new DIV that will contain the option list: */
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < ll; j++) {
        /* For each option in the original select element,
        create a new DIV that will act as an option item: */
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        console.log(c, "js");
        c.addEventListener("click", function (e) {
          /* When an item is clicked, update the original select box,
            and the selected item: */

          var y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          setRoomType(h.innerHTML);
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function (e) {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }

    function closeAllSelect(elmnt) {
      /* A function that will close all select boxes in the document,
      except the current select box: */
      var x,
        y,
        i,
        xl,
        yl,
        arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      xl = x.length;
      yl = y.length;
      for (i = 0; i < yl; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i);
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }

    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */
    document.addEventListener("click", closeAllSelect);
  };

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }

  return (
    <>
      <section className="main-content address-page pb-5">
        <div className="container">
          <div className="add-items-wrapper  mb-4 mt-3 mt-sm-5">
            <h1 className="text-center text-uppercase pt-3 pt-sm-4 pt-md-5 mb-0">
              Edit Items
            </h1>
            <p className="small-font-size text-center light-grey-color2">
              Total items: {props.getItemsCountByRoom()}
            </p>

            <div className="add-room-options pt-3">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-7 col-md-7">
                  <div className="custom-dropdown">
                    <select
                      name="room-option"
                      value={roomType}
                      onSelect={(e) => console.log("object")}
                    >
                      <option value="">Please Selelct a room</option>
                      {roomList.map((room) => (
                        <option key={room.id} value={room.name}>
                          {room.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="add-room-button-wrapper">
                    <button
                      onClick={handleAddRoom}
                      className="custom-buton custom-buton-large w-100"
                    >
                      Add Room
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {rooms.map((room, i) => (
              <div key={i} className="selected-room mt-4 mt-sm-5">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-8 mb-4 mb-sm-4">
                    <div className="rounded-box">
                      <div className="heading-wrapper">
                        <div
                          onClick={() => console.log(room)}
                          className="selected-room-heading float-left"
                        >
                          {room.room_name}
                        </div>
                        <div className="selected-room-settings float-right">
                          <div className="dropdown dropleft">
                            <img
                              className="dropdown-toggle pointer-point"
                              data-toggle="dropdown"
                              src={settingsIconImage}
                            />
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton "
                            >
                              <a
                                className="dropdown-item"
                                style={{
                                  color: "#747474",
                                  fontSize: "15px",
                                  cursor: "pointer",
                                }}
                                data-toggle="modal"
                                data-target="#edit-category"
                                onClick={() => {
                                  setPopUpFlag(false);
                                  setSelectedRoom(room);
                                  setTimeout(() => {
                                    setPopUpFlag(true);
                                  }, 100);
                                }}
                              >
                                Edit
                              </a>
                              <Divider />
                              <a
                                className="dropdown-item"
                                style={{
                                  color: "#747474",
                                  fontSize: "15px",
                                  cursor: "pointer",
                                }}
                                data-toggle="modal"
                                data-target="#delete-room"
                                onClick={() => {
                                  setPopUpFlag(false);
                                  setSelectedRoom(room);
                                  setTimeout(() => {
                                    setPopUpFlag(true);
                                  }, 100);
                                }}
                              >
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="selected-items">
                        {room.itemList.map((item) => (
                          <div key={item.item_id} className="single-item">
                            <div className="row">
                              <div className="col-10">
                                <div className="item-name">{item.name}</div>
                                <div className="item-count small-font-size light-grey-color">
                                  {item.value} piece
                                </div>
                              </div>
                              <div className="col-2 text-right">
                                <div className="dropdown dropleft ">
                                  <img
                                    className="dropdown-toggle pointer-point"
                                    data-toggle="dropdown"
                                    src={editImage}
                                  />
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      className="dropdown-item"
                                      style={{
                                        color: "#747474",
                                        fontSize: "15px",
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      data-target="#details-item-in-room"
                                      onClick={() => {
                                        setPopUpFlag(false);
                                        setSelectedItem(item);
                                        setSelectedRoom(room);
                                        setTimeout(() => {
                                          setPopUpFlag(true);
                                        }, 100);
                                      }}
                                    >
                                      Details
                                    </a>
                                    <Divider />
                                    <a
                                      className="dropdown-item"
                                      style={{
                                        color: "#747474",
                                        fontSize: "15px",
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      data-target="#delete-item-from-room"
                                      onClick={() => {
                                        setPopUpFlag(false);
                                        setSelectedItem(item);
                                        setSelectedRoom(room);
                                        setTimeout(() => {
                                          setPopUpFlag(true);
                                        }, 100);
                                      }}
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="single-item">
                          <div className="row">
                            <div className="col-12 text-center">
                              <img
                                src={addIconImage}
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setPopUpFlag(false);
                                  setSelectedRoom(room);
                                  setTimeout(() => {
                                    setPopUpFlag(true);
                                  }, 100);
                                }}
                                data-toggle="modal"
                                data-target="#add-item-in-room"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row justify-content-center ">
            <div className="">
              {isEdited && (
                <button
                  onClick={UpdateItemsByRoom}
                  className="custom-btn-small custom-buton"
                >
                  Update items List
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Edit Category Name --> */}
      <div
        className="modal fade  edit-category popup-bottom mt-0"
        id="edit-category"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="edit-category"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30 mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="edit-category-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {popUpFlag && (
              <EditRoomName
                room={selectedRoom}
                handleUpdateRoomName={handleUpdateRoomName}
                closePopUp={() =>
                  document.getElementById("edit-category-close").click()
                }
              />
            )}
          </div>
        </div>
      </div>

      {/* <!-- Delete Item popup --> */}
      <div
        className="modal fade delete-item popup-bottom mt-0"
        id="delete-room"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="delete-item"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30 mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="DeleteRoomClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {popUpFlag && (
              <DeteleRoom
                handleDeleteRoom={handleDeleteRoom}
                room={selectedRoom}
                closePopUp={() =>
                  document.getElementById("DeleteRoomClose").click()
                }
              />
            )}
          </div>
        </div>
      </div>

      {/* <!-- Add Item Popup --> */}
      <div
        className="modal fade add-item popup-bottom full-height"
        id="add-item-in-room"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="add-item"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="add-item-in-room-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {popUpFlag && (
              <AddItemsInRoom
                rooms={rooms}
                room={selectedRoom}
                handleAddItemsInRoom={handleAddItemsInRoom}
                closePopUp={() =>
                  document.getElementById("add-item-in-room-close").click()
                }
              />
            )}
          </div>
        </div>
      </div>

      {/* <!-- Item Detail Popup --> */}
      <div
        className="modal fade item-detail popup-bottom full-height"
        id="details-item-in-room"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="item-detail"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="details-item-in-room-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {popUpFlag && (
              <ItemDetailsInRoom
                handleUpdateItemDetailsInRoom={handleUpdateItemDetailsInRoom}
                item={selectedItem}
                room={selectedRoom}
                closePopUp={() =>
                  document.getElementById("details-item-in-room-close").click()
                }
              />
            )}
          </div>
        </div>
      </div>

      {/* <!-- Delete Item popup --> */}
      <div
        className="modal fade delete-item popup-bottom mt-0"
        id="delete-item-from-room"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="delete-item"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30 mt-3">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
                id="delete-item-from-room-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {popUpFlag && (
              <DeleteItemFromRoom
                handleDeleteFromRoom={handleDeleteFromRoom}
                item={selectedItem}
                room={selectedRoom}
                closePopUp={() =>
                  document.getElementById("delete-item-from-room-close").click()
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddItemsByRoom;
