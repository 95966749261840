import React, { useEffect, useState } from "react";
import { filterImage } from "../../assets";
import { withRouter } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import { invokeApi } from "../../bl_libs/invokeApi";
import AllTransactions from "./components/All-Transactions/AllTransactions";
import DebitTransactions from "./components/Debit-Transactions/DebitTransactions";
import CreditTransactions from "./components/Credit-Transactions/CreditTransactions";

function Transactions(props) {
  const [requestType, setRequestType] = useState("ALL");
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState({
    all: 0,
    debit: 0,
    credit: 0,
  });

  const FetchTotalTransactions = () => {
    let requestObj = {
      path: "/app_api/total_trasaction",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setTotal({
          all: res.total_transaaction_amount,
          debit: res.debit_transaaction_amount,
          credit: res.credit_transaaction_amount,
        });
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    FetchTotalTransactions();
  }, []);

  if (isLoading) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">Transactions</p>
            <div className="Circular-Progress ">
              <CircularProgress />
            </div>
          </div>
        </section>
      </>
    );
  }
  return (
    <>
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title">Transactions</p>
          <div className="calendar-wrapper mt-40 mt-m-20 pb-30 ">
            <div className="row payment-tab-row ">
              <div className="col-12 pb-4 text-right">
                <Button
                  onClick={() => props.history.push("/company/payment-history")}
                  variant="outlined"
                  color="primary"
                  className="ml-3"
                  endIcon={<UpdateIcon />}
                >
                  View Payments
                </Button>
              </div>
              {/* <div className="col-4 text-right pb-4 ">
                <span
                  className="black-color pointer-point"
                  data-toggle="modal"
                  data-target="#change-filter"
                >
                  <img src={filterImage} />
                  <span className="pl-2">Filter</span>
                </span>
              </div> */}
              {/* New section */}
              <div
                onClick={() => setRequestType("ALL")}
                className={
                  requestType === "ALL"
                    ? "col-12 col-md-4 payment-tab-active"
                    : "col-12 col-md-4 payment-tab"
                }
              >
                <span>All Transactions</span>
                <div>
                  <span className="payment-tab-total">${total.all}</span>
                </div>
              </div>
              <div
                onClick={() => setRequestType("DEBIT")}
                className={
                  requestType === "DEBIT"
                    ? "col-6 col-md-4 payment-tab-active"
                    : "col-6 col-md-4 payment-tab"
                }
              >
                <span>Total Debit</span>
                <div>
                  <span className="payment-tab-total">${total.debit}</span>
                </div>
              </div>
              <div
                onClick={() => setRequestType("CREDIT")}
                className={
                  requestType === "CREDIT"
                    ? "col-6 col-md-4 payment-tab-active "
                    : "col-6 col-md-4 payment-tab "
                }
              >
                <span>Total Credit</span>
                <div>
                  <span className="payment-tab-total">${total.credit}</span>
                </div>
              </div>
            </div>

            {requestType === "ALL" && <AllTransactions />}
            {requestType === "DEBIT" && <DebitTransactions />}
            {requestType === "CREDIT" && <CreditTransactions />}
          </div>
        </div>
      </section>
    </>
  );
}

export default Transactions;
