import React, { useState } from "react";
import { makeStyles, Badge } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import NumberFormat from "react-number-format";
import { invokeApi } from "../../../bl_libs/invokeApi";
import {useSnakbar} from '../../../Context/Context-Snakbar/ContextSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
}));

function AddItem({update, setUpdate}) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const [errMessage, setErrMessage] = useState();
  const [name, setName] = useState();
  const [price, setPrice] = useState();
  const [description, setDescription] = useState();
  const [status, setStatus] = useState("1");
  const handleSubmit = (e) => {
    e.preventDefault();
    let requestObj = {
      path: `/api/company/add_company_extra_item`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: {
        name,
        description,
        type: "2",
        unit_price: price,
        currency: "USD",
        is_active: status,
      },
    };

    // setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "responseeeeeeeeeeeeeee");
      if (res.code === 200) {

        document.getElementById('close-modal').click();
        showSuccessSnak("Item Added Successfully");
        setUpdate(!update);
        // SetRequests(res.emailtemplate);
        // setIsLoading(false);
      }
    });
  };
  return (
    <>
      <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
        <div className="modal-body p-0">
          <h5 className="text-center" id="add-truck-title">
            Add Item
          </h5>
          {/* {errMessage && (
            <Alert variant="filled" severity="error">
              {errMessage}
            </Alert>
          )} */}
          <div className="form-box pt-20">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="name" className="overline dark-grey-color">
                      Item Name
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control small-font-size"
                      id="name"
                      placeholder="Item name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="price" className="overline dark-grey-color">
                      Unit Price
                    </label>
                    <input
                      required
                      type="number"
                      className="form-control small-font-size"
                      id="price"
                      placeholder="Unit Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label
                      for="exampleFormControlTextarea1"
                      className="overline dark-grey-color"
                    >
                      Description
                    </label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Desciption (optional)"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="status" className="overline dark-grey-color">
                      Status
                    </label>
                    <select
                      class="form-control"
                      id="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={"1"}>Active</option>
                      <option value={"0"}>In-Active</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-sm-6">
                  <div className="form-group pt-3">
                    <button
                      type="submit"
                      className="red-btn custom-buton custom-buton-large w-100"
                    >
                      Add Item
                    </button>
                    <button className="d-none" id='close-modal'
                    type="button"
                      data-dismiss="modal"
                      aria-label="Close"/>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddItem;
