import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  addImage,
  dateIconImage,
  greyBackArrowImage,
  subtractImage,
  timeIconImage,
} from "../../../../assets";
import { useState } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import { useEffect } from "react";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import { CircularProgress } from "@material-ui/core";
import { pick_up, drop_off } from "../../../../ui_libs/constants";
import { Alert } from "@material-ui/lab";
import { withRouter } from "react-router";
// import { Login, Register } from "../../../../pages";
import { useSnakbar } from "../../../../Context/Context-Snakbar/ContextSnackbar";
import AddPrices from "../Add-Prices/AddPrices";
import { time_convert } from "../../../../ui_libs/Functions/functions";
function ConfirmRequest(props) {
  const { showErrorSnak } = useSnakbar();
  const {
    requestDetails,
    requestKeyValue,
    setRequestKeyValue,
    itemsList,
    addressList,
    setScreen,
    isDirectRequest,
    companyProfile,
    salesmanList,
    setSalesmanList,
    truckList,
    setTruckList,
    leadSourcelist,
    setLeadSourcelist,
    selectedTrucks,
    setSelectedTrucks,
  } = props;
  console.log(props, "props");
  const [errMessage, setErrMessage] = React.useState("");
  const [isLoading, setIsLoading] = useState(true);
  const animatedComponents = makeAnimated();
  const [isLoadingForImage, setIsLoadingForImage] = useState(false);
  const [isLoadingForReq, setIsLoadingForReq] = useState(false);
  const [isCustomerPriceEmpty, setIsCustomerPriceEmpty] = useState(false);
  const [formatData, setFormatData] = useState([]);
  const [estimatePrice, setEstimatePrice] = useState({
    start: "",
    end: "",
  });

  const [confirmScreen, setConfirmScreen] = useState("");
  const [reqPostData, setReqPostData] = useState();

  const handleComment = (e) => {
    setRequestKeyValue({ ...requestKeyValue, comments: e.target.value });
  };

  const handleCompanyNotes = (e) => {
    setRequestKeyValue({ ...requestKeyValue, company_notes: e.target.value });
  };

  const handleCustomerPrice = (e) => {
    setRequestKeyValue({ ...requestKeyValue, customer_price: e.target.value });
  };
  const handleChangeInKeyValues = (e) => {
    setRequestKeyValue({
      ...requestKeyValue,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeInSalesTeam = (e) => {
    setRequestKeyValue({ ...requestKeyValue, salesman: e.target.value });
  };
  const handleChangeInTruckArray = (e) => {
    setSelectedTrucks(e);
    let temp = [];
    if (e !== [] && e !== null) {
      e.map((truck) => {
        temp = [...temp, { id: String(truck.value) }];
      });
    }
    setRequestKeyValue({ ...requestKeyValue, truck_id_array: temp });
  };

  const handleChangeInLeadSource = (e) => {
    setRequestKeyValue({ ...requestKeyValue, lead_source: e.target.value });
  };

  console.log(itemsList.extraItemsList, "request details");

  const fetchEstimatePrice = () => {
    let temp;
    if (itemsList.type === "by_room") {
      let filteredEmptyRooms = itemsList.byRoom.filter(
        (room) => room.itemList.length > 0
      );
      temp = [];
      filteredEmptyRooms.map((room) => {
        room.itemList.map((item) => {
          if (item.value > 0) {
            // temp.push({ _id: `${item.id}`, quantity: `${item.value}` });
            temp.push({
              _id: `${item.id ? item.id : "0"}`,
              name: `${item.name}`,
              type: `${item.type}`,
              unit_price: `${item.unit_price ? item.unit_price : "0"}`,
              size: `${item.size}`,
              quantity: `${item.value}`,
            });
          }
        });
      });
      //now add extra items in this
      itemsList.extraItemsList.map((item) => {
        if (item.value > 0 && item.isSelected) {
          // temp.push({ _id: `${item.id}`, quantity: `${item.value}` });
          temp.push({
            _id: `${item.id ? item.id : "0"}`,
            name: `${item.name}`,
            type: `${item.type}`,
            unit_price: `${item.unit_price ? item.unit_price : "0"}`,
            size: `${item.size}`,
            quantity: `${item.value}`,
          });
        }
      });
    } else if (itemsList.type === "by_common_list") {
      //filter non zero value items
      let filteredItems = itemsList.byCommonList.filter(
        (item) => item.value > 0
      );
      //now format the array for hiting API
      temp = filteredItems.map((item) => {
        // return { _id: `${item.id}`, quantity: `${item.value}` };
        return {
          _id: `${item.id ? item.id : "0"}`,
          name: `${item.name}`,
          type: `${item.type}`,
          unit_price: `${item.unit_price ? item.unit_price : "0"}`,
          size: `${item.size}`,
          quantity: `${item.value}`,
        };
      });
      //now add extra items in this
      itemsList.extraItemsList.map((item) => {
        if (item.value > 0 && item.isSelected) {
          // temp.push({ _id: `${item.id}`, quantity: `${item.value}` });
          temp.push({
            _id: `${item.id ? item.id : "0"}`,
            name: `${item.name}`,
            type: `${item.type}`,
            unit_price: `${item.unit_price ? item.unit_price : "0"}`,
            size: `${item.size}`,
            quantity: `${item.value}`,
          });
        }
      });
    }

    let address_list_formated = addressList.map((address) => {
      return {
        street_address: address.street_number ? address.street_number : "N/A",
        apartment_number: address.unitNumber,
        address_detail: address.addressDetails,
        is_storage: address.isStorage ? "1" : "0",
        complete_address: address.address,
        zip_code: `${address.zip_code}`,
        city: address.city ? address.city : "N/A",
        state: address.state ? address.state.short : "N/A",
        country: address.country ? address.country : "N/A",
        is_stairs: address.liftType === "stairs" ? "1" : "0",
        is_elevator: address.liftType === "stairs" ? "0" : "1",
        is_hardwood_floor: address.isHardwoodFloor ? "1" : "0",
        number_of_flights:
          address.liftType === "stairs" ? `${address.flights}` : null,
        parking_and_building_info: address.parkingInfo,
        latitude: `${address.lat}`,
        longitude: `${address.lng}`,
        address_type: address.type === "pick-up" ? pick_up : drop_off,
      };
    });

    console.log(temp, "Format");

    let requestObj = {
      path: "/api/customer/calculate_estimated_price",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      items_id_array: temp ? temp : [],
      address_array: address_list_formated,
      move_type: requestDetails.moveType,
      movers:
        requestKeyValue.movers === 0
          ? getMoversForCalculations().toString()
          : requestKeyValue.movers.toString(),
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        setEstimatePrice({
          start: res.data.Estimated_Price_Start,
          end: res.data.Estimated_Price_End,
        });
      }
      setIsLoading(false);
    });
  };

  const handleUploadAndReturn = (img) => {
    return new Promise((resolve) => {
      let requestObj = {
        path: "/app_api/upload_s3_file",
        method: "POST",
        headers: {},
      };

      let formData = new FormData();
      formData.append("upload_file", img);
      requestObj["postData"] = formData;
      invokeApi(requestObj).then((res) => {
        console.log(res);
        if (res.code === 200) {
          resolve(res.file_url);
        }
      });
    });
  };

  const handleSubmitRequest = () => {
    // salesman link validations
    if (isDirectRequest) {
      // if (requestKeyValue.salesman === "") {
      //   showErrorSnak("Please select a salesman");
      //   return;
      // }
      if (requestKeyValue.truck_id_array.length < 1) {
        showErrorSnak("Please select at least one truck");
        return;
      }
      // if(requestKeyValue.lead_source===""){
      //   showErrorSnak("Please select lead source");
      //   return;
      // }
      if (!requestKeyValue.customer_first_name) {
        showErrorSnak("Please enter a valid first name.");
        return;
      }

      if (!requestKeyValue.customer_last_name) {
        showErrorSnak("Please enter a valid last name.");
        return;
      }

      if (!requestKeyValue.customer_email) {
        showErrorSnak("Please enter a valid email address.");
        return;
      }
    }

    if (isDirectRequest && !requestKeyValue.customer_phone_number) {
      showErrorSnak("Please enter a valid phone number.");
      return;
    }

    if (isDirectRequest && requestKeyValue.customer_phone_number) {
      if (
        requestKeyValue.customer_phone_number[
          requestKeyValue.customer_phone_number.length - 1
        ] === "_"
      ) {
        showErrorSnak("Please enter a valid phone number.");
        return;
      }
    }

    if (requestKeyValue.rate === "Hourly") {
      if (!requestKeyValue.cash_payment_rate) {
        showErrorSnak("Please enter your cash hourly rate.");
        return;
      }

      if (!requestKeyValue.card_payment_rate) {
        showErrorSnak("Please enter your card hourly rate.");
        return;
      }

      if (!requestKeyValue.minimum_hours) {
        showErrorSnak("Please enter your expected minimum hours.");
        return;
      }
    }

    if (requestKeyValue.rate === "Flat") {
      if (!requestKeyValue.cash_flat_min) {
        showErrorSnak("Please enter your minimum cash rate.");
        return;
      }

      if (!requestKeyValue.cash_flat_max) {
        showErrorSnak("Please enter your maximum cash rate.");
        return;
      }

      if (!requestKeyValue.card_flat_min) {
        showErrorSnak("Please enter your minimum card rate.");
        return;
      }

      if (!requestKeyValue.card_flat_max) {
        showErrorSnak("Please enter your maximum card rate.");
        return;
      }
    }

    //show image uploading loader
    setIsLoadingForImage(true);

    let temp = [];

    //check type by room or by common list to get items from it
    //if it is by room
    if (itemsList.type === "by_room") {
      let filteredEmptyRooms = itemsList.byRoom.filter(
        (room) => room.itemList.length > 0
      );

      filteredEmptyRooms.map((room) => {
        room.itemList.map((item) => {
          if (item.value > 0) {
            temp.push(item);
          }
        });
      });
      //now add extra items in this
      itemsList.extraItemsList.map((item) => {
        if (item.value > 0 && item.isSelected) {
          temp.push(item);
        }
      });

      //if it is by common list
    } else if (itemsList.type === "by_common_list") {
      //filter non zero value items
      let filteredItems = itemsList.byCommonList.filter(
        (item) => item.value > 0
      );

      // now its filtered non empty value array

      temp = filteredItems.map((item) => {
        return item;
      });
      //now add extra items in this
      itemsList.extraItemsList.map((item) => {
        if (item.value > 0 && item.isSelected) {
          temp.push(item);
        }
      });
    }

    //upload all images from items to server

    let arr = []; //use to store promises

    for (let i = 0; i < temp.length; i++) {
      temp[i].pics = [];
      if (temp[i].hasOwnProperty("images")) {
        for (let j = 0; j < temp[i].images.length; j++) {
          arr.push(
            handleUploadAndReturn(temp[i].images[j].image).then((res) => {
              temp[i].pics.push({ _pic: res });
            })
          );
        }
      } else {
        temp[i].pics = [];
      }
    }

    //check All promises status

    Promise.all(arr).then((res) => {
      console.log(res, "all done");
      setIsLoadingForImage(false);
      setIsLoadingForReq(localStorage.getItem("token") ? true : false);
      handleRequestApi(temp);
    });
  };

  const getMoversForCalculations = () => {
    const moveSize = requestDetails.moveType;
    console.log(requestDetails, "R_D");

    if (moveSize === "Studio") {
      return 2;
    } else if (moveSize === "1 bed room apt.") {
      return 2;
    } else if (moveSize === "2 bed room apt.") {
      return 3;
    } else if (moveSize === "3 bed room apt.") {
      return 4;
    } else if (moveSize === "4 bed room apt.") {
      return 4;
    } else return 4;
  };

  const handleRequestApi = (list) => {
    const { role, company_direct_access_link } = JSON.parse(
      localStorage.getItem("user")
    );

    const company_api = "/api/company/generate_request_by_company";

    let requestObj = {
      path: company_api,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      address_array: addressList.map((address) => {
        return {
          street_address: address.street_number ? address.street_number : "N/A",
          apartment_number: address.unitNumber,
          address_detail: address.addressDetails,
          is_storage: address.isStorage ? "1" : "0",
          complete_address: address.address,
          zip_code: `${address.zip_code}`,
          city: address.city ? address.city : "N/A",
          state: address.state ? address.state.short : "N/A",
          country: address.country ? address.country : "N/A",
          is_stairs: address.liftType === "stairs" ? "1" : "0",
          is_elevator: address.liftType === "stairs" ? "0" : "1",
          is_hardwood_floor: address.isHardwoodFloor ? "1" : "0",
          number_of_flights:
            address.liftType === "stairs" ? `${address.flights}` : null,
          parking_and_building_info: address.parkingInfo,
          latitude: `${address.lat}`,
          longitude: `${address.lng}`,
          address_type: address.type === "pick-up" ? pick_up : drop_off,
          // company_direct_access_link: company_direct_access_link,
        };
      }),

      starting_lat: `${addressList[0].lat}`,
      starting_long: `${addressList[0].lng}`,
      schedule_time:
        moment(requestDetails.date).format("MMM Do YY") +
        " " +
        requestDetails.time.move_duration,
      move_type: requestDetails.moveType,
      payment_status: requestDetails.isCardVarified ? "1" : "0",
      customer_budget: isDirectRequest ? "N/A" : requestKeyValue.customer_price,
      sale_team_id: requestKeyValue.salesman,
      truck_id_array: requestKeyValue.truck_id_array,
      lead_source: requestKeyValue.lead_source,
      final_comment: requestKeyValue.comments,
      company_notes: requestKeyValue.company_notes,
      s_date: moment(requestDetails.date).format("MM-DD-YYYY"),
      s_time: requestDetails.time.move_duration,
      movers:
        requestKeyValue.movers === 0
          ? getMoversForCalculations().toString()
          : requestKeyValue.movers.toString(),
      items_array: list.map((item) => {
        return {
          _id: `${item.id ? item.id : "0"}`,
          category_name: item.category_name,
          name: item.name,
          type: item.type,
          size: `${item.size}`,
          unit_price: `${item.unit_price ? item.unit_price : "0"}`,
          currency: "USA",
          quantity: `${item.value}`,
          additional_info: item.comments,
          items_image_array: item.pics,
          added_by_type: `${item.added_by_type}`,
        };
      }),
    };

    if (isDirectRequest) {
      postData.customer_phone_number = requestKeyValue.customer_phone_number;
      postData.phone_number = requestKeyValue.customer_phone_number;
      postData.first_name = requestKeyValue.customer_first_name;
      postData.last_name = requestKeyValue.customer_last_name;
      postData.email = requestKeyValue.customer_email;
      postData.start_window_from = requestDetails.start_window_from;
      postData.start_window_to = requestDetails.start_window_to;

      // remove request type form the object
      delete postData.request_type;

      if (requestKeyValue.rate === "Hourly") {
        postData = {
          ...postData,
          movers: `${requestKeyValue.movers}`,
          cash_payment_rate: `${requestKeyValue.cash_payment_rate}`,
          card_payment_rate: `${requestKeyValue.card_payment_rate}`,
          minimum_hours: `${requestKeyValue.minimum_hours}`,
          rate_method: `${requestKeyValue.rate}`,
        };
      } else {
        postData = {
          ...postData,
          movers: `${requestKeyValue.movers}`,
          cash_min_flat_rate: `${requestKeyValue.cash_flat_min}`,
          cash_max_flat_rate: `${requestKeyValue.cash_flat_max}`,
          card_min_flat_rate: `${requestKeyValue.card_flat_min}`,
          card_max_flat_rate: `${requestKeyValue.card_flat_max}`,
          rate_method: `${requestKeyValue.rate}`,
        };
      }
    }

    console.log(postData, "postData");

    requestObj["postData"] = postData;

    if (!localStorage.getItem("token")) {
      if (isDirectRequest && companyProfile.user_type === 4) {
        // skip handling for the salesman link
      } else {
        setReqPostData(postData);
        document.getElementById("login-popup-toggle") &&
          document.getElementById("login-popup-toggle").click();
        return;
      }
    }
    invokeApi(requestObj).then((res) => {
      console.log(res, "res");
      if (res.code === 200) {
        AcceptRequestToo(
          res.data.Request_ID.id,
          res.data.Request_ID.customer_id
        );
      } else {
        setIsLoadingForReq(false);
        setErrMessage(res.message);
      }
    });
  };

  const AcceptRequestToo = (id, customer_id) => {
    let requestObj = {
      path: "/api/company/accepted_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData;
    if (requestKeyValue.rate === "Hourly") {
      postData = {
        request_id: `${id}`,
        customer_id: `${customer_id}`,
        movers: `${requestKeyValue.movers}`,
        cash_payment_rate: `${requestKeyValue.cash_payment_rate}`,
        card_payment_rate: `${requestKeyValue.card_payment_rate}`,
        minimum_hours: `${requestKeyValue.minimum_hours}`,
        rate_method: `${requestKeyValue.rate}`,
        customer_phone_number: `${requestKeyValue.customer_phone_number}`,
      };
    } else {
      postData = {
        request_id: `${id}`,
        customer_id: `${customer_id}`,
        movers: `${requestKeyValue.movers}`,
        cash_min_flat_rate: `${requestKeyValue.cash_flat_min}`,
        cash_max_flat_rate: `${requestKeyValue.cash_flat_max}`,
        card_min_flat_rate: `${requestKeyValue.card_flat_min}`,
        card_max_flat_rate: `${requestKeyValue.card_flat_max}`,
        rate_method: `${requestKeyValue.rate}`,
        customer_phone_number: `${requestKeyValue.customer_phone_number}`,
      };
    }

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "Api Response");
      if (res.code === 200) {
        props.setCreatedRequest(res.data);
        props.setScreen(8);
        setIsLoadingForReq(false);
        localStorage.removeItem("createRequest");
      } else {
        setIsLoadingForReq(false);
        setErrMessage(res.message);
      }
    });
  };

  const handlePush = (target) => {
    document.getElementById("login-popup-close") &&
      document.getElementById("login-popup-close").click();
    if (target === "login") {
      setConfirmScreen("login");
      // props.history.push({
      //   pathname: "/login",
      //   state: reqPostData,
      // });
    } else {
      setConfirmScreen("signup");
    }
  };

  const handlePrevScreen = () => {
    if (localStorage.getItem("token") && !isDirectRequest) {
      /**
       * if user logged in and request is on Market Place
       */
      setScreen(5);
    } else {
      /**
       * if user not logged in and request is on CRM.
       */
      if (isDirectRequest) {
        if (itemsList.type === "by_room") {
          setScreen(3);
        } else if (itemsList.type === "by_common_list") {
          setScreen(4);
        } else {
          setScreen(2);
        }
      } else {
        /**
         * if user not logged in and request is on Market Place.
         */
        setScreen(5);
      }
    }
  };
  const hanldeMoversChange = (e) => {
    setRequestKeyValue({
      ...requestKeyValue,
      movers: parseInt(e.target.value),
    });
  };

  useEffect(() => {
    setRequestKeyValue({
      ...requestKeyValue,
      cash_payment_rate: "",
      card_payment_rate: "",
      minimum_hours: "",
      cash_flat_max: "",
      cash_flat_min: "",
      card_flat_max: "",
      card_flat_min: "",
    });
  }, [requestKeyValue.rate]);
  //==================================================
  const fetchSalesmanList = () => {
    if (!localStorage.getItem("token")) return;
    let requestObj = {
      path: "/api/company/get_sale_team",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "sales team");
      setSalesmanList(res.sale_team);
    });
  };
  //=====================================================

  //==================================================
  const fetchleadSourcelist = () => {
    if (!localStorage.getItem("token")) return;
    let requestObj = {
      path: "/api/company/get_lead_source_text",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      console.log(JSON.parse(res.lead_source_text), "lead source");
      if (res.lead_source_text && JSON.parse(res.lead_source_text).length > 0) {
        setLeadSourcelist(JSON.parse(res.lead_source_text));
      } else {
        setLeadSourcelist([
          "Google Maps",
          "Google Search",
          "Yelp",
          "Postcard",
          "Used your service already",
          "Facebook",
          "Instagram",
          "Recommendation",
          "Saw Your Truck",
          "Bing",
          "Other",
        ]);
      }
    });
  };
  //=====================================================

  //==================================================
  const fetchTruckList = () => {
    if (!localStorage.getItem("token")) return;
    let requestObj = {
      path: "/api/company/get_truck",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      let temp = res.data.allTruck.map((truck) => {
        return {
          value: truck.id,
          label: truck.name,
        };
      });
      setTruckList(temp);
    });
  };
  //=====================================================
  useEffect(() => {
    fetchEstimatePrice();
    fetchSalesmanList();
    fetchTruckList();
    fetchleadSourcelist();
    setRequestKeyValue({
      ...requestKeyValue,
      movers:
        requestKeyValue.movers === 0
          ? getMoversForCalculations()
          : requestKeyValue.movers,
    });
  }, [requestKeyValue.movers]);

  //for image uploading
  if (isLoadingForImage) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress style={{ marginBottom: 20 }} />
          <p>Uploading images . . .</p>
        </div>
      </>
    );
  }
  if (isLoadingForReq) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress style={{ marginBottom: 20 }} />
          <p>Generating Request . . .</p>
        </div>
      </>
    );
  }
  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }

  // if (confirmScreen === "login") {
  //   return (
  //     <Login
  //       isDirectRequest={isDirectRequest}
  //       reqPostData={reqPostData}
  //       setConfirmScreen={setConfirmScreen}
  //     />
  //   );
  // }

  // if (confirmScreen === "signup") {
  //   return (
  //     <Register
  //       isDirectRequest={isDirectRequest}
  //       reqPostData={reqPostData}
  //       setConfirmScreen={setConfirmScreen}
  //     />
  //   );
  // }

  return (
    <>
      <section className="main-content address-page">
        <div className="container">
          <div className="add-items-wrapper mb-m-90 mb-90 mt-3 mt-sm-4">
            <h1 className="text-center text-uppercase pt-3 pt-sm-4 pt-md-5 mb-0">
              Confirm Request Details
            </h1>

            <div className="selected-room mt-3 mt-sm-4">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-md-10 col-xl-8   mb-4 mb-sm-4">
                  {errMessage && (
                    <Alert variant="filled" severity="error">
                      {errMessage}
                    </Alert>
                  )}
                  <h3>Move Details</h3>
                  <div className="row req-date-time-info pt-1 pt-sm-2 no-gutters pb-sm-4">
                    <div className="col-12 col-sm-5 req-date-time-info-box">
                      <div className="row no-gutters small-font-size">
                        <div className="col-12 pl-1 pl-sm-0 pr-1 req-info-text">
                          <img className="pr-2" src={dateIconImage} />
                          {moment(requestDetails.date).format("MMM Do YY")}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-7 req-date-time-info-box small-font-size">
                      <div className="row no-gutters">
                        <div className="col-12 pl-1 pl-sm-0 pr-1 req-info-text">
                          <img src={timeIconImage} className="pr-2" />
                          {/* {requestDetails.time.from +
                            " - " +
                            requestDetails.time.to} */}
                          {(requestDetails.start_window_from ||
                            requestDetails.start_window_to) &&
                            ` ${
                              requestDetails.start_window_from
                                ? time_convert(requestDetails.start_window_from)
                                : "-"
                            } to ${
                              requestDetails.start_window_to
                                ? time_convert(requestDetails.start_window_to)
                                : "-"
                            }`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="req-addresses-wrapper row mt-2 mt-sm-3 ">
                    {addressList.map((address) => (
                      <div
                        key={address.id}
                        className="col-12 col-sm-6 mb-3 d-flex"
                      >
                        <div className="rounded-box p-3 ">
                          <div className="address-pin-zipcode">
                            <span>
                              <img src={address.icon} />
                            </span>
                            <span className="sub-title dark-grey-color text-uppercase"></span>
                            <span className="black-color pl-1">
                              {address.address}
                            </span>
                          </div>
                          <div className="pt-2">
                            <span className="dark-grey-color small-sub-title">
                              Zip:{" "}
                            </span>
                            <span className="black-color small-font-size pl-3">
                              {address.zip_code ? address.zip_code : "N/A"}
                            </span>
                          </div>
                          <div className="pt-2">
                            <span className="dark-grey-color small-sub-title">
                              Unit or Ap. number:
                            </span>
                            <span className="black-color small-font-size pl-3">
                              {address.unitNumber ? address.unitNumber : "N/A"}
                            </span>
                          </div>
                          <div className="pt-2">
                            <span className="dark-grey-color small-sub-title">
                              Storage:{" "}
                            </span>
                            <span className="black-color small-font-size pl-3">
                              {address.isStorage ? "Yes" : "No"}
                            </span>
                          </div>
                          <div className="pt-2">
                            <span className="dark-grey-color small-sub-title">
                              Lift type:{" "}
                            </span>
                            <span className="black-color small-font-size pl-3">
                              {address.liftType === "stairs"
                                ? "Mover(s) need to use stairs"
                                : "Mover(s) can use elevator"}
                            </span>
                          </div>
                          {address.liftType === "stairs" && (
                            <div className="pt-2">
                              <span className="dark-grey-color small-sub-title">
                                Number of flights:{" "}
                              </span>
                              <span className="black-color small-font-size pl-3">
                                {address.flights==0?"Ground Floor":address.flights}
                              </span>
                            </div>
                          )}

                          <div className="pt-2">
                            <span className="dark-grey-color small-sub-title">
                              Parking and Building info:{" "}
                            </span>
                            <span className="black-color small-font-size pl-3">
                              {address.parkingInfo
                                ? address.parkingInfo
                                : "N/A"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr />
                  <div className="final-hours-text border-bottom">
                    <h3>What can effect your final hours?</h3>
                    <p className="dark-grey-color small-font-size mb-0">
                      Long walk from the truck into apt, stairs, elevator,
                      small/misc/kitchen items weren't boxed up prior to movers
                      arrival, weather conditions, drive time due to the traffic
                      conditions between the pick up and drop off locations.
                    </p>
                  </div>
                  <div className="comment-box pt-3">
                    {isDirectRequest ? (
                      <>
                        {/* Company link case */}
                        {/* {companyProfile.user_type === 0 && (
                          <div className="row justify-content-center ">
                            <div className="col-12">
                              <label className="overline dark-grey-color">
                                Comments
                              </label>
                              <textarea
                                className="form-control small-font-size text-area"
                                placeholder="Some comments."
                                value={requestKeyValue.comments}
                                onChange={handleComment}
                              ></textarea>
                            </div>
                        

                            <div className="col-6 pt-3">
                            
                              <label
                                htmlFor="distance"
                                className="overline dark-grey-color"
                              >
                                How Many movers you want for move?
                              </label>
                              <select
                                name=""
                                value={requestKeyValue.movers}
                                onChange={hanldeMoversChange}
                                className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                              >
                                <option value="2">2 Movers</option>
                                <option value="3">3 Movers</option>
                                <option value="4">4 Movers</option>
                              </select>
                            </div>
                            <div className="col-6 pt-3">
                              <div className="form-group">
                                <label
                                  htmlFor="price"
                                  className="overline dark-grey-color text-left"
                                >
                                  Add Your Phone Number
                                </label>
                                <NumberFormat
                                  required
                                  onChange={handleChangeInKeyValues}
                                  value={requestKeyValue.customer_phone_number}
                                  name="customer_phone_number"
                                  className="form-control small-font-size"
                                  id="customer_phone_number"
                                  placeholder="(217) 555-0113"
                                  format="(###) ###-####"
                                  mask="_"
                                />
                              </div>
                            </div>
                          </div>
                        )} */}

                        {/* Salesman link case */}

                        <div className="row justify-content-center ">
                          <div className="col-12 col-sm-6 pt-3">
                            <div className="form-group">
                              <label
                                htmlFor="salesman"
                                className="overline dark-grey-color"
                              >
                                Salesman
                              </label>
                              <select
                                name=""
                                value={requestKeyValue.salesman}
                                onChange={handleChangeInSalesTeam}
                                className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                              >
                                <option value="" hidden>
                                  Select Salesman
                                </option>
                                {salesmanList &&
                                  salesmanList.map((salesman, i) => {
                                    return (
                                      <option
                                        value={salesman.user_id}
                                      >{`${salesman.first_name} ${salesman.last_name}`}</option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 pt-3">
                            {/* <div className="form-group">
                              <label
                                htmlFor="trucks"
                                className="overline dark-grey-color"
                              >
                                Trucks
                              </label>
                              <select
                                name=""
                                value={requestKeyValue.truck_id_array}
                                // onChange={handleChangeInTruckArray}
                                onChange={(e)=>console.log(e.target.value)}
                                className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                              >
                                <option value="" hidden>
                                  Select Truck
                                </option>
                                {truckList &&
                                  truckList.map((truck, i) => {
                                    return (
                                      <option
                                        value={truck.id}
                                      >{truck.name}</option>
                                    );
                                  })}
                              </select>
                            </div> */}
                            <div className="form-group">
                              <label
                                htmlFor="from-time"
                                className="overline dark-grey-color"
                              >
                                Choose Trucks
                              </label>

                              {truckList && (
                                <Select
                                  // defaultValue={}
                                  isMulti
                                  components={animatedComponents}
                                  name="name"
                                  defaultValue={selectedTrucks}
                                  options={truckList}
                                  isLoading={false}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={handleChangeInTruckArray}
                                  isDisabled={false}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-12 pt-3">
                            <div className="form-group">
                              <label
                                htmlFor="lead_source"
                                className="overline dark-grey-color"
                              >
                                Select Lead Source
                              </label>
                              <select
                                name=""
                                value={requestKeyValue.lead_source}
                                onChange={handleChangeInLeadSource}
                                className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                              >
                                <option value="" hidden>
                                  Select Lead Source
                                </option>
                                {leadSourcelist &&
                                  leadSourcelist.map((source, i) => {
                                    return (
                                      <option value={source}>{source}</option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-12">
                            <label className="overline dark-grey-color">
                              Comments
                            </label>
                            <textarea
                              className="form-control small-font-size text-area"
                              placeholder="Some comments."
                              value={requestKeyValue.comments}
                              onChange={handleComment}
                            ></textarea>
                          </div>
                          <div className="col-12">
                            <label className="overline dark-grey-color">
                              Company Notes
                            </label>
                            <textarea
                              className="form-control small-font-size text-area"
                              placeholder="Comapny notes..."
                              value={requestKeyValue.company_notes}
                              onChange={handleCompanyNotes}
                            ></textarea>
                          </div>

                          <div className="col-12 pt-3">
                            <label
                              htmlFor="distance"
                              className="overline dark-grey-color"
                            >
                              How Many movers you want for move?
                            </label>
                            <select
                              name=""
                              value={requestKeyValue.movers}
                              onChange={hanldeMoversChange}
                              className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                            >
                              <option value="2">2 Movers</option>
                              <option value="3">3 Movers</option>
                              <option value="4">4 Movers</option>
                            </select>
                          </div>
                          <div className="col-12 col-sm-6 pt-3">
                            <div className="form-group">
                              <label
                                htmlFor="email"
                                className="overline dark-grey-color"
                              >
                                First Name
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control small-font-size"
                                id="first_name"
                                name="customer_first_name"
                                value={requestKeyValue.customer_first_name}
                                onChange={handleChangeInKeyValues}
                                placeholder="John"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 pt-sm-3">
                            <div className="form-group">
                              <label
                                htmlFor="email"
                                className="overline dark-grey-color"
                              >
                                Last Name
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control small-font-size"
                                id="last_name"
                                name="customer_last_name"
                                value={requestKeyValue.customer_last_name}
                                onChange={handleChangeInKeyValues}
                                placeholder="Doe"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label
                                htmlFor="email"
                                className="overline dark-grey-color"
                              >
                                Email
                              </label>
                              <input
                                required
                                name="customer_email"
                                value={requestKeyValue.customer_email}
                                onChange={handleChangeInKeyValues}
                                type="email"
                                className="form-control small-font-size"
                                id="email"
                                placeholder="example@gmail.com"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label
                                htmlFor="price"
                                className="overline dark-grey-color text-left"
                              >
                                Add Your Phone Number
                              </label>
                              <NumberFormat
                                required
                                onChange={handleChangeInKeyValues}
                                value={requestKeyValue.customer_phone_number}
                                className="form-control small-font-size"
                                name="customer_phone_number"
                                id="customer_phone_number"
                                placeholder="(217) 555-0113"
                                format="(###) ###-####"
                                mask="_"
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <AddPrices
                              setInputs={setRequestKeyValue}
                              inputs={requestKeyValue}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="row justify-content-center ">
                        <div className="col-12">
                          <label className="overline dark-grey-color">
                            Comments
                          </label>
                          <textarea
                            className="form-control small-font-size text-area"
                            placeholder="Some comments."
                            value={requestKeyValue.comments}
                            onChange={handleComment}
                          ></textarea>
                        </div>
                        {/*  */}

                        <div className="col-12 pt-3">
                          {/* value={requestKeyValue.movers}
                          onChange={hanldeMoversChange} */}
                          <label
                            htmlFor="distance"
                            className="overline dark-grey-color"
                          >
                            How Many movers you want for move?
                          </label>
                          <select
                            name=""
                            value={requestKeyValue.movers}
                            onChange={hanldeMoversChange}
                            className="custom-select custom-select-lg custom-select-sm form-control form-control-sm"
                          >
                            <option value="2">2 Movers</option>
                            <option value="3">3 Movers</option>
                            <option value="4">4 Movers</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  {!isDirectRequest && (
                    <div className="move-price-wrapper">
                      <h2 className="dark-grey-color text-center">
                        Estimated price range:
                      </h2>
                      <p className="estimate-price text-center">
                        ${estimatePrice.start} - {estimatePrice.end}
                      </p>
                      <div className="row justify-content-center pt-2 pt-sm-3">
                        <div className="col-6 col-sm-4 col-xl-3">
                          <div className="form-group">
                            <label
                              htmlFor="price"
                              className="overline dark-grey-color text-left"
                            >
                              Add Your Price
                            </label>
                            <NumberFormat
                              style={{
                                border: isCustomerPriceEmpty && "solid 1px red",
                              }}
                              type="text"
                              allowNegative={false}
                              className="form-control small-font-size"
                              id="zip"
                              placeholder="70"
                              value={requestKeyValue.customer_price}
                              onChange={handleCustomerPrice}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="button-wrapper padding-bottom-90">
                    <div className="row justify-content-center pt-3">
                      <div className="col-12 col-sm-8 col-md-6">
                        <div className="form-group button-wrapper">
                          <button
                            onClick={handleSubmitRequest}
                            className="custom-buton custom-buton-large w-100"
                          >
                            Submit a Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="next-prev">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7 col-md-4">
              <div className="float-left">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handlePrevScreen}
                  className="dark-grey-color prev"
                >
                  <img src={greyBackArrowImage} /> Prev
                </div>
              </div>

              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="login-popup-toggle"
        data-toggle="modal"
        data-target="#login-popup"
      ></div>
      <div
        className="modal fade"
        id="login-popup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="logout-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                id="login-popup-close"
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="logout-title">
                  Get moving
                </h5>
                <p className="text-center sub-title pb-4 mb-3 pt-40">
                  Please Login or Signup to request move.
                </p>
                <div className="row justify-content-center text-center">
                  <div className="col-6">
                    <button
                      onClick={() => handlePush("login")}
                      className="custom-btn-small custom-buton float-right"
                    >
                      LOG IN
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={() => handlePush("signup")}
                      className="custom-btn-small custom-buton custom-button-pink float-left"
                    >
                      SIGN UP
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ConfirmRequest);
