import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  masterCardLogoImage,
  visaImage,
  defaultCardImage,
} from "../../../../assets";

function ConfirmMakePayment(props) {
  const { handleConfirm, plan, cards } = props;
  const { title, amount, duration } = plan;

  const [isAgree, setIsAgree] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  return (
    <>
      <div className="row w-100 mt-5 mb-5">
        <div className="col-0 col-sm-2 col-md-2 col-xl-3"></div>
        <div className="col-12 col-sm-8 col-md-8 col-xl-5">
          {errMessage && (
            <Alert variant="filled" severity="error">
              {errMessage + ". "}
              {errMessage ===
                "Cannot charge a customer that has no active card" && (
                <Link to="/dashboard/my-cards">Add Card</Link>
              )}
            </Alert>
          )}

          <div className="form-box-wrapper">
            <>
              <span className="mt-4 black-bold-15" id="change-filter-title">
                Pay for the subscription to confirm it. After payment you will
                be given access to a LA Moving Star CRM Moving software then you can see
                your direct requests and start moving with clients directly
                please confirm your subscription plan.
              </span>

              <div className="text-center light-grey-text"></div>
              <div className="form-box pt-20">
                <div className="row pb-3">
                  <div className="col-6 text-left dark-grey-color">
                    Subscription Amount:
                  </div>
                  <div className="col-6 text-right black-color">
                    ${amount}/{duration}
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-6 text-left dark-grey-color">
                    Subscription Plan:
                  </div>
                  <div className="col-6 text-right black-color">{title}</div>
                </div>

                <div className="row pb-3">
                  <div className="col-6 text-left dark-grey-color">
                    Subscription Duration:
                  </div>
                  <div
                    className="col-6 text-right black-color"
                    style={{ textTransform: "capitalize" }}
                  >
                    {plan.amount === "0" ? "2 Months" : 1 + duration}
                  </div>
                </div>
                <div className="row">
                  {plan.amount !== "0" &&
                    cards.map((card) => (
                      <div className="col-12 ">
                        <div className="card-box">
                          {card.brand === "Visa" ? (
                            // Visa
                            <img width="30" src={visaImage}></img>
                          ) : card.brand === "MasterCard" ? (
                            // MasterCard
                            <img src={masterCardLogoImage}></img>
                          ) : (
                            // OtherCards
                            <img width="30" src={defaultCardImage}></img>
                          )}
                          {/*  */}

                          <span className="pl-2">
                            {" "}
                            XX{card.last4.slice(2, 4)}
                          </span>
                          <span
                            className="font-weight-bold"
                            style={{ float: "right" }}
                          >
                            ${amount}
                          </span>
                        </div>
                      </div>
                    ))}

                  <div className="col-12 col-sm-6 mt-3 mb-3">
                    <label className="dark-grey-color mt-2 custom-checkbox-wrapper">
                      <input
                        required
                        checked={isAgree}
                        onChange={() => setIsAgree(!isAgree)}
                        type="checkbox"
                        name="storage"
                      />
                      <span className="caption dark-grey-color schedule-checkbox">
                        Agree to terms
                      </span>
                      <span className="custom-check-box"></span>
                    </label>
                  </div>
                  <div className="col-12 pt-2 pb-3">
                    <button
                      type="button"
                      onClick={() => {
                        //call function is use checked agree
                        if (isAgree) {
                          handleConfirm();
                        }
                      }}
                      className="red-btn custom-buton custom-buton-large w-100"
                    >
                      Confirm
                    </button>
                  </div>

                  {/* <div className="col-12">123</div> */}
                </div>
              </div>
            </>
          </div>
        </div>
        <div className="col-0 col-sm-2 col-md-2 col-xl-4"></div>
      </div>
    </>
  );
}

export default ConfirmMakePayment;
