import { CircularProgress, Snackbar } from "@material-ui/core";
import { id } from "date-fns/esm/locale";
import React, { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { Link, withRouter } from "react-router-dom";
import {
  subtractImage,
  addImage,
  editWhiteImage,
} from "../../../../../../assets";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import MuiAlert from "@material-ui/lab/Alert";
import Alert from "@material-ui/lab/Alert";
import { getMovers } from "../../../../../../ui_libs/Functions/functions";
import EditRequest from "../Edit-Request/EditRequest";
function NewRequest(props) {
  const { fetchRequestDetails, requestData, setIsLoadingPage } = props;
  const { company_booklet_link } = JSON.parse(localStorage.getItem("user"));
  const { customer_budget, recommend_amount, id, movers, customer_id } =
    requestData.request;
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [errMessage, setErrMessage] = React.useState("");
  const [inputs, setInputs] = useState({
    id: props.match.params.requestId,
    movers: getMovers(requestData),
    rate: "Hourly",
    cash_flat_min: "",
    cash_flat_max: "",
    card_flat_min: "",
    card_flat_max: "",
    cash_payment_rate: "",
    card_payment_rate: "",
    minimum_hours: "",
    customer_phone_number: requestData.request.customer_phone_number
      ? requestData.request.customer_phone_number
      : requestData?.customer_data.phone,
  });
  const handleSubtract = () => {
    if (isLoadingBtn) return;
    if (inputs.movers <= 1) return;
    setInputs({ ...inputs, movers: inputs.movers - 1 });
  };

  const handleChangeInPrice = (e) => {
    setInputs({ ...inputs, [e.target.id]: e.target.value });
  };

  const handleAdd = () => {
    if (isLoadingBtn) return;
    setInputs({ ...inputs, movers: inputs.movers + 1 });
  };
  const AcceptRequest = () => {
    let requestObj = {
      path: "/api/company/accept_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData;
    if (inputs.rate === "Hourly") {
      postData = {
        request_id: `${id}`,
        customer_id: `${customer_id}`,
        movers: `${inputs.movers}`,
        cash_payment_rate: `${inputs.cash_payment_rate}`,
        card_payment_rate: `${inputs.card_payment_rate}`,
        minimum_hours: `${inputs.minimum_hours}`,
        rate_method: `${inputs.rate}`,
        customer_phone_number: `${inputs.customer_phone_number}`,
      };
    } else {
      postData = {
        request_id: `${id}`,
        customer_id: `${customer_id}`,
        movers: `${inputs.movers}`,
        cash_min_flat_rate: `${inputs.cash_flat_min}`,
        cash_max_flat_rate: `${inputs.cash_flat_max}`,
        card_min_flat_rate: `${inputs.card_flat_min}`,
        card_max_flat_rate: `${inputs.card_flat_max}`,
        rate_method: `${inputs.rate}`,
        customer_phone_number: `${inputs.customer_phone_number}`,
      };
    }

    setIsLoadingBtn(true);

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "Api Response");

      if (res.code === 200) {
        fetchRequestDetails();
      } else {
        setErrMessage(res.message);
        setIsLoadingBtn(false);
      }
    });
  };

  const handleCancelRequest = () => {
    let requestObj = {
      path: "/app_api/cancel_request",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${id}`,
    };

    requestObj["postData"] = postData;
    setIsLoadingBtn(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "Api Response");
      if (res.code === 200) {
        props.history.push("/company/direct-requests");
      } else {
        setErrMessage(res.message);
        setIsLoadingBtn(false);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      inputs.customer_phone_number &&
      inputs.customer_phone_number[inputs.customer_phone_number.length - 1] ===
        "_"
    ) {
      setErrMessage("Please enter a valid phone number.");
      return;
    }
    AcceptRequest();
  };

  //for handle edit

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setErrMessage("");
  }, [isEdit]);

  if (isEdit) {
    return (
      <EditRequest
        requestData={requestData}
        fetchRequestDetails={fetchRequestDetails}
        setIsLoadingPage={setIsLoadingPage}
        setIsEdit={setIsEdit}
      />
    );
  }

  return (
    <>
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <h6 className="text-white pt-1">Request #{id}</h6>
            </div>
            <div className="col-2 text-right">
              <img
                className="pl-1 pointer-point"
                src={editWhiteImage}
                onClick={() => setIsEdit(true)}
              ></img>
            </div>
          </div>
        </div>
        {errMessage && (
          <Alert variant="filled" severity="error">
            {errMessage}
          </Alert>
        )}{" "}
        {!company_booklet_link && (
          <Alert variant="filled" severity="warning">
            Please add your booklet before accepting the move request.{" "}
            <Link to="/company" className="pl-2 custom-link">
              Add Booklet
            </Link>
          </Alert>
        )}
        <div className="request-left-box p-3 p-sm-4">
          <div className="row">
            <div className="col-12">
              <div className="request-staus small-font-size">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#C4314B" />
                </svg>
                {" New Direct Move Request"}
              </div>
              <div className="bid-instructions small-sub-title pt-20">
                Please, Accept the request to continue
              </div>
            </div>
          </div>
          <div className="bid-form-wrapper pt-3">
            <form onSubmit={handleSubmit}>
              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color">
                  Requested Movers:
                </div>
                <div className="col-6 text-right black-color">
                  {getMovers(requestData)}
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-12">
                  <label htmlFor="email" className="overline dark-grey-color">
                    Movers
                  </label>
                </div>
                <div className="col-12">
                  <img
                    className="pointer-point"
                    onClick={handleSubtract}
                    src={subtractImage}
                  />
                  <span className="movers-count-box small-font-size black-color text-center">
                    {inputs.movers}
                  </span>

                  <img
                    className="pointer-point"
                    onClick={handleAdd}
                    src={addImage}
                  />
                </div>
              </div>

              <div className="row small-font-size pt-3">
                <div className="col-6 pt-1 pb-2 text-left overline dark-grey-color">
                  Rate Method:
                </div>

                <div className="col-3 pb-2">
                  <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                    Hourly
                    <input
                      type="radio"
                      id="pick-up"
                      className="custom-radio"
                      value="pick-up"
                      checked={inputs.rate === "Hourly" && true}
                      onChange={() => setInputs({ ...inputs, rate: "Hourly" })}
                    />
                    <span className="custom-radio-box"></span>
                  </label>
                </div>
                <div className="col-3 pb-2">
                  <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
                    Flat
                    <input
                      type="radio"
                      id="pick-up"
                      className="custom-radio"
                      value="pick-up"
                      checked={inputs.rate === "Flat" && true}
                      onChange={() => setInputs({ ...inputs, rate: "Flat" })}
                    />
                    <span className="custom-radio-box"></span>
                  </label>
                </div>
              </div>
              {inputs.rate === "Hourly" && (
                <>
                  <div className="row pt-3 small-font-size">
                    <div className="col-6 pt-3 text-left overline dark-grey-color">
                      Cash hour rate:
                    </div>
                    <div className="col-6 text-right black-color">
                      <NumberFormat
                        allowNegative={false}
                        disabled={isLoadingBtn}
                        allowLeadingZeros
                        value={inputs.cash_payment_rate}
                        required
                        onChange={handleChangeInPrice}
                        type="text"
                        className="form-control small-font-size"
                        id="cash_payment_rate"
                        placeholder="100"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="row small-font-size pt-3">
                    <div className="col-6 pt-3 text-left overline dark-grey-color">
                      Card hour rate:
                    </div>
                    <div className="col-6 text-right black-color">
                      <NumberFormat
                        allowNegative={false}
                        disabled={isLoadingBtn}
                        allowLeadingZeros
                        value={inputs.card_payment_rate}
                        required
                        onChange={handleChangeInPrice}
                        type="text"
                        className="form-control small-font-size"
                        id="card_payment_rate"
                        placeholder="120"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="row small-font-size pt-3">
                    <div className="col-6 pt-3 text-left overline dark-grey-color">
                      Minimum hours:
                    </div>
                    <div className="col-6 text-right black-color">
                      <NumberFormat
                        allowNegative={false}
                        disabled={isLoadingBtn}
                        allowLeadingZeros
                        value={inputs.minimum_hours}
                        required
                        onChange={handleChangeInPrice}
                        type="text"
                        className="form-control small-font-size"
                        id="minimum_hours"
                        placeholder="4"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </>
              )}
              {/* Flat Rate */}
              {inputs.rate === "Flat" && (
                <>
                  <div className="row small-font-size">
                    <div className="col-12 pt-3 pb-2 text-left overline dark-grey-color">
                      Cash flat rate:
                    </div>
                    <div className="col-2 pt-3 text-right overline dark-grey-color">
                      Min:
                    </div>
                    <div className="col-4 text-right black-color">
                      <NumberFormat
                        allowNegative={false}
                        disabled={isLoadingBtn}
                        allowLeadingZeros
                        value={inputs.cash_flat_min}
                        required
                        onChange={handleChangeInPrice}
                        type="text"
                        className="form-control small-font-size"
                        id="cash_flat_min"
                        placeholder="100"
                        autoComplete="off"
                      />
                    </div>

                    <div className="col-2 pt-3 text-right overline dark-grey-color">
                      Max:
                    </div>
                    <div className="col-4 text-right black-color">
                      <NumberFormat
                        allowNegative={false}
                        disabled={isLoadingBtn}
                        allowLeadingZeros
                        value={inputs.cash_flat_max}
                        required
                        onChange={handleChangeInPrice}
                        type="text"
                        className="form-control small-font-size"
                        id="cash_flat_max"
                        placeholder="120"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="row pt-3 small-font-size">
                    <div className="col-12 pt-3 pb-2 text-left overline dark-grey-color">
                      Card flat rate:
                    </div>
                    <div className="col-2 pt-3 text-right overline dark-grey-color">
                      Min:
                    </div>
                    <div className="col-4 text-right black-color">
                      <NumberFormat
                        allowNegative={false}
                        disabled={isLoadingBtn}
                        allowLeadingZeros
                        value={inputs.card_flat_min}
                        required
                        onChange={handleChangeInPrice}
                        type="text"
                        className="form-control small-font-size"
                        id="card_flat_min"
                        placeholder="100"
                        autoComplete="off"
                      />
                    </div>

                    <div className="col-2 pt-3 text-right overline dark-grey-color">
                      Max:
                    </div>
                    <div className="col-4 text-right black-color">
                      <NumberFormat
                        allowNegative={false}
                        disabled={isLoadingBtn}
                        allowLeadingZeros
                        value={inputs.card_flat_max}
                        required
                        onChange={handleChangeInPrice}
                        type="text"
                        className="form-control small-font-size"
                        id="card_flat_max"
                        placeholder="120"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row small-font-size pt-3 pb-2">
                <div className="col-6 pt-3 text-left overline dark-grey-color">
                  Customer Phone Number:
                </div>
                <div className="col-6 text-right black-color">
                  <NumberFormat
                    disabled={isLoadingBtn}
                    onChange={handleChangeInPrice}
                    value={inputs.customer_phone_number}
                    className="form-control small-font-size"
                    id="customer_phone_number"
                    placeholder="(217) 555-0113"
                    format="(###) ###-####"
                    mask="_"
                  />
                </div>
              </div>

              {isLoadingBtn ? (
                <div className="text-center p-1" style={{ margin: 2 }}>
                  <CircularProgress />
                </div>
              ) : (
                <div className="row pt-3 pb-3">
                  <div className="col-6 ">
                    <button
                      className="custom-btn-small custom-buton float-right"
                      data-toggle="modal"
                      data-target="#bid-placed"
                      type="submit"
                      disabled={!company_booklet_link}
                    >
                      Accept
                    </button>
                  </div>
                  <div className="col-6 ">
                    <button
                      className="custom-btn-small custom-buton custom-button-pink "
                      data-toggle="modal"
                      data-target="#bid-placed"
                      type="button"
                      onClick={handleCancelRequest}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(NewRequest);
