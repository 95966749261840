import React from "react";

function Confirmation(props) {
  const { bank, amount, WidthdrawPayment } = props;
  return (
    <div className="rounded-box-payment pb-4">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-xl-6 pt-4 pb-4">
          <div
            className={
              window.innerWidth < 760
                ? "row rounded-box-payment p-4"
                : "row rounded-box p-4"
            }
          >
            <div className="col-12 pt-2 pb-2 border-bottom sub-title grey-text">
              Withdraw Confirmation
            </div>
            {/* <div className="col-12 pt-2 sub-title black-color">
              Payment Details:
            </div> */}

            <div className="col-6 pt-4 col-md-6 pt-2 sub-title black-color">
              Withdraw Bank:
            </div>
            <div className="col-6 pt-4 col-md-6 pt-2 sub-title black-color text-right text-md-left">
              {bank.label}
            </div>
            <div className="col-8 col-md-6 pt-2 sub-title black-color">
              Withdraw Amount:
            </div>
            <div className="col-4 col-md-6 pt-2 sub-title black-color text-right text-md-left">
              ${amount}
            </div>
            <div className="col-12 ">
              <div className="col-12 pt-40 pb-40">
                <div className="row justify-content-center">
                  <div className="status-buttons">
                    <span
                      onClick={WidthdrawPayment}
                      className="custom-btn-small custom-button-extra-small req-status-button filter-button req-done active"
                    >
                      Confirm Withdraw
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
