import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { addIconImage, editImage } from "../../../../assets";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import ConnectedAccount from "../Connected-Account/ConnectedAccount";
import ExternalBank from "../External-Bank/ExternalBank";
function ShowBank(props) {
  const {
    banks,
    profile,
    setCurrentScreen,
    showErrorSnak,
    showSuccessSnak,
    FetchBankDetails,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState(false);

  const DeleteExternalBank = () => {
    let requestObj = {
      path: "/api/company/delete_bank_account_detail",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      external_bank_id: selectedAccount.external_id,
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "ADD_ECTERNAL_BANK_RES");
      if (res.code === 200) {
        showSuccessSnak(res.message);
        FetchBankDetails();
      } else {
        //showErrorSnak(res.message);
        setIsLoading(false);
      }
    });
  };

  const MakeAccountDefault = () => {
    let requestObj = {
      path: "/api/company/make_deafult_external_bank_account",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      external_bank_id: selectedAccount.external_id,
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "ADD_ECTERNAL_BANK_RES");
      if (res.code === 200) {
        showSuccessSnak(res.message);
        FetchBankDetails();
      } else {
        //showErrorSnak(res.message);
        setIsLoading(false);
      }
    });
  };

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }
  return (
    <div>
      <ConnectedAccount profile={profile} setCurrentScreen={setCurrentScreen} />

      <div>
        {banks.map((bank, i) => (
          <ExternalBank
            setSelectedAccount={setSelectedAccount}
            bank={bank}
            count={i}
            key={bank.external_id}
          />
        ))}
      </div>
      {/* Add new external account  */}
      <div className="row pt-4 pr-2 justify-content-end">
        <div className="col-6 text-right">
          <a
            onClick={() => setCurrentScreen("ADD_E_A")}
            href=""
            className="small-text-bold red-color add-new-btn"
            data-toggle="modal"
            data-target="#add-truck"
          >
            <img src={addIconImage} /> <span>Add New</span>
          </a>
        </div>
      </div>

      <div
        className="modal fade"
        id="deletBank"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="logout-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {selectedAccount && (
              <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
                <div className="modal-body p-0">
                  <h5 className="text-center" id="logout-title">
                    Confirmation
                  </h5>
                  <p className="text-center sub-title pb-4 mb-3 pt-40">
                    Are your sure you want to delete this external bank?
                  </p>

                  <div className="row justify-content-center text-center">
                    <div className="col-6">
                      <button
                        onClick={DeleteExternalBank}
                        data-dismiss="modal"
                        aria-label="Close"
                        className="custom-btn-small custom-buton float-right"
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="custom-btn-small custom-buton custom-button-pink float-left"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* MAKE ACCOUNT DEFAULT */}

      <div
        className="modal fade"
        id="defaultBank"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="logout-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {selectedAccount && (
              <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
                <div className="modal-body p-0">
                  <h5 className="text-center" id="logout-title">
                    Confirmation
                  </h5>
                  <p className="text-center sub-title pb-4 mb-3 pt-40">
                    Are your sure you want to make this account default?
                  </p>

                  <div className="row justify-content-center text-center">
                    <div className="col-6">
                      <button
                        onClick={MakeAccountDefault}
                        data-dismiss="modal"
                        aria-label="Close"
                        className="custom-btn-small custom-buton float-right"
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="custom-btn-small custom-buton custom-button-pink float-left"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowBank;
