import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import { makeStyles, Badge } from "@material-ui/core";
import { invokeApi, s3baseUrl } from "../../../bl_libs/invokeApi";
import { useEffect } from "react";
import NumberFormat from "react-number-format";
import { validatePasswordStrength } from "../../../ui_libs/Validations/validations";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));
function EditManager(props) {
  const classes = useStyles();

  const { selectedManager, closePopUp, fetchManagersList } = props;

  console.log(selectedManager);

  //for show error
  const [errMessage, setErrMessage] = React.useState("");

  const [managerInputs, setManagerInputs] = useState({
    name: selectedManager.name,
    phone_number: selectedManager.user_model?.phone,
    email: selectedManager.user_model?.email,
    pass: "",
    confirm_pass: "",
    profile_picture: selectedManager.profile_picture,
  });

  //preview image from pic url

  const [preview, setPreview] = useState(selectedManager.profile_picture);
  ////////////////////////////////////////////////////////////////////handle update password section

  // for update password
  const [updatePasswordScreen, setUpdatePasswordScreen] = useState(false);

  const [updatePassword, setUpdatePassword] = useState({
    password: "",
    confirm_password: "",
  });

  const [passStrength, setPassStrength] = React.useState({
    color: "",
    strength: "",
    level: 0,
  });

  const handleUpdatePasswordInputs = (e) => {
    if (e.target.id === "password") {
      setPassStrength(validatePasswordStrength(e.target.value));
    }
    setUpdatePassword({
      ...updatePassword,
      [e.target.id]: e.target.value.trim(),
    });
  };

  const handleUpdatePasswordSubmit = (e) => {
    e.preventDefault();

    //validations step for password
    if (updatePassword.password.length < 8) {
      setErrMessage("Minimum 8 characters required for password");
      return;
    }
    //
    //check password strength
    //
    if (passStrength.level < 3) {
      setErrMessage(
        "A minimum 8 characters password contains a combination of uppercase letter and number. And at least one special character are required."
      );
      return;
    }
    //match password
    if (updatePassword.password !== updatePassword.confirm_password) {
      setErrMessage("Your password and confirmation password does not match");
      return;
    }

    //Api call
    let requestObj = {
      path:
        "/api/company/update_password_manager/" + selectedManager.user_model.id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = updatePassword;
    requestObj["postData"] = postData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setDeleteSuccessMessage("Manager password update successfully");
        setTimeout(() => {
          closePopUp();
        }, 1000);
      } else {
        setErrMessage(res.message);
      }
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////handle delete section

  //for delete tab
  const [deleteScreen, setDeleteScreen] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState("");

  const handleManagerDelete = () => {
    console.log("delete");
    let requestObj = {
      path: "/api/company/delete_manager/" + selectedManager.user_model.id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res);
        setDeleteSuccessMessage("Manager Deleted Successfully");
        fetchManagersList();
        setTimeout(() => {
          closePopUp();
        }, 1000);
      } else {
        setErrMessage(res.message);
      }
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  const handleChangeManagerInputs = (e) => {
    setManagerInputs({
      ...managerInputs,
      [e.target.id]: e.target.value,
    });
  };

  //handle upload files

  const handleUpload = (event) => {
    let requestObj = {
      path: "/app_api/upload_s3_file",
      method: "POST",
      headers: {},
    };

    let formData = new FormData();
    formData.append("upload_file", event.target.files[0]);
    formData.append("upload_type", "profile");
    requestObj["postData"] = formData;
    invokeApi(requestObj).then((res) => {
      console.log(res);
      if (res.code === 200) {
        setManagerInputs({
          ...managerInputs,
          profile_picture: res.file_url,
        });
        setPreview(res.file_url);
      } else {
        setErrMessage(res.message);
      }
    });
  };

  //handle salesman submit
  const alphaNumeric = (txt) => {
    var letterNumber = /^[a-zA-Z0-9 ]+$/;
    return letterNumber.test(txt);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validString = (string) => {
    const re = /^[a-z A-Z]+$/;
    return re.test(string);
  };

  //handle submit for salesman

  const handleManagerSubmit = (e) => {
    e.preventDefault();

    //first name validation

    if (managerInputs.name.length < 2) {
      setErrMessage("Name must have 2 characters.");
      return;
    } else if (managerInputs.name.length > 35) {
      setErrMessage("Name must be less the 35 characters.");
      return;
    } else if (
      !alphaNumeric(managerInputs.name) ||
      !isNaN(managerInputs.name) ||
      !isNaN(managerInputs.name[0]) ||
      !validString(managerInputs.name.trim())
    ) {
      setErrMessage("Please enter a valid first name.");
      return;
    } else {
      setErrMessage("");
    }

    if (managerInputs.phone_number[13] === "_") {
      setErrMessage("Please enter a valid phone number.");
      return;
    } else {
      setErrMessage("");
    }

    //email validation

    if (!validateEmail(managerInputs.email)) {
      setErrMessage("Please enter a valid email address.");
      return;
    } else {
      setErrMessage("");
    }

    //Api call
    let requestObj = {
      path: "/api/company/edit_manager/" + selectedManager.user_id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      name: managerInputs.name.trim(),
      email: managerInputs.email.trim(),
      phone: managerInputs.phone_number.trim(),
      profile_pic: preview,
    };

    requestObj["postData"] = postData;
    console.log(requestObj, "obj");

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res);
        fetchManagersList();
        setDeleteSuccessMessage("Manager updated successfully");
        setTimeout(() => {
          closePopUp();
        }, 1000);
      } else {
        console.log(res);
        setErrMessage(res.message);
      }
    });
  };

  useEffect(() => {
    console.log(props, "effect");
  }, []);

  if (deleteSuccessMessage !== "") {
    return (
      <Alert variant="filled" severity="success">
        {deleteSuccessMessage}
      </Alert>
    );
  }

  if (updatePasswordScreen) {
    return (
      <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5 ">
        <div className="modal-body p-0">
          <h5 className="text-center" id="change-password-title">
            Update password
          </h5>
          {errMessage && (
            <Alert variant="filled" severity="error">
              {errMessage}
            </Alert>
          )}

          <div className="form-box pt-20">
            <form onSubmit={handleUpdatePasswordSubmit}>
              <div className="form-group">
                <label htmlFor="password" className="overline dark-grey-color">
                  New Password
                </label>
                <input
                  required
                  type="password"
                  className="form-control small-font-size"
                  id="password"
                  value={updatePassword.password}
                  onChange={handleUpdatePasswordInputs}
                  placeholder="*****************"
                />
                {passStrength.level > 0 && updatePassword.password && (
                  <div
                    className="progress"
                    style={{ height: 2, marginTop: -3 }}
                  >
                    <div
                      className={
                        "progress-bar " +
                        ((passStrength.level < 2 && "bg-danger") ||
                          // (passStrength.level === 2 && "bg-info") ||
                          (passStrength.level === 3 &&
                            updatePassword.password.length >= 8 &&
                            "bg-success"))
                      }
                      role="progressbar"
                      style={{ width: passStrength.level * 33.333 + "%" }}
                      aria-valuenow={passStrength.level}
                      aria-valuemin="0"
                      aria-valuemax="3"
                    ></div>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label
                  htmlFor="confirm_password"
                  className="overline dark-grey-color"
                >
                  Re-Enter New Password
                </label>
                <input
                  required
                  type="password"
                  className="form-control small-font-size"
                  id="confirm_password"
                  value={updatePassword.confirm_password}
                  onChange={handleUpdatePasswordInputs}
                  placeholder="*****************"
                />
              </div>
              <div className="form-group pt-3">
                <button
                  type="submit"
                  className="red-btn custom-buton custom-buton-large w-100"
                >
                  Update Password
                </button>
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: "#C4314B",
                  cursor: "pointer",
                }}
              >
                <span
                  onClick={() => {
                    setUpdatePasswordScreen(false);
                    setErrMessage("");
                  }}
                >
                  CANCEL
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (deleteScreen) {
    return (
      <>
        {deleteSuccessMessage !== "" ? (
          <Alert variant="filled" severity="success">
            {deleteSuccessMessage}
          </Alert>
        ) : (
          <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
            <div className="modal-body p-0">
              {errMessage && (
                <Alert variant="filled" severity="error">
                  {errMessage}
                </Alert>
              )}
              <h5 className="text-center" id="logout-title">
                Delete Manager
              </h5>

              <p className="text-center sub-title pb-4 mb-3 pt-40">
                Are your sure you want to delete this salesman?
              </p>
              <div className="row justify-content-center text-center">
                <div className="col-6">
                  <button
                    onClick={handleManagerDelete}
                    className="custom-btn-small custom-buton float-right"
                  >
                    Yes
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="custom-btn-small custom-buton custom-button-pink float-left"
                    onClick={() => {
                      setDeleteScreen(false);
                      setErrMessage("");
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {" "}
      <div className="modal-body p-0">
        <h5 className="text-center" id="add-salesman-title">
          Edit Manager
        </h5>
        {errMessage && (
          <Alert variant="filled" severity="error">
            {errMessage}
          </Alert>
        )}
        <div className="form-box pt-20">
          <form onSubmit={handleManagerSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label
                    htmlFor="first_name"
                    className="overline dark-grey-color"
                  >
                    First Name
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control small-font-size"
                    id="name"
                    value={managerInputs.name}
                    onChange={handleChangeManagerInputs}
                    placeholder="Full Name"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label
                    htmlFor="phone_number"
                    className="overline dark-grey-color"
                  >
                    Phone Number
                  </label>
                  <NumberFormat
                    required
                    className="form-control small-font-size"
                    id="phone_number"
                    value={managerInputs.phone_number}
                    onChange={handleChangeManagerInputs}
                    placeholder="(217) 555-0113"
                    format="(###) ###-####"
                    mask="_"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email" className="overline dark-grey-color">
                    Email
                  </label>
                  <input
                    required
                    type="email"
                    className="form-control small-font-size"
                    id="email"
                    value={managerInputs.email}
                    onChange={handleChangeManagerInputs}
                    placeholder="example@gmail.com"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <label
                  htmlFor="re-enter-password"
                  className="overline dark-grey-color"
                >
                  Profile Pic
                </label>
                {!preview ? (
                  <>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="icon-button-file-salesman"
                      onChange={handleUpload}
                      type="file"
                      value=""
                    />
                    <label htmlFor="icon-button-file-salesman">
                      <svg
                        style={{ cursor: "pointer", marginLeft: 5 }}
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="rounded-circle"
                      >
                        <rect width="50" height="50" rx="6" fill="#F2F2F2" />
                        <path
                          d="M16 17V14H18V17H21V19H18V22H16V19H13V17H16ZM19 23V20H22V17H29L30.83 19H34C35.1 19 36 19.9 36 21V33C36 34.1 35.1 35 34 35H18C16.9 35 16 34.1 16 33V23H19ZM26 32C28.76 32 31 29.76 31 27C31 24.24 28.76 22 26 22C23.24 22 21 24.24 21 27C21 29.76 23.24 32 26 32ZM22.8 27C22.8 28.77 24.23 30.2 26 30.2C27.77 30.2 29.2 28.77 29.2 27C29.2 25.23 27.77 23.8 26 23.8C24.23 23.8 22.8 25.23 22.8 27Z"
                          fill="#C4C4C4"
                        />
                      </svg>
                    </label>
                  </>
                ) : (
                  <>
                    <Badge
                      color="secondary"
                      overlap="circle"
                      badgeContent={
                        <span
                          style={{
                            fontSize: 16,
                            marginBottom: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPreview("");
                            setManagerInputs({
                              ...managerInputs,
                              profile_picture: "",
                            });
                          }}
                        >
                          x
                        </span>
                      }
                    >
                      <img
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        src={s3baseUrl + preview}
                        style={{
                          margin: 5,
                          borderRadius: "50%",
                        }}
                      ></img>
                    </Badge>
                  </>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6">
                <div className="form-group pt-3">
                  <button
                    type="submit"
                    className="red-btn custom-buton custom-buton-large w-100"
                  >
                    Update Manager
                  </button>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#C4314B",
                    cursor: "pointer",
                  }}
                >
                  <p onClick={() => setUpdatePasswordScreen(true)}>
                    CHANGE PASSWORD
                  </p>
                  <span onClick={() => setDeleteScreen(true)}>DELETE</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditManager;
