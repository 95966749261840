import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
function SubscriptionCard(props) {
  const { plan, handleSelectPlan, userSubscription } = props;
  const { title, amount, duration, detail, features } = plan;

  const [htmlCode, setHtmlCode] = useState("");

  const replaceSpecialCodes = () => {
    let have_yes = true;
    let have_no = true;
    let code = detail;
    while (have_yes) {
      const index = code.search("@YES@");
      if (index === -1) {
        have_yes = false;
      } else {
        code = code.replace(
          "@YES@",
          `<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>`
        );
      }
    }

    while (have_no) {
      const index = code.search("@NO@");
      if (index === -1) {
        have_no = false;
      } else {
        code = code.replace(
          "@NO@",
          `<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg>`
        );
      }
    }
    setHtmlCode(code);
  };

  useEffect(() => {
    replaceSpecialCodes();
  }, []);
  return (
    <>
      <div className="plan">
        <div className="titleContainer">
          <div className="title">{title}</div>
        </div>
        <div className="infoContainer">
          <div className="price">
            <p>${amount}</p>
            <span>/{duration}</span>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: htmlCode }}
            className="features text-left"
          ></div>
          <a
            className="selectPlan"
            onClick={() => {
              handleSelectPlan(plan);
            }}
          >
            Select Plan
          </a>
        </div>
      </div>
    </>
  );
}

export default SubscriptionCard;
