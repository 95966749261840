import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import { blackBackArrowImage } from "../../../../assets";
import { invokeApi } from "../../../../bl_libs/invokeApi";
import { useSnakbar } from "../../../../Context/Context-Snakbar/ContextSnackbar";

function ChangeEmail(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const { profile, GetCompanyProfile, setMainScreen } = props;
  const handleSubmitEmail = (e) => {
    e.preventDefault();
    console.log(updatedEmail, "updatedEmail");

    if (updatedEmail === profile.email) {
      showErrorSnak("You entered same email address.");
      return;
    }

    if (!validateEmail(updatedEmail)) {
      showErrorSnak("Please enter a valid email address.");
      return;
    }
    SendCodeOnEmail();
  };
  const handleSubmitCode = (e) => {
    e.preventDefault();
    varifyEmail();
  };

  const [updatedEmail, setUpdatedEmail] = useState("");

  const [isLoading, setIsLoading] = React.useState(false);

  const [code, setCode] = useState("");

  const [screen, setScreen] = React.useState("ENTER_EMAIL");

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const UpdateCompanyProfile = () => {
    let requestObj = {
      path: "/api/company/update_compony_profile",
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      name: profile.name.trim(),
      address: profile.address.trim(),
      state: profile.state,
      about_company: profile.about_company.trim(),
      thumbnail: profile.thumbnail,
      email_on_request: profile.email_on_request,
      phone: profile.phone,
      about_company: profile.about_company.trim(),
      ca_number: profile.ca_number,
      dot_number: profile.dot_number,
      mtr_number: profile.mtr_number,
      pli_file_path: profile.pli_file_path,
      pdi_file_path: profile.pdi_file_path,
      cpi_file_path: profile.cpi_file_path,
      gli_file_path: profile.gli_file_path,
      wc_file_path: profile.wc_file_path,
      email: updatedEmail, //here updated Email
      profile_pic: profile.profile_picture,
      invoice: profile.invoice,
      confirmation_letter: profile.confirmation_letter,
    };
    setIsLoading(true);
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        GetCompanyProfile();
        setMainScreen(""); // show main screen
        showSuccessSnak("Email updated successfully.");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const SendCodeOnEmail = () => {
    console.log("SendCodeOnEmail");
    let requestObj = {
      path: "/app_api/send_verification_code_on_email",
      method: "POST",
      headers: {},
    };
    let postData = {
      email: updatedEmail.trim(),
      name: profile.name,
    };
    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setScreen("ENTER_CODE");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const varifyEmail = () => {
    let requestObj = {
      path: "/app_api/verify_code_send_on_email",
      method: "POST",
      headers: {},
    };
    let postData = {
      email: updatedEmail,
      code: code,
    };

    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        UpdateCompanyProfile();
      } else {
        setIsLoading(false);
      }
    });
  };

  if (isLoading) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-foremanDefaultImagefluid">
            <p className="small-text-bold page-title">
              <img
                className="pointer-point pr-1"
                src={blackBackArrowImage}
                onClick={() => setMainScreen("")}
              />
              <span>Edit Email</span>
            </p>
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </section>
      </>
    );
  }

  if (screen === "ENTER_CODE") {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">
              <img
                className="pointer-point pr-1"
                src={blackBackArrowImage}
                onClick={() => setMainScreen("")}
              />
              <span>Edit Email</span>
            </p>
            <div className="calendar-wrapper">
              <div className="row justify-content-center pt-40">
                <div className="col-10 col-md-6 col-xl-5">
                  {/*  */}
                  <div className="form-box-wrapper">
                    <div className="form-box">
                      <h5 className="text-center">Check your Email</h5>

                      <div className="evom-form login-form">
                        <form onSubmit={handleSubmitCode}>
                          <div className="row justify-content-center">
                            <div></div>
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  htmlFor="email"
                                  className="overline dark-grey-color"
                                >
                                  Enter code
                                </label>
                                <NumberFormat
                                  required
                                  onChange={(e) => {
                                    setCode(e.target.value);
                                  }}
                                  className="form-control small-font-size"
                                  id="phone"
                                  value={code}
                                  placeholder="Enter your 6 digit confirmation code"
                                  format="######"
                                  mask="-"
                                  allowLeadingZeros
                                  autoComplete="off"
                                />
                              </div>
                            </div>

                            <div className="col-12 col-sm-12">
                              <div className="form-group button-wrapper">
                                <button className="custom-buton custom-buton-large w-100">
                                  Verify
                                </button>
                              </div>

                              <div className="no-account-text-wrapper-custom text-center mt-4 pt-4">
                                <p className="light-grey-color">
                                  I didn't receive the email?{" "}
                                  <span
                                    onClick={SendCodeOnEmail}
                                    className="custom-link pointer-point"
                                  >
                                    Resend
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  if (screen === "ENTER_EMAIL") {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title">
              <img
                className="pointer-point pr-1"
                src={blackBackArrowImage}
                onClick={() => setMainScreen("")}
              />
              <span>Edit Email</span>
            </p>
            <div className="calendar-wrapper">
              <div className="row justify-content-center pt-40">
                <div className="col-10 col-md-6 col-xl-5">
                  <div className="form-box-wrapper">
                    <div className="form-box">
                      <h5 className="text-center">Enter New Email</h5>
                      <div className="evom-form login-form">
                        <form onSubmit={handleSubmitEmail}>
                          <div className="form-group">
                            <label
                              htmlFor="company_name"
                              className="overline dark-grey-color"
                            >
                              Email
                            </label>
                            <input
                              required
                              value={updatedEmail}
                              onChange={(e) => setUpdatedEmail(e.target.value)}
                              type="email"
                              className="form-control small-font-size"
                              id="updated-email"
                              placeholder="Enter your email"
                              autoComplete="off"
                            />
                          </div>

                          <div className="form-group button-wrapper">
                            <button
                              type="submit"
                              className="custom-buton custom-buton-large w-100"
                            >
                              Update Email
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return <>Invalid Screen</>;
}

export default withRouter(ChangeEmail);
