import React from "react";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { invokeApi } from "../../bl_libs/invokeApi";
import SetPassword from "../Set-Password/SetPassword";
import EmailSended from "./components/EmailSended";
function ForgetPassword(props) {
  const [errMessage, setErrMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [emailSended, setEmailSended] = React.useState(false);
  const [inputs, setInputs] = React.useState({
    email: "",
  });
  const [token, setToken] = React.useState("");
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    //validations step for email
    if (!validateEmail(inputs.email)) {
      setErrMessage("Please enter a valid email");
      return;
    }

    let requestObj = {
      path: "/app_api/reset_password",
      method: "POST",
      headers: {},
    };
    let postData = {
      email: inputs.email,
      user_type: "0",
    };
    requestObj["postData"] = postData;

    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        console.log(res);
        setToken(res.data.reset_token);
        setEmailSended(true);
        setIsLoading(false);
      } else {
        setErrMessage(res.message);
        if (res.message === "Invalid Email") {
          setErrMessage(
            "We couldn't find your account with this email address"
          );
        }
        setIsLoading(false);
      }
    });
  };

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }
  if (emailSended) {
    return <EmailSended />;
    // <SetPassword email={inputs.email} token={token} />;
  } else
    return (
      <section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-0 col-md-3 col-xl-4"></div>
            <div className="col-12 col-md-6 col-xl-4">
              {errMessage && (
                <Alert variant="filled" severity="error">
                  {errMessage}
                </Alert>
              )}

              <div className="form-box-wrapper">
                <div className="form-box">
                  <h5 className="text-center">Forgot password</h5>
                  <div className="evom-form login-form">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className="overline dark-grey-color"
                        >
                          Email
                        </label>
                        <input
                          value={inputs.email}
                          required
                          onChange={handleChange}
                          type="email"
                          className="form-control small-font-size"
                          id="email"
                          placeholder="example@gmail.com"
                        />
                      </div>
                      <div className="form-group button-wrapper">
                        <button
                          type="submit"
                          className="custom-buton custom-buton-large w-100"
                        >
                          Send Email
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-0 col-md-3 col-xl-4"></div>
          </div>
        </div>
      </section>
    );
}

export default ForgetPassword;
