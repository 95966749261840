import React from "react";
import {
  invokeApi,
  s3baseUrl,
  TinyMceEditorKey,
} from "../../bl_libs/invokeApi";
import { Editor } from "@tinymce/tinymce-react";

function CustomEditor(props) {
  const { data, editorRef } = props;

  function example_image_upload_handler(blobInfo, success, failure, progress) {
    let requestObj = {
      path: "/app_api/upload_s3_file",
      method: "POST",
      headers: {},
    };

    let _formData = new FormData();
    _formData.append("upload_file", blobInfo.blob());
    requestObj["postData"] = _formData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "IMAGE UPLOADER");
      if (res.code === 200) {
        success(s3baseUrl + res.file_url);
      } else {
        failure(res.message);
      }
    });
  }
  return (
    <div>
      <Editor
        apiKey={TinyMceEditorKey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={data}
        init={{
          images_upload_handler: example_image_upload_handler,
          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </div>
  );
}

export default CustomEditor;
