import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { addressARedIcon, addressBRedIcon } from "../../../../../assets";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class GoogleMapPointing extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   lat: this.props.addressA.lat,
    //   lng: this.props.addressA.lng,
    // };
    console.log(props, "props");
  }
  static defaultProps = {
    center: {
      lat: 30.6681998,
      lng: 73.111356,
    },
    zoom: 12,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          //   bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
          defaultCenter={{ lat: 30.6681998, lng: 73.111356 }}
          defaultZoom={7}
        >
          {/* <AnyReactComponent
            lat={this.props.addressA.lat}
            lng={this.props.addressA.lng}
            text={<img src={addressARedIcon} />}
          />
          <AnyReactComponent
            lat={this.props.addressB.lat}
            lng={this.props.addressB.lng}
            text={<img src={addressBRedIcon} />}
          /> */}
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapPointing;
