//address type
export const pick_up = "1";
export const drop_off = "2";

//Customer Requests tab constants

export const active = "0";
export const completed = "1";
export const cancelled = "2";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SORT_BY_OPTIONS = [
  { label: "Request ID", value: "id" },
  { label: "Movers", value: "movers" },
  { label: "Schedule Time", value: "schedule_time" },
  { label: "Move Type", value: "move_type" },
  { label: "Net Distance", value: "net_distance" },
  { label: "Request Timeline Status", value: "request_timeline_status" },
];

export const TIMELINE_STATUS_OPTIONS = [
  { label: "All Requests", value: "" },
  { label: "New Request", value: "0" },
  // { label: "Bid submitted", value: "1" },
  // { label: "Bid accepted", value: "2" },
  { label: "Awaiting Customer Response", value: "3" },
  { label: "Direct Request Accepted", value: "3.1" },
  { label: "Truck assigned and ready to move", value: "4" },
  { label: "Move in progress", value: "5" },
  { label: "Completed, waiting for confirmation", value: "6" },
  { label: "Completed", value: "7" },
  { label: "Feedback Given", value: "8" },
];

export const CALENDER_STATUSES = [
  {
    label: "New Request Status",
    value: "new_request_status",
  },
  {
    label: "Awaiting Company Response Status",
    value: "awaiting_company_response_status",
  },
  {
    label: "Direct Request Accepted Status",
    value: "direct_request_accepted_status",
  },
  {
    label: "Truck Assigned And Ready To Move Status",
    value: "truck_assigned_and_ready_to_move_status",
  },
  {
    label: "Move In Progress Status",
    value: "move_in_progress_status",
  },
  {
    label: "Completed Waiting For Confirmation Status",
    value: "completed_waiting_for_confirmation_status",
  },
  {
    label: "Completed Status",
    value: "completed_status",
  },
  {
    label: "Feedback Given Status",
    value: "feedback_given_status",
  },
];
export const CALENDER_COLORS = [
  {
    label: "Default",
    value: "",
    color: "#039be5",
  },
  {
    label: "Lavender",
    value: "1",
    color: "#7986cb",
  },
  {
    label: "Sage",
    value: "2",
    color: "#33b679",
  },

  {
    label: "Grape",
    value: "3",
    color: "#8e24aa",
  },
  {
    label: "Flamingo",
    value: "4",
    color: "#e67c73",
  },
  {
    label: "Banana",
    value: "5",
    color: "#f6c026",
  },
  {
    label: "Tangerine",
    value: "6",
    color: "#f5511d",
  },
  {
    label: "Peacock",
    value: "7",
    color: "#039be5",
  },
  {
    label: "Graphite",
    value: "8",
    color: "#616161",
  },
  {
    label: "Blueberry",
    value: "9",
    color: "#3f51b5",
  },
  {
    label: "Basil",
    value: "10",
    color: "#0b8043",
  },
  {
    label: "Tomato",
    value: "11",
    color: "#d60000",
  },
];
