import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { blackBackArrowImage } from "../../assets";
import { invokeApi } from "../../bl_libs/invokeApi";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
function AddItem(props) {
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const [isLoading, setIsLoading] = React.useState(true);

 

  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
    };
    let requestObj = {
      path: "/api/company/edit_email_template/" + props.match.params.id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData,
    };
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        showSuccessSnak("Item added successfully.");
        props.history.goBack();
      } else {
        showErrorSnak(res.message);
        setIsLoading(false);
      }
    });
  };


  // if (isLoading) {
  //   return (
  //     <>
  //       <section id="content-wrapper" className="content-wrapper calendar-page">
  //         <div className="container-fluid">
  //           <p className="small-text-bold page-title">
  //             <img
  //               className="pointer-point pr-1"
  //               src={blackBackArrowImage}
  //               onClick={() => props.history.goBack()}
  //             />
  //             <span>Add Extra Item</span>
  //           </p>
  //           <div className="Circular-Progress">
  //             <CircularProgress />
  //           </div>
  //         </div>
  //       </section>
  //     </>
  //   );
  // }
  return (
    <section id="content-wrapper" className="content-wrapper calendar-page">
      <div className="container-fluid">
        <p className="small-text-bold page-title">
          <img
            className="pointer-point pr-1"
            src={blackBackArrowImage}
            onClick={() => props.history.goBack()}
          />
          <span>Add Extra Item</span>
        </p>
        <div className="calendar-wrapper">
          <div className="row justify-content-center pt-40">
            <div className="col-10 col-lg-8">
              <form onSubmit={handleSubmit}>
                <div className="form-group ">
                  <label htmlFor="name" className="overline dark-grey-color">
                    Name
                  </label>
                  <input
                    required
                    // onChange={handleChange}
                    // value={emailData.title}
                    name="title"
                    className="form-control small-font-size"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label
                    for="exampleFormControlTextarea1"
                    className="overline dark-grey-color"
                  >
                    Description
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label for="price" className="overline dark-grey-color">
                    Unit Price
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="price"
                    // placeholder="name@example.com"
                  />
                </div>
                <div class="form-group">
                  <label for="status" className="overline dark-grey-color">Status</label>
                  <select class="form-control" id="status">
                    <option value={true}>Active</option>
                    <option value={false}>In-Active</option>
                  </select>
                </div>

                {/* <div className="form-group ">
                  <label htmlFor="email" className="overline dark-grey-color">
                    Email Template
                  </label>
                  <CustomEditor
                    data={
                      emailData.email_message ? emailData.email_message : ""
                    }
                    editorRef={editorRef}
                  />
                </div> */}
                <div className="form-group button-wrapper col-12">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                      <button
                        type="submit"
                        className="custom-buton custom-buton-large w-100"
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddItem;
