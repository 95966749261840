import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  startRatingImage,
  photoUserImage,
  whiteArrowImage,
  calendarImage,
  requestsImage,
  chatImage,
  paymentImage,
  aboutImage,
  logoutImage,
  companyProfilePicImage,
  mapIconImage,
  emailIconImaage,
  phoneIconImage,
  editBillingIconImage,
  editIconImage,
  truckImg1Image,
  truckImg2Image,
  addIconImage,
} from "../../assets";
import { Header } from "./components";
import { Link, withRouter } from "react-router-dom";
import { DirectLinkUrl, invokeApi, s3baseUrl } from "../../bl_libs/invokeApi";
import { useSockets } from "../../Context/Context-Sockets/ContextSockets";
import { Avatar, Badge } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import InfoIcon from "@material-ui/icons/Info";
import { Rating } from "@material-ui/lab";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { CopyToClipboard, SharePopup } from "../../components";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ShareIcon from "@material-ui/icons/Share";
import AssessmentIcon from "@material-ui/icons/Assessment";
const Main = (props) => {
  const { userList, socketOFF, handleRefreshChatList } = useSockets();
  const {
    name,
    profile_picture,
    company_rating,
    company_direct_access_link,
    is_ever_subscription_status,
  } = JSON.parse(localStorage.getItem("user"));

  //company information from localsotorage

  const { children } = props;
  const [sideNavActive, setSideNavActive] = useState(false);
  const [subscribtion, setSubscribtion] = useState("");
  const [active, setActive] = useState(0);

  const handleBadgeDot = () => {
    let message = userList.find((user) => user.unread_messages > 0);

    return message ? false : true;
  };

  let chatDot = handleBadgeDot();

  const logout = () => {
    let requestObj = {
      path: "/app_api/log_out",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      socketOFF();
      const welcome = localStorage.getItem("welcome");
      localStorage.clear();
      localStorage.setItem("welcome", welcome);
      props.history.push("/login");
    });
  };

  const handleClickOnSidebar = (value) => {
    if (window.innerWidth < 769) {
    }
    setSideNavActive(false);
    setActive(value);
  };

  const GetCompanyProfile = () => {
    let requestObj = {
      path: "/api/company/profile_company",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({ ...res.profile, company_rating: res.company_rating })
        );
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  const CheckForSubscription = () => {
    let requestObj = {
      path: "/api/company/get_subscription",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setSubscribtion(res.subscribtion_data);
      } else {
      }
    });
  };

  // when open side bar check for subscription for update state
  useEffect(() => {
    CheckForSubscription();
  }, [sideNavActive]);

  useEffect(() => {
    GetCompanyProfile();
  }, []);

  useEffect(() => {
    const currentPath = props.history.location.pathname;
    console.log(currentPath);
    if (currentPath.search("/calender") > 0) {
      setActive(1);
    } else if (currentPath.search("/company-requests") > 0) {
      setActive(2);
    } else if (currentPath.search("/chats") > 0) {
      setActive(3);
    } else if (currentPath.search("/payment-history") > 0) {
      setActive(4);
    } else if (currentPath.search("/about-us") > 0) {
      setActive(5);
    } else if (currentPath.search("/bank-account") > 0) {
      setActive(6);
    } else if (currentPath.search("/direct-requests") > 0) {
      setActive(7);
    } else if (currentPath.search("/stats") > 0) {
      setActive(8);
    } else {
      setActive("");
    }
  }, [props.history.location.pathname]);

  return (
    <>
      <Header
        sideNavActive={sideNavActive}
        setSideNavActive={setSideNavActive}
        setActive={setActive}
        subscription={subscribtion}
        CheckForSubscription={CheckForSubscription}
      />
      <div className="wrapper">
        {/* <!-- Sidebar  --> */}
        <nav
          id="sidebar"
          style={
            window.innerWidth < 769
              ? {
                  position: "fixed",
                  zIndex: 1000,
                  height: "100vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                  paddingTop: window.innerWidth === 768 ? 55 : 0,
                  marginTop: 0,
                }
              : {}
          }
          className={sideNavActive ? "" : "active"}
        >
          <div className="sidebar-top">
            <div className="sidebar-profile float-left">
              <div className="">
                {profile_picture ? (
                  <img
                    className="circle-box rounded-circle side-bar-image"
                    src={
                      profile_picture
                        ? s3baseUrl + profile_picture
                        : companyProfilePicImage
                    }
                  />
                ) : (
                  <Avatar
                    style={{
                      width: 65,
                      height: 65,
                      // backgroundColor: "#c4314b",
                      fontSize: 26,
                    }}
                  >
                    {/* {name[0].toUpperCase()} */}
                  </Avatar>
                )}
              </div>
              <h6 className="text-white pt-2">{name}</h6>

              <div className="caption text-white ">
                <span>
                  Rating: {company_rating === null ? 0 : company_rating}
                </span>
                {company_rating && (
                  <Rating
                    className=" pl-2 pt-2 align-bottom"
                    value={company_rating}
                    precision={0.1}
                    readOnly
                  />
                )}
              </div>
            </div>
            <div className="toggle-menu float-right">
              <button
                type="button"
                id="sidebarCollapse"
                className="btn"
                onClick={() => setSideNavActive(!sideNavActive)}
              >
                <img src={whiteArrowImage} />
              </button>
            </div>
            <div className="clearfix"></div>
            <div className="search-button-wrapper">
              <button
                onClick={() => {
                  props.history.push("/company/search");
                  handleClickOnSidebar("");
                }}
                className="custom-btn-small red-btn"
              >
                Search
              </button>
            </div>
          </div>

          <ul className="list-unstyled components">
            <li className={active === 1 ? "active" : ""}>
              <Link
                to="/company/calender"
                onClick={() => handleClickOnSidebar(1)}
              >
                <img src={calendarImage} /> <span>Calendar</span>
              </Link>
            </li>
            <li className={active === 2 ? "active" : ""}>
              <Link
                to="/company/company-requests"
                onClick={() => handleClickOnSidebar(2)}
              >
                <img src={requestsImage} /> <span>Company Requests</span>
              </Link>
            </li>
            {is_ever_subscription_status && (
              <li className={active === 7 ? "active" : ""}>
                <Link
                  to="/company/direct-requests"
                  onClick={() => handleClickOnSidebar(7)}
                >
                  <TrendingUpIcon style={{ color: "black" }} />{" "}
                  <span>Direct Requests</span>
                </Link>
              </li>
            )}
            <li className={active === 3 ? "active" : ""}>
              <Link
                to="/company/chats"
                onClick={() => {
                  handleRefreshChatList();
                  handleClickOnSidebar(3);
                }}
              >
                <Badge
                  style={{ paddingLeft: 0 }}
                  color="primary"
                  variant="dot"
                  invisible={chatDot}
                >
                  <img src={chatImage} />
                </Badge>{" "}
                <span>Chat</span>
              </Link>
            </li>

            <li className={active === 4 ? "active" : ""}>
              <Link
                to="/company/payment-history"
                onClick={() => handleClickOnSidebar(4)}
              >
                <AttachMoneyIcon style={{ color: "black" }} />
                <span>Payment History</span>
              </Link>
            </li>

            <li className={active === 8 ? "active" : ""}>
              <Link to="/company/stats" onClick={() => handleClickOnSidebar(8)}>
                <AssessmentIcon style={{ color: "black" }} />
                <span>Company Stats</span>
              </Link>
            </li>

            <li className={active === 6 ? "active" : ""}>
              <Link
                to="/company/bank-account"
                onClick={() => handleClickOnSidebar(6)}
              >
                <AccountBalanceIcon style={{ color: "black" }} />
                <span>Bank Account</span>
              </Link>
            </li>
            <li className={active === 5 ? "active" : ""}>
              <Link
                to="/company/about-us"
                onClick={() => handleClickOnSidebar(5)}
              >
                <InfoIcon style={{ color: "black" }} /> <span>About Us</span>
              </Link>
            </li>

            {sideNavActive &&
              subscribtion &&
              (!subscribtion.subscription_status ? (
                <li className="text-center">
                  <button
                    onClick={() => {
                      console.log(subscribtion);
                      setSideNavActive(false);
                      props.history.push("/subscriptions-plan");
                    }}
                    className="custom-btn-small red-btn"
                  >
                    Choose a plan
                  </button>
                </li>
              ) : (
                sideNavActive && (
                  <>
                    <li className="text-center">
                      <button
                        onClick={() => {
                          console.log(subscribtion);
                          setSideNavActive(false);
                          props.history.push("/create-request");
                        }}
                        className="custom-btn-small red-btn"
                        style={{ width: 210 }}
                      >
                        Create New Request
                      </button>
                    </li>
                    <li className="text-center">
                      <CopyToClipboard
                        content={DirectLinkUrl + company_direct_access_link}
                      />
                    </li>
                    <li className="text-center">
                      {sideNavActive ? (
                        <button
                          style={{
                            color: "white",
                            outline: "none",
                            width: 210,
                          }}
                          className="custom-btn-small red-btn ml-1 mr-1 mt-2"
                          data-toggle="modal"
                          data-target="#share-link-email"
                        >
                          Share Via Email <ShareIcon className="ml-1" />
                        </button>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="text-center">
                      {sideNavActive ? (
                        <button
                          style={{
                            color: "white",
                            outline: "none",
                            width: 210,
                          }}
                          className="custom-btn-small red-btn ml-1 mr-1 mt-2"
                          data-toggle="modal"
                          data-target="#share-link-phone"
                        >
                          Share Via Phone <ShareIcon className="ml-1" />
                        </button>
                      ) : (
                        ""
                      )}
                    </li>
                  </>
                )
              ))}
          </ul>
        </nav>
        {/* <!-- Page Content  --> */}
        {children}
      </div>
      {/* share popups */}
      <SharePopup />
      <div
        className="modal fade"
        id="logout"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="logout-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="logout-title">
                  Log out
                </h5>
                <p className="text-center sub-title pb-4 mb-3 pt-40">
                  Are your sure you want to logout?
                </p>
                <div className="row justify-content-center text-center">
                  <div className="col-6">
                    <button
                      onClick={logout}
                      data-dismiss="modal"
                      aria-label="Close"
                      className="custom-btn-small custom-buton float-right"
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="custom-btn-small custom-buton custom-button-pink float-left"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default withRouter(Main);
