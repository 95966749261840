import React from "react";
import LocationDetails from "./components/Location-Details/LocationDetails";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AddressSelector from "./components/Addresses/AddressSelector";
import moment from "moment";
import {
  greyBackArrowImage,
  redNextArrowImage,
  addressARedIcon,
  addressBRedIcon,
  addressCRedIcon,
  addressDRedIcon,
  addressERedIcon,
  addressFRedIcon,
} from "../../assets";
import AddItemsOption from "./components/Add-Item_Options/AddItemsOption";
import { useEffect } from "react";
import AddItemsByRoom from "./components/ByRoomList/AddItemsByRoom";
import AddItemsByCommonList from "./components/CommonList/AddItemsByCommonList";
// import AddCard from "./components/Add-Card/AddCard";
import ConfirmRequest from "./components/Confirm-Request/ConfirmRequest";
import Congratulations from "./components/Congratulations-Screen/Congratulations";
import ExtraItemsScreen from "./components/Extra-Items/ExtraItemsScreen";
import { invokeApi, s3baseUrl } from "../../bl_libs/invokeApi";
import { withRouter } from "react-router-dom";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";
import { CircularProgress } from "@material-ui/core";

import ReactGA from "react-ga";
function RequestScreens(props) {
  const { showErrorSnak } = useSnakbar();
  const [isDirectRequest, setIsDirectRequest] = useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [companyProfile, setCompanyProfile] = useState({});
  const [createdRequest, setCreatedRequest] = useState({});
  //times

  const [customFromTime, setCustomFromTime] = useState([]);
  const [customToTime, setCustomToTime] = useState([]);

  const getCustomFromTime = () => {
    let current = moment().format("HH:mm");
    let arrTime = current.split(":");
    let stringHour = Number(arrTime[0]) + 1;

    let strringMinute = Number(arrTime[1]);
    if (strringMinute >= 30) {
      stringHour++;
    }
    let minuteGreater30 = true;
    if (strringMinute <= 30) {
      minuteGreater30 = false;
    }
    let newtimeList = [];
    for (let i = stringHour; i <= 23; i++) {
      if (i <= 9) {
        newtimeList.push(`0${i}:00`);

        if (i == stringHour) {
          if (!minuteGreater30) {
            console.log("first",newtimeList)

            newtimeList.splice(0, 1);
          }
        }

        newtimeList.push(`0${i}:30`);
      } else {
        newtimeList.push(`${i}:00`);

        if (i == stringHour) {
          if (!minuteGreater30) {
            console.log("first",newtimeList)
            
            newtimeList.splice(0, 1);

          }
        }

        newtimeList.push(`${i}:30`);
      }
    }
    setCustomFromTime([...newtimeList]);
    setCustomToTime([...newtimeList]);
    console.log(newtimeList, "newTimeList");

    console.log(arrTime.contains, "timeeee");
  };
  const getCustomToTime = (value) => {
    let index = customFromTime.indexOf(value);
    let newToTime = [...customFromTime];
    newToTime.splice(0, index + 1);
    setCustomToTime([...newToTime]);
  };
  React.useEffect(() => {
    getCustomFromTime();
  }, []);

  /////////////////////////////////////////////////////// collect data for requests
  const [addressListRefresh, setAddressListRefresh] = useState("");
  const [salesmanList, setSalesmanList] = useState();
  const [truckList, setTruckList] = useState();
  const [selectedTrucks, setSelectedTrucks] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).selectedTrucks
      : []
  );
  const [leadSourcelist, setLeadSourcelist] = useState();
  const [addressList, setAddressList] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).addressList
      : [
          {
            label: "A",
            icon: addressARedIcon,
            type: "pick-up",
            id: uuidv4(),
            zip_code: "",
            address: "",
            street_number: "",
            route: "",
            city: "",
            country: "",
            formated_address: "",
            lat: "",
            lng: "",
            moveType: "",
            unitNumber: "",
            addressDetails: "",
            isStorage: false,
            liftType: "elevator",
            flights: 1,
            isHardwoodFloor: false,
            parkingInfo: "",
            placeholder: "Pickup Address",
            order: 1,
            isEmpty: false,
            isZipEmpty: false,
          },
          {
            label: "B",
            icon: addressBRedIcon,
            type: "drop-off",
            id: uuidv4(),
            zip_code: "",
            address: "",
            street_number: "",
            route: "",
            city: "",
            country: "",
            formated_address: "",
            lat: "",
            lng: "",
            moveType: "",
            unitNumber: "",
            addressDetails: "",
            isStorage: false,
            liftType: "elevator",
            flights: 1,
            isHardwoodFloor: false,
            parkingInfo: "",
            placeholder: "Drop-off Address",
            order: 1,
            isEmpty: false,
            isZipEmpty: false,
          },
        ]
  );

  const handleInsertAddress = () => {
    //validation only 6 addresses allow
    if (addressList.length >= 6) return;
    let temp = addressList.map((addr) => addr);
    let i = addressList.length;
    temp.push({
      label:
        i === 0
          ? "A"
          : i === 1
          ? "B"
          : i === 2
          ? "C"
          : i === 3
          ? "D"
          : i === 4
          ? "E"
          : "F",
      icon:
        i === 0
          ? addressARedIcon
          : i === 1
          ? addressBRedIcon
          : i === 2
          ? addressCRedIcon
          : i === 3
          ? addressDRedIcon
          : i === 4
          ? addressERedIcon
          : addressFRedIcon,
      type: "pick-up",
      id: uuidv4(),
      zip_code: "",
      address: "",
      street_number: "",
      route: "",
      city: "",
      country: "",
      formated_address: "",
      lat: "",
      lng: "",
      moveType: "",
      unitNumber: "",
      addressDetails: "",
      isStorage: false,
      liftType: "elevator",
      flights: 1,
      isHardwoodFloor: "",
      parkingInfo: "",
      placeholder: "New Address",
      order: 2,
      isEmpty: false,
      isZipEmpty: false,
    });
    setAddressList(temp);
  };

  ////////////////////////////////////////////screen 0
  //get selected date

  const [date, setDate] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).date
      : ""
  );

  const [isDateEmpty, setIsDateEmpty] = useState(false);

  //get time range
  // let startWindow =
  //   (new Date().getHours() + 1).length < 2
  //     ? "0" + new Date().getHours() + 1 + ":00"
  //     : new Date().getHours() + 1 + ":00";
  // let endWindow =
  //   (new Date().getHours() + 2).length < 2
  //     ? "0" + new Date().getHours() + 2 + ":00"
  //     : new Date().getHours() + 2 + ":00";
  const [timeRange, setTimeRange] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).timeRange
      : {
          // from: "",
          // to: "",
          move_duration: "",
          start_window_from: "",
          start_window_to: "",
        }
  );
  useEffect(() => {
    console.log(date, "dateeee");
if(customFromTime.length>0){
  getCustomToTime(customFromTime[0])
    setTimeRange({
      ...timeRange,
      start_window_from: customFromTime[0],
      start_window_to: customToTime[0]=="23:30"?customToTime[0]:customToTime[1],
    });
  }
  }, [customFromTime, date]);

  ///////////////////////////////////////////////////////////screen 0 end

  ///////////////////////////////////////////////////////////screen 1

  const [moveType, setMoveType] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).moveType
      : ""
  );

  const [isMoveTypeSelected, setIsMoveTypeSelected] = useState(true);

  const [moveTypeList, setMoveTypeList] = useState([]);

  const fetchListForMoverType = () => {
    let requestObj = {
      path: "/api/customer/get_move_type",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      // console.log(res, "MOVE SIZE RESPONSCE");
      if (res.code === 200) {
        setMoveTypeList(res.data.moveTypeItems);
      }
    });
  };

  ///////////////////////////////////////////////////////////screen 1 end

  ///////////////////////////////////////////////////////////////screen 2

  const [addItemsOptionType, setAddItemsOptionType] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).addItemsOptionType
      : ""
  );

  ///////////////////////////////////////////////////////////screen 2 end

  ///////////////////////////////////////////////////////////////screen 3

  const [rooms, setRooms] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).rooms
      : []
  );

  const getItemsCountByRoom = () => {
    let count = 0;
    rooms.map((room) => {
      room.itemList.map((item) => {
        count++;
      });
    });
    return count;
  };

  ///////////////////////////////////////////////////////////screen 3 end

  ////////////////////////////////////////////////////////////////screen 4

  const [commonList, setCommonList] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).commonList
      : []
  );

  ///////////////////////////////////////////////////////////screen 4 end

  ////////////////////////////////////////////////////////////////screen 5
  const { company_direct_access_link } = JSON.parse(
    localStorage.getItem("user")
  );
  const [extraItemsList, setExtraItemList] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).extraItemsList
      : []
  );

  const fetchExtraList = () => {
    let requestObj = {
      path: "/api/customer/get_item",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      type: "2",
      company_direct_link: company_direct_access_link,
      // category_id: "",
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "Extra items");
      if (res.code === 200) {
        let temp = res.data.items.map((item) => {
          return {
            ...item,
            value: 0,
            item_id: uuidv4(),
            comments: "",
            isSelected: false,
            type: "2",
            category_name: "Extra Items",
          };
        });
        if (localStorage.getItem("createRequest")) {
          let previousSavedItems = JSON.parse(
            localStorage.getItem("createRequest")
          ).extraItemsList;
          // console.log(previousSavedItems,"previousSavedItems");
          // console.log(temp,"previousSavedItems temp");
          temp.map((tempItem) => {
            let isExist = false;
            previousSavedItems.map((previousItem) => {
              if (tempItem.name == previousItem.name) {
                isExist = true;
              }
            });
            if (!isExist) {
              previousSavedItems.push(tempItem);
            }
          });
          previousSavedItems.map((previousItem, index) => {
            let isExist = false;
            temp.map((newItem) => {
              if (previousItem.name == newItem.name) {
                isExist = true;
              }
            });
            if (!isExist) {
              previousSavedItems.splice(index, 1);
            }
          });
          setExtraItemList([...previousSavedItems]);
        } else {
          setExtraItemList(temp);
        }
      }
    });
  };

  ///////////////////////////////////////////////////////////screen 5 end

  ////////////////////////////////////////////////////////////////screen 6

  const [cardInputs, setCardInputs] = useState({
    card_number: "",
    card_holder_name: "",
    exp_date: "",
    cvv: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
    isVarified: false,
  });

  const [cards, setCards] = useState([]);
  const [isLoadingCard, setIsLoadingCard] = React.useState(false);

  const fetchCardsList = () => {
    if (!localStorage.getItem("token")) return;
    let requestObj = {
      path: "/api/customer/checking_card_exist",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {};
    requestObj["postData"] = postData;
    setIsLoadingCard(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "CARD RESPONSE");
      if (res.code === 200) {
        //store cards in state
        if (res.retrive_stripe_card) {
          setCards(res.retrive_stripe_card.data);
        }
      } else {
        // remain this empty
      }
      setIsLoadingCard(false);
    });
  };
  ////////////////////////////////////////////////////////////////screen 6 end

  ////////////////////////////////////////////////////////////////screen 7

  const fetchSalesmanList = () => {
    if (!localStorage.getItem("token")) return;
    let requestObj = {
      path: "/api/company/get_sale_team",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setIsLoadingCard(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "sales team");
      setIsLoadingCard(false);
    });
  };

  const [requestKeyValue, setRequestKeyValue] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).requestKeyValue
      : {
          salesman: "",
          truck_id_array: [],
          lead_source: "",
          comments: "",
          company_notes: "",
          estimated_price: "",
          customer_price: "",
          movers: 0,
          customer_phone_number: "",
          customer_email: "",
          customer_first_name: "",
          customer_last_name: "",
          rate: "Hourly",
        }
  );

  let requestDetails = {
    date: date,
    time: timeRange,
    isCardVarified: cardInputs.isVarified,
    moveType: moveType,
    start_window_from: timeRange.start_window_from,
    start_window_to: timeRange.start_window_to,
  };

  ////////////////////////////////////////////////////////////////screen 7 end

  /////////////////////////////////////////////////////// validation functions
  //
  //validation for screen 0
  //
  const isAddressValid = () => {
    //handling for address A
    let temp = addressList.map((addr, i) => {
      if (i === 0 && addr.address === "") {
        return {
          ...addr,
          isEmpty: true,
        };
      } else {
        return { ...addr, isEmpty: false };
      }
    });
    setAddressList(temp);

    if (addressList[0].address === "") {
      addressList[0].isEmpty = true;
      return false;
    }
    //handling for address B
    let temp2 = addressList.map((addr, i) => {
      if (i === 1 && addr.address === "") {
        return {
          ...addr,
          isEmpty: true,
        };
      } else {
        return { ...addr, isEmpty: false };
      }
    });
    setAddressList(temp2);

    if (addressList[1].address === "") {
      addressList[1].isEmpty = true;
      return false;
    }
    return true;
  };

  const isValidLocations = () => {
    let flag = true;
    if (moveType === "") {
      flag = false;
      setIsMoveTypeSelected(false);
    } else {
      setIsMoveTypeSelected(true);
    }

    let temp = addressList.map((addr) => {
      if (addr.zip_code === "") {
        flag = false;
        return {
          ...addr,
          isZipEmpty: true,
        };
      } else {
        return { ...addr, isZipEmpty: false };
      }
    });
    setAddressList(temp);
    console.log(temp);

    return flag;
  };

  /////////////////////////////////////////////////////////////// Screens

  const [screen, setScreen] = useState(
    localStorage.getItem("createRequest")
      ? JSON.parse(localStorage.getItem("createRequest")).screen
      : 0
  );

  const handleNextScreen = () => {
    if (screen === 0) {
      //if date is empty
      if (
        date === "" ||
        timeRange.start_window_from === "" ||
        timeRange.start_window_to === ""
      ) {
        setIsDateEmpty(true);
        return;
      }

      setIsDateEmpty(false);
      if (isAddressValid()) {
        setScreen(1);
      }
    }
    if (screen === 1) {
      if (isValidLocations()) {
        setScreen(2);
      } else {
        showErrorSnak("Please select move size.");
        window.scrollTo(0, 0);
      }
    }

    // skip items screen and reset add items option
    if (screen === 2) {
      setScreen(7);
      setAddItemsOptionType("");
    }

    if (screen === 3 || screen === 4) {
      // if (isDirectRequest) {
      //   setScreen(7);
      // } else {
      setScreen(5);
      // }
    }
    if (screen === 5) {
      if (localStorage.getItem("token") && !isDirectRequest) {
        setScreen(7);
      } else {
        setScreen(7);
      }
    }
    if (screen === 6) {
      setScreen(7);
    }
  };
  //
  const handlePrevScreen = () => {
    if (screen === 1) {
      setScreen(0);
    }
    if (screen === 2) {
      setScreen(1);
    }
    if (screen === 3) {
      setScreen(2);
    }
    if (screen === 4) {
      setScreen(2);
    }
    if (screen === 5) {
      if (addItemsOptionType === "by_room") {
        setScreen(3);
      } else {
        setScreen(4);
      }
    }
    if (screen > 5) {
      if ((!localStorage.getItem("token") || isDirectRequest) && screen === 7) {
        setScreen(5);
      } else {
        setScreen(screen - 1);
      }
    }
  };

  const checkForValidLink = () => {
    let requestObj = {
      path: "/api/company/validate_generated_link",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      generated_link: props.match.params.direct_link,
    };

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "checkForValidLink");
      if (res.code === 200) {
        if (res.data === true) {
          setIsLoading(false);
          setCompanyProfile(res.profile);
        } else {
          showErrorSnak("Company direct link is invalid.");
        }
      }
    });
  };

  const checkForDirectLink = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // console.log(window.location.pathname, "window.location.pathname");
    // if (window.location.pathname === "/create-request") {
    //   setIsDirectRequest(false);
    //   setTimeout(() => {
    //     setIsLoading(false);
    //   }, 1000);
    // } else {
    //   // alert(props.match.params.direct_link);
    //   setIsDirectRequest(true);
    //   checkForValidLink();
    // }
  };

  const getStepName = (screen) => {
    if (screen === 0) {
      return "map-screen";
    }

    if (screen === 1) {
      return "location-details";
    }
    if (screen === 2) {
      return "add-items-option";
    }
    if (screen === 3) {
      return "by-room";
    }
    if (screen === 4) {
      return "by-common-list";
    }
    if (screen === 5) {
      return "extra-items";
    }
    if (screen === 6) {
      return "add-card";
    }
    if (screen === 7) {
      return "confirm-request";
    }
    if (screen === 8) {
      localStorage.removeItem("createRequest");
      return "congratulations";
    }
  };

  // For scroll to top
  // When new screen renders
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      ReactGA.pageview(window.location.pathname + "/" + getStepName(screen));
    }, 200);
  }, [screen]);

  useEffect(() => {
    // When user select other option insted of selected option
    // We will clear previous option items list Automaticlly
    setCommonList(
      localStorage.getItem("createRequest")
        ? JSON.parse(localStorage.getItem("createRequest")).commonList
        : []
    );
    setRooms(
      localStorage.getItem("createRequest")
        ? JSON.parse(localStorage.getItem("createRequest")).rooms
        : []
    );
  }, [addItemsOptionType]);

  useEffect(() => {
    /**
     * Show company logo in header if user come from link
     */

    const img = document.getElementById("direct-link-logo");
    const span = document.getElementById("direct-link-title");
    console.log(companyProfile, "companyProfile");
    // logo handling
    if (companyProfile.thumbnail) {
      img.src = s3baseUrl + companyProfile?.thumbnail;
      img.style = "";
    } else if (companyProfile.profile_picture) {
      img.src = s3baseUrl + companyProfile?.profile_picture;
      img.style = "";
    }
    // Name handling
    if (companyProfile.name) {
      span.innerText = companyProfile?.name;
    }
  }, [companyProfile]);
  useEffect(() => {
    if (screen !== 8) {
      localStorage.setItem(
        "createRequest",
        JSON.stringify({
          screen,
          addressList,
          requestKeyValue,
          date,
          timeRange,
          moveType,
          addItemsOptionType,
          rooms,
          commonList,
          extraItemsList,
          selectedTrucks,
        })
      );
    }
    console.log(extraItemsList, "extra itemsss");
  }, [
    screen,
    addressList,
    timeRange,
    date,
    requestKeyValue,
    moveType,
    addItemsOptionType,
    rooms,
    commonList,
    extraItemsList,
    selectedTrucks,
  ]);

  useEffect(() => {
    fetchExtraList();
    fetchListForMoverType();
    fetchCardsList();
    checkForDirectLink();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }

  return (
    <div
      style={
        window.innerWidth === 768 || window.innerWidth === 767
          ? { paddingTop: 60 }
          : {}
      }
    >
      {screen === 0 && (
        <AddressSelector
          //all addressList
          isDirectRequest={isDirectRequest}
          addressList={addressList}
          setAddressList={setAddressList}
          addressListRefresh={addressListRefresh}
          setAddressListRefresh={setAddressListRefresh}
          handleInsertAddress={handleInsertAddress}
          date={date}
          setDate={setDate}
          isDateEmpty={isDateEmpty}
          setIsDateEmpty={setIsDateEmpty}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          customFromTime={customFromTime}
          setCustomFromTime={setCustomFromTime}
          customToTime={customToTime}
          setCustomToTime={setCustomToTime}
          getCustomFromTime={getCustomFromTime}
          getCustomToTime={getCustomToTime}
        />
      )}
      {screen === 1 && (
        <LocationDetails
          //all addressList
          addressList={addressList}
          setAddressList={setAddressList}
          //move type list from api
          moveTypeList={moveTypeList}
          isMoveTypeSelected={isMoveTypeSelected}
          //choose move type
          moveType={moveType}
          setMoveType={setMoveType}
          handleNextScreen={handleNextScreen}
        />
      )}
      {screen === 2 && (
        <AddItemsOption
          setScreen={setScreen}
          setAddItemsOptionType={setAddItemsOptionType}
          addItemsOptionType={addItemsOptionType}
        />
      )}

      {screen === 3 && (
        <AddItemsByRoom
          getItemsCountByRoom={getItemsCountByRoom}
          rooms={rooms}
          setRooms={setRooms}
        />
      )}

      {screen === 4 && (
        <AddItemsByCommonList
          commonList={commonList}
          setCommonList={setCommonList}
        />
      )}

      {screen === 5 && (
        <ExtraItemsScreen
          extraItemsList={extraItemsList}
          setExtraItemList={setExtraItemList}
        />
      )}

      {/* {screen === 6 && (
        <AddCard
          cardInputs={cardInputs}
          setCardInputs={setCardInputs}
          cards={cards}
          fetchCardsList={fetchCardsList}
          isLoadingCard={isLoadingCard}
          setIsLoadingCard={setIsLoadingCard}
        />
      )} */}

      {screen === 7 && (
        <ConfirmRequest
          addressList={addressList}
          setScreen={setScreen}
          companyProfile={companyProfile}
          isDirectRequest={isDirectRequest}
          requestDetails={requestDetails}
          requestKeyValue={requestKeyValue}
          salesmanList={salesmanList}
          setSalesmanList={setSalesmanList}
          truckList={truckList}
          setTruckList={setTruckList}
          selectedTrucks={selectedTrucks}
          setSelectedTrucks={setSelectedTrucks}
          setRequestKeyValue={setRequestKeyValue}
          leadSourcelist={leadSourcelist}
          setLeadSourcelist={setLeadSourcelist}
          itemsList={{
            type: addItemsOptionType,
            byRoom: rooms,
            byCommonList: commonList,
            extraItemsList: extraItemsList,
          }}
          setCreatedRequest={setCreatedRequest}
        />
      )}

      {screen === 8 && <Congratulations />}

      {/* if screen is not 3,4,6 render this because for screen 3,4,6 we render in different condition below  */}

      {screen !== 6 &&
        screen !== 8 &&
        screen !== 7 &&
        screen !== 2 &&
        screen !== 3 &&
        screen !== 4 && (
          <div className="next-prev">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-7 col-md-4">
                <div className="float-left">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handlePrevScreen}
                    className="dark-grey-color prev"
                  >
                    {screen === 0 ? (
                      <div
                        onClick={() => props.history.push("/company/calender")}
                      >
                        <img src={greyBackArrowImage} /> Back
                      </div>
                    ) : (
                      <>
                        <img src={greyBackArrowImage} /> Prev
                      </>
                    )}
                  </div>
                </div>
                <div className="float-right">
                  {screen !== 2 && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={handleNextScreen}
                      className="red-color"
                    >
                      Next
                      <img src={redNextArrowImage} />
                    </div>
                  )}
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        )}

      {/* //specific rendering bottom bar for screen 2*/}
      {screen === 2 && (
        <div className="next-prev">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7 col-md-4">
              <div className="float-left">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handlePrevScreen}
                  className="dark-grey-color prev"
                >
                  <img src={greyBackArrowImage} /> Prev
                </div>
              </div>
              {isDirectRequest && (
                <div className="float-right">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handleNextScreen}
                    className="red-color"
                  >
                    Skip
                    <img src={redNextArrowImage} />
                  </div>
                </div>
              )}
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}

      {/* //specific rendering bottom bar for screen 6 */}
      {screen === 6 && (
        <div className="next-prev">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7 col-md-4">
              <div className="float-left">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handlePrevScreen}
                  className="dark-grey-color prev"
                >
                  <img src={greyBackArrowImage} /> Prev
                </div>
              </div>
              <div className="float-right">
                {cards.length > 0 && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handleNextScreen}
                    className="red-color"
                  >
                    Next
                    <img src={redNextArrowImage} />
                  </div>
                )}
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}

      {screen === 4 && (
        <div className="next-prev">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7 col-md-4">
              <div className="float-left">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handlePrevScreen}
                  className="dark-grey-color prev"
                >
                  <img src={greyBackArrowImage} /> Prev
                </div>
              </div>
              {commonList.length > 0 ? (
                <div className="float-right">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handleNextScreen}
                    className="red-color"
                  >
                    Next
                    <img src={redNextArrowImage} />
                  </div>
                </div>
              ) : (
                <div className="float-right">
                  {isDirectRequest && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={handleNextScreen}
                      className="red-color"
                    >
                      Skip
                      <img src={redNextArrowImage} />
                    </div>
                  )}
                </div>
              )}
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}

      {screen === 3 && (
        <div className="next-prev">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7 col-md-4">
              <div className="float-left">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handlePrevScreen}
                  className="dark-grey-color prev"
                >
                  <img src={greyBackArrowImage} /> Prev
                </div>
              </div>
              {getItemsCountByRoom() > 0 ? (
                <div className="float-right">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handleNextScreen}
                    className="red-color"
                  >
                    Next
                    <img src={redNextArrowImage} />
                  </div>
                </div>
              ) : (
                <div className="float-right">
                  {isDirectRequest && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={handleNextScreen}
                      className="red-color"
                    >
                      Skip
                      <img src={redNextArrowImage} />
                    </div>
                  )}
                </div>
              )}
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}
      {/* {((screen === 4 && commonList.length === 0) ||
        (screen === 3 && getItemsCountByRoom() === 0)) && (
        <div className="next-prev">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7 col-md-4">
              <div className="float-left">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handlePrevScreen}
                  className="dark-grey-color prev"
                >
                  <img src={greyBackArrowImage} /> Prev
                </div>
              </div>

              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default withRouter(RequestScreens);
