import { Alert } from "@material-ui/lab";
import React from "react";
import Cards from "react-credit-cards";
import { checkCircleImage, deleteBinImage } from "../../../../assets";
function ShowCard(props) {
  const { cards, errMessage, handleDeleteCard } = props;

  const handleDelete = () => {
    handleDeleteCard();
  };
  return (
    <>
      <section className="main-content address-page">
        <div className="container">
          <div className="add-items-wrapper mb-m-90 mb-90 mt-3 mt-sm-4">
            <div className="selected-room mt-4 mt-sm-4">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 mb-4 mb-sm-4">
                  {errMessage && (
                    <Alert variant="filled" severity="error">
                      {errMessage}
                    </Alert>
                  )}
                  <div className="rounded-box  mt-5">
                    <h1 className="text-center custom-card-heading text-uppercase p-3 pt-sm-4 mb-0">
                      Your Card
                    </h1>
                    <div className="row justify-content-center pt-4">
                      <div className="col-12 col-sm-8 col-md-6 text-center">
                        <div className="form-group button-wrapper">
                          <span className="red-color font-weight-bold pr-2">
                            Verified
                          </span>
                          <span>
                            <img src={checkCircleImage} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <form>
                      <div className="row">
                        {cards.map((card) => (
                          <div
                            key={card.id}
                            className="col-12 col-sm-12 col-md-12"
                          >
                            <Cards
                              name={card.name}
                              number={"**** **** **** " + card.last4}
                              expiry={`${
                                card.exp_month < 10
                                  ? "0" + card.exp_month
                                  : card.exp_month
                              }/${card.exp_year}`}
                              cvc=""
                              preview={true}
                              issuer={card.brand}
                            />
                          </div>
                        ))}
                      </div>

                      <div className="row justify-content-center pt-4 pb-2">
                        <div className="col-12 col-sm-8 col-md-6 text-center">
                          <div className="form-group button-wrapper">
                            <span
                              className="red-color font-weight-bold pr-2 pointer-point"
                              //   onClick={handleDelete}
                              data-target="#delete-card"
                              data-toggle="modal"
                            >
                              Delete
                            </span>
                            <span>{/* <img src={deleteBinImage} /> */}</span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="delete-card"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="logout-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="logout-title">
                  Delete Card
                </h5>
                <p className="text-center sub-title pb-4 mb-3 pt-40">
                  Are your sure you want to delete this card?
                </p>
                <div className="row justify-content-center text-center">
                  <div className="col-6">
                    <button
                      onClick={handleDelete}
                      data-dismiss="modal"
                      aria-label="Close"
                      className="custom-btn-small custom-buton float-right"
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="custom-btn-small custom-buton custom-button-pink float-left"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowCard;
