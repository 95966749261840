import React from "react";

function DeteleRoom(props) {
  return (
    <div className="form-wrapper pl-4 pr-4 pl-sm-4 pr-sm-4">
      <div className="modal-body p-0">
        <h6 id="delete-item-title">
          Are you sure, you want to delete this room?
        </h6>
      </div>
      <div className="row pt-30 justify-content-center">
        <div className="col-6 col-sm-3">
          <button
            className="custom-buton custom-btn-small w-100"
            onClick={() => {
              props.handleDeleteRoom(props.room.room_id);
              props.closePopUp();
            }}
          >
            YES
          </button>
        </div>
        <div className="col-6 col-sm-3">
          <button
            onClick={() => props.closePopUp()}
            className="custom-buton custom-btn-small custom-button-pink w-100"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeteleRoom;
