import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/logo.png";

export default function Headers() {
  return (
    <div className="main-bg">
      <header>
        <div className="container-fluid">
          <div className="logo logo-center">
            <Link to="/">
              <img src={logo} height={"48 px"}  alt="LA Moving Star" title="LA Moving Star" />
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
}
