import React, { useEffect, useState } from "react";
import { checkCircleImage } from "../../../../assets";
import NumberFormat from "react-number-format";
import { Alert } from "@material-ui/lab";
import { invokeApi, PK_KEY } from "../../../../bl_libs/invokeApi";
import { set } from "date-fns/esm";
import { CircularProgress } from "@material-ui/core";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useSnakbar } from "../../../../Context/Context-Snakbar/ContextSnackbar";
import SubscriptionPreview from "../Subscription-Preview/SubscriptionPreview";
function AddCard(props) {
  const { HitSubscription, HitFreeTrail, plan } = props;

  const { showErrorSnak, showSuccessSnak } = useSnakbar();

  const [cardInputs, setCardInputs] = useState({
    card_number: "",
    card_holder_name: "",
    exp_date: "",
    cvv: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
    isVarified: false,
  });

  const [cards, setCards] = useState([]);
  const [isLoadingCard, setIsLoadingCard] = React.useState(false);

  const fetchCardsList = () => {
    setIsLoadingCard(true);
    if (!localStorage.getItem("token")) return;
    let requestObj = {
      path: "/api/customer/checking_card_exist",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {};
    requestObj["postData"] = postData;
    setIsLoadingCard(true);
    invokeApi(requestObj).then((res) => {
      console.log(res, "CARD RESPONSE");
      if (res.code === 200) {
        //store cards in state
        if (res.retrive_stripe_card) {
          setCards(res.retrive_stripe_card.data);
        }
      } else {
        // remain this empty
      }
      setIsLoadingCard(false);
    });
  };

  console.log(cards, "CARDS FROM PROPS");

  const [errMessage, setErrMessage] = useState("");

  const handleChange = (e) => {
    if (cardInputs.isVarified) return;
    setCardInputs({ ...cardInputs, [e.target.id]: e.target.value });
    console.log(cardInputs, "CARD INPUTS");
  };

  const addCard = (token) => {
    let requestObj = {
      path: "/api/customer/add_card",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      token: token,
    };
    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        //do success action
        setCardInputs({ ...cardInputs, isVarified: true });
        fetchCardsList();
        setErrMessage("");
      } else {
        setErrMessage(res.message);
        setIsLoadingCard(false);
      }
    });
  };

  const createToken = () => {
    const params = {
      number: cardInputs.card_number,
      cvc: cardInputs.cvv.trim(),
      exp_month: cardInputs.exp_date.slice(0, 2),
      exp_year: cardInputs.exp_date.slice(3, 5),
      name: cardInputs.card_holder_name,
      address_line1: cardInputs.address_1,
      address_line2: cardInputs.address_2,
      address_city: cardInputs.city,
      address_state: cardInputs.state,
      address_zip: cardInputs.zip,
    };
    // console.log(params, "PARAMS");
    setIsLoadingCard(true);
    window.Stripe.createToken(params, function (status, response) {
      console.log(response);
      if (status === 200) {
        addCard(response.id);
      } else {
        showErrorSnak(response.error.message);
        setIsLoadingCard(false);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setCardInputs({ ...cardInputs, isVarified: true });
    createToken();
  };

  const handleConfirm = () => {
    plan.amount === "0" ? HitFreeTrail() : HitSubscription(plan.id);
  };

  useEffect(() => {
    fetchCardsList();
    if (window.Stripe.setPublishableKey) {
      window.Stripe.setPublishableKey(PK_KEY);
    }
  }, []);

  if (isLoadingCard) {
    return (
      <>
        <div className="Circular-Progress">
          <CircularProgress />
        </div>
      </>
    );
  }

  if (cards.length > 0 || plan.amount === "0") {
    return (
      <div className="pt-5">
        <SubscriptionPreview
          plan={plan}
          handleConfirm={handleConfirm}
          cards={cards}
        />
      </div>
    );
  }
  return (
    <>
      <section className="main-content address-page pb-5">
        <div className="container">
          <div className="add-items-wrapper mb-m-90 mb-90 mt-3 mt-sm-4">
            <h1 className="text-center text-uppercase pt-3 pt-sm-4 pt-md-5 mb-0">
              Add Card
            </h1>

            <div className="selected-room mt-4 mt-sm-4">
              <div className="row justify-content-center ">
                <div className="col-12 col-sm-8 mb-4 mb-sm-4">
                  {errMessage && (
                    <Alert variant="filled" severity="error">
                      {errMessage}
                    </Alert>
                  )}
                  <div className="rounded-box p-3">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="card_number"
                              className="overline dark-grey-color"
                            >
                              card number
                            </label>
                            <NumberFormat
                              disabled={cardInputs.isVarified}
                              required
                              onChange={handleChange}
                              className="form-control small-font-size"
                              id="card_number"
                              placeholder="1245 1245 1245 1245"
                              value={cardInputs.card_number}
                              format="#### #### #### ####"
                              allowLeadingZeros
                              mask=""
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="card_holder_name"
                              className="overline dark-grey-color"
                            >
                              card holder name
                            </label>
                            <input
                              disabled={cardInputs.isVarified}
                              required
                              onChange={handleChange}
                              type="text"
                              className="form-control small-font-size"
                              id="card_holder_name"
                              placeholder="John Doe"
                              value={cardInputs.card_holder_name}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label
                              htmlFor="exp_date"
                              className="overline dark-grey-color"
                            >
                              Exp date
                            </label>
                            <NumberFormat
                              disabled={cardInputs.isVarified}
                              required
                              onChange={handleChange}
                              format="##/##"
                              mask="-"
                              className="form-control small-font-size"
                              id="exp_date"
                              placeholder="MM/YY"
                              value={cardInputs.exp_date}
                              allowLeadingZeros
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label
                              htmlFor="cvv"
                              className="overline dark-grey-color"
                            >
                              Cvv
                            </label>
                            <NumberFormat
                              disabled={cardInputs.isVarified}
                              required
                              onChange={handleChange}
                              type="text"
                              className="form-control small-font-size"
                              id="cvv"
                              minLength={4}
                              placeholder="123"
                              value={cardInputs.cvv}
                              allowLeadingZeros
                              format="####"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="address_1"
                              className="overline dark-grey-color"
                            >
                              Address 1
                            </label>
                            <input
                              disabled={cardInputs.isVarified}
                              required
                              onChange={handleChange}
                              type="text"
                              className="form-control small-font-size"
                              id="address_1"
                              placeholder="295 N Rodeo Dr"
                              value={cardInputs.address_1}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="address_2"
                              className="overline dark-grey-color"
                            >
                              Address 2
                            </label>
                            <input
                              disabled={cardInputs.isVarified}
                              onChange={handleChange}
                              type="text"
                              className="form-control small-font-size"
                              id="address_2"
                              placeholder="295 N Rodeo Dr"
                              value={cardInputs.address_2}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="city"
                              className="overline dark-grey-color"
                            >
                              City
                            </label>
                            <input
                              disabled={cardInputs.isVarified}
                              required
                              onChange={handleChange}
                              type="text"
                              className="form-control small-font-size"
                              id="city"
                              placeholder="Beverly Hills"
                              value={cardInputs.city}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label
                              htmlFor="state"
                              className="overline dark-grey-color"
                            >
                              State
                            </label>
                            <input
                              disabled={cardInputs.isVarified}
                              required
                              onChange={handleChange}
                              type="text"
                              className="form-control small-font-size"
                              id="state"
                              placeholder="CA"
                              value={cardInputs.state}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label
                              htmlFor="zip"
                              className="overline dark-grey-color"
                            >
                              Zip
                            </label>
                            <input
                              disabled={cardInputs.isVarified}
                              required
                              onChange={handleChange}
                              type="text"
                              className="form-control small-font-size"
                              id="zip"
                              placeholder="90210"
                              value={cardInputs.zip}
                            />
                          </div>
                        </div>
                      </div>

                      {cardInputs.isVarified ? (
                        <div className="row justify-content-center pt-4">
                          <div className="col-12 col-sm-8 col-md-6 text-center">
                            <div className="form-group button-wrapper">
                              <span className="red-color font-weight-bold pr-2">
                                Verified
                              </span>
                              <span>
                                <img src={checkCircleImage} />
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row justify-content-center pt-4">
                          <div className="col-12 col-sm-8 col-md-6">
                            <div className="form-group button-wrapper">
                              <button
                                className="custom-buton custom-buton-large w-100"
                                type="submit"
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddCard;

// export default SubscriptionPayment;
