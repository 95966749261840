import React, { useState } from "react";
import NumberFormat from "react-number-format";

function AddPrices({ inputs, setInputs }) {
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const handleChangeInPrice = (e) => {
    setInputs({ ...inputs, [e.target.id]: e.target.value });
  };
  return (
    <>
      <div className="row small-font-size pt-3">
        <div className="col-6 pt-1 pb-2 text-left overline dark-grey-color">
          Rate Method:
        </div>

        <div className="col-3 pb-2">
          <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
            Hourly
            <input
              type="radio"
              id="pick-up"
              className="custom-radio"
              value="pick-up"
              checked={inputs.rate === "Hourly" && true}
              onChange={() => setInputs({ ...inputs, rate: "Hourly" })}
            />
            <span className="custom-radio-box"></span>
          </label>
        </div>
        <div className="col-3 pb-2">
          <label className="dark-grey-color mb-0 custom-radio-box-wrapper">
            Flat
            <input
              type="radio"
              id="pick-up"
              className="custom-radio"
              value="pick-up"
              checked={inputs.rate === "Flat" && true}
              onChange={() => setInputs({ ...inputs, rate: "Flat" })}
            />
            <span className="custom-radio-box"></span>
          </label>
        </div>
      </div>
      {inputs.rate === "Hourly" && (
        <>
          <div className="row pt-3 small-font-size">
            <div className="col-6 pt-3 text-left overline dark-grey-color">
              Cash hour rate:
            </div>
            <div className="col-6 text-right black-color">
              <NumberFormat
                allowNegative={false}
                disabled={isLoadingBtn}
                allowLeadingZeros
                value={inputs.cash_payment_rate}
                required
                onChange={handleChangeInPrice}
                type="text"
                className="form-control small-font-size"
                id="cash_payment_rate"
                placeholder="100"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="row small-font-size pt-3">
            <div className="col-6 pt-3 text-left overline dark-grey-color">
              Card hour rate:
            </div>
            <div className="col-6 text-right black-color">
              <NumberFormat
                allowNegative={false}
                disabled={isLoadingBtn}
                allowLeadingZeros
                value={inputs.card_payment_rate}
                required
                onChange={handleChangeInPrice}
                type="text"
                className="form-control small-font-size"
                id="card_payment_rate"
                placeholder="120"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="row small-font-size pt-3">
            <div className="col-6 pt-3 text-left overline dark-grey-color">
              Minimum hours:
            </div>
            <div className="col-6 text-right black-color">
              <NumberFormat
                allowNegative={false}
                disabled={isLoadingBtn}
                allowLeadingZeros
                value={inputs.minimum_hours}
                required
                onChange={handleChangeInPrice}
                type="text"
                className="form-control small-font-size"
                id="minimum_hours"
                placeholder="4"
                autoComplete="off"
              />
            </div>
          </div>
        </>
      )}
      {/* Flat Rate */}
      {inputs.rate === "Flat" && (
        <>
          <div className="row small-font-size">
            <div className="col-12 pt-3 pb-2 text-left overline dark-grey-color">
              Cash flat rate:
            </div>
            <div className="col-2 pt-3 text-right overline dark-grey-color">
              Min:
            </div>
            <div className="col-4 text-right black-color">
              <NumberFormat
                allowNegative={false}
                disabled={isLoadingBtn}
                allowLeadingZeros
                value={inputs.cash_flat_min}
                required
                onChange={handleChangeInPrice}
                type="text"
                className="form-control small-font-size"
                id="cash_flat_min"
                placeholder="100"
                autoComplete="off"
              />
            </div>

            <div className="col-2 pt-3 text-right overline dark-grey-color">
              Max:
            </div>
            <div className="col-4 text-right black-color">
              <NumberFormat
                allowNegative={false}
                disabled={isLoadingBtn}
                allowLeadingZeros
                value={inputs.cash_flat_max}
                required
                onChange={handleChangeInPrice}
                type="text"
                className="form-control small-font-size"
                id="cash_flat_max"
                placeholder="120"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row pt-3 small-font-size">
            <div className="col-12 pt-3 pb-2 text-left overline dark-grey-color">
              Card flat rate:
            </div>
            <div className="col-2 pt-3 text-right overline dark-grey-color">
              Min:
            </div>
            <div className="col-4 text-right black-color">
              <NumberFormat
                allowNegative={false}
                disabled={isLoadingBtn}
                allowLeadingZeros
                value={inputs.card_flat_min}
                required
                onChange={handleChangeInPrice}
                type="text"
                className="form-control small-font-size"
                id="card_flat_min"
                placeholder="100"
                autoComplete="off"
              />
            </div>

            <div className="col-2 pt-3 text-right overline dark-grey-color">
              Max:
            </div>
            <div className="col-4 text-right black-color">
              <NumberFormat
                allowNegative={false}
                disabled={isLoadingBtn}
                allowLeadingZeros
                value={inputs.card_flat_max}
                required
                onChange={handleChangeInPrice}
                type="text"
                className="form-control small-font-size"
                id="card_flat_max"
                placeholder="120"
                autoComplete="off"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AddPrices;
