import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  startRatingImage,
  photoUserImage,
  whiteArrowImage,
  calendarImage,
  requestsImage,
  chatImage,
  paymentImage,
  aboutImage,
  logoutImage,
  companyProfilePicImage,
  mapIconImage,
  emailIconImaage,
  phoneIconImage,
  editBillingIconImage,
  editIconImage,
  truckImg1Image,
  truckImg2Image,
  addIconImage,
} from "../../assets";
import { Header } from "./components";
import { Link, withRouter } from "react-router-dom";
import { invokeApi, s3baseUrl } from "../../bl_libs/invokeApi";
import { useSockets } from "../../Context/Context-Sockets/ContextSockets";
import { Avatar, Badge } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import InfoIcon from "@material-ui/icons/Info";
import { Rating } from "@material-ui/lab";
import { useSnakbar } from "../../Context/Context-Snakbar/ContextSnackbar";

const Main = (props) => {
  const { userList, socketOFF, handleRefreshChatList } = useSockets();
  const { showErrorSnak, showSuccessSnak } = useSnakbar();
  const { name, profile_picture, company_rating } = JSON.parse(
    localStorage.getItem("user")
  );

  //company information from localsotorage

  const { children } = props;
  const [sideNavActive, setSideNavActive] = useState(false);
  const [active, setActive] = useState(0);

  const handleBadgeDot = () => {
    let message = userList.find((user) => user.unread_messages > 0);

    return message ? false : true;
  };

  let chatDot = handleBadgeDot();

  const logout = () => {
    let requestObj = {
      path: "/app_api/log_out",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      socketOFF();
      const welcome = localStorage.getItem("welcome");
      localStorage.clear();
      localStorage.setItem("welcome", welcome);
      props.history.push("/login");
    });
  };

  const handleClickOnSidebar = (value) => {
    if (window.innerWidth < 769) {
    }
    setSideNavActive(false);
    setActive(value);
  };

  const GetCompanyProfile = () => {
    let requestObj = {
      path: "/api/company/profile_company",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({ ...res.profile, company_rating: res.company_rating })
        );
      } else {
        //showErrorSnak(res.message);
      }
    });
  };

  useEffect(() => {
    GetCompanyProfile();
  }, []);

  useEffect(() => {
    const currentPath = props.history.location.pathname;
    console.log(currentPath);
    if (currentPath.search("/calender") > 0) {
      setActive(1);
    } else if (currentPath.search("/company-requests") > 0) {
      setActive(2);
    } else if (currentPath.search("/chats") > 0) {
      setActive(3);
    } else if (currentPath.search("/payment-history") > 0) {
      setActive(4);
    } else if (currentPath.search("/about-us") > 0) {
      setActive(5);
    } else if (currentPath.search("/bank-account") > 0) {
      setActive(6);
    } else {
      setActive("");
    }
  }, [props.history.location.pathname]);

  return (
    <>
      <div className="">
        <Header />
        <div className="wrapper">
          <main className="w-100">{children}</main>
        </div>
      </div>
      <div
        className="modal fade"
        id="logout"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="logout-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-30">
            <div className="modal-header text-center border-0 pb-0">
              <button
                type="button"
                className="close black-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
              <div className="modal-body p-0">
                <h5 className="text-center" id="logout-title">
                  Log out
                </h5>
                <p className="text-center sub-title pb-4 mb-3 pt-40">
                  Are your sure you want to logout?
                </p>
                <div className="row justify-content-center text-center">
                  <div className="col-6">
                    <button
                      onClick={logout}
                      data-dismiss="modal"
                      aria-label="Close"
                      className="custom-btn-small custom-buton float-right"
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="custom-btn-small custom-buton custom-button-pink float-left"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default withRouter(Main);
