import React, { useEffect, useState } from "react";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { filterImage, requestsImage } from "../../assets";
import { BarChart, BasicTable, CardBox, PaiChart } from "./components";
import { invokeApi } from "../../bl_libs/invokeApi";
import { CircularProgress } from "@material-ui/core";
import StatsFilter from "./components/StatsFilter";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { set } from "date-fns/esm";
// icons
const requestImage = <img src={requestsImage} alt="" />;
const dolor = <MonetizationOnIcon style={{ color: "black" }} />;
// colors
const colors = {
  light_green: "#55b42d",
  dark_green: "#22886e",
  pink: "#e28495",
  dark_pink: "#c4314b",
  crm_color: "rgb(219, 68, 55)",
  market_place_color: "rgb(66, 133, 244)",
};

function CompanyStats() {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState(
    localStorage.getItem("dashboardFilter")
      ? JSON.parse(localStorage.getItem("dashboardFilter")).options
      : ""
  );
  const [showFilter, setShowFilter] = useState(false);
  // const [customDate, setCustomDate] = useState(false);
  // const [dropdownValue, setDropdownValue] = useState("30");
  // const [customStartDate, setCustomStartDate] = useState(
  // new Date(moment().subtract(30, "days"))
  // );
  // const [customEndDate, setCustomEndDate] = useState(new Date(moment()));
  // const [customDays, setCustomDays] = useState(
  //   moment(customEndDate).diff(moment(customStartDate), "days")
  // );
  // const [customType, setCustomType] = useState(
  //   customDays > 90 ? "years" : customDays > 30 ? "months" : "days"
  // );
  const [stats, setStats] = useState({
    all_payment: "",
    available_payment: "",
    count_active_request: "",
    count_all_request: "",
    count_cancelled_request: "",
    count_completed_request: "",
    count_final_amount_of_crm_request: "",
    count_final_amount_of_workplace_request: "",
    count_final_amount_request: "",
    count_request_crm: "",
    count_request_workplace: "",
    in_review_payment: "",
    move_request: [],
    move_size_Servies_array: [],
    work_in_progress_payment: "",
  });
  const [filter, setFilter] = useState(
    localStorage.getItem("dashboardFilter")
      ? JSON.parse(localStorage.getItem("dashboardFilter")).filter
      : {
          type: "days",
          start_date: "",
          end_date: "",
          day: "",
        }
  );
  const getFormattedDates = (day = 0, type = "days") => {
    const end_date = moment().subtract(0, "days").format("YYYY-MM-DD");
    const start_date = moment().subtract(day, "days").format("YYYY-MM-DD");
    return { start_date, end_date, day, type };
  };
  const fetchStates = () => {
    if (filter.start_date === "") return;
    if (typeof filter.day != "string") {
      filter.day = `${filter.day}`;
    }
    let requestObj = {
      path: "/api/company/company_dashboard",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    const postData = filter;

    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        setStats(res.data);
        setIsLoading(false);
      } else {
      }
    });
  };
  useEffect(() => {
    if (!localStorage.getItem("dashboardFilter")) {
      const data = getFormattedDates(30, "days");
      setFilter(data);
    }
  }, []);
  useEffect(() => {
    fetchStates();
  }, [filter]);

  const handleClear = async () => {
    setOptions("");
    localStorage.removeItem("dashboardFilter");
    const data = getFormattedDates(365, "years");
    setFilter(data);
    setShowFilter(false);
  };
  if (isLoading) {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper calendar-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title"></p>
            <div className="container-fluid">
              <div className="Circular-Progress">
                <CircularProgress />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  return (
    <section id="content-wrapper" className="content-wrapper calendar-page">
      <div className="container-fluid">
        <div className="calendar-wrapper mt-3 mt-m-2 pb-30">
          <div className="row">
            <div className="col-8">
              <p className="small-text-bold page-title">Company Stats</p>
            </div>
            <div className="col-4">
              <span
                className="black-color pointer-point float-right"
                data-toggle="modal"
                data-target="#stats-filter"
              >
                <img src={filterImage} />
                <span className="pl-2">Filter</span>
              </span>
            </div>
            {localStorage.getItem("dashboardFilter") && (
              <div className="col-12">
                <div className="float-right py-1 px-2" style={{"backgroundColor":"#e3b6ba", "borderRadius":"20px"}}>
                  {JSON.parse(localStorage.getItem("dashboardFilter")).options === "7" && <>Last 7 Days</>}
                  {JSON.parse(localStorage.getItem("dashboardFilter")).options === "30" && <>Last 30 Days</>}
                  {JSON.parse(localStorage.getItem("dashboardFilter")).options === "90" && <>Last 3 Months</>}
                  {JSON.parse(localStorage.getItem("dashboardFilter")).options === "custom" && (
                    <>
                      {moment(filter.start_date).format("ll") +
                        " to " +
                        moment(filter.end_date).format("ll")}
                    </>
                  )}
                {/* <a onClick={handleClear}><i class="close fas fa-times ml-2 mt-1" style={{width:"16px", height:"16px"}} /></a> */}
                </div>
              </div>
            )}
            <div className="col-12 col-md-6 col-lg-4 mt-3">
              <CardBox
                icon={requestImage}
                title="TOTAL REQUESTS"
                count={stats.count_all_request}
                color={colors.dark_pink}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-3">
              <CardBox
                icon={requestImage}
                title="ACTIVE REQUESTS"
                count={stats.count_active_request}
                color={colors.light_green}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-3">
              <CardBox
                icon={requestImage}
                title="COMPLETED REQUESTS"
                count={stats.count_completed_request}
                color={colors.dark_green}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-3">
              <CardBox
                icon={requestImage}
                title="CANCELLED REQUESTS"
                count={stats.count_cancelled_request}
                color={colors.pink}
              />
            </div>

            <div className="col-12 col-md-6 col-lg-4 mt-3">
              <CardBox
                icon={requestImage}
                title="MARKET PLACE REQUESTS"
                count={stats.count_request_workplace}
                color={colors.market_place_color}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-3">
              <CardBox
                icon={requestImage}
                title="CRM REQUESTS"
                count={stats.count_request_crm}
                color={colors.crm_color}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-5">
              <p className="small-text-bold page-title">Payments</p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-1">
              <CardBox
                icon={dolor}
                title="MARKET PLACE REVENUE"
                count={
                  "$" + stats.count_final_amount_of_workplace_request.toFixed(2)
                }
                color={colors.dark_green}
              />
            </div>

            <div className="col-12 col-md-6 col-lg-4 mt-3">
              <CardBox
                icon={dolor}
                title="CRM REVENUE"
                count={"$" + stats.count_final_amount_of_crm_request.toFixed(2)}
                color={colors.dark_green}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-3">
              <CardBox
                icon={dolor}
                title="AVERAGE MARKET PLACE MOVE PRICE"
                count={
                  "$" +
                  stats.avg_final_amount_of_market_place_request.toFixed(2)
                }
                color={colors.dark_green}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-3">
              <CardBox
                icon={dolor}
                title="AVERAGE CRM MOVE PRICE"
                count={"$" + stats.avg_final_amount_of_crm_request.toFixed(2)}
                color={colors.dark_green}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-5">
              <p className="small-text-bold page-title">Move Requests</p>
            </div>

            <div className="col-12 mt-2">
              <BarChart move_request={stats.move_request} filter={filter} />
            </div>

            <div className="col-12 mt-5">
              <p className="small-text-bold page-title">Move Size Services</p>
            </div>
            <div className="col-12 mt-2">
              <BasicTable move_size_data={stats.move_size_Servies_array} />
            </div>
          </div>
        </div>
      </div>
      <StatsFilter
        filter={filter}
        setFilter={setFilter}
        options={options}
        setOptions={setOptions}
        setShowFilter={setShowFilter}
        handleClear={handleClear}
      />
    </section>
  );
}

export default CompanyStats;
