import React from "react";
import { Redirect, Link } from "react-router-dom";
import logo from "../../../../assets/images/logo.png";
import { logoImage, navBtnImage } from "../../../../assets";

export default function Headers() {
  return (
    <div className="main-bg">
      <header>
        <div className="container-fluid">
          <div className="mobile-toggle-button position-fixed">
            {localStorage.getItem("token") && (
              <button type="button" id="sidebarCollapse2" className="btn">
                <img src={navBtnImage} />
              </button>
            )}
          </div>
        </div>
        <div className="container-fluid">
          <div className="logo logo-center">
            <Link to="/">
              <img src={logo} height={"48 px"} alt="LA Moving Star" title="LA Moving Star" />
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
}
