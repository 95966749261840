export const validatePasswordStrength = (pass) => {
  console.log(pass);
  var matchedCase = new Array();
  matchedCase.push("[$@$!%*#?&]"); // Special Charector
  matchedCase.push("[A-Z]"); // Uppercase Alpabates
  matchedCase.push("[0-9]"); // Numbers
  // matchedCase.push("[a-z]"); // Lowercase Alphabates
  var ctr = 0;
  for (var i = 0; i < matchedCase.length; i++) {
    if (new RegExp(matchedCase[i]).test(pass)) {
      ctr++;
    }
  }
  var color = "";
  var strength = "";
  switch (ctr) {
    case 0:
    case 1:
    case 2:
      strength = "Very Weak";
      color = "red";
      break;
    case 3:
      strength = "Medium";
      color = "orange";
      break;
    case 4:
      strength = "Strong";
      color = "green";
      break;
  }
  return {
    color,
    strength,
    level: ctr,
  };
};
