import { CircularProgress, Snackbar } from "@material-ui/core";
import { id } from "date-fns/esm/locale";
import React from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import { subtractImage, addImage } from "../../../../../../assets";
import { invokeApi } from "../../../../../../bl_libs/invokeApi";
import MuiAlert from "@material-ui/lab/Alert";
import Alert from "@material-ui/lab/Alert";
function NewRequest(props) {
  const { fetchRequestDetails, requestData } = props;
  const { customer_budget, recommend_amount, id, customer_movers } =
    requestData.request;

  const [inputs, setInputs] = useState({
    id: props.match.params.requestId,
    movers: customer_movers,
    your_price: "",
  });

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [errMessage, setErrMessage] = React.useState("");

  const handleSubtract = () => {
    if (isLoadingBtn) return;
    if (inputs.movers <= 1) return;
    setInputs({ ...inputs, movers: inputs.movers - 1 });
  };

  const handleAdd = () => {
    if (isLoadingBtn) return;
    setInputs({ ...inputs, movers: inputs.movers + 1 });
  };

  const handleChangeInPrice = (e) => {
    setInputs({ ...inputs, your_price: e.target.value });
  };

  const placeBid = () => {
    let requestObj = {
      path: "/api/company/palce_bid",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${inputs.id}`,
      bid_amount: `${inputs.your_price}`,
      movers: `${inputs.movers}`,
    };
    setIsLoadingBtn(true);

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log(res, "Api Response");

      if (res.code === 200) {
        fetchRequestDetails();
      } else {
        setErrMessage(res.message);
        setIsLoadingBtn(false);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (parseInt(inputs.your_price) < 1) {
      setErrMessage("Please ender a valid price");
      return;
    }
    if (parseInt(inputs.movers) < 1) {
      setErrMessage("Please ender movers");
      return;
    }
    setErrMessage("");

    placeBid();
  };

  return (
    <>
      <div className="rounded-box">
        <div className="heading-wrapper black-bg text-center">
          <h6 className="text-white">Request #{id}</h6>
        </div>
        {errMessage && (
          <Alert variant="filled" severity="error">
            {errMessage}
          </Alert>
        )}
        <div className="request-left-box p-3 p-sm-4">
          <div className="row text-center">
            <div className="col-12">
              <div className="request-staus small-font-size">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#C4314B" />
                </svg>
                {" New Request"}
              </div>
              <div className="bid-instructions small-sub-title pt-20">
                Please, fill this form to place bid
              </div>
            </div>
          </div>
          <div className="bid-form-wrapper pt-3">
            <form onSubmit={handleSubmit}>
              <div className="row pt-2">
                <div className="col-12">
                  <label htmlFor="email" className="overline dark-grey-color">
                    Movers
                  </label>
                </div>
                <div className="col-12">
                  <img
                    className="pointer-point"
                    onClick={handleSubtract}
                    src={subtractImage}
                  />
                  <span className="movers-count-box small-font-size black-color text-center">
                    {inputs.movers}
                  </span>

                  <img
                    className="pointer-point"
                    onClick={handleAdd}
                    src={addImage}
                  />
                </div>
              </div>
              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color">
                  Estimated price:
                </div>
                <div className="col-6 text-right black-color">
                  ${recommend_amount}
                </div>
              </div>
              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color">
                  Client price:
                </div>
                <div className="col-6 text-right black-color">
                  ${customer_budget}{" "}
                </div>
              </div>
              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color">
                  Requested Movers:
                </div>
                <div className="col-6 text-right black-color">
                  {customer_movers}{" "}
                </div>
              </div>
              <div className="row small-font-size pt-3">
                <div className="col-6 text-left dark-grey-color">
                  Your price:
                </div>
                <div className="col-6 text-right black-color">
                  <NumberFormat
                    allowNegative={false}
                    disabled={isLoadingBtn}
                    allowLeadingZeros
                    value={inputs.your_price}
                    required
                    onChange={handleChangeInPrice}
                    type="text"
                    className="form-control small-font-size"
                    id="your-price"
                    placeholder="100"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="row text-center pt-4">
                <div className="col-12 caption light-grey-color">
                  "Your price" is determined based on the info client provided.
                  If during the move client would have more items then "Your
                  price" may be increased accordingly.
                </div>
              </div>
              <div className="row pt-3 pb-3">
                <div className="col-12 ">
                  {isLoadingBtn ? (
                    <div className="text-center p-1" style={{ margin: 2 }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <button
                      className="custom-buton custom-buton-large w-100"
                      data-toggle="modal"
                      data-target="#bid-placed"
                      type="submit"
                    >
                      Place Bid
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(NewRequest);
