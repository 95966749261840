import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

const PrivateRouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  if (!localStorage.getItem("user")) {
    const welcome = localStorage.getItem("welcome");
    localStorage.clear();
    localStorage.setItem("welcome", welcome);
  }

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login"></Redirect>;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default PrivateRouteWithLayout;
