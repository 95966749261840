import React from "react";
import { withRouter } from "react-router-dom";
import { congratulationsImage } from "../../../../assets";
import { DirectLinkUrl } from "../../../../bl_libs/invokeApi";
import { CopyToClipboard } from "../../../../components";
function Welcome(props) {
  const { company_direct_access_link } = JSON.parse(
    localStorage.getItem("user")
  );
  return (
    <>
      <section className="main-content address-page">
        <div className="container">
          <div className="add-items-wrapper pt-100 pb-5">
            <div className="to-do-img text-center pb-40">
              <img src={congratulationsImage} />
            </div>
            <div className="row text-center justify-content-center">
              <div className="col-12 col-sm-8 small-font-size text-center">
                <h4 className="text-center text-uppercase">Congrats</h4>
                <p
                  className="light-grey-color font-weight-bod"
                  style={{ fontSize: 20, lineHeight: 1.5 }}
                >
                  Congrats on start using the LA Moving Star moving software , in order to
                  see your direct requests and start moving with clients
                  directly please share your direct link with client.
                </p>
              </div>
            </div>
            <div className="row pt-30 justify-content-center">
              <div className="col-12 text-center col-sm-8 col-md-6">
                <CopyToClipboard
                  content={DirectLinkUrl + company_direct_access_link}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(Welcome);
