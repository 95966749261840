import React, { useState } from "react";
import GoogleMapTracking from "./google-Map-Tracking/GoogleMapTracking";

function Destination(props) {
  const [addressB, setAddressB] = useState({
    zip_code: props.addressB.zip_code,
    address: props.addressB.address,
    lat: props.addressB.lat,
    lng: props.addressB.lng,
  });

  const handleClick = () => {
    props.setAddressB(addressB);
    if (addressB.address !== "") {
      props.setIsAddressBEmpty(false);
    }
    props.closePopUp();
  };
  return (
    <>
      <div className="form-wrapper pl-4 pr-4 pl-sm-5 pr-sm-5">
        <div className="modal-body p-0">
          {/* <h5 className="text-center" id="change-password-title">
                Destination Address
              </h5> */}
          <div className="form-box">
            <div className=" " style={{}}>
              <GoogleMapTracking address={addressB} setAddress={setAddressB} />
            </div>
            <div className="form-group pt-3">
              <button
                type="button"
                className="red-btn custom-buton custom-buton-large w-100"
                onClick={handleClick}
              >
                Set
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Destination;
