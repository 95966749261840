import React from "react";
import { filterImage } from "../../assets";
import "date-fns";
import ActiveRequests from "./components/Active-Requests/ActiveRequests";
import { useState } from "react";
import CancelRequests from "./components/Cancel-Requests/CancelRequests";
import DoneRequests from "./components/Done-Requests/DoneRequests";
import ActiveFilter from "./components/Active-Requests/ActiveFilter";
import CancelRequestsFilter from "./components/Cancel-Requests/CancelRequestsFilter";
import DoneRequestFilter from "./components/Done-Requests/DoneRequestFilter";
import RejectedRequest from "./components/Rejected-Request/RejectedRequest";

function CompanyRequests() {
  const [requestType, setRequestType] = useState("active");

  return (
    <>
      <section id="content-wrapper" className="content-wrapper calendar-page">
        <div className="container-fluid min-width-650">
          <p className="small-text-bold page-title">Company requests</p>
          <div className="calendar-wrapper mt-40 mt-m-20 pb-30">
            <div className="row">
              <div className="col-8 text-left">
                <div className="status-buttons mb-3">
                  <span
                    onClick={() => setRequestType("active")}
                    className={
                      requestType === "active"
                        ? "custom-btn-small custom-button-extra-small req-status-button req-active active"
                        : "custom-btn-small custom-button-extra-small req-status-button filter-button req-active"
                    }
                  >
                    Active
                  </span>
                  <span
                    onClick={() => setRequestType("cancelled")}
                    className={
                      requestType === "cancelled"
                        ? "custom-btn-small custom-button-extra-small req-status-button filter-button req-cancelled  active"
                        : "custom-btn-small custom-button-extra-small req-status-button filter-button req-cancelled "
                    }
                  >
                    Cancelled
                  </span>
                  <span
                    onClick={() => setRequestType("done")}
                    className={
                      requestType === "done"
                        ? "custom-btn-small custom-button-extra-small req-status-button filter-button req-done mt-2 mt-sm-0 active"
                        : "custom-btn-small custom-button-extra-small req-status-button filter-button req-done mt-2 mt-sm-0"
                    }
                  >
                    Done
                  </span>
                  <span
                    onClick={() => setRequestType("rejected")}
                    className={
                      requestType === "rejected"
                        ? "custom-btn-small custom-button-extra-small req-status-button filter-button req-cancelled mt-2 mt-sm-0 active"
                        : "custom-btn-small custom-button-extra-small req-status-button filter-button req-cancelled mt-2 mt-sm-0"
                    }
                  >
                    Rejected
                  </span>

                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="col-4 text-right">
                {requestType !== "rejected" && (
                  <span
                    className="black-color pointer-point"
                    data-toggle="modal"
                    data-target="#change-filter"
                  >
                    <img src={filterImage} />
                    <span className="pl-2">Filter</span>
                  </span>
                )}
              </div>
            </div>
            {/* // */}
            {requestType === "active" && <ActiveRequests />}
            {requestType === "cancelled" && <CancelRequests />}
            {requestType === "done" && <DoneRequests />}
            {requestType === "rejected" && <RejectedRequest />}
          </div>
        </div>
      </section>
      {/* {requestType === "active" && <ActiveFilter />} */}
      {/* {requestType === "cancelled" && <CancelRequestsFilter />} */}
      {/* {requestType === "done" && <DoneRequestFilter />} */}
    </>
  );
}

export default CompanyRequests;
