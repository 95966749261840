import React from "react";
import { useState } from "react";

function EditRoomName(props) {
  const [room, setRoom] = useState(props.room);

  const handleSubmit = (e) => {
    e.preventDefault();

    props.handleUpdateRoomName(room);
    props.closePopUp();
  };
  return (
    <div className="form-wrapper pl-4 pr-4 pl-sm-4 pr-sm-4">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <label className="overline dark-grey-color">Room Name</label>
            <input
              required
              type="text"
              className="form-control small-font-size"
              id="truck-name"
              placeholder="Enter room name"
              value={room.room_name}
              onChange={(e) => setRoom({ ...room, room_name: e.target.value })}
            />
          </div>
        </div>
        <div className="row pt-30 justify-content-center">
          <div className="col-12">
            <button
              type="submit"
              className="red-btn custom-buton custom-buton-large w-100"
            >
              Done
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditRoomName;
