import React, {Component} from 'react';
import Intro from "./components/intro.component";
import Menu from "./components/menu.component";

class HomePage extends Component {
    render() {
        return (
            <div>
                <Intro/>

                <Menu/>
            </div>
        );
    }
}

export default HomePage;