import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { blackBackArrowImage } from "../../assets";
import { invokeApi } from "../../bl_libs/invokeApi";
import ChangeInAddress from "./components/Change-In-Address/ChangeInAddress";
import ChangeInExtraItems from "./components/Change-In-ExtraItems/ChangeInExtraItems";
import ChangeInItems from "./components/Change-In-Items/ChangeInItems";

function EditRequestAcceptance(props) {
  const [requestData, setRequestData] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  //address Confirmation
  const AddressConfirmation = (response) => {
    let requestObj = {
      path: "/api/company/change_address_confirmation",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      request_id: `${props.match.params.requestId}`,
      status: response,
    };

    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        props.history.goBack();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  //change in Items Confirmation
  const ItemsConfirmation = (response) => {
    let requestObj = {
      path: "/api/customer/change_item_confirmation",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      request_id: `${props.match.params.requestId}`,
      status: response,
    };

    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        props.history.goBack();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  //Extra Items Confirmation
  const ExtraItemsConfirmation = (response) => {
    let requestObj = {
      path: "/api/customer/change_extra_item_confirmation",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let postData = {
      request_id: `${props.match.params.requestId}`,
      status: response,
    };

    requestObj["postData"] = postData;
    setIsLoading(true);
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        props.history.goBack();
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  //fetch request details using requestt id
  const fetchRequestDetails = (req_Id) => {
    let requestObj = {
      path: "/api/company/company_request_detail",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    let postData = {
      request_id: `${req_Id}`,
    };

    requestObj["postData"] = postData;
    invokeApi(requestObj).then((res) => {
      console.log("RESPONSE", res);
      if (res.code === 200) {
        //check and verify status of request is 2 (pending acceptance)
        //if not redirect to listing request
        setRequestData(res);
        setIsLoading(false);
      } else {
        setErrMessage(res.message);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchRequestDetails(props.match.params.requestId);
  }, []);
  if (isLoading) {
    return (
      <section id="content-wrapper" className="content-wrapper rating-page">
        <div className="container-fluid">
          <p className="small-text-bold page-title ">
            <img
              className="pointer-point"
              src={blackBackArrowImage}
              onClick={() => props.history.goBack()}
            />
            <span>Request DETAIL</span>
          </p>
          <div className="container-fluid">
            <div className="Circular-Progress">
              <CircularProgress />
            </div>
          </div>
        </div>
      </section>
    );
  }

  //if change accure in address

  if (props.match.params.type === "address") {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper rating-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title ">
              <img
                className="pointer-point"
                src={blackBackArrowImage}
                onClick={() => props.history.goBack()}
              />
              <span>Request DETAIL</span>
            </p>

            <ChangeInAddress
              requestData={requestData}
              AddressConfirmation={AddressConfirmation}
              errMessage={errMessage}
            />
          </div>
        </section>
      </>
    );
  }

  //if change accure in items

  if (props.match.params.type === "items") {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper rating-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title ">
              <img
                className="pointer-point"
                src={blackBackArrowImage}
                onClick={() => props.history.goBack()}
              />
              <span>Request DETAIL</span>
            </p>

            <ChangeInItems
              requestData={requestData}
              ItemsConfirmation={ItemsConfirmation}
              errMessage={errMessage}
            />
          </div>
        </section>
      </>
    );
  }
  //if change accure in Extra items

  if (props.match.params.type === "extra-items") {
    return (
      <>
        <section id="content-wrapper" className="content-wrapper rating-page">
          <div className="container-fluid">
            <p className="small-text-bold page-title ">
              <img
                className="pointer-point"
                src={blackBackArrowImage}
                onClick={() => props.history.goBack()}
              />
              <span>Request DETAIL</span>
            </p>

            <ChangeInExtraItems
              requestData={requestData}
              ExtraItemsConfirmation={ExtraItemsConfirmation}
              errMessage={errMessage}
            />
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(EditRequestAcceptance);
