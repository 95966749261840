import React from "react";
import { Alert } from "@material-ui/lab";
function ChangeInExtraItems(props) {
  const { ExtraItemsConfirmation, errMessage } = props;

  let { extra_request_items, change_item_extra } = props.requestData;

  extra_request_items = extra_request_items.filter(
    (item) => item.quantity !== 0
  );
  change_item_extra = change_item_extra.filter((item) => item.quantity !== 0);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6" id="onebox">
          <div className=" rounded-box items-info-wrapper mt-2 ">
            <div className="info-header text-center">
              <h6 className="black-color">Previous Extra Items List</h6>
            </div>
            <div className="info-detail-wrapper p-3 pt-20">
              {extra_request_items.map((item, i) => (
                <div key={i} className="row pt-1 ">
                  <div className="col-9 text-left small-font-size">
                    {item.name}
                  </div>
                  <div className="col-3 small-sub-title">{item.quantity} </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 mt-4 mt-lg-0" id="twobox">
          <div className="rounded-box items-info-wrapper mt-2">
            <div className="info-header text-center">
              <h6 className="black-color">Changed Extra Items List</h6>
            </div>
            {errMessage && (
              <Alert variant="filled" severity="error">
                {errMessage}
              </Alert>
            )}
            <div className="info-detail-wrapper p-3 pt-20">
              {change_item_extra.map((item, i) => (
                <div key={i} className="row pt-1">
                  <div className="col-9 text-left small-font-size">
                    {item.name}
                  </div>
                  <div className="col-3 small-sub-title">{item.quantity} </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-3"></div>
        <div className="col-6 mt-4  justify-content-center d-flex">
          <button
            className="custom-btn-small custom-buton m-4"
            onClick={() => ExtraItemsConfirmation("1")}
          >
            Accept
          </button>
          <button
            className="custom-btn-small custom-buton m-4"
            onClick={() => ExtraItemsConfirmation("0")}
          >
            Reject
          </button>
        </div>
        <div className="col-3"></div>
      </div>
    </>
  );
}

export default ChangeInExtraItems;
